import { Component, Inject, HostListener, Input, Optional } from "@angular/core"

import { RichtextComponentRef } from "@anzar/core"
import { User } from "@backend/pyzar.api"
import { WorkerCardService } from "../../worker.module/worker-card.component"


@Component({
    selector: "rege-worker-link",
    templateUrl: "./worker-link.component.pug",
    providers: [WorkerCardService]
})
export class WorkerLinkComponent {
    public content: string
    public userId: number

    @Input()
    public set user(val: User) {
        if (this._user !== val) {
            if (this._user = val) {
                this.content = val.name.formatted
                this.userId = val.id
            } else {
                this.content = null
                this.userId = null
            }
        }
    }
    public get user(): User { return this._user }
    private _user: User

    public constructor(
        @Inject(RichtextComponentRef) @Optional() protected cmpRef: RichtextComponentRef,
        @Inject(WorkerCardService) protected readonly workerSheet: WorkerCardService) {
        if (cmpRef) {
            const params = cmpRef.params
            this.content = params.content as string
            this.userId = params.user_id as number
        }
    }

    @HostListener("tap", ["$event"])
    public onTap(event: Event) {
        event.preventDefault()
        this.workerSheet.show(this.userId)
    }
}
