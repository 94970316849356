import { Component, Inject, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { Location } from "@angular/common"
import { take } from "rxjs/operators"

import { Destructible, ToastService } from "@anzar/core"
import { AbstractCLEditor, AbstractCLEditorService } from "../common.module/components/card-list/abstract"
import { UserEditorService } from "./sheet/user-editor.service"


@Component({
    templateUrl: "./user.screen.pug",
    providers: [
        { provide: AbstractCLEditorService, useClass: UserEditorService }
    ]
})
export class UserScreen extends Destructible implements OnInit {
    @ViewChild("editor", { static: true }) public readonly editor: AbstractCLEditor

    public user$ = this.service.user$
    public newUser: boolean = false

    public constructor(
        @Inject(AbstractCLEditorService) public readonly service: UserEditorService,
        @Inject(Router) private readonly router: Router,
        @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(Location) private readonly location: Location) {
        super()

        this.destruct.subscription(this.service.id$).subscribe(id => {
            if (id) {
                this.location.replaceState(`/users/${id}`)
            }
        })
    }

    public ngOnInit() {
        this.destruct.subscription(this.route.params).subscribe(params => {
            this.service.id = Number(params["id"]) || null
            this.newUser = !this.service.id
        })
    }

    public doSave() {
        this.editor
            .save()
            .pipe(
                take(1),
                this.toast.handleSave({ align: "bottom center", successMsg: "A mentés sikeresen megtörtént" })
            )
            .subscribe(() => {

            })
    }

    public doCancel() {
        this.router.navigate(["/users"])
    }
}
