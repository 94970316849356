import { Injectable, Inject, Optional } from "@angular/core"
import { Observable, Subject, NEVER, timer } from "rxjs"
import { switchMap, map, shareReplay, catchError, takeUntil, finalize, exhaustMap, take } from "rxjs/operators"
import { parseISO } from "date-fns"

import { ToastService, ProgressEvent, ComponentLayerRef } from "@anzar/core"
import { TaskManager, TaskLogRecord, TaskResult, TaskInfo } from "@backend/pyzar.api"
import { TaskDetailsFactory } from "./task-details-factory"
import type { TaskDetailsComponent } from "./task-details.component"
import { RunningTask } from "./running-task"

export const DONE_STATUS = ["success", "failure", "cancelled"]


export interface HandleTaskOptions {
    beginMessage: string
    onError?: (err: any) => void
}


export interface TaskProgressEvent extends ProgressEvent {
    id: string
    name: string
    status: string
    logs: TaskLogRecord[]
    result?: TaskResult
}


export type TaskEvent = { type: "progress", data: TaskProgressEvent, layer: ComponentLayerRef<TaskDetailsComponent> }
    | { type: "button", role: string, layer: ComponentLayerRef<TaskDetailsComponent> }
    | { type: "layer", action: "show" | "hide", layer: ComponentLayerRef<TaskDetailsComponent> }
    | { type: "success", data: any, layer: ComponentLayerRef<TaskDetailsComponent> }
    | { type: "failure", data: any, layer: ComponentLayerRef<TaskDetailsComponent> }


@Injectable({ providedIn: "root" })
export class TaskService {
    private _infoCache: { [key: string]: Observable<TaskProgressEvent> } = {}

    public constructor(
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(TaskManager) private readonly backend: TaskManager,
        @Inject(TaskDetailsFactory) @Optional() private readonly details: TaskDetailsFactory[]) {
    }

    public start(name: string, args: { [key: string]: any } | null = null): Observable<TaskEvent> {
        return this.backend.start({ name, args }).pipe(
            this.toast.catchError(),
            switchMap(info => {
                return this._showDetails(info)

            }),
            shareReplay(1)
        )
    }

    private _showDetails(info: TaskInfo): Observable<TaskEvent> {
        return new Observable(observer => {
            const runningTask = new RunningTask(this.backend, this.toast, info.id)
            const dfactory = this.getDetailFactory(info.name)
            const layerRef = dfactory.create([
                { provide: TaskService, useValue: this },
                { provide: RunningTask, useValue: runningTask },
            ]) as ComponentLayerRef<TaskDetailsComponent>

            layerRef.subscribe(layerEvent => {
                if (layerEvent.type === "showing") {
                    observer.next({ type: "layer", action: "show", layer: layerRef })
                } else if (layerEvent.type === "destroy") {
                    observer.next({ type: "layer", action: "hide", layer: layerRef })
                    observer.complete()
                } else if (layerEvent.type === "button") {
                    observer.next({ type: "button", role: layerEvent.data, layer: layerRef })
                }
            })

            layerRef.show()

            const infoSub = runningTask.info.subscribe(rinfo => {
                if (rinfo.result != null) {
                    if (rinfo.status === "success") {
                        observer.next({ type: "success", data: rinfo.result, layer: layerRef })
                    } else if (rinfo.status === "failure") {
                        observer.next({ type: "failure", data: rinfo.result, layer: layerRef })
                    }
                }
            })

            return () => {
                layerRef?.hide()
                infoSub?.unsubscribe()
            }
        })
    }

    public getDetailFactory(name: string): TaskDetailsFactory {
        if (this.details) {
            let def = null
            for (const h of this.details) {
                if (h.name === name) {
                    return h
                } else if (h.name === "default") {
                    def = h
                }
            }
            return def
        }
        return null
    }
}
