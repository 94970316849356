import { Component, forwardRef, Inject, ChangeDetectorRef, Input } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { Router } from "@angular/router"
import { tap, take } from "rxjs/operators"

import { LayerService, ModalLayer } from "@anzar/core"
import { Provider, ProviderBackend } from "@backend/org.api"
import { LeaveReason } from "@backend/enums.api"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { ProviderEditorService } from "./provider-editor.service"
import { SectionSheetService } from "../section/section-sheet.service"


@Component({
    selector: ".rege-provider-auto-close",
    templateUrl: "./provider-auto-close.component.pug",
    host: {
        "[style.padding.px]": "16"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => ProviderAutoCloseComponent) }
    ]
})
export class ProviderAutoCloseComponent extends AbstractCLEditorBlock<ProviderEditorService> {
    @Input() public provider: Provider

    public readonly leaveReasonSrc = LeaveReason.DATA

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: ProviderEditorService,
        @Inject(ProviderBackend) private readonly providerBackend: ProviderBackend,
        @Inject(SectionSheetService) private readonly sheet: SectionSheetService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(Router) private readonly router: Router) {
        super(editorSvc, new FormGroup({
            auto_close: new FormControl(),
            auto_close_reason: new FormControl()
        }))
    }

    public ngOnInit() {
        super.ngOnInit()
        this._reset()
    }

    public save() {
        let data = this.form.value
        if (this.provider && this.provider.id) {
            data.id = this.provider.id
        }
        data.auto_close = data.auto_close || 0
        data.auto_close_reason = data.auto_close_reason

        return this.providerBackend.save({ data }).pipe(
            tap(provider => {
                this.provider = provider
                this._reset()
                this.editorSvc.providerUpdated(provider)
            }))
    }

    private _reset() {
        if (this.provider) {
            this.form.reset({
                auto_close: this.provider.auto_close,
                auto_close_reason: this.provider.auto_close_reason
            })
        }
    }
}
