import { Component, Input, Inject, OnDestroy, ChangeDetectorRef, Optional, AfterViewInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Subject, Observable } from "rxjs"
import { debounceTime, take, switchMap } from "rxjs/operators"

import { Destruct, StaticSource, NzTouchEvent } from "@anzar/core"
import { EventBackendSource, EventBackend } from "@backend/event.api"
import { Section } from "@backend/org.api"
import { AuthService } from "@pyzar/auth.module"
import { CurrentSection } from "../../section.service/level"
import { ClosingGuardService, CloseableTracker } from "../../common"
import { DISPLAY_SERVICES, DISPLAY_TEXT } from "./client-feed-entry.component"
import { ClientFeedService } from "./client-feed.service"
import { ClientSheet } from "../client-sheet.component"
import { ClientService } from "../client.service"
import { HashtagClickHandler, HashtagComponent } from "../../richtext.module"


// export const CHK_TYPES_TO_FILTER = {
//     // "care": ["care", "street_care", "dispatcher", "communal"],
//     // "service": ["service"],
//     // "data_change": ["data_change"]
//     "care": ["care"],
//     "service": ["service"],
//     "data_change": ["data_change"]
// }


class FilterByHashtag extends HashtagClickHandler {
    public feed: ClientFeedComponent

    public handle(hashtag: HashtagComponent, event: NzTouchEvent): void {
        this.feed.filterByHashtag(`#${hashtag.content}`)
    }
}


@Component({
    selector: ".rege-client-feed",
    templateUrl: "./client-feed.template.pug",
    providers: [
        { provide: HashtagClickHandler, useClass: FilterByHashtag }
    ]
})
export class ClientFeedComponent implements OnDestroy, AfterViewInit {
    public readonly destruct = new Destruct()

    @Input() public clientId: number
    public addEntryMode: boolean = false

    public typeCheckbox = new FormControl()
    public textFilter = new FormControl()
    public sectionFilter = new FormControl()

    public filter: { [key: string]: any } = {}
    private sectionsFilter: { [key: string]: any } = {}
    public displayList: boolean = false
    public displayMode: number = 0

    private readonly _filterChanged = this.destruct.subject(new Subject<void>())
    public readonly sectionSrc = new StaticSource(Section as any, [])

    public canCreateEvent$: Observable<boolean> = this.section.id$.pipe(
        switchMap(sectionId => {
            return this.auth.hasPermission({
                permission: "event.care.create",
                extra: { level_id: sectionId || "any" }
            })
        })
    )

    public constructor(
        @Inject(EventBackendSource) public readonly source: EventBackendSource,
        @Inject(EventBackend) private readonly eventBackend: EventBackend,
        @Inject(ChangeDetectorRef) public readonly cdr: ChangeDetectorRef,
        @Inject(ClientFeedService) private readonly clientFeddSvc: ClientFeedService,
        @Inject(AuthService) public readonly auth: AuthService,
        @Inject(CurrentSection) public readonly section: CurrentSection,
        @Inject(ClosingGuardService) @Optional() closingGuard: ClosingGuardService,
        @Inject(ClientSheet) @Optional() sheet: ClientSheet,
        @Inject(HashtagClickHandler) htClickHandler: FilterByHashtag) {
        htClickHandler.feed = this

        this.destruct.subscription(this._filterChanged).subscribe(x => {
            this.filter = { ...this.filter }
            this.cdr.detectChanges()
        })

        this.destruct.subscription(this.typeCheckbox.valueChanges).subscribe(value => {
            if (value.length !== 0) {
                // this.sectionsFilter.type = this.filter.type = { "in": types }
                this.sectionsFilter.types = this.filter.types = value
            } else {
                delete this.filter.types
                delete this.sectionsFilter.types
            }
            this.displayList = true
            this.displayMode = 0

            if (value.indexOf("care") > -1 || value.indexOf("data_change") > -1) {
                this.displayMode |= DISPLAY_TEXT
            }
            if (value.indexOf("service") > -1) {
                this.displayMode |= DISPLAY_SERVICES
            }

            this._filterChanged.next()
            this.updateSectionsFilter()
        })

        this.destruct.subscription(this.textFilter.valueChanges).pipe(debounceTime(400)).subscribe(value => {
            if (value && value.length) {
                this.filter["search_text"] = value
            } else {
                delete this.filter["search_text"]
            }
            this._filterChanged.next()
        })

        this.destruct.subscription(this.sectionFilter.valueChanges).subscribe(value => {
            if (value) {
                this.filter.section_id = value
            } else {
                delete this.filter.section_id
            }
            this._filterChanged.next()
        })

        if (closingGuard) {
            const closeable = closingGuard.closeableTracker("Nem mentetted el az új gondozási bejegzyésed! Biztosan bezárod?", (close) => {
                if (!close && sheet) {
                    sheet.selectedTabIndex = ClientService.TAB_NEWS
                }
            })
            closeable.determine = () => {
                return !this.addEntryMode
            }
            this.destruct.disposable(closeable)
        }
    }

    public ngAfterViewInit() {
        this.sectionsFilter.client_id = this.filter.client_id = this.clientId
        this._filterChanged.next()
        this.typeCheckbox.setValue(["care"])
    }

    public startAddEntry() {
        this.addEntryMode = true
    }

    public cancelAddEntry() {
        this.addEntryMode = false
    }

    public export() {
        this.clientFeddSvc.export(this.typeCheckbox.value, this.sectionFilter.value, null, this.clientId)
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    public filterByHashtag(value: string) {
        this.textFilter.setValue(value)
    }

    private updateSectionsFilter() {
        this.eventBackend.get_sections({ filter: this.sectionsFilter })
            .pipe(take(1))
            .subscribe(sections => {
                let existing = sections.find(s => s.id === this.sectionFilter.value)
                if (!existing) {
                    this.sectionFilter.setValue(null)
                }
                this.sectionSrc.replace(sections.sort((a, b) => a.title.localeCompare(b.title)) as any)
            })
    }
}
