import { Component, Injectable, Inject, ChangeDetectorRef } from "@angular/core"
import { take, shareReplay } from "rxjs/operators"

import { LayerService, DialogService, ModalLayer, LayerRef } from "@anzar/core"

import { WorkerRole } from "@backend/enums.api"
import { Level, LevelBackend } from "@backend/org.api"
import { User } from "@backend/pyzar.api"
import { WorkerBackend } from "@backend/worker.api"
import { FsService } from "@pyzar/fs.module"
import { AuthService } from "@pyzar/auth.module"


@Injectable({ providedIn: "root" })
export class WorkerCardService {
    public constructor(
        @Inject(LayerService) public readonly layerSvc: LayerService,
        @Inject(DialogService) public readonly dialogSvc: DialogService,
        @Inject(WorkerBackend) public readonly workers: WorkerBackend) {
    }

    public show(workerId: number) {
        this.workers.get({ id: workerId })
            .subscribe(worker => {
                const ref = this.layerSvc.createFromComponent(
                    WorkerCardComponent,
                    new ModalLayer({ backdrop: { type: "filled", hideOnClick: true }, minHeight: 130 }),
                    null,
                    [{ provide: User, useValue: worker }])
                ref.show()
            })
    }
}


@Component({
    selector: "rege-worker-card",
    templateUrl: "./worker-card.component.pug"
})
export class WorkerCardComponent {
    public workerImage: string

    public _levels: Array<[Level, WorkerRole]>

    public isAdmin$ = this.auth
        .hasPermission({ group: "admin", userId: this.worker.id })
        .pipe(shareReplay(1))

    public constructor(
        @Inject(User) public readonly worker: User,
        @Inject(AuthService) public readonly auth: AuthService,
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(LayerRef) protected readonly layerRef: LayerRef,
        @Inject(LevelBackend) levelBackend: LevelBackend,
        @Inject(FsService) fsService: FsService) {

        if (worker && worker.profile_image_id) {
            this.workerImage = fsService.getImageUrl(worker.profile_image_id, 100, 100)
        } else {
            this.workerImage = null
        }

        levelBackend.worker_levels({ user_id: worker.id, variant: "section" })
            .pipe(take(1)).subscribe(levels => {
                this._levels = levels
                this.cdr.markForCheck()
            })
    }

    public close() {
        this.layerRef.hide()
    }
}
