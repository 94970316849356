import { Component, Inject, Optional } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { take, switchMap, map, tap } from "rxjs/operators"

import { LayerRef, ToastService, StaticSource, Model, Field } from "@anzar/core"
import { ClientBanBackend } from "@backend/documents.api"
import { LevelBackend, Level } from "@backend/org.api"
import { Client } from "@backend/client.api"
import { SectionService } from "../../../section.service"
import { CurrentSection } from "../../../section.service/level"
import { DOCUMENT_FORM_DATA } from "../documents.service"


class LevelItem extends Model {
    @Field({ primary: true }) public id: number
    @Field() public label: string
}


@Component({
    selector: "rege-doc-form-ban",
    templateUrl: "./ban-form.component.pug"
})
export class ClientBanFormComponent {
    public readonly form = new FormGroup({
        id: new FormControl(),
        begin: new FormControl(new Date()),
        end: new FormControl(),
        level_id: new FormControl(),
        description: new FormControl(),
        policy_violation: new FormControl(),
    })

    public readonly levelSrc = new StaticSource(LevelItem, [])

    public constructor(
        @Inject(Client) private readonly client: Client,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(ClientBanBackend) private readonly clientBanBackend: ClientBanBackend,
        @Inject(LevelBackend) private readonly levelBackend: LevelBackend,
        @Inject(SectionService) private readonly sectionSvc: SectionService,
        @Inject(CurrentSection) currentSection: CurrentSection,
        @Inject(DOCUMENT_FORM_DATA) @Optional() private readonly initialData: any) {

        this.sectionSvc.ownSections$
            .pipe(
                take(1),
                switchMap(sections => {
                    let ids = sections.map(s => s.id)
                    return this.levelBackend
                        .search({
                            filter: { parent_id: { "in": ids } }
                        })
                        .pipe(take(1), map(res => [sections, res]))
                }),
                tap(([sections, providers]) => {
                    let providerGroups: { [key: number]: Level[] } = {}
                    for (const provider of providers) {
                        if (providerGroups[provider.parent_id]) {
                            providerGroups[provider.parent_id].push(provider)
                        } else {
                            providerGroups[provider.parent_id] = [provider]
                        }
                    }

                    let result: LevelItem[] = []
                    for (const sectionId in providerGroups) {
                        const section = (sections as Level[]).find(s => s.id === Number(sectionId))
                        const providers = providerGroups[sectionId]
                        result.push(new LevelItem({ id: section.id, label: section.title }))
                        if (providers.length > 1) {
                            for (const provider of providers) {
                                result.push(new LevelItem({ id: provider.id, label: `${section.title} / ${provider.title}` }))
                            }
                        }
                    }

                    result.sort((a, b) => a.label.localeCompare(b.label))
                    this.levelSrc.replace(result)
                }),
                switchMap(_ => currentSection.id$.pipe(take(1))),
                tap(sectionId => {
                    if (this.initialData) {
                        this.form.reset(this.initialData)
                    } else {
                        this.form.get("level_id").setValue(sectionId)
                    }
                })
            )
            .subscribe()
    }

    public save() {
        this.clientBanBackend
            .ban({
                id: this.form.value.id,
                client_id: this.client.id,
                level_id: this.form.value.level_id,
                begin: this.form.value.begin,
                end: this.form.value.end,
                description: this.form.value.description,
                policy_violation: this.form.value.policy_violation,
            })
            .pipe(take(1), this.toastSvc.handleSave({ align: "bottom center" }))
            .subscribe(() => {
                this.layerRef.close()
            })
    }

    public cancel() {
        this.layerRef.close()
    }
}
