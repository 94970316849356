import { Component, Inject, ChangeDetectorRef, Input, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Observable, of } from "rxjs"
import { distinctUntilChanged, switchMap } from "rxjs/operators"

import { Destructible, CropRegion, ImageRef, UploadedFile } from "@anzar/core"
import { FileBackend, File as FsFile } from "@backend/pyzar.api"
import { ImageCropService } from "../image-crop/image-crop.service"
import { FsService } from "@pyzar/fs.module"



interface PicMeta {
    crop: CropRegion
}



@Component({
    selector: ".rege-profile-image-input",
    templateUrl: "./profile-image-input.component.pug"
})
export class ProfileImageInputComponent extends Destructible implements OnInit {
    @Input() public readonly control: FormControl
    @Input() public previewCircle: boolean = true
    @Input() public previewRect: boolean = true

    public get isEmpty(): boolean { return !this.fileInput.value }

    public readonly fileInput = new FormControl()

    public set picMeta(val: PicMeta) {
        if (this._picMeta !== val) {
            this._picMeta = val
            this.cdr.markForCheck()
        }
    }
    public get picMeta(): PicMeta { return this._picMeta }
    private _picMeta: PicMeta

    public set crop(val: CropRegion) {
        if (this._crop !== val) {
            this._crop = val

            if (val) {
                if (!this._picMeta) {
                    this._picMeta = {} as any
                }
                this._picMeta.crop = val
            } else {
                if (this._picMeta) {
                    delete this._picMeta.crop
                }
            }

            this.cdr.markForCheck()
        }
    }
    public get crop(): CropRegion { return this._crop }
    private _crop: CropRegion

    public readonly image = new ImageRef()

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(ImageCropService) private readonly cropSvc: ImageCropService,
        @Inject(FileBackend) private readonly fileBackend: FileBackend,
        @Inject(FsService) private readonly fsService: FsService) {
        super()

        this.destruct.subscription(this.fileInput.valueChanges)
            .pipe(distinctUntilChanged((a, b) => a === b))
            .subscribe(preview => {
                if (preview instanceof File) {
                    this.crop = null
                    this.image.load(preview)
                    setTimeout(this.editCrop.bind(this), 100)
                    // this.editCrop()
                } else if (preview instanceof UploadedFile) {
                    this.image.load(preview.inlineUrl)
                    this.control.setValue(preview.id)
                    this.picMeta = preview.meta as any
                    this.crop = this.picMeta ? this.picMeta.crop : null
                } else {
                    this.image.load(null)
                    this.crop = null
                    this.control.setValue(null)
                }
            })

        // this.destruct.subscription(this.form.get("cropInfo").valueChanges).subscribe(cropInfo => {
        //     if (!this.picMeta) {
        //         this.picMeta = {}
        //     }
        //     this.picMeta.crop = cropInfo
        //     this.cdr.markForCheck()
        // })
    }

    public ngOnInit() {
        this.destruct.subscription(this.control.valueChanges)
            .pipe(
                distinctUntilChanged((a, b) => a === b),
                switchMap(fileId => {
                    if (fileId) {
                        return this.fileBackend.get({ id: fileId })
                    } else {
                        return of(null as FsFile)
                    }
                })
            )
            .subscribe(file => {
                if (file) {
                    const uploaded = this.fsService.newUploadedFile(file)
                    this.picMeta = uploaded.meta as any
                    this.fileInput.setValue(uploaded)
                } else {
                    this.reset()
                }
            })
    }

    public editCrop() {
        this.cropSvc.crop(this.image, this.crop).subscribe(cropInfo => {
            const alreadyCropped = !!this.crop
            if (alreadyCropped) {
                if (cropInfo) {
                    this.crop = cropInfo
                }
            } else {
                this.crop = cropInfo
            }
            if (!this.crop && this.fileInput.value instanceof File) {
                this.fileInput.setValue(null)
            }
            this.cdr.markForCheck()
        })
    }

    public reset() {
        this.fileInput.setValue(null)
    }
}
