import { Component, Input, ChangeDetectionStrategy, ElementRef, Inject, OnChanges, SimpleChanges } from "@angular/core"


@Component({
    selector: ".rege-markdown",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegeMarkdownComponent implements OnChanges {
    @Input() public content: string

    public constructor(@Inject(ElementRef) private readonly el: ElementRef<HTMLElement>) {

    }

    public ngOnChanges(changes: SimpleChanges) {
        if ("content" in changes) {
            this.el.nativeElement.innerHTML = changes.content.currentValue
        }
    }
}
