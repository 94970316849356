import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Route } from "@angular/router"
import { NzModule } from "@anzar/core"

import { OrgApi } from "@backend/org.api"
import { WorkerApi } from "@backend/worker.api"
import { ServiceApi } from "@backend/service.api"
import { Service_bnoApi } from "@backend/service_bno.api"

import { ComponentsModule } from "../components"
import { PlaceModule } from "../place.module"
import { FsModule } from "@pyzar/fs.module"
import { PyzarCommonModule } from "@pyzar/common.module"
import { PyzarTaskModule, TaskDetailsFactory } from "@pyzar/task.module"

import { GlobalScreen } from "./global/global.screen"
import { GlobalEditorComponent } from "./global/global-editor.component"
import { GlobalBasicDataComponent } from "./global/global-basic-data.component"

import { MaintainerScreen } from "./maintainer/maintainer.screen"
import { MaintainerEditorComponent } from "./maintainer/maintainer-editor.component"
import { MaintainerBasicDataComponent } from "./maintainer/maintainer-basic-data.component"

import { RegionScreen } from "./region/region.screen"
import { RegionEditorComponent } from "./region/region-editor.component"
import { RegionBasicDataComponent } from "./region/region-basic-data.component"

import { InstitutionScreen } from "./institution/institution.screen"
import { InstitutionEditorComponent } from "./institution/institution-editor.component"
import { InstitutionBasicDataComponent } from "./institution/institution-basic-data.component"

import { HeadquarterScreen } from "./headquarter/headquarter.screen"
import { HeadquarterEditorComponent } from "./headquarter/headquarter-editor.component"
import { HeadquarterBasicDataComponent } from "./headquarter/headquarter-basic-data.component"

import { SectionSheetComponent } from "./section/section-sheet.component"
import { SectionSheetScreen } from "./section/section-sheet.screen"
import { SectionEditorComponent } from "./section/section-editor.component"
import { SectionBasicDataComponent } from "./section/section-basic-data.component"
import { SectionRestrictionComponent } from "./section/section-restriction.component"
import { ClientRequirementsComponent } from "./section/client-requirements.component"
import { SectionRemoveComponent } from "./section/section-remove.component"

import { ProviderEditorComponent } from "./provider/provider-editor.component"
import { ProviderBasicDataComponent } from "./provider/provider-basic-data.component"
import { ProviderRemoveComponent } from "./provider/provider-remove.component"
import { ProviderAutoCloseComponent } from "./provider/provider-auto-close.component"

import { MarkEditorComponent } from "./blocks/mark-editor.component"
import { WorkerEditorComponent } from "./blocks/worker-editor.component"
import { KenysziComponent } from "./blocks/kenyszi.component"
import { KenysziImportDetailsFactory } from "./blocks/import-task-details-factory"
import { DutyEditorComponent } from "./blocks/duty-editor.component"
import { ServiceEditorComponent } from "./blocks/service-editor.component"
import { RoomEditorComponent } from "./blocks/room-editor.component"
import { ScheduledEditorComponent } from "./blocks/scheduled-editor.component"
import { BNOEditorComponent } from "./blocks/bno-editor.component"
import { DocumentsBlockComponent } from "./blocks/documents-block.component"

import { OrgScreen } from "./org.screen"
// import { LevelGridComponent } from "./level-grid.component"


export const ORG_ROUTES: Route[] = [
    { path: "beallitasok", component: OrgScreen },
    { path: "global/:id", component: GlobalScreen },
    { path: "fenntarto", component: MaintainerScreen },
    { path: "fenntarto/:id", component: MaintainerScreen },
    { path: "regio", component: RegionScreen },
    { path: "regio/:id", component: RegionScreen },
    { path: "intezmeny", component: InstitutionScreen },
    { path: "intezmeny/:id", component: InstitutionScreen },
    { path: "szekhely", component: HeadquarterScreen },
    { path: "szekhely/:id", component: HeadquarterScreen },
    { path: "reszleg", component: SectionSheetScreen },
    { path: "reszleg/:id", component: SectionSheetScreen },
]


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        OrgApi,
        ServiceApi,
        ComponentsModule,
        FsModule,
        PyzarCommonModule,
        PlaceModule,
        Service_bnoApi,
        WorkerApi,
        PyzarTaskModule,
    ],
    declarations: [
        GlobalScreen,
        GlobalEditorComponent,
        GlobalBasicDataComponent,

        MaintainerScreen,
        MaintainerEditorComponent,
        MaintainerBasicDataComponent,

        RegionScreen,
        RegionEditorComponent,
        RegionBasicDataComponent,

        InstitutionScreen,
        InstitutionEditorComponent,
        InstitutionBasicDataComponent,

        HeadquarterScreen,
        HeadquarterEditorComponent,
        HeadquarterBasicDataComponent,

        SectionSheetComponent,
        SectionSheetScreen,
        SectionRemoveComponent,

        SectionEditorComponent,
        ProviderEditorComponent,
        ProviderRemoveComponent,

        SectionBasicDataComponent,
        MarkEditorComponent,
        WorkerEditorComponent,
        ProviderBasicDataComponent,
        ProviderAutoCloseComponent,
        KenysziComponent,
        DutyEditorComponent,
        ServiceEditorComponent,
        RoomEditorComponent,
        ScheduledEditorComponent,
        BNOEditorComponent,
        SectionRestrictionComponent,
        ClientRequirementsComponent,
        DocumentsBlockComponent,

        OrgScreen,
        // levelGridComponent,
    ],
    exports: [
        SectionSheetComponent,
        SectionSheetScreen,

        SectionEditorComponent,

        ProviderEditorComponent,

        OrgScreen,
        // levelGridComponent
    ],
    entryComponents: [
        ProviderRemoveComponent,
        SectionRemoveComponent
    ],
    providers: [
        { provide: TaskDetailsFactory, useClass: KenysziImportDetailsFactory, multi: true }
    ]
})
export class OrgModule {

}
