import { Component, Input, ChangeDetectionStrategy } from "@angular/core"


@Component({
    selector: ".rege-file-icon",
    templateUrl: "./file-icon.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileIconComponent {
    @Input() public mime: string
    @Input() public color: string
}
