import { Component, Inject } from "@angular/core"

import { RichtextComponentRef } from "@anzar/core"


@Component({
    selector: "rege-provider-service",
    templateUrl: "./provider-service.component.pug"
})
export class ProviderServiceComponent {
    public readonly content: string

    public constructor(@Inject(RichtextComponentRef) protected readonly cmpRef: any) {
        this.content = cmpRef.params.content
    }
}
