import { Component, forwardRef, Inject, Injectable } from "@angular/core"
import { FormGroup } from "@angular/forms"
import { of } from "rxjs"
import { switchMap, map } from "rxjs/operators"

import { LoadFields } from "@anzar/core"
import { LevelWorker, LevelWorkerBackend } from "@backend/org.api"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { UserEditorService, CustomUserBlock } from "@pyzar/auth.module"
import { WorkerLevelsFactory } from "./levels.component"


const ROLE_FIELDS: LoadFields<LevelWorker> = [
    { level: ["title", "variant"] },
    { rolev: ["label", "position"] }
]


@Component({
    selector: ".rege-worker-levels-ro",
    templateUrl: "levels-ro.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => WorkerLevelsRoComponent) }
    ]
})
export class WorkerLevelsRoComponent extends AbstractCLEditorBlock<UserEditorService> {
    public readonly roles$ = this.destruct.subscription(this.editorSvc.id$).pipe(
        switchMap(userId => {
            return this.levelWorkerBackend
                .search({
                    filter: { user_id: userId },
                    order: { "rolev.position": "asc", "level.title": "asc" }
                }, { loadFields: ROLE_FIELDS })
        })
    )

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: UserEditorService,
        @Inject(LevelWorkerBackend) public readonly levelWorkerBackend: LevelWorkerBackend) {
        super(editorSvc, new FormGroup({}))
    }

    public save() {
        return of(true)
    }
}


@Injectable()
export class WorkerLevelsRoFactory extends WorkerLevelsFactory {
    public create(userId: number): CustomUserBlock {
        const levels = super.create(userId)
        return {
            title: "Munkahelyek",
            icon: "rege-worker-levels-icon",
            component: WorkerLevelsRoComponent,
            visible: levels.visible.pipe(map(v => !v))
        }
    }
}
