import { Inject, Injectable } from "@angular/core"
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router"
import { Observable, of } from "rxjs"
import { tap, map, catchError } from "rxjs/operators"


import { SectionService } from "./section.service"


@Injectable()
export class SectionGuard implements CanActivate, CanActivateChild {
    public constructor(
        @Inject(SectionService) protected readonly sectionSvc: SectionService,
        @Inject(Router) public readonly router: Router) {

    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.sectionSvc.selected.pipe(
            map(section => !!section),
            catchError(err => of(false)),
            tap(val => {
                if (!val) {
                    this.router.navigate(["hirfolyam"])
                    // this.sectionSvc.goToSelectSection(state.url)
                }
            }))
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(childRoute, state)
    }
}
