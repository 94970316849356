import { Injectable, Inject, OnDestroy, EventEmitter, NgZone, Optional } from "@angular/core"
import { Observable, Subject, of, throwError, forkJoin, merge, zip, race, concat, EMPTY } from "rxjs"
import { startWith, take, map, switchMap, tap, exhaustMap, filter, mapTo, shareReplay, debounceTime, materialize, catchError } from "rxjs/operators"
import { startOfDay, isSameDay, compareAsc, compareDesc, setYear, setMonth, setDate } from "date-fns"

import { Destruct, LoadFields, Model, Field, StaticSource, LayerService, ModalLayer, DialogService, ToastService } from "@anzar/core"
import { ProgressEvent } from "@anzar/core"
import { DutyBackend, Duty, ProviderService } from "@backend/org.api"
import { Event, EventBackend, EventUsage } from "@backend/event.api"
import { Client, ClientBackend } from "@backend/client.api"
import { CurrentSection } from "../section.service/level"
import { MarkService, Alert } from "../mark.module"
import { ProviderServices } from "../service.module/provider.service"
import { CreateDutyComponent, PROVIDER_ID, DUTY_DATE, DUTY_VALUE } from "./create-duty.component"
import { BedReservationBackend } from "@backend/service_room.api"
import { ClientService } from "../client.module"
import { DUTY_FIELDS as DUTY_FIELDS_FOR_EDIT } from "./duty-list.component"


const EVENT_FIELDS: LoadFields<Event> = [
    "id", "client_id", "duty_id", "place_id", "place_extra", "begin", "end", "type", "subject_time",
    { client: ["id", "name", "social_insurance_id", "birth_date", "profile_image_id"] },
    {
        services: [
            "event_id", "provider_service_id"
        ]
    },
    { richtext: ["text", "content"] as any },
    { place: ["id", "description", "location"] }
]


export const DUTY_FIELDS: LoadFields<Duty> = [
    "id", "begin", "end", "provider_id", "section_id",
    {
        events: EVENT_FIELDS
    },
    {
        vehicles: ["id", "name"]
    },
    {
        workers: [
            { user: ["id", "name"] }
        ]
    },
    { places: ["id", "description"] }
]


const CLIENT_FIELDS: LoadFields<Client> = [
    "id", "name", "social_insurance_id", "birth_date"
]

export class ServiceUsage extends Model {
    @Field({ primary: true }) public id: any
    @Field() public client: Client
    @Field() public usage: ServiceUsageEvent[]
    @Field() public alerts: Alert[]
    @Field() public events: Event[]

    protected readonly _usageChange = new EventEmitter<ServiceUsageEvent[]>()

    public readonly usageStream = this._usageChange
        .pipe(startWith(null), map(x => x || this.usage || []), shareReplay())

    public findUsage(service: ProviderService): ServiceUsageEvent {
        return this.usage.find(s => s.providerService.id === service.id)
    }

    public emitChange() {
        this._usageChange.emit(this.usage)
    }
}


export class ServiceUsageEvent {
    public constructor(
        public duty: Duty,
        public usage: ServiceUsage,
        public providerService: ProviderService,
        public enabled: boolean,
        public pending: boolean,
        public eventId?: number) { }
}


export class UsageInterceptor {
    public _trigger = new Subject<ServiceUsageEvent>()
    public _interceptor: Observable<ServiceUsageEvent>

    public intercept(cb: (event: ServiceUsageEvent) => Observable<ServiceUsageEvent>): void {
        if (this._interceptor) {
            throw new Error("Only one interceptor allowed")
        } else {
            this._interceptor = this._trigger.pipe(exhaustMap(cb))
        }
    }
}


export class ClientHasMissingFields extends Error {
    constructor(public readonly client: Client) {
        super()
    }
}


@Injectable()
export class DutyService implements OnDestroy {
    public readonly destruct = new Destruct()

    public set date(val: Date) {
        if (val) {
            val = startOfDay(val)
        }
        if (!isSameDay(this._date, val)) {
            this._date = val
            this._dateChanged.next(val)
        }
    }
    public get date(): Date { return this._date }
    private _date: Date
    private _dateChanged = new Subject<Date>()
    public date$ = this._dateChanged.pipe(shareReplay(1))

    private _reloadDuties = new Subject<void>()
    private _softReload = new Subject<void>()

    public set selectedDuty(val: Duty) {
        if (this._selectedDuty !== val) {
            this._selectedDuty = val

            if (val) {
                delete this._pendingDutyId
            }

            if (val) {
                this.date = val.begin
            }

            this._selectedDutyChange.next(val)
        }
    }
    public get selectedDuty(): Duty { return this._selectedDuty }
    private _selectedDuty: Duty
    private _pendingDutyId: number
    private _selectedDutyChange = new Subject<Duty>()

    public readonly selectedDuty$ = this._selectedDutyChange.pipe(shareReplay(1))

    public readonly duties = merge(
        merge(this.section.providers, this.date$, this._reloadDuties)
            .pipe(tap(v => { this.usage.replace([]); this.selectedDuty = null })),
        this._softReload).pipe(
            debounceTime(5),
            switchMap(v => zip(this.section.providers, this.date$)),
            switchMap(v => {
                const [providers, date] = v
                const providerIds = providers.map(p => p.id)
                return this.backend.search_by_date(
                    { provider_ids: providerIds, date: date },
                    { loadFields: DUTY_FIELDS })
            }),
            map(duties => duties.sort((a, b) => compareAsc(a.begin, b.begin))),
            tap(duties => {
                if (this._pendingDutyId) {
                    let duty = duties.find(d => d.id === this._pendingDutyId)
                    delete this._pendingDutyId
                    this.selectedDuty = duty
                } else {
                    this.selectedDuty = null
                }
            }),
            shareReplay(1)
        )

    private readonly placeholders$ = merge(this.section.id$, this.date$).pipe(
        switchMap(_ => zip(this.section.id$, this.date$).pipe(take(1))),
        switchMap(([sectionId, date]) => {
            return this.eventBackend.get_placeholders(
                { section_id: sectionId, subject_date: date },
                { loadFields: EVENT_FIELDS })
        }),
        tap(placeholders => {
            this._placeholders = placeholders
        }),
        shareReplay(1)
    )
    private _placeholders: Event[]


    public readonly usage = new StaticSource(ServiceUsage, [])
    public readonly onExpand: Observable<ServiceUsage> = new Subject<ServiceUsage>()
    public readonly onUsageChange: Observable<ServiceUsageEvent> = new Subject<ServiceUsageEvent>()
    public readonly busy: Observable<boolean> = new Subject<boolean>()
    public readonly autoDuty: boolean = false

    public set isBusy(val: boolean) {
        if (this._isBusy !== val) {
            this._isBusy = val;
            (this.busy as Subject<boolean>).next(val)
        }
    }
    public get isBusy(): boolean { return this._isBusy }
    private _isBusy: boolean

    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(DialogService) private readonly dialogSvc: DialogService,
        @Inject(ProviderServices) private readonly provider: ProviderServices,
        @Inject(DutyBackend) private readonly backend: DutyBackend,
        @Inject(EventBackend) private readonly eventBackend: EventBackend,
        @Inject(CurrentSection) private readonly section: CurrentSection,
        @Inject(NgZone) private readonly zone: NgZone,
        @Inject(UsageInterceptor) @Optional() private readonly interceptor: UsageInterceptor,
        @Inject(MarkService) private readonly markSvc: MarkService,
        @Inject(BedReservationBackend) private readonly bedResv: BedReservationBackend,
        @Inject(ClientBackend) private readonly clientBackend: ClientBackend,
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(ToastService) private readonly toastSvc: ToastService) {

        this.usage.addCustomFilter("provider_service_id", (record, value) => {
            let idList = Array.isArray(value) ? value : (value ? [value] : null)
            if (!idList || idList.length === 0) {
                return true
            }

            for (const u of record.usage) {
                if (idList.indexOf(u.providerService.id) !== -1) {
                    return true
                }
            }
            return false
        })

        this.usage.addCustomFilter("duty_id", (record, value) => {
            let idList = Array.isArray(value) ? value : (value ? [value] : null)
            if (!idList || idList.length === 0) {
                return true
            }

            for (const u of record.usage) {
                if (idList.indexOf(u.duty.id) !== -1) {
                    return true
                }
            }
            return false
        })

        this.usage.addCustomSorter("name", (a, b, dir) => {
            if (a.events && b.events) {
                let idx = 0

                if (a.client && b.client) {
                    idx = a.client.name.formatted.localeCompare(b.client.name.formatted)
                } else if (a.client) {
                    idx = - 1
                } else if (b.client) {
                    idx = 1
                }

                return dir === "asc" ? idx : idx * -1
            } else if (a.events) {
                return 1
            } else if (b.events) {
                return -1
            }
            return 0
        })

        this.usage.addCustomSorter("time", (a, b, dir) => {
            if (a.events && b.events) {
                return dir === "asc"
                    ? compareAsc(a.events[0].subject_time, b.events[0].subject_time)
                    : compareDesc(a.events[0].subject_time, b.events[0].subject_time)
            } else if (a.events) {
                return 1
            } else if (b.events) {
                return -1
            }
            return 0
        })

        this.destruct.subscription(merge(this.duties, section.manualDuty, provider.services, this.placeholders$))
            .pipe(
                debounceTime(20),
                tap(_ => this.isBusy = true),
                switchMap(v => zip(this.duties, section.manualDuty, provider.services).pipe(take(1))),
                map(v => {
                    const [duties, manualDuty] = v;
                    (this as any).autoDuty = !manualDuty
                    return duties
                }),
                tap(duties => {
                    if (!this.autoDuty && !this.selectedDuty && duties.length === 1) {
                        this.selectedDuty = duties[0]
                    }
                }),
                map(duties => {
                    let entries: { [key: string]: Event[] } = {}

                    if (this.autoDuty) {
                        for (const duty of duties) {
                            for (const event of duty.events) {
                                let gkey = event.client_id || event.id
                                if (!entries[gkey]) {
                                    entries[gkey] = [event]
                                } else {
                                    entries[gkey].push(event)
                                }
                            }
                        }

                        if (this._placeholders) {
                            for (const placeholder of this._placeholders) {
                                let gkey = placeholder.client_id
                                if (!entries[gkey]) {
                                    entries[gkey] = [placeholder]
                                } else {
                                    entries[gkey].push(placeholder)
                                }
                            }
                        }
                    } else {
                        for (const duty of duties) {
                            for (const event of duty.events) {
                                let gkey = event.id
                                if (!entries[gkey]) {
                                    entries[gkey] = [event]
                                } else {
                                    entries[gkey].push(event)
                                }
                            }
                        }
                    }

                    let usage = []
                    for (const k in entries) {
                        usage.push(this._mergeEvents(duties, entries[k][0].client, entries[k]))
                    }
                    return usage
                }),
                switchMap(usageList => {
                    const indexes: number[] = []
                    const clientIds = usageList.map((u, index) => {
                        if (u.client) {
                            indexes.push(index)
                            return u.client.id
                        } else {
                            return null
                        }
                    }).filter(v => !!v)

                    if (clientIds.length) {
                        return this.markSvc.getClientsAlertLevel(clientIds)
                            .pipe(
                                tap(levels => {
                                    for (let i = 0, l = indexes.length; i < l; i++) {
                                        usageList[indexes[i]].alerts = levels[i]
                                    }
                                }),
                                mapTo(usageList)
                            )
                    } else {
                        return of(usageList)
                    }
                })
            )
            .subscribe(usageList => {
                if (this.selectedDuty || !this.autoDuty || this.usage.data.length === 0) {
                    this.usage.replace(usageList)
                }
                this.isBusy = false
            })

        this.destruct.subscription(markSvc.clientMarkChanged)
            .pipe(
                map(v => {
                    return this.usage.data.find(u => u.client.id === v.client_id)
                }),
                filter(v => !!v),
                switchMap(usage => {
                    return this.markSvc.getClientAlertLevel(usage.client.id)
                        .pipe(
                            tap(alert => {
                                (usage as ServiceUsage).alerts = alert
                                usage.emitChange()
                            })
                        )
                })
            ).subscribe()
    }

    public getDuty(dutyId: number): Observable<Duty> {
        return this.duties
            .pipe(
                switchMap(duties => {
                    let duty = duties.find(d => d.id === dutyId)
                    if (duty) {
                        return of(duty)
                    } else {
                        return this.backend.get({ id: dutyId }, { loadFields: DUTY_FIELDS })
                    }
                }),
                take(1)
            )
    }

    public realoadEvent(eventId: number) {
        this._reloadDuties.next()
    }

    public addClient(
        addClient: Client | number,
        expand: boolean = true,
        autotick: boolean | number[] = true,
        setServices: ProviderService[] = null): Observable<Readonly<ServiceUsage>> {

        const client$ = typeof addClient === "number"
            ? this.clientBackend.get({ id: addClient }, { loadFields: CLIENT_FIELDS })
            : of(addClient)

        return client$.pipe(switchMap(client => {
            return this.markSvc.getClientAlertLevel(client.id)
                .pipe(
                    switchMap(alerts => {
                        return this.section.section.pipe(
                            take(1),
                            switchMap(section => {
                                if (!section.allow_partial_client_data) {
                                    const requiredFieldsAlert = alerts.find((alert) => alert.mark === "required_fields")
                                    if (requiredFieldsAlert) {
                                        return this.clientSvc.createClient(false, client).pipe(
                                            switchMap(result => {
                                                if (result) {
                                                    this.markSvc.invalidateAlertLevel(result.id)
                                                    return this.markSvc.getClientAlertLevel(result.id)
                                                } else {
                                                    throw new ClientHasMissingFields(client)
                                                }
                                            })
                                        )
                                    } else {
                                        return of(alerts)
                                    }
                                } else {
                                    return of(alerts)
                                }
                            })
                        )
                    }),
                    switchMap(alerts => {
                        let existing = this.usage.data.find(u => u.client.id === client.id)
                        let isFirst = true
                        if (!existing) {
                            existing = new ServiceUsage({ id: client.id, client, usage: [], alerts: alerts })
                            this.usage.add(existing as ServiceUsage, 0)
                        } else {
                            (existing as ServiceUsage).alerts = alerts
                            isFirst = false
                        }

                        if (expand) {
                            this.expand(existing as ServiceUsage)
                        }

                        return of({ usage: existing, isFirst })
                    }),
                    switchMap(v => {
                        const { usage, isFirst } = v

                        if (isFirst) {
                            return this._autoTickOnAdd(usage as ServiceUsage, autotick, setServices)
                        } else {
                            return this._autoTickOnAdd(usage as ServiceUsage, false, setServices)
                        }
                    }),
                    take(1)
                )
        }))
    }

    public addClients(
        clients: Client[],
        autoTick: boolean | number[],
        setServices?: ProviderService[]): Observable<[ProgressEvent, Readonly<ServiceUsage>]> {

        const observables = clients.map(client =>
            this.addClient(client, false, autoTick, setServices)
                .pipe(
                    catchError(err => {
                        if (err instanceof ClientHasMissingFields) {
                            this.toastSvc.error(`${err.client.name.formatted} adatlapja hiányos`)
                            return of(null)
                        } else {
                            return throwError(() => err)
                        }
                    })
                )
        )
        const total = clients.length
        let progress = 0
        return concat(...observables).pipe(map(v => {
            progress++
            return [{ percent: progress / total, total: total, current: progress } as ProgressEvent, v] as any
        }))
    }

    public deleteUsage(usage: ServiceUsage) {
        let qs = usage.events
            ? usage.events.map(event => this.eventBackend.remove({ id: event.id }))
            : [of(null)]

        return forkJoin(qs).pipe(tap(() => {
            const idx = this.usage.data.findIndex(item => item.pk === usage.pk)
            this.usage.removeAt(idx)
        }))
    }

    public createDuty() {
        this.provider.all
            .pipe(
                switchMap(providers => {
                    if (providers.length === 1) {
                        return of(providers[0])
                    } else {
                        // throw Error("Nincs provider, vagy egynél több van")
                        return throwError("Nincs provider, vagy egynél több van")

                    }
                }),
                switchMap(provider => {
                    let behavior = new ModalLayer({
                        elevation: 10,
                        rounded: 3,
                        position: {
                            align: "top center",
                            constraint: {
                                ref: "viewport",
                                inset: 24
                            }
                        }
                    })
                    let wnd = this.layerSvc.createFromComponent(CreateDutyComponent, behavior, null, [
                        { provide: PROVIDER_ID, useValue: provider.id },
                        { provide: DUTY_DATE, useValue: this.date },
                        { provide: CurrentSection, useValue: this.section },
                    ])
                    wnd.show()
                    return race(
                        wnd.destruct.on.pipe(mapTo(null)),
                        wnd.output.pipe(
                            filter(event => event.type === "duty-created"),
                            map(event => event.data as Duty)
                        )
                    )
                })
            )
            .subscribe(duty => {
                if (duty) {
                    this._pendingDutyId = duty.id
                    this._reloadDuties.next()
                }
            })
    }

    public editDuty(duty_: Duty) {
        let behavior = new ModalLayer({
            elevation: 10,
            rounded: 3,
            position: {
                align: "top center",
                constraint: {
                    ref: "viewport",
                    inset: 24
                }
            }
        })

        this.backend.get({ id: duty_.id }, { loadFields: DUTY_FIELDS_FOR_EDIT })
            .pipe(
                switchMap(duty => {
                    let wnd = this.layerSvc.createFromComponent(CreateDutyComponent, behavior, null, [
                        { provide: CurrentSection, useValue: this.section },
                        { provide: DUTY_DATE, useValue: duty.begin },
                        { provide: DUTY_VALUE, useValue: duty },
                    ])
                    wnd.show()
                    return race(
                        wnd.destruct.on.pipe(mapTo(null)),
                        wnd.output.pipe(
                            filter(event => event.type === "duty-created"),
                            map(event => event.data as Duty)
                        )
                    )
                })
            )
            .subscribe(duty => {
                if (duty) {
                    if (this.selectedDuty && this.selectedDuty.id === duty.id) {
                        this._pendingDutyId = duty.id
                    }
                    this._reloadDuties.next()
                }
            })
    }

    public expand(usage: ServiceUsage) {
        (this.onExpand as Subject<ServiceUsage>).next(usage)
    }

    public setUsage(
        usage: ServiceUsage,
        service: ProviderService,
        enabled: boolean,
        bypassInterception: boolean = false): Observable<ServiceUsageEvent | null> {
        // console.log("setUsage", usage.client.id, service.id, usage, service, enabled)

        // return this._canChangeUsage(usage, service, enabled).pipe(
        //     switchMap(v => this._confirmDelete(usage, service, enabled)),
        return this._confirmDelete(usage, service, enabled).pipe(
            switchMap(v => {
                if (this.autoDuty) {
                    return this._getDutyByProvider(service.provider_id, enabled)
                } else {
                    return this.getDuty(usage.events[0].duty_id)
                }
            }),
            map(duty => {
                if (duty) {
                    let event: ServiceUsageEvent = usage.usage.find(u => u.providerService.id === service.id)
                    if (!event) {
                        event = new ServiceUsageEvent(duty, usage, service, enabled, false)
                    }
                    return event
                } else {
                    return null
                }
            }),
            switchMap(event => {
                if (event) {
                    event.enabled = enabled
                    if (!enabled) {
                        event.pending = false
                    }

                    if (this.interceptor && !bypassInterception) {
                        return new Observable<ServiceUsageEvent>(o => {
                            const s1: any = this.interceptor._interceptor.pipe(take(1)).subscribe(v => {
                                o.next(v)
                                o.complete()
                            })
                            this.interceptor._trigger.next(event)
                            return () => {
                                s1.unsubscribe()
                            }
                        })
                    } else {
                        event.pending = false

                    }
                }
                return of(event)
            }),
            switchMap((event: ServiceUsageEvent) => {
                if (event) {
                    this._updateServiceUsage(event)
                    if (event.pending) {
                        return of(event)
                    } else {
                        return this._setUsage(event).pipe(
                            switchMap(v => {
                                const dutyIds = usage.usage.map(u => u.duty.id)
                                return this.eventBackend.search({
                                    filter: { duty_id: { in: dutyIds }, client_id: usage.client.id },
                                    order: {}
                                }, { loadFields: EVENT_FIELDS })
                            }),
                            switchMap(events => {
                                return this.duties.pipe(take(1), map(duties => [duties, events] as [Duty[], Event[]]))
                            }),
                            tap(([duties, events]) => {
                                const serviceUsage = this._mergeEvents(duties, usage.client, events)
                                usage.events = serviceUsage.events
                                usage.usage = serviceUsage.usage
                                usage.emitChange()
                            }),
                            mapTo(event)
                        )
                    }
                } else {
                    return of(null)
                }
            }),
            tap(v => {
                if (v) {
                    (this.onUsageChange as Subject<ServiceUsageEvent>).next(v)
                }
            })
        )
    }

    private _setUsage(event: ServiceUsageEvent): Observable<ServiceUsageEvent | null> {
        let { duty, usage, providerService: service, enabled } = event

        let eventType = "service"
        let eventId = null
        if (usage.events && usage.events.length === 1 && usage.events[0].type !== "placeholder") {
            eventId = usage.events[0].id
        }

        const client = usage.client
        const params = {
            duty_id: duty.id,
            client_id: client.id,
            provider_service_id: service.id,
            type: eventType,
            event_id: eventId
        }
        let queries: Array<Observable<EventUsage>> = []

        if (enabled) {
            queries.push(
                this.eventBackend.set_usage(params).pipe(
                    tap(result => {
                        if (result) {
                            event.eventId = result.event_id
                        }
                    }),
                    switchMap(result => {
                        if (result) {
                            event.eventId = result.event_id

                            if (service.is_primary) {
                                return this._autoTickOnPrimaryChange(usage, service).pipe(mapTo(result))
                            }
                        }

                        return of(result)
                    })
                )
            )

            if (service.is_primary) {
                const usableService = this.provider.getServiceById(service.id)
                for (const svcToRemove of usableService.services) {
                    if (svcToRemove.id !== service.id) {
                        queries.push(this.eventBackend.del_usage({ ...params, provider_service_id: svcToRemove.id }))
                    }
                }
            }

        } else {
            queries.push(this.eventBackend.del_usage(params))
        }

        if (queries.length > 1) {
            return forkJoin(queries).pipe(mapTo(event))
        } else {
            return queries[0].pipe(mapTo(event))
        }
    }

    private _createPlaceholder(usage: ServiceUsage): Observable<ServiceUsage> {
        let subjectTime = new Date()
        subjectTime = setYear(subjectTime, this.date.getFullYear())
        subjectTime = setMonth(subjectTime, this.date.getMonth())
        subjectTime = setDate(subjectTime, this.date.getDate())
        return this.eventBackend
            .create_placeholder({ client_id: usage.client.id, section_id: this.section.id, subject_time: subjectTime })
            .pipe(map(event => {
                (usage as { events: Event[] }).events = [event]
                return usage
            }))
    }

    private _confirmDelete(usage: ServiceUsage, service: ProviderService, enabled: boolean): Observable<any> {
        if (enabled || usage.usage.length > 1) {
            return of(null)
        } else {
            return this.dialogSvc.deleteLowRisk("Ügyfél törlés megerősítése", `Biztosan törlöd ${usage.client.name.formatted} ügyfelet a listáról`)
                .pipe(switchMap(v => {
                    if (v) {
                        return this.deleteUsage(usage).pipe(switchMap(() => EMPTY))
                    } else {
                        return EMPTY
                    }
                }))
        }
    }

    private _canChangeUsage(usage: ServiceUsage, service: ProviderService, enabled: boolean) {
        let existing = usage.findUsage(service)
        if (existing) {
            if (existing.pending) {
                return of(true)
            } else {
                return of(!enabled)
            }
        } else {
            return of(enabled)
        }
    }

    private _getDutyByProvider(providerId: number, create: boolean): Observable<Duty | null> {
        return this.duties.pipe(switchMap(duties => {
            let duty = duties.filter(d => d.provider_id === providerId)

            if (duty.length > 0) {
                if (duty.length === 1) {
                    return of(duty[0])
                } else {
                    return throwError("Multiple duties found")
                }
            } else if (!this.date) {
                return throwError("Missing duty date")
            } else if (create) {
                return this.backend
                    .create_duty_for_provider({ provider_id: providerId, date: this.date })
                    .pipe(
                        take(1),
                        tap(() => {
                            this._softReload.next()
                        })
                    )
            } else {
                return of(null)
            }
        }))
    }

    private _createUsageList(events: Event[]): ServiceUsage[] {
        return []
    }

    private _mergeEvents(duties: Duty[], client: Client, events: Event[]): ServiceUsage {
        let usage: ServiceUsageEvent[] = []
        let id = client
            ? this.autoDuty ? client.id : events.map(e => e.id).join(",")
            : Math.random().toString(36)

        let result = new ServiceUsage({ id, client, usage, events })

        for (const event of events) {
            for (const u of event.services) {
                let service = this.provider.getProviderServiceById(u.provider_service_id)

                if (service) {
                    let duty = duties.find(d => d.id === event.duty_id)
                    let evt = new ServiceUsageEvent(duty, result, service, true, false, u.event_id)
                    usage.push(evt)
                } else {
                    console.error(`Undefined provider service: ${u.provider_service_id}`)
                }
            }
        }

        return result
    }

    private _updateServiceUsage(event: ServiceUsageEvent, realEvent: Event = null) {
        const { usage, enabled, pending } = event
        this.zone.runGuarded(() => {
            let found = false
            let l = usage.usage.length
            while (l-- > 0) {
                const u = usage.usage[l]
                if (u.providerService.id === event.providerService.id) {
                    found = true
                    if (!enabled) {
                        usage.usage.splice(l, 1)
                    }
                } else if (enabled && u.pending) {
                    usage.usage.splice(l, 1)
                }
            }

            if (!found && enabled) {
                usage.usage.push(event)
            }

            if (realEvent) {
                const idx = usage.events.findIndex(evt => evt.id === realEvent.id)
                if (enabled) {
                    if (idx === -1) {
                        usage.events.push(realEvent)
                    }
                }
            }

            usage.emitChange()
        })
    }

    private _autoTickOnAdd(
        usage: ServiceUsage,
        autoTick: boolean | number[],
        setServices: ProviderService[]): Observable<ServiceUsage> {

        return this.section.services.pipe(
            take(1),
            map(services => {
                if (autoTick === true) {
                    return services.filter(s => s.auto_tick === "always")
                } else if (Array.isArray(autoTick) && autoTick.length > 0) {
                    return services.filter(svc => svc.auto_tick === "always" && autoTick.indexOf(svc.provider_id) > -1)
                } else {
                    return []
                }
            }),
            switchMap(services => {
                if (setServices) {
                    services = services.concat(setServices)
                }

                services = services.filter((v, i, a) => a.findIndex(svc => svc.id === i))

                return this._applyAutoTickSvc(usage, services)
            }),
            switchMap(applied => {
                if (!applied) {
                    return this._createPlaceholder(usage)
                } else {
                    return of(applied)
                }
            })
        )
    }

    private _autoTickOnPrimaryChange(usage: ServiceUsage, primaryService: ProviderService): Observable<ServiceUsage> {
        const providerId = primaryService.provider_id
        return this.section.services.pipe(
            take(1),
            map(services => {
                return services.filter(s => s.provider_id === providerId && s.auto_tick === "provider")
            }),
            switchMap(services => {
                return this._applyAutoTickSvc(usage, services)
            })
        )
    }

    private _applyAutoTickSvc(usage: ServiceUsage, services: ProviderService[]): Observable<ServiceUsage> {
        let queries: Array<Observable<{ service: ProviderService, bypass: boolean }>> = []

        for (const svc of services) {
            if (svc.service.type === "room") {
                queries.push(
                    this.bedResv
                        .has_reservation({ client_id: usage.client.id, provider_service_id: svc.id, date: this._date })
                        .pipe(
                            map(v => {
                                return v ? { service: svc, bypass: true } : null
                            }),
                            take(1)
                        ))
            } else {
                queries.push(of({ service: svc, bypass: false }))
            }
        }

        return queries.length === 0
            ? of(null)
            : forkJoin(queries).pipe(
                take(1),
                map(res => res.filter(v => !!v)),
                switchMap(entries => {
                    if (entries && entries.length) {
                        let sq = entries.map(entry => {
                            return this.setUsage(usage, entry.service, true, entry.bypass).pipe(take(1))
                        })
                        return zip(...sq).pipe(mapTo(usage))
                    } else {
                        return of(null)
                    }
                })
            )
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}


function sortUsage(a: ServiceUsageEvent, b: ServiceUsageEvent) {
    return a.providerService.service.title.localeCompare(b.providerService.service.title)
}
