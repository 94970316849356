import { Injectable, InjectionToken, Inject } from "@angular/core"


export type ImageType = "poster" | "fanart" | "banner"


@Injectable({ providedIn: "root" })
export class ImageService {
    public static readonly BASE_URL = new InjectionToken<string>("ImageService.BASE_URL")

    public constructor(@Inject(ImageService.BASE_URL) public readonly baseUrl: string) {

    }

    public getShowImage(showId: number, type: ImageType, w: number, h: number, season: number = null, episode: number = null): string {
        return `${this.baseUrl}/media-image/show/${showId}/${type}/${w}/${h}`
    }
}
