import { Component, Inject, Optional, HostListener } from "@angular/core"

import { RichtextComponentRef, NzTouchEvent } from "@anzar/core"

import { HashtagClickHandler } from "./click-handler"


@Component({
    selector: "rege-hashtag",
    host: {
        "[style.cursor]": "clickHandler ? 'pointer' : null"
    },
    templateUrl: "./hashtag.component.pug"
})
export class HashtagComponent {
    public readonly content: string

    public constructor(
        @Inject(RichtextComponentRef) protected readonly cmpRef: any,
        @Inject(HashtagClickHandler) @Optional() public readonly clickHandler: HashtagClickHandler) {
        this.content = cmpRef.params.content
    }

    @HostListener("tap", ["$event"])
    public onTap(event: NzTouchEvent) {
        this.clickHandler?.handle(this, event)
    }
}
