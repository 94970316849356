import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzModule } from "@anzar/core"

import { ImageModule } from "../image.module"
import { FsModule } from "@pyzar/fs.module"

import { NameInputComponent, NameInputRequiredValidator } from "./name-input.component"
import { DateSwitcherComponent } from "./date-switcher.component"
import { AvatarComponent, ClientAvatar, UserAvatar } from "./avatar/avatar.component"

import { EmbedlistComponent } from "./embedlist/embedlist.component"
import { EmbedlistItemDirective } from "./embedlist/embedlist-item.directive"

import { FileIconComponent } from "./file-icon.component"

import { PageTitleSectionComponent, PageTitleHqComponent } from "./page-title.component"

import { InfoComponent } from "./info.component"
import { DateRangeComponent } from "./date-range.component"

import { TajPipe } from "./taj.pipe"
import { RegeMarkdownComponent } from "./md-renderer.component"
import { MarkdownPopupComponent, MarkdownPopupService } from "./md-popup.component"
export { MarkdownPopupService }


import { PanelComponent } from "./panel/panel.component"
export { PanelComponent }

import { StatusTextComponent } from "./status-text.component"




@NgModule({
    imports: [
        NzModule,
        CommonModule,
        ImageModule,
        FsModule,
    ],
    declarations: [
        NameInputComponent,
        NameInputRequiredValidator,
        DateSwitcherComponent,
        AvatarComponent,
        ClientAvatar,
        UserAvatar,

        EmbedlistComponent,
        EmbedlistItemDirective,

        FileIconComponent,

        PageTitleSectionComponent,
        PageTitleHqComponent,

        InfoComponent,
        DateRangeComponent,

        TajPipe,

        RegeMarkdownComponent,
        MarkdownPopupComponent,

        PanelComponent,

        StatusTextComponent
    ],
    exports: [
        NameInputComponent,
        NameInputRequiredValidator,
        DateSwitcherComponent,
        AvatarComponent,
        ClientAvatar,
        UserAvatar,

        EmbedlistComponent,
        EmbedlistItemDirective,

        FileIconComponent,

        PageTitleSectionComponent,
        PageTitleHqComponent,

        InfoComponent,
        DateRangeComponent,

        TajPipe,

        RegeMarkdownComponent,
        MarkdownPopupComponent,

        PanelComponent,

        StatusTextComponent
    ],
    providers: [
        MarkdownPopupService
    ],
    entryComponents: [
        MarkdownPopupComponent
    ]
})
export class ComponentsModule {

}
