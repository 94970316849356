import { Component } from "@angular/core"

import { RichtextComponentParams } from "@anzar/core"
import { AbstractInlineComponent } from "../abstract"
import { MedicalExamFormComponent } from "./medical-exam-form.component"


const DISPLAY_IN_CONTENT = ["contact_name", "contact_tel", "section_title", "diagnosis"]


@Component({
    selector: "rege-rt-medical-exam",
    templateUrl: "./medical-exam.component.pug"
})
export class MedicalExamComponent extends AbstractInlineComponent<MedicalExamFormComponent> {
    public readonly formComponent = MedicalExamFormComponent
    public readonly mustFill = false
    public readonly paramsText: string[] = []

    public onParamsUpdate(params: RichtextComponentParams) {
        (this as { paramsText: string[] }).paramsText = this.renderParams(params)
        super.onParamsUpdate(params)
    }

    public renderParams(params: RichtextComponentParams): string[] {
        let parts: any[] = []

        for (const name of DISPLAY_IN_CONTENT) {
            const v = params[name]
            if (v) {
                parts.push(v)
            }
        }

        return parts
    }
}
