import { Inject, Injectable } from "@angular/core"

import { BehaviorSubject, Observable, of, Subject } from "rxjs"
import { map, shareReplay, switchMap } from "rxjs/operators"

import { format } from "date-fns"

import { Destructible, FileDownloadService, LoadFields, ToastService } from "@anzar/core"

import { Provider, ProviderBackend } from "@backend/org.api"

declare const BACKEND_URL: string

export type DispatcherStatType = "daily" | "monthly"

const PROVIDER_FIELDS: LoadFields<Provider> = ["type", "kenyszi_type"]

@Injectable()
export class StatsService extends Destructible {
    public set sectionIds(val: number[]) {
        if (JSON.stringify(this._sectionIds) !== JSON.stringify(val)) {
            this._sectionIds = val
            this._sectionIdsChange.next(val)
        }
    }
    public get sectionIds(): number[] {
        return this._sectionIds
    }
    private _sectionIds: number[]

    public set providerTypes(val: string[]) {
        if (this._providerTypes.value !== val) {
            this._providerTypes.next(val)
        }
    }
    public get providerTypes(): string[] {
        return this._providerTypes.value
    }
    private _providerTypes = new BehaviorSubject<string[]>(null)

    private readonly _sectionIdsChange = this.destruct.subject(new Subject<number[]>())
    public readonly sectionIds$ = this._sectionIdsChange.pipe(shareReplay(1))

    private readonly providers$: Observable<Provider[]> = this.sectionIds$.pipe(
        switchMap(ids => {
            if (!ids || ids.length === 0) {
                return of([])
            }
            return this.providerBackend.search({ filter: { parent_id: { in: ids } } }, { loadFields: PROVIDER_FIELDS })
        }),
        shareReplay(1)
    )

    public readonly providerTypes$ = this._providerTypes.pipe(
        switchMap(types => {
            if (types && types.length) {
                return of(types)
            } else {
                return this.providers$.pipe(
                    map(result => result.map(r => r.type).filter((v, i, a) => a.indexOf(v) === i))
                )
            }
        }),
        shareReplay(1)
    )

    public readonly providerKenysziTypes$ = this.providers$.pipe(
        map(result => result.map(r => r.kenyszi_type).filter((v, i, a) => a.indexOf(v) === i)),
        shareReplay(1)
    )

    public constructor(
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(ProviderBackend) private readonly providerBackend: ProviderBackend
    ) {
        super()
    }

    public dispatcher(type: DispatcherStatType, begin: Date, end: Date) {
        const beginf = format(begin, "yyyy-MM-dd")
        const endf = format(end, "yyyy-MM-dd")
        return this._download(`${BACKEND_URL}/stats/dispatcher/${this._sectionPart()}/${type}/${beginf}/${endf}`)
    }

    public dispatcherCapacity(settingsId: string, begin: Date, end: Date) {
        const beginf = format(begin, "yyyy-MM-dd")
        const endf = format(end, "yyyy-MM-dd")
        return this._download(
            `${BACKEND_URL}/stats/dispatcher-capacity/${settingsId}/${this._sectionPart()}/${beginf}/${endf}`
        )
    }

    public bno_excel(begin: Date, end: Date) {
        const beginf = format(begin, "yyyy-MM-dd")
        const endf = format(end, "yyyy-MM-dd")
        return this._download(`${BACKEND_URL}/stats/bno_excel/${this._sectionPart()}/${beginf}/${endf}`)
    }

    public bno_case_study(begin: Date, end: Date) {
        const beginf = format(begin, "yyyy-MM-dd")
        const endf = format(end, "yyyy-MM-dd")
        return this._download(`${BACKEND_URL}/stats/bno_case_study/${this._sectionPart()}/${beginf}/${endf}`)
    }

    public street_care_duty(begin: Date, end: Date) {
        const beginf = format(begin, "yyyy-MM-dd")
        const endf = format(end, "yyyy-MM-dd")
        return this._download(`${BACKEND_URL}/stats/scd/${this._sectionPart()}/${beginf}/${endf}`)
    }

    public street_care_sum(begin: Date, end: Date) {
        const beginf = format(begin, "yyyy-MM-dd")
        const endf = format(end, "yyyy-MM-dd")
        return this._download(`${BACKEND_URL}/stats/scs/${this._sectionPart()}/${beginf}/${endf}`)
    }

    public usage(begin: Date, end: Date, compareFrom: Date, compareTo: Date, groupByKenyszi: boolean) {
        const beginf = format(begin, "yyyy-MM-dd")
        const endf = format(end, "yyyy-MM-dd")
        const cfrom = format(compareFrom, "yyyy-MM-dd")
        const cto = format(compareTo, "yyyy-MM-dd")
        const g = groupByKenyszi ? "true" : "false"
        return this._download(
            `${BACKEND_URL}/stats/usage/${this._sectionPart()}/${beginf}/${endf}/${cfrom}/${cto}/${g}`
        )
    }

    public daytimeShelter(begin: Date, end: Date) {
        const beginf = format(begin, "yyyy-MM-dd")
        const endf = format(end, "yyyy-MM-dd")
        return this._download(`${BACKEND_URL}/stats/daytime_shelter/${this._sectionPart()}/${beginf}/${endf}`)
    }

    public kenysziMarked(begin: Date, end: Date) {
        const beginf = format(begin, "yyyy-MM-dd")
        const endf = format(end, "yyyy-MM-dd")
        return this._download(`${BACKEND_URL}/stats/kenyszi-marked/${this._sectionPart()}/${beginf}/${endf}`)
    }

    public rsztop(begin: Date, end: Date) {
        const beginf = format(begin, "yyyy-MM-dd")
        const endf = format(end, "yyyy-MM-dd")
        return this._download(`${BACKEND_URL}/stats/rsztop/${this._sectionPart()}/${beginf}/${endf}`)
    }

    public roomUsage(begin: Date, end: Date) {
        const beginf = format(begin, "yyyy-MM-dd")
        const endf = format(end, "yyyy-MM-dd")
        return this._download(`${BACKEND_URL}/stats/service_room_usage/${this._sectionPart()}/${beginf}/${endf}`)
    }

    public workers(filter: any, sorter: any) {
        return this._download(
            // eslint-disable-next-line max-len
            `${BACKEND_URL}/stats/workers/${encodeURIComponent(JSON.stringify(filter))}/${encodeURIComponent(JSON.stringify(sorter))}`,
            "Munkatársak exportálása"
        )
    }

    private _download(url: string, msg?: string) {
        this.destruct
            .subscription(this.downloader.download(url))
            .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: msg || "Statisztika generálása" }))
            .subscribe()
    }

    private _sectionPart(): string {
        const part: { [key: string]: any } = {}
        part.level_ids = this.sectionIds
        part.provider_types = this.providerTypes
        return JSON.stringify(part)
    }
}
