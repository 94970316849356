import { Component, Inject, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core"
import { take } from "rxjs/operators"

import { FileDownloadService, ToastService } from "@anzar/core"
import { DocumentBackend, ClientBanBackend, FillBackend } from "@backend/documents.api"
import { LevelBackend } from "@backend/org.api"
import { FsService } from "@pyzar/fs.module"
import { DocumentItem } from "../abstract"
import { DocumentsService } from "../documents.service"

@Component({
    selector: ".rege-doc-item-fill",
    templateUrl: "./fill-item.component.pug"
})
export class FillItemComponent extends DocumentItem {
    public constructor(
        @Inject(DocumentBackend) docBackend: DocumentBackend,
        @Inject(DocumentsService) docSvc: DocumentsService,
        @Inject(FsService) fsService: FsService,
        @Inject(ToastService) toast: ToastService,
        @Inject(FileDownloadService) downSvc: FileDownloadService,
        @Inject(ClientBanBackend) protected readonly clientBanBackend: ClientBanBackend,
        @Inject(LevelBackend) protected readonly levelBackend: LevelBackend,
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(FillBackend) private readonly fillBackend: FillBackend) {
        super(docBackend, docSvc, fsService, toast, downSvc)
    }

    public remove() {
        this.fillBackend.remove({ id: this.document.fill.id }).subscribe(this.reload.bind(this))
    }
}
