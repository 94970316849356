import { Component, Inject, Input, OnChanges, SimpleChanges, ElementRef } from "@angular/core"
import { Router } from "@angular/router"
import { BehaviorSubject, merge, of } from "rxjs"
import { switchMap, shareReplay, map, tap } from "rxjs/operators"

import { KenysziReportBackend } from "@backend/kenyszi.api"
import { Provider } from "@backend/org.api"


@Component({
    selector: ".rege-kenyszi-preports",
    templateUrl: "./problematic-reports.component.pug"
})
export class ProblematicReportsComponent implements OnChanges {
    @Input() public until: Date
    @Input() public sectionId: number
    @Input() public providerId: number

    private until$ = new BehaviorSubject<Date>(null)
    private sectionId$ = new BehaviorSubject<number>(null)
    private providerId$ = new BehaviorSubject<number>(null)

    public readonly problematic$ = this.until$.pipe(
        switchMap(date => {
            if (!date) {
                return of([])
            }
            return merge(
                this.sectionId$.pipe(switchMap(sectionId => {
                    return sectionId
                        ? this.reportBackend.get_problematic_reports({ until: date, section_id: sectionId })
                        : of([])
                })),
                this.providerId$.pipe(switchMap(providerId => {
                    return providerId
                        ? this.reportBackend.get_problematic_reports({ until: date, provider_id: providerId })
                        : of([])
                }))

            )
        }),
        map(v => {
            this._displayGrouping = v[0]
            const problems = v[1] || []
            this.el.nativeElement.style.display = problems.length > 0 ? null : "none"
            return problems
        }),
        shareReplay(1)
    )

    public readonly entries$ = this.problematic$.pipe(
        map(problems => {
            let groups: { [key: string]: any } = {}
            for (const problem of problems) {
                const providers: Provider[] = problem[0].sort((a: any, b: any) => a.position - b.position)
                const key = `${providers.map(v => v.id).join(",")}`
                if (key in groups) {
                    groups[key].entries.push(problem[1])
                } else {
                    groups[key] = {
                        title: providers.map(p => p.title).join(", "),
                        ids: key,
                        entries: [problem[1]],
                        position: providers[0].position
                    }
                }
            }

            return Object.keys(groups)
                .map(key => groups[key])
                .sort((a: any, b: any) => {
                    if (a.position === b.position) {
                        return (a.date as string).localeCompare(b.date)
                    } else {
                        return a.position - b.position
                    }
                })
        }),
        shareReplay(1))

    public _displayGrouping: boolean = false

    public constructor(
        @Inject(KenysziReportBackend) private readonly reportBackend: KenysziReportBackend,
        @Inject(Router) private readonly router: Router,
        @Inject(ElementRef) private readonly el: ElementRef<HTMLElement>) {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if ("until" in changes) {
            this.until$.next(changes.until.currentValue)
        }

        if ("sectionId" in changes) {
            this.sectionId$.next(changes.sectionId.currentValue)
        }

        if ("providerId" in changes) {
            this.providerId$.next(changes.providerId.currentValue)
        }
    }

    public entryText(entry: any) {
        return `${entry.total} főből ${entry.added} fő jelentve${entry.missing ? " (kevés)" : ""}`
    }

    public goToEntry(group: any, entry: any) {
        this.router.navigate(["kenyszi/jelentes", group.ids, entry.date])
    }
}
