import dateFnsLocaleHU from "date-fns/locale/hu"

import { NgModule, LOCALE_ID } from "@angular/core"
import { CommonModule, registerLocaleData } from "@angular/common"
import localeHu from "@angular/common/locales/hu"
import { RouterModule, RouteReuseStrategy } from "@angular/router"
import { BrowserModule } from "@angular/platform-browser"
import { HttpClientXsrfModule, HTTP_INTERCEPTORS } from "@angular/common/http"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { PortalModule } from "@angular/cdk/portal"


import { NzModule, LOCALE_DATE, DEFAULT_ERROR_MESSAGES, FORM_FIELD_DEFAULT_VARIANT, FormFieldVariant } from "@anzar/core"
import { HTTPTransport, HTTPClient, RpcInterceptor } from "@anzar/rpc"

import { AuthModule } from "@pyzar/auth.module"
import { PyzarCommonModule, RpcErrorHandler } from "@pyzar/common.module"
import { PyzarTaskModule } from "@pyzar/task.module"

import { AppComponent } from "./app.component"
import { ROUTES, RegeRouteReuseStrategy } from "./routes"
import { AuthorizedViewport } from "./viewport/authorized.viewport"
import { PublicViewport } from "./viewport/public.viewport"


import { MaltaCommonModule, SentryService, SentryRpcBreadcrumb } from "./common"
import { FeedbackModule } from "./feedback.module"
import { ComponentsModule } from "./components"
import { SectionServiceModule } from "./section.service"
import { OrgModule } from "./org.module"
import { MessageModule } from "./message.module"
import { NotificationModule } from "./notification.module"
import { DutyModule } from "./duty.module"
import { ServiceModule } from "./service.module"
import { DispatcherModule } from "./dispatcher.module"
import { MarkModule } from "./mark.module"
import { ClientFeddScreenModule } from "./client-feed.screen"
import { ClientModule } from "./client.module"
import { KenysziModule } from "./kenyszi.module"
import { SelectSectionScreenModule } from "./select-section.screen"
import { StatsModule } from "./stats.module"
import { WorkerModule } from "./worker.module"
// import { DocumentModule } from "./document.module"
import { FsModule, FILE_DOWNLOAD_ENDPOINT, FILE_UPLOAD_ENDPOINT, FILE_INLINE_ENDPOINT, FILE_IMAGE_ENDPOINT } from "@pyzar/fs.module"
import { ImageModule } from "./image.module"
import { HelpModule } from "./help.module"
import { ConnectModule } from "./connect.module"
import { Critical_messageApi } from "@backend/critical_message.api"

registerLocaleData(localeHu, "hu")

// const APIS = [
//     ...MARK_REQUIRED_APIS
// ].filter((v, i, a) => a.indexOf(v) === i)


// const COMPONENTS = [
//     ...MARK_COMPONENTS
// ].filter((v, i, a) => a.indexOf(v) === i)


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(ROUTES, {
            enableTracing: false,
            onSameUrlNavigation: "reload",
            relativeLinkResolution: "corrected",
            useHash: false
        }),
        HttpClientXsrfModule.withOptions({
            cookieName: "Rege-Xsrf-Token",
            headerName: "Rege-Xsrf-Token"
        }),
        PortalModule,
        NzModule.forRoot(),
        PyzarCommonModule,
        PyzarTaskModule,
        ComponentsModule,
        AuthModule,
        SectionServiceModule,
        OrgModule,
        MessageModule,
        NotificationModule,
        DutyModule,
        ServiceModule,
        MaltaCommonModule.forRoot(),
        FeedbackModule,
        DispatcherModule,
        ClientFeddScreenModule,
        ClientModule,
        KenysziModule,
        SelectSectionScreenModule,
        MarkModule,
        StatsModule,
        ConnectModule,
        // documentModule,
        FsModule,
        ImageModule,
        HelpModule,
        WorkerModule,

        // ...APIS
        Critical_messageApi,
    ],
    declarations: [
        AppComponent,
        AuthorizedViewport,
        PublicViewport,
    ],
    exports: [
        MarkModule
        // ...COMPONENTS
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "hu" },
        HTTPTransport,
        HTTPClient,
        { provide: HTTPTransport.ENDPOINT, useValue: RPC_SERVICE_URL },
        { provide: RpcInterceptor, useClass: RpcErrorHandler, multi: true },
        { provide: RpcInterceptor, useClass: SentryRpcBreadcrumb, multi: true },
        { provide: LOCALE_DATE, useValue: dateFnsLocaleHU },
        { provide: SentryService.DSN, useValue: __SENTRY_DSN__ },
        { provide: SentryService.ENV, useValue: __SENTRY_ENV__ },
        { provide: SentryService.PLATFORM, useValue: __PLATFORM__ },
        {
            provide: DEFAULT_ERROR_MESSAGES,
            useValue: {
                required: "A mező kitöltése kötelező",
                invalidDatetime: "Hibás időpont",
            }
        },
        { provide: FILE_UPLOAD_ENDPOINT, useValue: FILE_UPLOAD_URL },
        { provide: FILE_DOWNLOAD_ENDPOINT, useValue: FILE_DOWNLOAD_URL },
        { provide: FILE_INLINE_ENDPOINT, useValue: FILE_INLINE_URL },
        { provide: FILE_IMAGE_ENDPOINT, useValue: FILE_IMAGE_URL },
        { provide: RouteReuseStrategy, useClass: RegeRouteReuseStrategy },
        { provide: FORM_FIELD_DEFAULT_VARIANT, useValue: new FormFieldVariant(true, false, true, false) }
        // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorDialogsService, multi: true },
        // { provide: RpcTransport, useClass: ExtjsTransport },
        // { provide: RPC_ENDPOINT, useValue: RPC_SERVICE_URL }
    ],
    entryComponents: [

    ]
})
export class AppModule {

}
