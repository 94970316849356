import { NgModule } from "@angular/core"
import { CriticalMessage, CriticalMessageBackend } from "./__anzar_rpc_output"

export { CriticalMessage, CriticalMessageBackend }


@NgModule({
    providers: [
        CriticalMessageBackend
    ],
})
export class Critical_messageApi {}