import { Component, Inject, ChangeDetectorRef, ViewChild, Input, OnInit, Output, EventEmitter } from "@angular/core"
import { Location } from "@angular/common"
import { ActivatedRoute, Router } from "@angular/router"
import { take } from "rxjs/operators"

import { Destructible, DialogService, ToastService } from "@anzar/core"
import { Story, StoryBackend, StoryEntry, StoryEntryBackend } from "@backend/dispatcher.api"
import { StoryEntryListComponent } from "./story-entry-list.component"
import { StoryFormComponent } from "./story-form.component"
import { CreateMode, StoriesService, StoryFormOptions } from "./stories.service"
import { ActionEvent } from "./story-entry-card.component"


const FORM_DATA = Symbol("@StoryFormData")


@Component({
    selector: ".rege-disp-story",
    templateUrl: "./story.component.pug"
})
export class StoryComponent extends Destructible implements OnInit {
    @ViewChild("entryList", { read: StoryEntryListComponent, static: false }) public readonly entryList: StoryEntryListComponent
    @ViewChild("entryForm", { read: StoryFormComponent, static: true }) public readonly entryForm: StoryFormComponent

    public set createMode(val: CreateMode) {
        if (this._createMode !== val) {
            this._createMode = val
            this.cdr.markForCheck()
        }
    }
    public get createMode(): CreateMode { return this._createMode }
    private _createMode: CreateMode

    @Input()
    public set story(val: Story) {
        if (this._story !== val) {
            this._story = val
        }
    }
    public get story(): Story { return this._story }
    private _story: Story

    public constructor(
        @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
        @Inject(Router) private readonly router: Router,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(Location) private readonly location: Location,
        @Inject(StoryBackend) private readonly storyBackend: StoryBackend,
        @Inject(DialogService) private readonly dialogSvc: DialogService,
        @Inject(StoryEntryBackend) private readonly entryBackend: StoryEntryBackend,
        @Inject(StoriesService) private readonly storiesSvc: StoriesService,
        @Inject(ToastService) private readonly toast: ToastService) {
        super()

        this.destruct.subscription(storiesSvc.reload$).subscribe(model => {
            if (model && model.id === this.story.id) {
                this.entryList.reload()
            }
        })

        this.destruct.any(() => {
            if (this.createMode) {
                this._setTmpFormData({ createMode: this.createMode, data: this.entryForm.form.value })
            } else {
                this._setTmpFormData(null)
            }
        })
    }

    public ngOnInit() {
        const initWith = this._getTmpFormData() || this.storiesSvc.initFormWith
        this.createMode = initWith.createMode
        if (initWith.data) {
            if (this.entryForm.setData(initWith.data)) {
                this.entryForm.form.markAllAsTouched()
                this.entryForm.form.markAsDirty()
            }
        }
    }

    public closeStory() {
        this._setStoryStatus(this.story, "done")
    }

    public openStory() {
        this._setStoryStatus(this.story, "progress")
    }

    public onCancel() {
        this.createMode = null
        this._setTmpFormData(null)
        this.storiesSvc.resetFormInit()
        if (!this.story.id) {
            this.storiesSvc.removeUnsaved()
        }
    }

    public onSave(entry: StoryEntry) {
        this._setTmpFormData(null)
        this.storiesSvc.resetFormInit()
        if (!this.story.id) {
            this.storiesSvc.reload()
        } else {
            this.storiesSvc.reloadStory(this.story)
        }
    }

    public doAction(event: ActionEvent) {
        switch (event.action) {
            case "callback":
                return this.doCallback(event.entry)

            case "edit":
                return this.doEditEntry(event.entry)

            case "remove":
                return this.doRemove(event.entry)

            case "move":
                return this.storiesSvc.startMoveStoryEntry(event.entry)

            case "moveIntoNewStory":
                return this.entryBackend.move_into_new_story({ entry_id: event.entry.id })
                    .pipe(take(1), this.toast.handleSave({ align: "bottom center" }))
                    .subscribe(res => {
                        if (res) {
                            this.storiesSvc.reload()
                            this.entryList.reload()
                        }
                    })
        }
    }

    public doCallback(entry: StoryEntry) {
        if (!this.entryForm.callbackIsSafe(entry)) {
            this.dialogSvc
                .confirm("Megerősítés", "Biztosan elkezded az újrahívást? A jelenlegi adatok egy része felül lesz írva a formban!")
                .pipe(take(1))
                .subscribe(ok => {
                    if (ok) {
                        this.createMode = "outgoing"
                        this.entryForm.startCallback(entry)
                    }
                })
        } else {
            this.createMode = "outgoing"
            this.entryForm.startCallback(entry)
        }
    }

    public doEditEntry(entry: StoryEntry) {
        if (this.entryForm.form.dirty) {
            this.dialogSvc
                .confirm("Megerősítés", "Biztosan elkezded a módosítást? A jelenlegi adatok törlődni fognak a formból!")
                .pipe(take(1))
                .subscribe(ok => {
                    if (ok) {
                        this.createMode = entry.is_incoming ? "incoming" : "outgoing"
                        this.entryForm.storyEntryId = entry.id
                    }
                })
        } else {
            this.createMode = entry.is_incoming ? "incoming" : "outgoing"
            this.entryForm.storyEntryId = entry.id
        }
    }

    public doRemove(entry: StoryEntry) {
        this.entryBackend.remove({ id: entry.id })
            .pipe(take(1), this.toast.handleSave({ align: "bottom center" }))
            .subscribe(res => {
                if (res) {
                    this.storiesSvc.reloadStory(this.story)
                }
            })
    }

    private _setStoryStatus(story: Story, status: "progress" | "done") {
        this.storyBackend
            .set_status({ id: story.id, status: status })
            .pipe(take(1))
            .subscribe(result => {
                story.status = result
                this.cdr.detectChanges()
                this.storiesSvc.reloadStory(story)
            })
    }

    private _getTmpFormData(): StoryFormOptions {
        return (this.story as any)[FORM_DATA]
    }

    private _setTmpFormData(val: StoryFormOptions) {
        (this.story as any)[FORM_DATA] = val
    }
}
