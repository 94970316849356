import { Injectable, Inject } from "@angular/core"
import { take, switchMap } from "rxjs/operators"

import { LayerService, MediaQueryService, ModalLayer, FullscreenLayer } from "@anzar/core"
import { HelpWindowComponent } from "./help-window.component"


@Injectable({ providedIn: "root" })
export class HelpService {
    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(MediaQueryService) private readonly mq: MediaQueryService) {

    }

    public show() {
        this.mq.watch("xs")
            .pipe(
                take(1),
                switchMap(xs => {
                    const behavior = xs.matches
                        ? new FullscreenLayer({})
                        : new ModalLayer({
                            backdrop: { type: "filled", hideOnClick: true },
                            closeable: false,
                            elevation: 10,
                            rounded: 3,
                            position: {
                                align: "top center",
                                anchor: {
                                    ref: "viewport",
                                    align: "top center"
                                },
                                constraint: {
                                    ref: "viewport",
                                    inset: 16
                                }
                            }
                        })

                    const ref = this.layerSvc.createFromComponent(HelpWindowComponent, behavior)
                    ref.show()
                    return ref.output
                })
            )
            .subscribe()
    }

    public hide() {

    }
}
