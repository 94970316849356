import { Injectable } from "@angular/core"
import { Subject } from "rxjs"


@Injectable({ providedIn: "root" })
export class MessageService {
    public readonly invalidated = new Subject<void>()

    public invalidate() {
        this.invalidated.next()
    }
}
