import { Component, Inject, ChangeDetectorRef } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { of, NEVER } from "rxjs"
import { take, switchMap, mapTo, catchError } from "rxjs/operators"

import { LayerRef, ToastService, Destructible, FileDownloadService } from "@anzar/core"
import { DocumentBackend, TemplateBackendSource, FillBackend } from "@backend/documents.api"
import { SectionBackendSource } from "@backend/org.api"
import { Client } from "@backend/client.api"
import { CurrentSection } from "../../../section.service/level"
import { FsService } from "@pyzar/fs.module"


@Component({
    selector: "rege-doc-form-fill",
    templateUrl: "./fill-form.component.pug"
})
export class FillFormComponent extends Destructible {
    public readonly form = new FormGroup({
        template_id: new FormControl(),
        section_id: new FormControl(),
    })

    public set busy(val: boolean) {
        if (this._busy !== val) {
            this._busy = val
            this.cdr.detectChanges()
        }
    }
    public get busy(): boolean { return this._busy }
    private _busy: boolean

    public constructor(
        @Inject(Client) private readonly client: Client,
        @Inject(CurrentSection) section: CurrentSection,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(TemplateBackendSource) public readonly tplSrc: TemplateBackendSource,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(FillBackend) private readonly fillBackend: FillBackend,
        @Inject(SectionBackendSource) public readonly sectionSrc: SectionBackendSource,
        @Inject(FsService) private readonly fsService: FsService,
        @Inject(FileDownloadService) public readonly downloader: FileDownloadService) {
        super()

        this.destruct.subscription(section.id$).subscribe(sectionId => {
            this.form.get("section_id").setValue(sectionId)
        })
    }

    public upload() {
        this._save()
            .pipe(this.toastSvc.handleSave({ align: "bottom center" }))
            .subscribe(() => {
                this.layerRef.close()
            })
    }

    public download() {
        this._save()
            .pipe(catchError(err => {
                this.toastSvc.error(err.message)
                return NEVER
            }))
            .subscribe(([fill, file]) => {
                this.downloader
                    .download(this.fsService.getDownloadUrl(file))
                    .pipe(this.toastSvc.handleFileDownload({ align: "bottom center" }))
                    .pipe(switchMap(event => {
                        if (event.state === "done") {
                            return this.fillBackend.remove({ id: fill.id }).pipe(mapTo(event))
                        } else {
                            return of(event)
                        }
                    }))
                    .subscribe(event => {
                        event.state === "done" && this.layerRef.close()
                    })

            })
    }

    private _save() {
        return this.fillBackend
            .fill({
                client_id: this.client.id,
                section_id: this.form.value.section_id,
                template_id: this.form.value.template_id
            })
            .pipe(take(1))
    }



    public cancel() {
        this.layerRef.close()
    }
}
