import { Component, Inject, OnDestroy, Input, ChangeDetectorRef, OnInit, ChangeDetectionStrategy } from "@angular/core"
import { merge } from "rxjs"
import { isSameDay } from "date-fns"

import { Destruct, DataSourceDirective, LoadFields } from "@anzar/core"
import { Event } from "@backend/event.api"
import { DISPLAY_TEXT, DISPLAY_SERVICES } from "./client-feed-entry.component"


const EVENT_FIELDS: LoadFields<Event> = [
    "id",
    "type",
    "created_time",
    "updated_time",
    "subject_time",
    "richtext_id",
    "place_extra",
    { richtext: ["text", "content"] as any },
    { services: [{ provider_service: [{ service: ["title"] }] }] },
    { section: ["id", "title"] },
    { place: ["id", "description"] },
    { creator: ["id", "name"] },
    { client: ["id", "name"] },
    { mark: ["id", "icon"] },
]


@Component({
    selector: ".rege-client-feed-list",
    templateUrl: "./client-feed-list.template.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientFeedListComponent implements OnDestroy, OnInit {
    public readonly destruct = new Destruct()

    @Input() public displayMode: number = DISPLAY_TEXT | DISPLAY_SERVICES

    public constructor(
        @Inject(DataSourceDirective) public readonly source: DataSourceDirective<Event>,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        source.loadFields(EVENT_FIELDS)
    }

    public ngOnInit() {
        const storage = this.source.storage
        this.destruct.subscription(merge(storage.busy, storage.empty)).subscribe(() => {
            this.cdr.detectChanges()
        })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    public dateHeadRequired(prev: Date, current: Date) {
        return !prev || !isSameDay(prev, current)
    }
}
