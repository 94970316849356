import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Location } from "@angular/common"
import { take } from "rxjs/operators"


import { Destructible, ToastService } from "@anzar/core"
import { Institution } from "@backend/org.api"
import { AbstractCLEditor, AbstractCLEditorService } from "@pyzar/common.module"
import { OrgService } from "../org.service"
import { InstitutionEditorService } from "./institution-editor.service"


@Component({
    templateUrl: "./institution.screen.pug",
    providers: [
        { provide: AbstractCLEditorService, useClass: InstitutionEditorService }
    ]
})
export class InstitutionScreen extends Destructible implements OnInit, AfterViewInit {
    @ViewChild("editor", { static: true }) public readonly editor: AbstractCLEditor
    public readonly buttonsBarVisible: boolean = true
    public readonly canSaveEditor: boolean = true
    public readonly institution: Institution

    public constructor(
        @Inject(AbstractCLEditorService) private readonly service: InstitutionEditorService,
        @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(Location) private readonly location: Location,
        @Inject(OrgService) private readonly orgService: OrgService,
        @Inject(ToastService) private readonly toast: ToastService) {
        super()
    }

    public ngAfterViewInit() {
        this.destruct.subscription(this.editor.status$).subscribe(_ => {
            this.cdr.detectChanges()
        })
    }

    public ngOnInit() {
        this.destruct.subscription(this.service.institution$).subscribe(institution => {
            (this as { institution: Institution }).institution = institution
            this.cdr.detectChanges()
        })

        this.destruct.subscription(this.route.queryParams).subscribe(params => {
            this.service.parentId = Number(params.parentId)
        })

        this.destruct.subscription(this.route.params).subscribe(params => {
            this.service.id = Number(params["id"])
        })
    }

    public save() {
        this.editor
            .save()
            .pipe(
                take(1),
                this.toast.handleSave({ align: "bottom center", successMsg: "A mentés sikeresen megtörtént" })
            )
            .subscribe(() => {
                this.location.replaceState(this.orgService.getEditorUrlFor("institution", this.service.id))
                this.service.reloadInstitution()
            })
    }
}
