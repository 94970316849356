import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { RouterModule, Route } from "@angular/router"
import { NzModule } from "@anzar/core"

import { WorkerApi } from "@backend/worker.api"
import { PyzarApi } from "@backend/pyzar.api"

import { ClientModule } from "../client.module"
import { WorkerModule } from "../worker.module"
import { ComponentsModule } from "../components"
import { RichtextModule } from "../richtext.module"

import { MessageBoardScreen } from "./message-board.screen"
import { MessageListComponent } from "./message-list.component"
import { MessageComponent } from "./message.component"
import { MessageAddComponent } from "./message-add.component"
import { MessagesNarrowComponent } from "./messages-narrow.component"
import { AliasPopupComponent } from "./alias-popup.component"


export const MESSAGE_ROUTES: Route[] = [
    { path: "", component: MessageBoardScreen }
]


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NzModule,
        ClientModule,
        WorkerModule,
        ComponentsModule,
        WorkerApi,
        PyzarApi,
        RichtextModule,
    ],
    declarations: [
        MessageBoardScreen,
        MessageListComponent,
        MessageComponent,
        MessageAddComponent,
        MessagesNarrowComponent,
        AliasPopupComponent
    ],
    exports: [
        MessageBoardScreen,
        MessageListComponent,
        MessageComponent,
        MessageAddComponent,
        MessagesNarrowComponent,
        AliasPopupComponent
    ],
    entryComponents: [
        AliasPopupComponent
    ]
})
export class MessageModule {

}
