import { Component, Inject } from "@angular/core"
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { startOfMonth, endOfMonth } from "date-fns"

import { StatsService } from "../stats.service"


@Component({
    selector: ".rege-stats-daytime_shelter",
    templateUrl: "./daytime_shelter.component.pug"
})
export class DaytimeShelterComponent {
    public readonly form = new FormGroup({
        date_from: new FormControl(startOfMonth(new Date()), [Validators.required]),
        date_to: new FormControl(endOfMonth(new Date()), [Validators.required]),
    })

    public constructor(
        @Inject(StatsService) private readonly stats: StatsService) {
    }

    public download() {
        this.stats.daytimeShelter(this.form.get("date_from").value, this.form.get("date_to").value)
    }
}
