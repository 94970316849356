import { Component, Input, Inject } from "@angular/core"

import { Client } from "@backend/client.api"


@Component({
    selector: "rege-create-client",
    templateUrl: "./create-client.component.pug"
})
export class CreateClientComponent {
    @Input() public showLoadButton: boolean = true

    constructor(@Inject(Client) public readonly client: Client) {

    }
}
