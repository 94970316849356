import { Component, Inject, forwardRef, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { tap } from "rxjs/operators"

import { RegionBackend, Region, LevelBackendSource } from "@backend/org.api"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { RegionEditorService } from "./region-editor.service"


@Component({
    selector: ".rege-region-basic-data",
    templateUrl: "./region-basic-data.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => RegionBasicDataComponent) }
    ]
})
export class RegionBasicDataComponent extends AbstractCLEditorBlock<RegionEditorService> {
    public readonly region: Region

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: RegionEditorService,
        @Inject(RegionBackend) protected readonly regionBackend: RegionBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(LevelBackendSource) public readonly levelSrc: LevelBackendSource) {
        super(editorSvc, new FormGroup({
            parent_id: new FormControl(),
            title: new FormControl()
        }))
    }

    public ngOnInit() {
        super.ngOnInit()
        this.destruct.subscription(this.editorSvc.region$).subscribe(region => {
            (this as { region: Region }).region = region
            this.form.reset({
                parent_id: region ? region.parent_id : this.editorSvc.parentId,
                title: region ? region.title : null,
            })
            this.cdr.detectChanges()
        })
    }

    public save() {
        let data = this.form.value

        if (this.region) {
            data.id = this.region.id
        }

        return this.regionBackend.save({ data }).pipe(tap(level => {
            this.editorSvc.id = level.id
        }))
    }
}
