import { Component } from "@angular/core"

import { RichtextComponentParams } from "@anzar/core"
import { AbstractInlineComponent } from "../abstract"
import { PublicServiceFormComponent } from "./public-service-form.component"


const DISPLAY_IN_CONTENT = ["contact_name", "contact_id", "plate_number", "transport", "text"]


@Component({
    selector: "rege-rt-public-service",
    templateUrl: "./public-service.component.pug"
})
export class PublicServiceComponent extends AbstractInlineComponent<PublicServiceFormComponent> {
    public readonly formComponent = PublicServiceFormComponent
    public readonly mustFill = false
    public readonly paramsText: string[] = []

    public onParamsUpdate(params: RichtextComponentParams) {
        (this as { paramsText: string[] }).paramsText = this.renderParams(params)
        super.onParamsUpdate(params)
    }

    public renderParams(params: RichtextComponentParams): string[] {
        let parts: any[] = []

        for (const name of DISPLAY_IN_CONTENT) {
            const v = params[name]
            if (v) {
                parts.push(v)
            }
        }

        return parts
    }
}
