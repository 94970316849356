import { Component, Inject, ChangeDetectorRef, ViewChild } from "@angular/core"

import { Place } from "@backend/place.api"
import { PlaceEditorComponent, PlaceCoords } from "./place-editor.component"
import { PlaceListComponent } from "./place-list.component"



@Component({
    selector: ".rege-place-screen",
    host: {
        "[style.width]": "'100%'",
        "[style.height]": "'100%'",
    },
    templateUrl: "./place.screen.pug"
})
export class PlaceScreenComponent {
    @ViewChild("list", { read: PlaceListComponent, static: true }) public readonly list: PlaceListComponent
    @ViewChild("editor", { read: PlaceEditorComponent, static: true }) public readonly editor: PlaceListComponent

    public editable: boolean

    public editingPlace: PlaceCoords

    public showPlaces: PlaceCoords[] = []

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {

    }

    public editPlace(place: Place) {
        this.editable = true
        this.editingPlace = place
        this.cdr.markForCheck()
    }

    public stopEdit() {
        this.editable = false
        this.editingPlace = null
        this.cdr.markForCheck()
    }

    public onPlaceChange(place: Place) {
        const form = this.list.placeForm
        if (place) {
            form.reset({
                place: place,
                title: place.description
            })
        } else {
            form.reset()
        }
    }

    public onSelection(selected: Place[]) {
        this.editingPlace = selected[0] || null
        this.cdr.detectChanges()
    }
}
