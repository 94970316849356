import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Route } from "@angular/router"
import { AgmCoreModule } from "@agm/core"
import { NzModule } from "@anzar/core"

import { PlaceApi } from "@backend/place.api"

import { PlaceScreenComponent } from "./place.screen"
import { PlaceEditorComponent } from "./place-editor.component"
import { PlaceListComponent } from "./place-list.component"

import { PlaceAutocompleteSrc } from "./place-datasource"
import { PlaceInputComponent } from "./place-input.component"

import { PlaceInfoComponent, PlaceInfoService } from "./place-info.component"
export { PlaceInfoService }


import { CurrentLocation } from "./current-location.service"
export { CurrentLocation }

import { AddressInputComponent, AddressInputRequiredValidator } from "./address-input.component"
export { AddressInputComponent, AddressInputRequiredValidator }

import { MapComponent } from "./map.component"
export { MapComponent }

import { MapService } from "./map.service"
export { MapService }


export const PLACE_ROUTES: Route[] = [
    { path: "szerkesztes", component: PlaceScreenComponent }
]


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        PlaceApi,
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyCqyRks_fV3F-CERcFi3zDeidEL1pykaDY"
        })
    ],
    declarations: [
        PlaceScreenComponent,
        PlaceEditorComponent,
        PlaceListComponent,
        PlaceInputComponent,
        PlaceInfoComponent,
        AddressInputComponent,
        AddressInputRequiredValidator,
        MapComponent
    ],
    exports: [
        PlaceScreenComponent,
        PlaceEditorComponent,
        PlaceListComponent,
        PlaceInputComponent,
        PlaceInfoComponent,
        AddressInputComponent,
        AddressInputRequiredValidator,
        MapComponent
    ],
    providers: [
        CurrentLocation,
        PlaceAutocompleteSrc,
        MapService,
        PlaceInfoService
    ],
    entryComponents: [
        MapComponent,
        PlaceInfoComponent,
    ]
})
export class PlaceModule {

}
