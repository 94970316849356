import { Component, Inject } from "@angular/core"
import { Router } from "@angular/router"
import { shareReplay, map } from "rxjs/operators"

import { LoadFields } from "@anzar/core"
import { Level, LevelBackend, LevelBackendSource } from "@backend/org.api"
import { LEVEL_CHILD, LEVEL_TITLE, OrgService } from "./org.service"


const LEVEL_FIELDS: LoadFields<Level> = ["id", "parent_id", "variant", "title", "is_leaf"]


@Component({
    templateUrl: "./org.screen.pug"
})
export class OrgScreen {
    public readonly root$ = this.levelBackend
        .search({ filter: { variant: "global", parentId: null } }, { loadFields: LEVEL_FIELDS })
        .pipe(
            map(v => {
                return v && v.length ? v[0] : null
            }),
            shareReplay(1)
        )

    public readonly levelFields = LEVEL_FIELDS

    public constructor(
        @Inject(LevelBackend) private readonly levelBackend: LevelBackend,
        @Inject(LevelBackendSource) public readonly levelSrc: LevelBackendSource,
        @Inject(Router) private readonly router: Router,
        @Inject(OrgService) private readonly orgService: OrgService) {

        // this.root$.subscribe(root => {
        //     console.log(root)
        // })
    }

    public getNewTooltip(level: Level) {
        return `Új ${LEVEL_TITLE[LEVEL_CHILD[level.variant]]} hozzáadása`
    }

    public getLevelVariantTitle(variant: string): string {
        return LEVEL_TITLE[variant]
    }

    public onEdit(level: Level) {
        this.router.navigate([this.orgService.getEditorUrlFor(level)])
    }

    public addNewLevel(under: Level) {
        this.router.navigate([this.orgService.getEditorUrlFor(LEVEL_CHILD[under.variant])], { queryParams: { parentId: under.id } })
    }
}
