import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from "@angular/core"


import { ICON_COLOR_BY_ALERT_LEVEL, Alert } from "./mark.service"


@Component({
    selector: ".rege-alert-icon",
    templateUrl: "./alert-icon.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertIconComponent implements OnChanges {
    @Input() public alerts: Alert[]
    public render: Alert[] = []
    public unseenMaxLevel: number


    public ngOnChanges(changes: SimpleChanges) {
        if ("alerts" in changes) {
            let groupByIcon: { [key: string]: number } = {}
            let unseenMaxLevel: number = 0

            for (const alert of changes.alerts.currentValue as Alert[]) {
                if (alert.level) {
                    if (alert.icon) {
                        if (!groupByIcon[alert.icon]) {
                            groupByIcon[alert.icon] = alert.level
                        } else {
                            groupByIcon[alert.icon] = Math.max(groupByIcon[alert.icon], alert.level)
                        }
                    } else {
                        unseenMaxLevel = Math.max(unseenMaxLevel, alert.level)
                    }
                }
            }

            let render = Object.keys(groupByIcon)
                .map(k => ({ level: groupByIcon[k], icon: k }))
                .sort(sortAlerts)

            if (render.length > 4) {
                unseenMaxLevel = Math.max(render[3].level)
            } else if (render.length < 4 && unseenMaxLevel) {
                render.push({ icon: null, level: unseenMaxLevel })
            }

            this.render = render
            this.unseenMaxLevel = unseenMaxLevel
        }
    }
}

function sortAlerts(a: Alert, b: Alert) {
    if (b.level === a.level) {
        return a.icon.localeCompare(b.icon)
    } else {
        return b.level - a.level
    }
}
