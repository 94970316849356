import { Component, Input, Inject, OnDestroy, ViewChild, AfterViewInit, HostListener, ChangeDetectorRef } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Subscription } from "rxjs"
import { take, takeUntil } from "rxjs/operators"

import { Destruct, CheckboxComponent, CheckboxState } from "@anzar/core"
import { ProviderService } from "@backend/org.api"
import { DutyService, ServiceUsage } from "../duty.module"


@Component({
    selector: ".rege-service-checkbox",
    templateUrl: "./service-checkbox.component.pug"
})
export class SerivceCheckboxComponent implements OnDestroy, AfterViewInit {
    public readonly destruct = new Destruct()

    @ViewChild("chk", { read: CheckboxComponent, static: false }) public readonly chk: CheckboxComponent

    @Input()
    public set service(val: ProviderService) {
        if (this._service !== val) {
            this._service = val
            if (!this.label) {
                this.label = val.service.title
            }
            this.updateModel()
        }
    }
    public get service(): ProviderService { return this._service }
    private _service: ProviderService

    @Input()
    public set usage(val: ServiceUsage) {
        if (this._usage !== val) {
            this._usage = val

            if (this.usageChange) {
                this.usageChange.unsubscribe()
            }

            if (val) {
                this.usageChange = val.usageStream.subscribe(usage => {
                    this.updateModel()
                })
            }
        }
    }
    public get usage(): ServiceUsage { return this._usage }
    private _usage: ServiceUsage

    @Input()
    public set label(val: string) {
        if (this._label !== val) {
            this._label = val
        }
    }
    public get label(): string { return this._label }
    private _label: string

    // @Output() public readonly changed: Observable<ChangeEvent> = this.destruct.subject(new EventEmitter<ChangeEvent>())

    protected model = new FormControl()
    protected skipChange: number = 0
    protected usageChange: Subscription

    private pendingState: CheckboxState

    public constructor(
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(DutyService) protected readonly dutyService: DutyService) {
    }

    public ngAfterViewInit() {
        if (this.pendingState) {
            this.setState(this.pendingState.checked, this.pendingState.indeterminate)
            delete this.pendingState
        }
    }

    @HostListener("tap", ["$event"])
    public onTap(event: Event) {
        if (event.defaultPrevented) {
            return
        }
        event.preventDefault()

        if (this.chk.checked) {
            this.changing(false)
        } else {
            this.changing(true)
        }
    }

    protected setState(checked: boolean, indeterminate: boolean) {
        if (!this.chk) {
            this.pendingState = { checked, indeterminate }
        } else {
            this.chk.checked = checked
            this.chk.indeterminate = indeterminate
        }

        if (!this.destruct.done) {
            this.cdr.detectChanges()
        }
    }

    protected changing(enabled: boolean) {
        this.dutyService.setUsage(this.usage, this.service, enabled)
            .pipe(take(1), takeUntil(this.destruct.on))
            .subscribe(result => {
                this.setState(result.enabled, result.pending)
            })
    }

    protected updateModel() {
        if (this.usage && this.service) {
            let usage = this.usage.findUsage(this.service)
            if (usage) {
                this.setState(usage.enabled, usage.pending)
            } else {
                this.setState(false, false)
            }
        }
    }

    public ngOnDestroy() {
        this.cdr.detach()
        this.destruct.run()
        this.usage = null
    }
}
