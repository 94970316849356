import { Component, Input, Directive, Inject, HostBinding } from "@angular/core"

import { Client } from "@backend/client.api"
import { User } from "@backend/pyzar.api"
import { FsService } from "@pyzar/fs.module"


const SIZE_BY_VARIANT = {
    "very-tiny": 20,
    "tiny": 28,
    "small": 36,
    "medium": 48,
    "large": 60,
}


@Component({
    selector: ".rege-avatar",
    templateUrl: "./avatar.component.pug"
})
export class AvatarComponent {
    @Input()
    public image: string | null

    @Input()
    public text: string | null

    @Input()
    public set variant(val: string) {
        if (this._variant !== val) {
            this._variant = val
            this._size = (SIZE_BY_VARIANT as any)[val as any]
        }
    }
    public get variant(): string { return this._variant }
    private _variant: string = "small"

    public _size: number = SIZE_BY_VARIANT.small

    @HostBinding("attr.color")
    public get color(): number | null {
        if (this.text) {
            return this.text.split("")
                .map(v => v.charCodeAt(0))
                .reduce((t, v) => t + v) % 8
        } else {
            return null
        }
    }
}


@Directive({
    selector: ".rege-avatar[client]"
})
export class ClientAvatar {
    @Input()
    public set client(val: Client) {
        if (this._client !== val) {
            this._client = val
            this.avatar.image = null
            if (val.name && val.name.family) {
                this.avatar.text = `${val.name.family[0]}${val.name.given ? val.name.given[0] : ''}`
            } else {
                this.avatar.text = "--"
            }
        }
    }
    public get client(): Client { return this._client }
    private _client: Client

    public constructor(@Inject(AvatarComponent) protected readonly avatar: AvatarComponent) {

    }
}


@Directive({
    selector: ".rege-avatar[user]"
})
export class UserAvatar {
    @Input()
    public set user(val: User) {
        if (this._user !== val) {
            if (this._user = val) {
                if (val.profile_image_id) {
                    this.avatar.image = this.fsService.getImageUrl(val.profile_image_id, this.avatar._size, this.avatar._size)
                } else {
                    this.avatar.image = null
                }
                this.avatar.text = `${val.name.family[0]}${val.name.given ? val.name.given[0] : ''}`
            } else {
                this.avatar.image = null
                this.avatar.text = null
            }
        }
    }
    public get user(): User { return this._user }
    private _user: User

    public constructor(
        @Inject(AvatarComponent) protected readonly avatar: AvatarComponent,
        @Inject(FsService) private readonly fsService: FsService) {

    }
}
