import { Component, Inject, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"

import { LayerRef, SelectComponent, LayerEvent, LoadFields } from "@anzar/core"
import { User } from "@backend/pyzar.api"
import { WorkerBackendSource } from "@backend/worker.api"
import { CurrentSection } from "../section.service/level"

const USER_FIELDS: LoadFields<User> = [
    "id", "name"
]


@Component({
    selector: "message-alias-popup",
    templateUrl: "./alias-popup.component.pug"
})
export class AliasPopupComponent {
    @ViewChild("select", { static: true }) public readonly select: SelectComponent<any>
    public readonly userId = new FormControl()

    public readonly fields = USER_FIELDS

    public constructor(
        @Inject(WorkerBackendSource) public readonly userSrc: WorkerBackendSource,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(CurrentSection) public readonly section: CurrentSection) {
    }

    public doCancel() {
        this.layerRef.hide()
    }

    public doSelect() {
        const selected = this.select.selected[0]
        this.layerRef.emit(new LayerEvent("select", selected))
        this.layerRef.hide()
    }
}
