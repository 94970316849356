import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, ViewChild } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { Observable, of } from "rxjs"
import { takeUntil, shareReplay, map, switchMap } from "rxjs/operators"

import { Destructible, LoadFields, MediaQueryService, GridComponent } from "@anzar/core"
import { User } from "@backend/pyzar.api"
import { WorkerBackendSource, WorkerBackend } from "@backend/worker.api"
import { CurrentHeadquerter } from "../section.service/level"
import { AuthService } from "@pyzar/auth.module"
import { StatsService } from "../stats.module/stats.service"


const USER_FIELDS: LoadFields<User> = [
    "id", "name", "username", "email", "phone", "is_active"
]


class ExtraData {
    public constructor(
        public readonly sectionTitles: string,
        public readonly clientNums: number) {

    }
}


@Component({
    selector: ".rege-workers-grid",
    templateUrl: "./workers-grid.template.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [StatsService]
})
export class WorkersGridComponent extends Destructible implements OnInit {
    @ViewChild("grid") private readonly grid: GridComponent

    public readonly fields = USER_FIELDS
    public readonly isActiveFilterValues = [
        { value: true, label: "Aktív" },
        { value: false, label: "Inaktív" },
    ]

    private _dataCache: { [key: number]: Observable<ExtraData> } = {}
    public readonly byHqId: number

    public readonly isSm$ = this.mq.watch("lt-md").pipe(map(v => v.matches), shareReplay(1))

    public constructor(
        @Inject(WorkerBackendSource) public readonly source: WorkerBackendSource,
        @Inject(WorkerBackend) private readonly workerBackend: WorkerBackend,
        @Inject(Router) private readonly router: Router,
        @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
        @Inject(CurrentHeadquerter) private readonly hq: CurrentHeadquerter,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(AuthService) public readonly auth: AuthService,
        @Inject(MediaQueryService) private readonly mq: MediaQueryService,
        @Inject(StatsService) private readonly stats: StatsService) {
        super()

        this.destruct.any(() => {
            delete this._dataCache
        })
    }

    public ngOnInit() {
        this.destruct.subscription(this.route.data)
            .pipe(
                switchMap(data => {
                    if (data.all) {
                        return of(undefined)
                    } else {
                        return this.hq.id$
                    }
                })
            )
            .subscribe(hqId => {
                if (this.byHqId !== hqId) {
                    (this as { byHqId: number }).byHqId = hqId
                    this.cdr.detectChanges()
                }
            })
    }

    public editUser(userId: number) {
        this.router.navigate(["/users", userId])
    }

    public newUser() {
        this.router.navigate(["/users/create"])
    }

    public getExtraData(userId: number): Observable<ExtraData> {
        if (this._dataCache[userId]) {
            return this._dataCache[userId]
        } else {
            return this._dataCache[userId] = this.workerBackend.extra_data({ user_id: userId })
                .pipe(
                    takeUntil(this.destruct.on),
                    map(result => {
                        return new ExtraData(result.section_titles.join(", "), result.client_nums)
                    }),
                    shareReplay(1)
                )
        }
    }

    public showClients(userId: number) {
        this.router.navigate(["/ugyfel/esetek", userId])
    }

    public doExport() {
        this.stats.workers(this.grid.source.filter, this.grid.source.sort)
    }
}
