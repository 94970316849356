import { Inject, Injectable, OnDestroy } from "@angular/core"
import { BehaviorSubject, ReplaySubject, Subject, } from "rxjs"
import { startWith, map, shareReplay, tap } from "rxjs/operators"
import { startOfDay, isWeekend, subDays } from "date-fns"

import { Destruct } from "@anzar/core"
import { Provider, ProviderBackend, ProviderService } from "@backend/org.api"
import { CurrentSection } from "../section.service/level"


export const MERGABLE_SERVICE_TYPES = ["room", "scheduled"]
export const EDITABLE_SERVICE_TYPES = ["room", "scheduled", "street_care", "social_mental", "bno", "communal"]


export class UsableService {
    public constructor(
        public title: string,
        public isEditable: boolean,
        public isPrimary: boolean,
        public isActive: boolean,
        public type: string,
        public readonly services: ProviderService[]) { }
}


export class ReportableProvider {
    public constructor(
        public title: string,
        public providers: Provider[],
        public ids: string) { }
}


@Injectable()
export class ProviderServices implements OnDestroy {
    public readonly destruct = new Destruct()

    private _all: Provider[]
    private _allById: { [key: number]: Provider } = {}
    private _grouped: Array<Provider[]> = []
    private _allO = this.destruct.subject(new Subject<Provider[]>())
    public readonly all = this._allO.pipe(startWith(null), map(x => x || this._all || []))
    public multipleProviders: boolean = false

    private _allProviderService: { [key: number]: ProviderService } = {}
    private _services: UsableService[]
    private _servicesO = this.destruct.subject(new Subject<UsableService[]>())
    public readonly services = this._servicesO.pipe(shareReplay(1))

    public readonly reportables: ReportableProvider[] = []

    public readonly kenysziAgreementDeadline = new ReplaySubject<Date>(1)
    public readonly kenysziUtilizationDeadline = new ReplaySubject<Date>(1)

    public constructor(
        @Inject(CurrentSection) protected readonly section: CurrentSection,
        @Inject(ProviderBackend) protected readonly providerBackend: ProviderBackend) {

        // !!! DONT REMOVE THIS
        this.destruct.subscription(this.services).subscribe()

        this.destruct.subscription(section.providers)
            .pipe(tap(providers => {
                let group: { [key: string]: Provider[] } = {}

                for (const provider of providers) {
                    const key = `${provider.type}-${provider.parent_id}-${provider.kenyszi_type}-${provider.nrszh_id}`
                    if (!group[key]) {
                        group[key] = [provider]
                    } else {
                        group[key].push(provider)
                    }
                }

                this._grouped = Object.keys(group)
                    .map(k => group[k].sort((a, b) => a.position - b.position))
                    .sort((a, b) => a[0].position - b[0].position);

                (this as any).reportables = this._grouped
                    .filter(p => p[0].type === "KENYSZI")
                    .map(providers => {
                        let title = providers.length === 1
                            ? providers[0].title
                            : providers.map(p => p.title).join(", ")
                        return new ReportableProvider(title, providers, providers.map(p => p.id).join(","))
                    })

            }))
            .subscribe(providers => {
                this._all = providers

                let allById: { [key: number]: Provider } = {}
                let allPsById: { [key: number]: ProviderService } = {}
                let services: UsableService[] = []
                let multiple: { [key: number]: UsableService[] } = {}
                let maxKenysziAgreementDeadline = 1
                let maxKenysziUtilizationDeadline = 1

                for (const p of providers) {
                    maxKenysziAgreementDeadline = Math.max(maxKenysziAgreementDeadline, p.report_options ? p.report_options.kenyszi_agreement_deadline || 0 : 0)
                    maxKenysziUtilizationDeadline = Math.max(maxKenysziUtilizationDeadline, p.report_options ? p.report_options.kenyszi_utilization_deadline || 0 : 0)

                    allById[p.id] = p
                    for (const ps of p.services) {
                        allPsById[ps.id] = ps

                        if (MERGABLE_SERVICE_TYPES.indexOf(ps.service.type) !== -1) {
                            let mergeInto = services.find(usable => {
                                const ups = usable.services[0]
                                const uprovider = allById[ups.provider_id]
                                return ups.service.id === ps.service.id
                                    && uprovider.nrszh_id === p.nrszh_id
                            })
                            if (mergeInto) {
                                mergeInto.services.push(ps)
                                mergeInto.isEditable = mergeInto.isEditable || serviceIsEditable(ps)
                                mergeInto.isPrimary = mergeInto.isPrimary || ps.is_primary
                                mergeInto.isActive = mergeInto.isActive || ps.is_active
                            } else {
                                mergeInto = new UsableService(
                                    ps.service.title,
                                    serviceIsEditable(ps),
                                    ps.is_primary,
                                    ps.is_active,
                                    ps.service.type,
                                    [ps])
                                services.push(mergeInto)
                            }
                            if (mergeInto.isPrimary && providers.length > 1) {
                                mergeInto.title = p.title
                            }
                        } else {
                            let usable = new UsableService(
                                ps.service.title,
                                serviceIsEditable(ps),
                                ps.is_primary,
                                ps.is_active,
                                ps.service.type,
                                [ps])
                            services.push(usable)

                            if (ps.is_primary) {
                                if (providers.length > 1) {
                                    usable.title = p.title
                                }
                            } else {
                                if (!multiple[ps.service.id]) {
                                    multiple[ps.service.id] = [usable]
                                } else {
                                    multiple[ps.service.id].push(usable)
                                }
                            }
                        }
                    }
                }

                this.kenysziAgreementDeadline.next(computeDeadline(new Date(), maxKenysziAgreementDeadline))
                this.kenysziUtilizationDeadline.next(computeDeadline(new Date(), maxKenysziUtilizationDeadline))

                this._allById = allById
                this._allProviderService = allPsById

                for (const k in multiple) {
                    const multi = multiple[k]
                    if (multi.length > 1) {
                        for (const ps of multi) {
                            const provider = allById[ps.services[0].provider_id]
                            ps.title = `${ps.services[0].service.title} (${provider.title})`
                        }
                    }
                }

                this.multipleProviders = providers.length > 1
                this._services = services.sort((a, b) => a.title.localeCompare(b.title))
                this._allO.next(providers)
                this._servicesO.next(this._services)
            })
    }

    public getById(id: number): Provider {
        return this._allById[id]
    }

    public getProviderSiblings(provider: Provider): Provider[] {
        return this._all.filter(p => p.parent_id === provider.parent_id && p.id !== provider.id)
    }

    public getSimilarsById(id: number): Provider[] {
        return this._grouped.filter(providers => {
            for (const provider of providers) {
                if (provider.id === id) {
                    return true
                }
            }
        })[0] || []
    }

    public getServiceById(providerServiceId: number): UsableService {
        return this._services.find(s => !!s.services.find(ss => ss.id === providerServiceId))
    }

    public getProviderServiceById(providerServiceId: number) {
        return this._allProviderService[providerServiceId]
    }

    public getPrimaryService(provider: Provider): UsableService {
        return this._services.find(s => {
            return s.isPrimary && s.services[0].provider_id === provider.id
        })
    }

    public getServicesByType(type: string): ProviderService[] {
        return Object.keys(this._allProviderService)
            .map((key: any) => {
                return this._allProviderService[key]
            })
            .filter(ps => {
                return ps.service.type === type
            })
    }

    // public getServicesFromSameGroup(providerService: ProviderService, othersOnly: boolean = true): ProviderService[] {
    //     let res: ProviderService[] = []
    //     let mainProvider = this.getById(providerService.provider_id)

    //     for (const ps of this._services) {
    //         const provider = this.getById(ps.provider_id)

    //         if (mainProvider.nrszh_id === provider.nrszh_id
    //             && mainProvider.type === provider.type
    //             && providerService.is_primary === ps.is_primary) {
    //             if (othersOnly && ps.id === providerService.id) {
    //                 continue
    //             }
    //             res.push(ps)
    //         }
    //     }

    //     return res
    // }

    public ngOnDestroy() {
        this.destruct.run()
    }


}


function serviceIsEditable(ps: ProviderService) {
    if (EDITABLE_SERVICE_TYPES.indexOf(ps.service.type) !== -1) {
        if (ps.service.type === "room") {
            return !!ps.is_detailed
        } else {
            return true
        }
    } else {
        return false
    }
}


function computeDeadline(ref: Date, dayc: number) {
        let result = startOfDay(ref)
        while (dayc > 0) {
            result = subDays(result, 1)
            if (!isWeekend(result)) {
                dayc--
            }
        }
        return result
    }
