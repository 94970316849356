import { Component, Inject, ChangeDetectorRef, OnChanges, SimpleChanges } from "@angular/core"
import { Observable } from "rxjs"
import { take, map, shareReplay } from "rxjs/operators"
import { subDays, startOfDay } from "date-fns"

import { ToastService, FileDownloadService } from "@anzar/core"
import { DocumentBackend, ClientBanBackend, Document } from "@backend/documents.api"
import { LevelBackend } from "@backend/org.api"
import { DocumentItem } from "../abstract"
import { DocumentsService, DOCUMENT_FORM_DATA } from "../documents.service"
import { FsService } from "@pyzar/fs.module"
import { AuthService } from "@pyzar/auth.module"
import { ClientBanFormComponent } from "./ban-form.component"
import { WorkerService } from "../../../worker.module"


const LEVEL_TITLE_CACHE: { [key: number]: Observable<string> } = {}


@Component({
    selector: ".rege-doc-item-ban",
    templateUrl: "./ban-item.component.pug"
})
export class ClientBanItemComponent extends DocumentItem implements OnChanges {
    public levelTitle: string
    public canRevoke: boolean

    public constructor(
        @Inject(DocumentBackend) docBackend: DocumentBackend,
        @Inject(DocumentsService) docSvc: DocumentsService,
        @Inject(FsService) fsService: FsService,
        @Inject(ToastService) toast: ToastService,
        @Inject(FileDownloadService) downSvc: FileDownloadService,
        @Inject(ClientBanBackend) protected readonly clientBanBackend: ClientBanBackend,
        @Inject(LevelBackend) protected readonly levelBackend: LevelBackend,
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(AuthService) public readonly auth: AuthService,
        @Inject(WorkerService) private readonly workerSvc: WorkerService) {
        super(docBackend, docSvc, fsService, toast, downSvc)
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ("document" in changes) {
            const newDoc = changes.document.currentValue as Document
            const level = newDoc.client_ban.level
            if (level.variant === "section") {
                this.levelTitle = level.title
            } else {
                this.getLevelTitle(level.parent_id).subscribe(parentTitle => {
                    this.levelTitle = `${parentTitle} / ${level.title}`
                    this.cdr.markForCheck()
                })
            }

            const newEnd = subDays(startOfDay(new Date()), 1)
            this.canRevoke = newEnd < startOfDay(newDoc.client_ban.end)
        }
    }

    public getLevelTitle(levelId: number): Observable<string> {
        if (LEVEL_TITLE_CACHE[levelId]) {
            return LEVEL_TITLE_CACHE[levelId]
        } else {
            return LEVEL_TITLE_CACHE[levelId] = this.levelBackend.get({ id: levelId }).pipe(
                take(1),
                map(level => level.title),
                shareReplay(1)
            )
        }
    }

    // public revoke() {
    //     this.clientBanBackend
    //         .revoke({ client_ban_id: this.document.client_ban.id })
    //         .pipe(take(1), this.toast.handleSave({ align: "bottom center" }))
    //         .subscribe(this.docSvc.reload.bind(this.docSvc))
    // }

    public remove() {
        this.clientBanBackend
            .remove({ client_ban_id: this.document.client_ban.id })
            .pipe(take(1), this.toast.handleSave({ align: "bottom center" }))
            .subscribe(this.docSvc.reload.bind(this.docSvc))
    }

    public extend() {
        this.docSvc.popup(ClientBanFormComponent, [
            {
                provide: DOCUMENT_FORM_DATA, useValue: {
                    begin: this.document.client_ban.end,
                    level_id: this.document.client_ban.level.id,
                    description: this.document.client_ban.description,
                    policy_violation: this.document.client_ban.policy_violation,
                }
            }
        ])
    }

    public edit() {
        this.docSvc.popup(ClientBanFormComponent, [
            {
                provide: DOCUMENT_FORM_DATA, useValue: {
                    id: this.document.client_ban.id,
                    begin: this.document.client_ban.begin,
                    end: this.document.client_ban.end,
                    level_id: this.document.client_ban.level.id,
                    description: this.document.client_ban.description,
                    policy_violation: this.document.client_ban.description,
                }
            }
        ])
    }

    public deleteInfo() {
        const ban = this.document.client_ban
        this.workerSvc.whoCanDoIt("Kitiltás törlése", ban.creator_id, ban.created_time, "client.ban.delete", ban.level.id)
    }

    public generalInfo() {
        const ban = this.document.client_ban
        this.workerSvc.whoCanDoIt("Kitiltás", ban.creator_id, ban.created_time, null, ban.level.id)
    }
}
