import { Inject, Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

import { RichtextAcProvider, RichtextAcItem, RichtextAcSession, LoadFields } from "@anzar/core"
import { User } from "@backend/pyzar.api"
import { WorkerBackend } from "@backend/worker.api"

const accountGroupSvg = require("mdi/account-group.svg")


@Injectable()
export class WorkerRichtextProvider extends RichtextAcProvider {
    public readonly trigger = /^&/
    public readonly terminate: any = null
    public readonly mustSelect = true
    public readonly minChars = 2
    public readonly loadFields: LoadFields<User> = ["id", "name"]

    public constructor(@Inject(WorkerBackend) protected readonly userBackend: WorkerBackend) {
        super()
    }

    public query(value: string): Observable<RichtextAcItem[]> {
        value = value.substr(1)
        return this.userBackend
            .search({
                filter: { "name.formatted": value },
                order: { "name.formatted": "asc" },
                begin: 0,
                count: 20
            }, { loadFields: this.loadFields } as any)
            .pipe(map(
                users => users.map(user => {
                    let name = this.formatUserName(user)
                    return new RichtextAcItem({
                        id: `user:${user.id}`,
                        label: name,
                        icon: accountGroupSvg
                    })
                })))
    }

    public onSelect(sess: RichtextAcSession, item: RichtextAcItem): void {
        this.replaceWithComponent(sess, "worker-link", {
            user_id: Number((item.id as String).split(":").pop()),
            content: item.label
        })
    }

    public onTerminate(sess: RichtextAcSession, text: string): boolean {
        return false
    }

    protected formatUserName(user: User): string {
        return [user.name.title, user.name.family, user.name.given].filter(v => !!v).join(" ")
    }
}
