import { Component, Inject, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, OnInit, Input, Output, Injectable } from "@angular/core"
import { FormControl } from "@angular/forms"
import { DomSanitizer, SafeStyle } from "@angular/platform-browser"
import { Subject, of, Observable, forkJoin } from "rxjs"
import { startWith, shareReplay, map, switchMap, finalize, tap, takeUntil } from "rxjs/operators"
import { startOfDay, addDays, format, subDays, startOfWeek, eachDayOfInterval, parseISO, isSameDay } from "date-fns"

import {
    LocaleService, DataSourceDirective, LoadFields, Filter, Sorter, Meta, Model,
    Field, Items, ListFilterService, ColumnsComponent, Destruct, NzRange, MediaQueryService
} from "@anzar/core"
import { RpcDataSource } from "@anzar/rpc"

import { Client } from "@backend/client.api"
import { SectionBackend } from "@backend/org.api"
import { MarkService, ICON_COLOR_BY_ALERT_LEVEL, Alert } from "../mark.module"
import { CurrentSection } from "../section.service/level"
import { ClientService } from "./client.service"


const CLIENTS_FIELD: LoadFields<Client> = [
    "id", "name", "social_insurance_id", "birth_date", "profile_image_id",
    { manager: ["name"] }
]


const DYNAMIC_COLUMNS = {
    lung_screening: "Tüdőszűrő",
    contract_mailing: "Postaszerződés",
    contract_package: "Csomagmegőrző szerződés",
    contract_payment: "Befizetés",
    ban: "Kitiltás",
    siid_deadline: "Tajfedezet",
    active_utilization: "Aktív jogviszony kezdete",
    covid_hospital_in: "Kórházban van",
    covid_hospital_leave: "Kórházban volt",
    last_event_from_manager: "Utolsó bejegyzés (esetfelelőstől)",
    last_event: "Utolsó bejegyzés (bárkitől)",
    dead: "Elhunyt",
}


class ListEntry extends Model {
    @Field({ primary: true }) public id: number
    @Field() public client: Client
    @Field() public alerts: Alert[]
    @Field() public checkboxes: CheckBox[]
    @Field() public dynamic: any
}


class ClientsResult extends Items<ListEntry> {
    public constructor(items: ListEntry[], range: NzRange, total?: number, public readonly todayActive?: number) {
        super(items, range, total)
    }
}


@Injectable()
export class SectionClientDs extends RpcDataSource<Client, SectionBackend> {
    public constructor(
        @Inject(SectionBackend) public readonly backend: SectionBackend,
        @Inject(MarkService) public readonly markSvc: MarkService) {
        super(backend)
    }

    protected _search(f?: Filter<Client>, s?: Sorter<Client>, r?: NzRange, m?: Meta<Client>): Observable<Model[]> {
        const begin = (f as any)!.date_from
        const end = (f as any)!.date_to

        if (!begin || !end) {
            return of([])
        }

        const days = eachDayOfInterval({ start: parseISO(begin), end: parseISO(end) })
        const now = new Date()
        const dynamicColumn = (f as any).dynamic_column

        return this.backend
            .clients({ filter: f, order: s, begin: r ? r.begin : null, count: r ? r.length : null }, m)
            .pipe(
                map(val => {
                    let [items, total, todayActive] = val
                    let newItems = items.map(v => {
                        const [client, alerts, byDateNfo, dynamicValue] = v
                        const checkboxes: CheckBox[] = []

                        for (const day of days) {
                            const dayInfo = byDateNfo[format(day, "yyyy-MM-dd")]
                            const chk = new CheckBox(
                                day,
                                dayInfo && dayInfo[0],
                                dayInfo ? dayInfo[1] : null,
                                dayInfo ? dayInfo[2] : 0,
                                ICON_COLOR_BY_ALERT_LEVEL[dayInfo ? dayInfo[2] : 0] || null,
                                isSameDay(now, day)
                            )
                            checkboxes.push(chk)
                        }
                        return new ListEntry({
                            id: client.id,
                            client,
                            alerts,
                            checkboxes,
                            dynamic: this._formatDynamicField(dynamicColumn, dynamicValue)
                        })
                    })

                    return new ClientsResult(newItems, r, total, todayActive)
                })
            )
    }

    private _formatDynamicField(field: string, value: any): string {
        switch (field) {
            case "lung_screening":
            case "contract_mailing":
            case "contract_payment":
            case "contract_package":
            case "ban":
            case "dead":
            case "siid_deadline":
            case "active_utilization":
                return value ? format(new Date(value), "yyyy. MM. dd.") : null

            case "covid_hospital_in":
                return value ? `Bekerült: ${format(new Date(value), "yyyy. MM. dd.")}` : null

            case "covid_hospital_leave":
                return value ? `Elhagyta: ${format(new Date(value), "yyyy. MM. dd.")}` : null

            case "last_event":
                if (value) {
                    return `${format(new Date(value[0]), "yyyy. MM. dd.")} — ${value[1]}`
                } else {
                    return "-"
                }

            case "last_event_from_manager":
                if (value) {
                    return `${format(new Date(value[0]), "yyyy. MM. dd.")}`
                } else {
                    return "-"
                }


            default:
                return value
        }
    }
}


export class CheckBox {
    public constructor(
        public readonly day: Date,
        public checked: boolean,
        public marks: string,
        public alterLevel: number,
        public alertColor: string,
        public isToday: boolean) { }
}


@Component({
    selector: ".rege-clients-grid",
    exportAs: "regeClientsGrid",
    templateUrl: "./clients-grid.template.pug",
    providers: [MarkService, DataSourceDirective, ListFilterService, SectionClientDs]
})
export class ClientsGridComponent implements OnDestroy, AfterViewInit, OnInit {
    public readonly destruct = new Destruct()

    @ViewChild("columns", { static: true }) public readonly columns: ColumnsComponent

    public set chkBegin(val: Date) {
        if (this._chkBegin !== val) {
            this._chkBegin = val
            this._chkBeginChanged.next(val)
        }
    }
    public get chkBegin(): Date { return this._chkBegin }
    private _chkBegin: Date

    @Input()
    public set userId(val: number) {
        if (this._userId !== val) {
            this._userId = val
            this._updateFilter()
        }
    }
    public get userId(): number { return this._userId }
    private _userId: number

    @Input()
    public set providerIds(val: number[]) {
        if (this._providerIds !== val) {
            this._providerIds = val
            this._updateFilter()
        }
    }
    public get providerIds(): number[] { return this._providerIds }
    private _providerIds: number[]

    public readonly dynamicColumns = Object.keys(DYNAMIC_COLUMNS).map(key => {
        return [key, (DYNAMIC_COLUMNS as any)[key]]
    })

    public set dynamicColumn(val: string) {
        if (this._dynamicColumn !== val) {
            this._dynamicColumn = val
            this.dynamicColumnTitle = (DYNAMIC_COLUMNS as any)[val]
            this._updateFilter()
            this.cdr.markForCheck()
        }
    }
    public get dynamicColumn(): string { return this._dynamicColumn }
    public _dynamicColumn: string = this.dynamicColumns[0][0]

    public dynamicColumnTitle: string = this.dynamicColumns[0][1]
    public readonly dynamicColumnHasValue = new FormControl()

    @Output()
    public totalChange = new Subject<[number, number]>()

    private _chkBeginChanged = new Subject<Date>()
    private _currentChkBegin = this._chkBeginChanged.pipe(shareReplay(1))

    public readonly chkBoxDates = this._currentChkBegin.pipe(
        map(date => {
            let res: Date[] = []
            let begin = startOfDay(date)

            for (let i = 0; i < 7; i++) {
                res.push(addDays(begin, i))
            }

            this.chkDates = res
            this._updateFilter()
            return res
        }),
        shareReplay(1)
    )

    public chkDates: Date[] = []
    public chkDateLabel: string
    public chkDateLabelDays: string[]
    public gridTemplate: SafeStyle

    private _chkCache: { [key: string]: Observable<CheckBox[]> } = {}

    public readonly isSm$ = this.mq.watch("lt-md").pipe(map(v => v.matches), shareReplay(1))

    public constructor(
        @Inject(SectionClientDs) protected readonly source: SectionClientDs,
        @Inject(ClientService) protected readonly cs: ClientService,
        @Inject(DomSanitizer) protected readonly sanitizer: DomSanitizer,
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(DataSourceDirective) public readonly dataSource: DataSourceDirective<Client>,
        @Inject(CurrentSection) public readonly section: CurrentSection,
        @Inject(MediaQueryService) private readonly mq: MediaQueryService,
        @Inject(LocaleService) localeSvc: LocaleService) {
        this.dataSource.dataSource = source
        this.dataSource.sort = { search: "asc" }
        this.dataSource.loadFields(CLIENTS_FIELD)

        this.destruct.subscription(section.id$).subscribe(v => {
            this._updateFilter()
        })

        this.destruct.subscription(this.chkBoxDates).subscribe(v => {
            let d1 = v[0]
            let d2 = v[v.length - 1]
            this.chkDateLabel = localeSvc.formatDateRange(d1, d2)

            let days: string[] = []
            for (const c of v) {
                days.push(format(c, "dd"))
            }
            this.chkDateLabelDays = days
            this._updateFilter()
            cdr.detectChanges()
        })

        this.destruct.subscription(dataSource.storage.result).subscribe((result: any) => {
            this.totalChange.next([result.total, result.todayActive])
        })

        this.destruct.subscription(this.dynamicColumnHasValue.valueChanges).subscribe(_ => {
            this._updateFilter()
        })
    }

    public ngOnInit() {
        this.chkBegin = startOfWeek(new Date(), { weekStartsOn: 1 })
    }

    public showClientSheet(event: Event, client: Client) {
        this.cs.showSheet(client.id).subscribe()
    }

    public prevChkDays() {
        this.chkBegin = subDays(this.chkBegin, 7)
    }

    public nextChkDays() {
        this.chkBegin = addDays(this.chkBegin, 7)
    }

    public ngAfterViewInit() {
        this.destruct.subscription(this.columns.layoutChanged).pipe(startWith(null)).subscribe(l => {
            this.gridTemplate = this.sanitizer.bypassSecurityTrustStyle(`48px / ${this.columns.gridColTemplate}`)
            this.cdr.detectChanges()
        })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    private _updateFilter() {
        let filter = {} as any

        if (this.userId) {
            filter.manager_id = this.userId
        }

        if (this.section.id) {
            filter.section_id = this.section.id
        }

        if (this.chkDates.length) {
            filter.date_from = this.chkDates[0]
            filter.date_to = this.chkDates[this.chkDates.length - 1]
        }

        if (this._dynamicColumn) {
            filter.dynamic_column = this._dynamicColumn
            filter.dynamic_column_has_value = this.dynamicColumnHasValue.value
        }

        if (this._providerIds) {
            filter.provider_ids = this._providerIds
        }

        this.dataSource.baseFilter = filter
        this.cdr.markForCheck()
    }
}
