import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzModule } from "@anzar/core"

import { NotificationApi } from "@backend/notification.api"

import { ClientModule } from "../client.module"
import { WorkerModule } from "../worker.module"
import { AuthModule } from "@pyzar/auth.module"
import { RichtextModule } from "../richtext.module"

import { NotificationService } from "./notification.service"
export { NotificationService }

import { NotificationPopupComponent } from "./notification-popup.component"
export { NotificationPopupComponent }

import { NotificationEntryComponent } from "./notification-entry.component"
export { NotificationEntryComponent }

import { NECaseTransferComponent } from "./ne-case.component"
import { NEEventComponent } from "./ne-event.component"


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        NotificationApi,
        AuthModule,
        ClientModule,
        WorkerModule,
        RichtextModule
    ],
    declarations: [
        NotificationPopupComponent,
        NotificationEntryComponent,

        NECaseTransferComponent,
        NEEventComponent
    ],
    exports: [
        NotificationPopupComponent,
        NotificationEntryComponent,
    ],
    providers: [
        NotificationService
    ],
    entryComponents: [NotificationPopupComponent]
})
export class NotificationModule {

}
