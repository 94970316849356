import { Component, Directive, Inject, ViewChild, TemplateRef, ViewContainerRef, ElementRef, Input, OnInit, OnDestroy, Optional, Self } from "@angular/core"
import { FormGroup, FormControl, Validator, AbstractControl, ValidationErrors, NG_VALIDATORS, ControlContainer, ValidatorFn } from "@angular/forms"
import { coerceBooleanProperty } from "@angular/cdk/coercion"

import { Destruct, LayerService, DropdownLayer, LayerRef, FormFieldComponent, InputGroupModel, InputModel, INPUT_MODEL_VALUE_CMP, FocusGroup } from "@anzar/core"
import { map, startWith } from "rxjs"


export interface NameInputValue {
    title?: string
    family?: string
    given?: string
}


@Directive({
    selector: ".rege-name-input[required]",
    providers: [
        { provide: NG_VALIDATORS, useExisting: NameInputRequiredValidator, multi: true }
    ]
})
export class NameInputRequiredValidator implements Validator {
    public validate(ctrl: AbstractControl): ValidationErrors | null {
        const value = ctrl.value as NameInputValue
        if (!value || !(value.family && value.given)) {
            return { required: true }
        }
        return null
    }
}


export class NameInputModel extends InputGroupModel<NameInputValue> {
    public get isEmpty(): boolean {
        const val = this.value
        return !(val && (val.family || val.given || val.title))
    }
}


function nameEqComparator(a: NameInputValue, b: NameInputValue): boolean {
    return (a && b
        && a.family === b.family
        && a.given === b.given
        && a.title === b.title)
        || (!a && !b)
}


@Component({
    selector: ".rege-name-input",
    templateUrl: "./name-input.component.pug",
    providers: [
        { provide: INPUT_MODEL_VALUE_CMP, useValue: nameEqComparator },
        { provide: InputGroupModel, useClass: NameInputModel },
        { provide: InputModel, useExisting: InputGroupModel },
        { provide: FocusGroup, useClass: FocusGroup },
    ]
})
export class NameInputComponent implements OnInit, OnDestroy {
    static formModel(validators?: ValidatorFn[]) {
        const group = new FormGroup({
            title: new FormControl(),
            family: new FormControl(),
            given: new FormControl()
        })

        if (validators && validators.length) {
            group.setValidators(validators)
        }

        return group
    }

    public readonly destruct = new Destruct()

    // @ViewChild("formattedEl", { read: ElementRef }) protected readonly formattedEl: ElementRef<HTMLElement>
    @ViewChild("detailed", { read: TemplateRef, static: true }) protected readonly detailedTpl: TemplateRef<any>

    public get group(): FormGroup {
        return this.igm.control as FormGroup
    }

    @Input() public label: string

    @Input()
    public set required(val: boolean) { this._required = coerceBooleanProperty(val) }
    public get required(): boolean { return this._required }
    private _required: boolean

    @Input()
    public set readonly(val: boolean) { this.igm.readonly = coerceBooleanProperty(val) }
    public get readonly(): boolean { return this.igm.readonly }

    public readonly formatted = new FormControl()
    protected ref: LayerRef

    protected _formattedIsFocused: boolean = false

    public constructor(
        @Inject(LayerService) protected readonly layerSvc: LayerService,
        @Inject(ViewContainerRef) protected readonly vcr: ViewContainerRef,
        @Inject(InputGroupModel) protected readonly igm: InputGroupModel<NameInputValue>,
        @Inject(ElementRef) private readonly el: ElementRef<HTMLElement>,
        @Inject(FormFieldComponent) @Optional() protected readonly ffc: FormFieldComponent) {
    }

    public ngOnInit() {
        this.destruct.subscription(this.igm.valueChanges).pipe(startWith(null), map(() => this.igm.value)).subscribe(value => {
            this._updateFormatted(value)
        })

        this.destruct.subscription(this.formatted.valueChanges).subscribe(value => {
            if (this._formattedIsFocused) {
                const newValue = this._parseName(value) as any
                const controls = (this.igm.control as FormGroup).controls

                for (const ctrlName in controls) {
                    const ctrl = controls[ctrlName]
                    if (newValue[ctrlName] !== ctrl.value) {
                        ctrl.markAsTouched()
                        ctrl.markAsDirty()
                    }
                }

                this.igm.emitValue(newValue)
            }
        })

        this.destruct.subscription(this.formatted.statusChanges.pipe(startWith(null))).subscribe(status => {
            if (this.formatted.touched) {
                this.group.markAsTouched()
            } else {
                this.group.markAsUntouched()
            }
        })
    }

    public showDetailed() {
        if (this.ref && this.ref.isVisible) {
            this.ref.hide()
            delete this.ref
        } else {
            let targetEl = this.ffc ? this.ffc.el.nativeElement : this.el.nativeElement
            let behavior = new DropdownLayer({
                backdrop: { crop: targetEl, hideOnClick: true, type: "empty" },
                elevation: 12,
                rounded: 3,
                position: {
                    align: "top left",
                    anchor: {
                        align: "bottom left",
                        margin: "0 0 -21 16",
                        ref: targetEl
                    }
                },
                minWidth: targetEl.offsetWidth + 32,
                trapFocus: true
            })
            this.ref = this.layerSvc.createFromTemplate(this.detailedTpl, this.vcr, behavior)
            this.igm.focusGroup.watch(this.ref.outlet.firstElement)
            this.ref.subscribe(event => {
                if (event.type === "hiding") {
                    this.igm.focusGroup.unwatch(this.ref.outlet.firstElement)
                }
            })
            this.ref.show()
        }
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    public onFormattedFocus() {
        this._formattedIsFocused = true
    }

    public onFormattedBlur() {
        this._formattedIsFocused = false
    }

    private _parseName(value: string): { title: string, family: string, given: string } {
        if (!value) {
            return { title: null, family: null, given: null }
        }
        let m = value.match(/^(\w+\.)|(dr|ifj|özv|báró|gróf)(\s|$)/i)
        let title: string = null

        if (m) {
            value = value.substr(m.index + m[0].length)
            title = m[0].toLowerCase()
            title = title[0].toUpperCase() + title.substr(1)
        }

        let parts = value.replace(/^\s+|\s+$/g, "").split(/\s+/)
        let family = parts.shift()
        let given = parts.join(" ")

        if (!family || !family.length) {
            family = null
        }

        if (!given || !given.length) {
            given = null
        }
        return { title, family, given }
    }

    private _updateFormatted = (value: NameInputValue) => {
        if (this._formattedIsFocused) {
            return
        }

        this.formatted.setValue(
            Object
                .keys(value)
                .map(k => (value as any)[k])
                .filter(v => v && v.length)
                .join(" "))
    }
}
