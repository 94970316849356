import { NgModule } from "@angular/core"
import { Place, PlaceBackend, PlaceBackendSource } from "./__anzar_rpc_output"

export { Place, PlaceBackend, PlaceBackendSource }


@NgModule({
    providers: [
        PlaceBackendSource,
        PlaceBackend
    ],
})
export class PlaceApi {}