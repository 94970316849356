import { Observable } from "rxjs"
import { map } from "rxjs/operators"

import { Filter, Sorter, NzRange, Meta, Model, Field, PrimaryKey } from "@anzar/core"
import { ClientBackendSource, Client } from "@backend/client.api"


export class ClientWithAlerts extends Model {
    @Field({ primary: true }) public id: number

    public constructor(
        public readonly client: Client,
        public readonly alerts: Array<{ level: number, icon: string }>) {
        super()
        this.id = client.id
    }
}


export class ClientWithAlertsSource extends ClientBackendSource {
    protected _search(f?: Filter<Client>, s?: Sorter<Client>, r?: NzRange, m?: Meta<Client>): Observable<ClientWithAlerts[]> {
        return this.backend.search_with_marks({ filter: f, order: s, begin: r ? r.begin : null, count: r ? r.length : null }, m as any)
            .pipe(map(result => {
                return result.map(v => new ClientWithAlerts(v[0], v[1] as any))
            }))
    }

    protected _get(id: PrimaryKey, m?: Meta<Client>): Observable<ClientWithAlerts> {
        return this.backend.get({ id: id as number }, m).pipe(map(v => v ? new ClientWithAlerts(v, []) : null))
    }
}
