import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzModule } from "@anzar/core"

import { ComponentsModule } from "../components"
import { RichtextModule, DispatcherRTModule } from "../richtext.module"

import { StoryEntryCardComponent } from "./story-entry-card.component"
export { StoryEntryCardComponent }


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        ComponentsModule,
        RichtextModule,
        DispatcherRTModule
    ],
    declarations: [
        StoryEntryCardComponent
    ],
    exports: [
        StoryEntryCardComponent
    ]
})
export class DispatcherSharedModule {

}
