import { Component, Input, forwardRef, Inject, ChangeDetectorRef } from "@angular/core"

import { Region } from "@backend/org.api"
import { AbstractCLEditor, AbstractCLEditorService } from "@pyzar/common.module"
import { AuthService } from "@pyzar/auth.module"
import { RegionEditorService } from "./region-editor.service"


@Component({
    selector: ".rege-region-editor",
    templateUrl: "./region-editor.component.pug",
    providers: [
        { provide: AbstractCLEditor, useExisting: forwardRef(() => RegionEditorComponent) }
    ]
})
export class RegionEditorComponent extends AbstractCLEditor {
    @Input() public region: Region

    public constructor(
        @Inject(AbstractCLEditorService) service: RegionEditorService,
        @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
        @Inject(AuthService) public readonly auth: AuthService) {
        super(service)

        this.destruct.subscription(service.region$).subscribe(region => {
            this.region = region
            this.cdr.detectChanges()
        })
    }
}
