import { Component, Inject, forwardRef, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { tap } from "rxjs/operators"

import { HeadquarterBackend, Headquarter, LevelBackendSource } from "@backend/org.api"
import { WorkerBackendSource } from "@backend/worker.api"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { AddressInputComponent } from "../../place.module"
import { HeadquarterEditorService } from "./headquarter-editor.service"


@Component({
    selector: ".rege-headquarter-basic-data",
    templateUrl: "./headquarter-basic-data.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => HeadquarterBasicDataComponent) }
    ]
})
export class HeadquarterBasicDataComponent extends AbstractCLEditorBlock<HeadquarterEditorService> {
    public readonly headquarter: Headquarter

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: HeadquarterEditorService,
        @Inject(HeadquarterBackend) protected readonly headquarterBackend: HeadquarterBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(LevelBackendSource) public readonly levelSrc: LevelBackendSource,
        @Inject(WorkerBackendSource) public readonly userSrc: WorkerBackendSource) {
        super(editorSvc, new FormGroup({
            parent_id: new FormControl(),
            title: new FormControl(),
            boss_id: new FormControl(),
            phone_number: new FormControl(),
            place: AddressInputComponent.formGroup(),
        }))
    }

    public ngOnInit() {
        super.ngOnInit()
        this.destruct.subscription(this.editorSvc.headquarter$).subscribe(headquarter => {
            (this as { headquarter: Headquarter }).headquarter = headquarter
            this.form.reset({
                parent_id: headquarter ? headquarter.parent_id : this.editorSvc.parentId,
                title: headquarter ? headquarter.title : null,
                boss_id: headquarter ? headquarter.boss_id : null,
                phone_number: headquarter ? headquarter.phone_number : null,
                place: headquarter && headquarter.place ? {
                    city: headquarter.place.city,
                    sublocality: headquarter.place.sublocality,
                    public_place: headquarter.place.public_place,
                    street_number: headquarter.place.street_number,
                    postal_code: headquarter.place.postal_code,
                } : null,
            })
            this.cdr.detectChanges()
        })
    }

    public save() {
        let data = this.form.value

        if (this.headquarter) {
            data.id = this.headquarter.id
        }

        return this.headquarterBackend.save({ data }).pipe(tap(level => {
            this.editorSvc.id = level.id
            this.editorSvc.reloadHeadquarter()
        }))
    }
}
