import { Component, ChangeDetectionStrategy } from "@angular/core"


@Component({
    selector: ".rege-info",
    templateUrl: "./info.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoComponent {

}
