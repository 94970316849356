import { Component, Inject, ViewChild } from "@angular/core"
import { FormGroup, FormControl, Validators } from "@angular/forms"


import { LoadFields, RICHTEXT_EDITABLE, LayerRef, RichtextComponentRef, SelectComponent, SelectionModel, SingleSelection } from "@anzar/core"
import { SectionBackendSource, Section } from "@backend/org.api"
import { AbstractForm } from "../abstract"


const SECTION_FIELDS: LoadFields<Section> = [
    "id", "title", "utilization"
]


@Component({
    selector: "rege-rt-housing-form",
    templateUrl: "./housing-form.component.pug",
    providers: [{ provide: SelectionModel, useClass: SingleSelection }]
})
export class HousingFormComponent extends AbstractForm {
    @ViewChild("select", { static: true }) private readonly select: SelectComponent<Section>

    public readonly form = new FormGroup({
        section_id: new FormControl(null, [Validators.required]),
        section_title: new FormControl()
    })

    public readonly fields = SECTION_FIELDS

    public constructor(
        @Inject(LayerRef) layerRef: LayerRef,
        @Inject(RichtextComponentRef) rtRef: RichtextComponentRef<AbstractForm>,
        @Inject(RICHTEXT_EDITABLE) rtEditable: boolean,
        @Inject(SectionBackendSource) public readonly sectionSrc: SectionBackendSource,
        @Inject(SelectionModel) protected readonly selection: SelectionModel<Section>) {
        super(layerRef, rtRef, rtEditable)

        this.destruct.subscription(this.form.get("section_id").valueChanges).subscribe(value => {
            const selected = this.select.selected[0]
            this.form.get("section_title").setValue(selected ? selected.title : null)
        })
    }

    public utilizationText(section: Section): string {
        const percent = section.utilization[0] ? Math.round(section.utilization[1] / section.utilization[0] * 100) : 0
        return `férőhely: ${section.utilization[0]} / kihasználtság: ${section.utilization[1]} / ${percent}%`
    }
}
