import { NgModule } from "@angular/core"
import { Mark, AlertLevel, OrgMark, OrgMarkAlert, ClientMark, MarkBackend, OrgMarkBackend, OrgMarkAlertBackend, ClientMarkBackend, ClientMarkBackendSource, MarkBackendSource, OrgMarkAlertBackendSource, OrgMarkBackendSource } from "./__anzar_rpc_output"

export { Mark, AlertLevel, OrgMark, OrgMarkAlert, ClientMark, MarkBackend, OrgMarkBackend, OrgMarkAlertBackend, ClientMarkBackend, ClientMarkBackendSource, MarkBackendSource, OrgMarkAlertBackendSource, OrgMarkBackendSource }


@NgModule({
    providers: [
        ClientMarkBackendSource,
        MarkBackendSource,
        OrgMarkAlertBackendSource,
        OrgMarkBackendSource,
        ClientMarkBackend,
        MarkBackend,
        OrgMarkAlertBackend,
        OrgMarkBackend
    ],
})
export class MarkApi {}