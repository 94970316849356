import { Component, Inject, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core"
import { Subject, merge, zip } from "rxjs"
import { switchMap, shareReplay, map, debounceTime, tap, filter, take } from "rxjs/operators"

import { Destruct } from "@anzar/core"
import { MarkBackend, OrgMark } from "@backend/mark.api"
import { CurrentSection } from "../section.service/level"
import { MarkService, ClientMarker } from "./mark.service"


const MANUAL_MARKABLE = ["daily", "deadline", "siid_deadline", "lung_screening", "contract", "toggle_wdate", "covid_vaccination", "date_range"]


@Component({
    selector: ".rege-mark-list",
    templateUrl: "./mark-list.template.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkListComponent implements OnDestroy {
    public destruct = new Destruct()

    @Input()
    public set clientId(val: number) {
        if (this._clientId !== val) {
            this._clientId = val
            this._clientIdChanged.next(val)
        }
    }
    public get clientId(): number { return this._clientId }
    private _clientId: number

    private _clientIdChanged = new Subject<number>()
    private _clientIdO = this._clientIdChanged.pipe(shareReplay())
    private _cmChanged = this.markService.clientMarkChanged.pipe(filter(cm => cm.client_id === this.clientId))

    public readonly clientMarks = merge(this._clientIdO, this.section.id$, this._cmChanged).pipe(
        debounceTime(10),
        switchMap(v => zip(this._clientIdO, this.section.id$)),
        switchMap(v => {
            const [client_id, section_id] = v
            return this.markBackend.get_due_alerts({ client_ids: [client_id], section_id }).pipe(map(res => res[0] || []))
        }),
        shareReplay(1)
    )

    public readonly marked = merge(this.markService.marks, this.clientMarks).pipe(
        debounceTime(10),
        switchMap(x => zip(this.markService.marks, this.clientMarks)),
        map(v => {
            const [marks, clientMarks] = v

            let res: ClientMarker[] = []

            for (const mark of marks) {
                let cm = clientMarks.find(v => v[0].org_mark_id === mark.id)
                if (cm) {
                    res.push(new ClientMarker(mark, cm[0], cm[1], cm[2]))
                }
            }

            return res.sort((a, b) => {
                if (a.alert.level === b.alert.level) {
                    if (a.mark.mark.position === b.mark.mark.position) {
                        return a.mark.mark.title.localeCompare(b.mark.mark.title)
                    } else {
                        return a.mark.mark.position - b.mark.mark.position
                    }
                } else {
                    return b.alert.level - a.alert.level
                }
            })
        }),
        shareReplay(1)
    )

    public readonly unmarked = this.marked.pipe(
        switchMap(v => zip(this.markService.marks, this.marked)),
        map(v => {
            const [marks, marked] = v
            return marks.filter(m => {
                return MANUAL_MARKABLE.indexOf(m.mark.type) !== -1
                    && !m.mark.is_technical
                    && !marked.find(mm => mm.mark.id === m.id)
            })
        }),
        shareReplay(1)
    )

    public _markedList: ClientMarker[] = []
    public _unmarkedList: OrgMark[] = []

    private readonly watchChange = this.unmarked.pipe(
        switchMap(v => zip(this.marked, this.unmarked).pipe(take(1))),
        tap(v => {
            [this._markedList, this._unmarkedList] = v
        }),
        shareReplay(1)
    )

    public constructor(
        @Inject(MarkService) private readonly markService: MarkService,
        @Inject(MarkBackend) private readonly markBackend: MarkBackend,
        @Inject(CurrentSection) private readonly section: CurrentSection,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {

        // this._clientIdO.subscribe(v => console.log("_clientIdO", v))
        // this._cmChanged.subscribe(v => console.log("_cmChanged", v))
        // this.clientMarks.subscribe(v => console.log("clientMarks", v))
        // this.marked.subscribe(v => console.log("marked", v))
        // this.unmarked.subscribe(v => console.log("unmarked", v))
        // this.markService.marks.subscribe(v => console.log("this.markService.marks", v))
        // this.section.id$.subscribe(v => console.log("this.section.change", v))


        this.destruct.subscription(this.watchChange).subscribe(m => {
            this.cdr.detectChanges()
        })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}
