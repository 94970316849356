import { Component, Inject } from "@angular/core"
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"

import {
    BehaviorSubject,
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    merge,
    of,
    ReplaySubject,
    shareReplay,
    startWith,
    switchMap,
    take
} from "rxjs"

import { isEqual } from "lodash"

import {
    Destructible,
    FileDownloadService,
    FORM_FIELD_DEFAULT_VARIANT,
    FormFieldVariant,
    ToastService
} from "@anzar/core"

import { FsService } from "@pyzar/fs.module"

import { PartnerConnect, PartnerConnectDocumentType, PartnerConnectRepo } from "@backend/connect.api"
import { ProviderVariant, WorkerRole } from "@backend/enums.api"

import { NameInputComponent } from "../components/name-input.component"

export const FORM_FIELD_STYLE = new FormFieldVariant(true, false, false, false)
declare const BACKEND_URL: string

@Component({
    selector: "rege-connect-form",
    providers: [{ provide: FORM_FIELD_DEFAULT_VARIANT, useValue: FORM_FIELD_STYLE }],
    templateUrl: "./connect-form.component.pug"
})
export class ConnectFormComponent extends Destructible {
    public readonly ProviderVariantSrc = ProviderVariant.DATA
    public readonly partnerConnectDocumentTypes = PartnerConnectDocumentType.DATA.data
    public readonly WorkerRoleSrc = WorkerRole.DATA

    readonly form = new FormGroup({
        token: new FormControl(),
        partner: new FormGroup({
            name: new FormControl(null, [Validators.required]),
            hq: new FormControl(null, [Validators.required]),
            contact_name: NameInputComponent.formModel(),
            contact_email: new FormControl(null, [Validators.required]),
            contact_tel: new FormControl(null, [Validators.required]),
            company_reg_number: new FormControl(null),
            billing_name: new FormControl(null, [Validators.required]),
            billing_address: new FormControl(null, [Validators.required]),
            billing_tax_number: new FormControl(null, [Validators.required]),
            billing_email: new FormControl(null, [Validators.required])
        }),
        user: new FormGroup({
            name: NameInputComponent.formModel(),
            email: new FormControl(null, [Validators.required]),
            tel: new FormControl(null, [Validators.required])
        }),
        institutions: new FormArray([])
    })

    readonly formValue$ = this.form.valueChanges.pipe(
        startWith(null),
        map(() => this.form.value),
        distinctUntilChanged(isEqual),
        debounceTime(500),
        shareReplay(1)
    )

    public set busy(val: boolean) {
        if (this.busy$.value !== val) {
            this.busy$.next(val)
        }
    }
    public get busy(): boolean {
        return this.busy$.value
    }

    readonly busy$ = new BehaviorSubject(false)

    get institutions() {
        return this.form.get("institutions") as FormArray
    }

    readonly instituionValues = this.institutions.valueChanges.pipe(
        startWith(null),
        map(() => this.institutions.value),
        shareReplay(1)
    )

    readonly token$ = this.route.params.pipe(
        map(v => v.token as string),
        filter(v => !!v),
        distinctUntilChanged(),
        shareReplay(1)
    )
    readonly reload = new ReplaySubject<void>(1)
    readonly reload$ = this.reload.pipe(
        switchMap(() => this.token$),
        shareReplay(1)
    )
    readonly connect$ = merge(this.token$, this.reload$).pipe(
        switchMap(token => this.pc.get_by_token({ token })),
        shareReplay(1)
    )
    readonly isSubmitted$ = this.connect$.pipe(
        map(v => v?.is_submitted),
        shareReplay(1)
    )

    constructor(
        @Inject(Router) private readonly router: Router,
        @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
        @Inject(PartnerConnectRepo) private readonly pc: PartnerConnectRepo,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(FsService) private readonly fs: FsService,
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService
    ) {
        super()

        this.destruct.subscription(this.instituionValues).subscribe(institutions => {
            for (let instIndex = 0; instIndex < institutions.length; instIndex++) {
                const institution = institutions[instIndex]
                const providers = institution.providers

                let allKenysziTypeSet = true
                let firstUnset = true

                for (let pindex = providers.length - 1; pindex >= 0; pindex--) {
                    const provider = providers[pindex]
                    if (!provider.variant) {
                        if (firstUnset) {
                            firstUnset = false
                            allKenysziTypeSet = false
                        } else {
                            this.doDeleteProvider(instIndex, pindex)
                        }
                    }
                }

                if (allKenysziTypeSet) {
                    this.doAddProvider(instIndex)
                }
            }
        })

        this.destruct
            .subscription(this.formValue$)
            .pipe(
                filter(value => !!value && !!value.token),
                switchMap(value => {
                    const data = { ...value }
                    const token = data.token
                    delete data.token
                    return this.pc.autosave({ token, data }).pipe(catchError(() => of(null)))
                })
            )
            .subscribe()

        this.destruct
            .subscription(this.connect$)
            .pipe(toast.catchError(() => this.router.navigate(["/info"])))
            .subscribe(connect => {
                if (connect && connect.is_submitted) {
                    return
                }
                this.reset(connect)
            })
    }

    doAddInstituition() {
        this.institutions.push(this.institutionForm())
    }

    doDeleteInstituition(index: number) {
        this.institutions.removeAt(index)
    }

    institutionForm(data: any = {}) {
        return new FormGroup({
            full_name: new FormControl(data.full_name, [Validators.required]),
            short_name: new FormControl(data.short_name, [Validators.required]),
            hq_address: new FormControl(data.hq_address, [Validators.required]),
            hq_tel: new FormControl(data.hq_tel, [Validators.required]),
            nrszh_id: new FormControl(data.nrszh_id, [Validators.required]),
            providers: new FormArray(
                data.providers ? data.providers.map((v: any) => this.providerForm(v)) : [this.providerForm()]
            )
        })
    }

    providerForm(data: any = {}) {
        return new FormGroup({
            variant: new FormControl(data.variant, [
                control => {
                    const parent = control.parent?.parent
                    if (!parent) {
                        return null
                    }

                    const providers = parent.value as Array<any>
                    const filledCount = providers.filter(v => !!v.variant).length

                    if (filledCount <= 0) {
                        return { required: true }
                    }

                    return null
                }
            ]),
            capacity: new FormControl(data.capacity || 0)
        })
    }

    doAddProvider(instIndex: number) {
        const providers = this.providers(instIndex)
        if (!providers) {
            return
        }
        providers.push(this.providerForm())
    }

    doDeleteProvider(instIndex: number, providerIndex: number) {
        const providers = this.providers(instIndex)
        if (!providers) {
            return
        }
        providers.removeAt(providerIndex)
    }

    providers(instIndex: number) {
        const inst = this.institutions.at(instIndex)
        if (!inst) {
            return {} as FormArray
        }
        return inst.get("providers") as FormArray
    }

    doSubmit() {
        this.form.updateValueAndValidity()
        this.form.markAllAsTouched()
        if (this.form.invalid) {
            return
        }

        this.busy = true

        const value = this.form.value
        const data = { ...value }
        const token = data.token
        delete data.token
        this.pc
            .submit({ token, data })
            .pipe(
                this.toast.catchError(() => (this.busy = false))
                // switchMap(downloadUrl =>
                //     this.downloader
                //         .download(downloadUrl)
                //         .pipe(this.toast.handleFileDownload({ align: "bottom center" }))
                // )
            )
            .subscribe(event => {
                this.busy = false
            })
        this.reload.next()
    }

    reset(pc: PartnerConnect) {
        const data = pc.data ? { ...pc.data } : {}
        this.form.reset(data)

        this.institutions.clear()
        if (data.institutions && data.institutions.length > 0) {
            for (const inst of data.institutions) {
                this.institutions.push(this.institutionForm(inst))
            }
        } else {
            this.institutions.push(this.institutionForm())
        }

        this.form.patchValue({ token: pc.token })
    }

    downloadDoc(type: string) {
        this.token$
            .pipe(
                take(1),
                switchMap(token =>
                    this.pc.get_docx_download_url({ token, doc_type: type }).pipe(this.toast.catchError())
                ),
                switchMap(url =>
                    this.downloader
                        .download(`${BACKEND_URL}${url}`)
                        .pipe(this.toast.handleFileDownload({ align: "bottom center" }))
                )
            )
            .subscribe()
    }
}
