import { Component, Inject, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Subject } from "rxjs"
import { shareReplay, startWith, switchMap } from "rxjs/operators"


import { ToastService } from "@anzar/core"
import { Permission, PermissionCategory, PermissionGroupRepo } from "@backend/pyzar.api"
import { NewGroupService } from "./new-group-component"


interface PermItem {
    id: string
    permission?: string
    category?: string
}


@Component({
    selector: ".pz-permission-editor",
    templateUrl: "./permission-editor.component.pug"
})
export class PermissionEditorComponent implements OnInit {
    @ViewChild("catWidth") public readonly catWidth: any

    private readonly _reloadGroup = new Subject<void>()
    public readonly groups$ = this._reloadGroup.pipe(
        startWith(null),
        switchMap(() => {
            return this.permGroupRepo.search({
                order: { label: "asc" }
            })
        }),
        shareReplay(1)
    )
    public readonly permissions = this.createPermItems()

    public hhRow: number
    public hhCol: number

    public readonly value = new FormControl()

    public constructor(
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(ElementRef) private readonly el: ElementRef<HTMLElement>,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(PermissionGroupRepo) private readonly permGroupRepo: PermissionGroupRepo,
        @Inject(NewGroupService) private readonly newGroupSvc: NewGroupService) {
    }

    public ngOnInit() {
        this.permGroupRepo.get_permissions().subscribe(perms => {
            this.value.setValue(perms)
        })
    }

    public save() {
        this.permGroupRepo
            .set_permissions({ permissions: this.value.value })
            .pipe(this.toast.handleSave({ align: "bottom center", successMsg: "Sikeresen mentve" }))
            .subscribe()
    }

    public onMouseMove(event: Event) {
        let row: number = null
        let col: number = null
        const root = this.el.nativeElement
        let target = event.target as HTMLElement

        while (target !== root) {
            if (target.dataset.nohover == null && (target.dataset.col != null || target.dataset.row != null)) {
                col = Number(target.dataset.col)
                row = Number(target.dataset.row)
                break
            }
            target = target.parentElement
        }

        col = isNaN(col) ? null : col
        row = isNaN(row) ? null : row

        this.setHH(col, row)
    }

    public onMouseLeave(event: Event) {
        this.setHH(null, null)
    }

    public doNewGroup(event: Event) {
        this.newGroupSvc.show(event.currentTarget as HTMLElement)
            .subscribe(group => {
                this._reloadGroup.next()
            })
    }

    private createPermItems() {
        const categorys = PermissionCategory.DATA.data.slice(0).sort((a, b) => a.label.localeCompare(b.label))
        const perms = Permission.DATA.data.slice(0).sort((a, b) => a.label.localeCompare(b.label))
        let result: PermItem[] = []

        for (const category of categorys) {
            result.push({
                id: category.id,
                category: category.label,
            })

            for (const perm of perms) {
                if (perm.category === category.id) {
                    result.push({
                        id: perm.id,
                        permission: perm.label
                    })
                }
            }
        }

        return result
    }

    private setHH(col: number, row: number) {
        if (this.hhCol !== col || this.hhRow !== row) {
            this.hhCol = col
            this.hhRow = row
            this.cdr.detectChanges()
        }
    }
}
