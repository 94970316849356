import { NgModule } from "@angular/core"
import { Resource, ResourceOpen, ResourceOpenDays, ResourceReservation, ResourceBackend, ResourceReservationBackend, ResourceBackendSource, ResourceReservationBackendSource } from "./__anzar_rpc_output"

export { Resource, ResourceOpen, ResourceOpenDays, ResourceReservation, ResourceBackend, ResourceReservationBackend, ResourceBackendSource, ResourceReservationBackendSource }


@NgModule({
    providers: [
        ResourceBackendSource,
        ResourceReservationBackendSource,
        ResourceBackend,
        ResourceReservationBackend
    ],
})
export class Service_scheduleApi {}