import { AbstractControl, ValidationErrors } from "@angular/forms"


// https://www.tankonyvtar.hu/hu/tartalom/tamop425/0038_informatika_Geda_Gabor_Hernyak_Zoltan-Algoritmizalas_es_adatmodellek/ch07s02.html
export function socialInsuranceIdValidator(control: AbstractControl): ValidationErrors | null {
    let value = control.value as string
    if (value && value.length) {
        if (/^0*$/.test(value)) {
            return { socialInsuranceId: true }
        }

        let parts = value.split("").map(Number)
        let sum1 = parts[0] * 3
            + parts[2] * 3
            + parts[4] * 3
            + parts[6] * 3
        let sum2 = parts[1] * 7
            + parts[3] * 7
            + parts[5] * 7
            + parts[7] * 7

        if ((sum1 + sum2) % 10 !== parts[8]) {
            return { socialInsuranceId: true }
        }
    }
    return null
}
