import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { ComponentsModule } from "../components"
import { PlaceModule } from "../place.module"

import { NzModule, RICHTEXT_AUTO_COMPLETE, RICHTEXT_COMPONENT } from "@anzar/core"

import { HashtagBackend } from "@backend/richtext.api"
import { WorkerApi } from "@backend/worker.api"

import { WorkerRichtextProvider } from "./worker/worker-provider"
import { WorkerLinkComponent } from "./worker/worker-link.component"

import { ClientRichtextProvider } from "./client/client-provider"
import { ClientLinkComponent } from "./client/client-link.component"

import { SectionLinkComponent } from "./section/section-link.component"
import { SectionInfoComponent, SectionInfoService } from "./section/section-info.component"

import { HashtagRichtextProvider } from "./hashtag/hashtag-provider"
import { HashtagClickHandler } from "./hashtag/click-handler"
import { HashtagComponent } from "./hashtag/hashtag.component"
export { HashtagComponent, HashtagClickHandler }

import { PlaceLinkComponent } from "./place/place-link.component"

import { ProviderServiceComponent } from "./provider-service/provider-service.component"

export { DispatcherRTModule } from "./dispatcher"


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        ComponentsModule,
        PlaceModule,
        WorkerApi
    ],
    declarations: [
        WorkerLinkComponent,
        ClientLinkComponent,
        SectionLinkComponent,
        SectionInfoComponent,
        HashtagComponent,
        PlaceLinkComponent,
        ProviderServiceComponent,
    ],
    exports: [
        WorkerLinkComponent,
        ClientLinkComponent,
        SectionLinkComponent,
        SectionInfoComponent,
        HashtagComponent,
        PlaceLinkComponent,
        ProviderServiceComponent,
    ],
    entryComponents: [
        WorkerLinkComponent,
        ClientLinkComponent,
        SectionLinkComponent,
        SectionInfoComponent,
        HashtagComponent,
        PlaceLinkComponent,
        ProviderServiceComponent,
    ],
    providers: [
        HashtagBackend,
        SectionInfoService,

        { provide: RICHTEXT_AUTO_COMPLETE, useClass: WorkerRichtextProvider, multi: true },
        { provide: RICHTEXT_COMPONENT, useValue: { id: "worker-link", component: WorkerLinkComponent }, multi: true },

        { provide: RICHTEXT_AUTO_COMPLETE, useClass: ClientRichtextProvider, multi: true },
        { provide: RICHTEXT_COMPONENT, useValue: { id: "client-link", component: ClientLinkComponent }, multi: true },

        { provide: RICHTEXT_AUTO_COMPLETE, useClass: HashtagRichtextProvider, multi: true },
        { provide: RICHTEXT_COMPONENT, useValue: { id: "hashtag", component: HashtagComponent }, multi: true },

        { provide: RICHTEXT_COMPONENT, useValue: { id: "provider-service", component: ProviderServiceComponent }, multi: true },
    ]
})
export class RichtextModule {

}
