import { Component, Inject, ChangeDetectorRef } from "@angular/core"

import { Destructible } from "@anzar/core"
import { CurrentSection, CurrentHeadquerter } from "../section.service/level"


export abstract class AbstarctTitle extends Destructible {
    public set subtitle(val: string) {
        if (this._subtitle !== val) {
            this._subtitle = val
            this.cdr.markForCheck
        }
    }
    public get subtitle(): string { return this._subtitle }
    private _subtitle: string

    public constructor(protected readonly cdr: ChangeDetectorRef) {
        super()
    }
}


@Component({
    selector: ".rege-page-title:not([variant]), .rege-page-title[variant='section']",
    templateUrl: "./page-title.component.pug"
})
export class PageTitleSectionComponent extends AbstarctTitle {
    public constructor(
        @Inject(CurrentSection) private readonly section: CurrentSection,
        @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
        super(cdr)

        this.destruct.subscription(this.section.section).subscribe(section => {
            this.subtitle = section?.title
        })
    }
}


@Component({
    selector: ".rege-page-title[variant='hq']",
    templateUrl: "./page-title.component.pug"
})
export class PageTitleHqComponent extends AbstarctTitle {
    public constructor(
        @Inject(CurrentHeadquerter) private readonly hq: CurrentHeadquerter,
        @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
        super(cdr)

        this.destruct.subscription(this.hq.headquarter).subscribe(headquarter => {
            this.subtitle = headquarter?.title
        })
    }
}
