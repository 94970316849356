import { Component, InjectionToken, Inject } from "@angular/core"
import { NEVER } from "rxjs"
import { take, catchError } from "rxjs/operators"

import { LayerRef, LayerEvent, ToastService } from "@anzar/core"
import { Section } from "@backend/org.api"
import { BedReservation, BedReservationBackend } from "@backend/service_room.api"
import type { ListEntry } from "./room.component"


export const OVERLAPPING_RESERVATIONS = new InjectionToken("OVERLAPPING_RESERVATIONS")
export const RESERVING_ENTRY = new InjectionToken("RESERVING_ENTRY")
export const RESERVING_CLIENT_ID = new InjectionToken("RESERVING_CLIENT")


@Component({
    selector: "rege-room-conflict-dialog",
    templateUrl: "./conflict-dialog.component.pug"
})
export class RoomConflictDialogComponent {
    protected readonly entry: ListEntry

    public constructor(
        @Inject(OVERLAPPING_RESERVATIONS) public readonly overlapping: Array<[Section, BedReservation]>,
        @Inject(RESERVING_ENTRY) entry: any,
        @Inject(RESERVING_CLIENT_ID) protected readonly clientId: number,
        @Inject(LayerRef) protected readonly layerRef: LayerRef,
        @Inject(BedReservationBackend) protected readonly resvBackend: BedReservationBackend,
        @Inject(ToastService) protected readonly toastSvc: ToastService) {
        this.entry = entry
    }

    public close() {
        this.layerRef.hide()
    }

    public resolve() {
        this.resvBackend.freeing_range({
            bed_id: this.entry.bed.id,
            client_id: this.clientId,
            begin: this.entry.begin.value,
            end: this.entry.end.value
        })
            .pipe(take(1))
            .pipe(catchError(err => {
                this.toastSvc.error(err.message)
                return NEVER
            }))
            .subscribe(res => {
                if (res === true) {
                    this.layerRef.emit(new LayerEvent("resolved"))
                }
                this.close()
            })
    }
}
