import { Component, Inject, OnDestroy, Input, ChangeDetectorRef, OnInit } from "@angular/core"
import { ActivatedRoute, Router, } from "@angular/router"
import { Location } from "@angular/common"
import { FormControl } from "@angular/forms"
import { Subject, Observable, of, Observer, merge, zip, BehaviorSubject, ReplaySubject, combineLatest } from "rxjs"
import { switchMap, finalize, startWith, debounceTime, take, shareReplay, map, filter, distinctUntilChanged } from "rxjs/operators"

import { isDate, differenceInDays, startOfDay, subDays, format, parse, parseISO } from "date-fns"

import { Destruct, LocalStorageBucket, LocalStorageService, DialogService, LoadFields, ProgressEvent, MediaQueryService, CordovaService } from "@anzar/core"


import { Client } from "@backend/client.api"
import { BNOReservationBackend } from "@backend/service_bno.api"
import { ProviderBackend, ProviderService, Duty, DutyBackend } from "@backend/org.api"
import { KenysziReportBackend } from "@backend/kenyszi.api"
import { BedReservationBackend } from "@backend/service_room.api"
import { CurrentSection } from "../section.service/level"
import { MarkService } from "../mark.module"
import { DutyService, UsageInterceptor } from "../duty.module"
import { ProviderServices, UsableService, ReportableProvider } from "./provider.service"
import { EditorService } from "./editor/editor.service"
import { MailingService } from "./editor/mailing/mailing.service"
import { StreetCareDialogService } from "./street/street-care-dialog.service"


const TABBED_SERVICE_TYPES = ["scheduled", "room", "mailing", "bno"]
const AUTOFILL_SERVICE_TYPES = ["room", "bno"]
const CREATE_PAGE_SERVICE_TYPES = ["street_care", "communal"]
const CLIENT_FIELDS: LoadFields<Client> = ["id", "name", "social_insurance_id", "birth_date"]


export class AutofillService {
    public constructor(
        public title: string,
        public providerIds: number[],
        public services: ProviderService[],
        public autoTick: boolean,
        public doAction: (af: AutofillService) => void) {

    }
}


@Component({
    templateUrl: "./service-usage.screen.pug",
    providers: [
        DutyService,
        ProviderServices,
        UsageInterceptor,
        EditorService,
        MarkService,
        CurrentSection,
    ]
})
export class ServiceUsageScreen implements OnDestroy, OnInit {
    public readonly destruct = new Destruct()

    public tabbedServices: UsableService[] = []
    public primaryServices: UsableService[] = []
    public pinnedServices: UsableService[] = []

    @Input()
    public set isEditable(val: boolean) {
        if (this._isEditable !== val) {
            this._isEditable = val
            this.cdr.markForCheck()
        }
    }
    public get isEditable(): boolean { return this._isEditable }
    private _isEditable: boolean = true

    public readonly clientSelect = this.destruct.subject(new Subject<Client>())
    public readonly psIdFilter = new FormControl()

    public duties: Duty[] = []
    public selectedDuty: Duty = null
    public hasWithoutClient: boolean = false
    public useCreatePage: boolean = false
    public displayPrimaryServices: boolean = false
    public autofill: AutofillService[] = []
    public readonly providerIds: number[] = []
    public _providerIds: string
    public readonly sortBy: "name" | "time" = "time"
    public readonly sortDir: "asc" | "desc" = "asc"
    public readonly sorter: { [key: string]: string } = { time: "asc" }
    public isSm: boolean = false
    public reportProblemsUntil: Date

    public readonly progress$ = new Subject<ProgressEvent>()

    public set showProgressBar(val: boolean) {
        if (this._showProgressBar !== val) {
            this._showProgressBar = val
            this.cdr.markForCheck()
        }
    }
    public get showProgressBar(): boolean { return this._showProgressBar }
    private _showProgressBar: boolean = false
    private _lsBucket: LocalStorageBucket

    public readonly enableProblematicReportsUpdate = new BehaviorSubject(true)

    public readonly hasProblemWithToadyReports$ = merge(this.dutyService.date$, this.section.id$, this.provider.all, this.enableProblematicReportsUpdate, this.dutyService.usage.changed).pipe(
        switchMap(v => this.enableProblematicReportsUpdate.pipe(take(1))),
        filter(v => v),
        switchMap(v => zip(this.dutyService.date$, this.section.id$).pipe(take(1))),
        debounceTime(200),
        switchMap(([date, sectionId]) => {
            if (date && sectionId && this.provider.reportables?.length && this.enableProblematicReportsUpdate.value) {
                return this.kenysziReportBackend
                    .get_problematic_reports({ until: date, section_id: sectionId, fix_date: true })
                    .pipe(map(res => {
                        return res[1].length > 0
                    }))
            } else {
                return of(false)
            }
        }),
        map(v => {
            return v
        }),
        shareReplay(1)
    )

    public readonly currentDate = new ReplaySubject<Date | null>(1)

    public readonly isEditable$ = combineLatest({
        date: this.currentDate,
        agreement: this.provider.kenysziAgreementDeadline,
        utilization: this.provider.kenysziUtilizationDeadline,
    }).pipe(map(values => {
        return !!(values.date && (values.date >= values.agreement || values.date >= values.utilization))
    }), shareReplay(1))

    public constructor(
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(CurrentSection) public readonly section: CurrentSection,
        @Inject(ProviderBackend) protected readonly providerBackend: ProviderBackend,
        @Inject(DutyService) public readonly dutyService: DutyService,
        @Inject(DutyBackend) protected readonly dutyBackend: DutyBackend,
        @Inject(EditorService) protected readonly editorService: EditorService,
        @Inject(ProviderServices) public readonly provider: ProviderServices,
        @Inject(MarkService) private readonly markService: MarkService,
        @Inject(DialogService) private readonly dialogSvc: DialogService,
        @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
        @Inject(Router) private readonly router: Router,
        @Inject(Location) private readonly location: Location,
        @Inject(MailingService) private readonly mailingSvc: MailingService,
        @Inject(BedReservationBackend) private readonly bedResv: BedReservationBackend,
        @Inject(BNOReservationBackend) private readonly bnoResv: BNOReservationBackend,
        @Inject(LocalStorageService) localStorage: LocalStorageService,
        @Inject(MediaQueryService) public readonly mq: MediaQueryService,
        @Inject(CordovaService) public readonly cordova: CordovaService,
        @Inject(KenysziReportBackend) private readonly kenysziReportBackend: KenysziReportBackend,
        @Inject(StreetCareDialogService) private readonly streetCareDialog: StreetCareDialogService) {

        this._lsBucket = localStorage.newBucket("ServiceUsageScreen")
        this.destruct.disposable(this._lsBucket)
        this.destruct.subscription(this._lsBucket.changes$)
            .pipe(startWith(this._lsBucket))
            .subscribe(bucket => {
                this.setSorting(bucket.get("sortBy", "time") as string, bucket.get("sortDir", "asc") as string)
                this.psIdFilter.setValue((bucket.get("psIdFilter", {}) as any)[this.section.id] || [])
            })

        let hideTimeout: any = null
        this.destruct.subscription(this.progress$).subscribe(p => {
            if (hideTimeout) {
                clearTimeout(hideTimeout)
                hideTimeout = null
            }
            if ((p.percent < 1.0 && p.percent !== 0) || p.percent == null) {
                this.showProgressBar = true
            } else if (this.showProgressBar) {
                hideTimeout = setTimeout(() => {
                    this.showProgressBar = false
                    this.progress$.next({ percent: 0 })
                }, 500)
            }
        })

        this.destruct.subscription(dutyService.busy).subscribe(busy => {
            if (busy) {
                if (!this.showProgressBar) {
                    this.progress$.next({})
                }
            } else {
                this.progress$.next({ percent: 1 })
            }
        })

        this.destruct.subscription(mq.watch("lt-md")).subscribe(res => {
            this.isSm = res.matches
            this.cdr.markForCheck()
        })
    }

    public ngOnInit() {
        this.destruct.subscription(this.provider.services).subscribe(services => {
            let primary = []
            let pinned = []
            let tabbed = []
            let autofill: { [key: string]: ProviderService[] } = {}

            this.hasWithoutClient = false
            this.useCreatePage = false
            this.displayPrimaryServices = false

            for (const service of services) {
                for (const ps of service.services) {
                    if (ps.is_primary) {
                        let primaryTitle = this.provider.multipleProviders
                            ? this.provider.getById(ps.provider_id).title
                            : ps.service.title
                        primary.push(new UsableService(primaryTitle, service.isEditable, true, service.isActive, ps.service.type, [ps]))

                        if (CREATE_PAGE_SERVICE_TYPES.indexOf(ps.service.type) !== -1) {
                            this.useCreatePage = true
                        } else {
                            this.displayPrimaryServices = true
                        }
                    }

                    if (ps.is_pinned && ps.is_active) {
                        pinned.push(new UsableService(ps.service.title, service.isEditable, service.isPrimary, service.isActive, ps.service.type, [ps]))
                    }

                    if (ps.service.without_client) {
                        this.hasWithoutClient = true
                    }

                    // if (AUTOFILL_SERVICE_TYPES.indexOf(ps.service.type) !== -1) {
                    //     if (!autofill[ps.service.type]) {
                    //         autofill[ps.service.type] = [ps]
                    //     } else {
                    //         autofill[ps.service.type].push(ps)
                    //     }
                    // }
                }

                if (TABBED_SERVICE_TYPES.indexOf(service.type) !== -1 && service.isActive && (service.isEditable || service.type === "mailing")) {
                    tabbed.push(service)
                }
            }

            this.autofill = this.computeAutoFill(services)
            this.tabbedServices = tabbed
            this.primaryServices = primary.sort((a, b) => {
                return this.provider.getById(a.services[0].provider_id).position
                    - this.provider.getById(b.services[0].provider_id).position
            })
            this.pinnedServices = pinned.sort((a, b) => {
                return a.title.localeCompare(b.title)
            })
            // this.autofill = Object.keys(autofill).map(k => {
            //     const services = autofill[k]
            //     return new AutofillService(services[0].service.title, services[0].service.type, services)
            // })

            // nem létező elsődleges szolgáltatás filterek törlése
            let psIdFilter = this.psIdFilter.value as number[]
            if (psIdFilter) {
                psIdFilter = psIdFilter.filter(id => !!this.primaryServices.find(ps => ps.services[0].id === id))
                this.psIdFilter.setValue(psIdFilter)
            }

            this.cdr.markForCheck()
        })

        this.destruct.subscription(this.route.paramMap)
            .pipe(
                switchMap(params => {
                    (this as { providerIds: number[] }).providerIds = params.get("providerIds").split(",").map(Number)
                    this._providerIds = this.providerIds.join(",")
                    this.section.filterProviders(this.providerIds)
                    const date = params.get("date")

                    if (date) {
                        this.onDateChange(parseISO(date))
                    } else {
                        this.onDateChange(new Date())
                    }
                    const dutyId = Number(params.get("dutyId"))
                    return dutyId ? this.dutyService.getDuty(dutyId) : of(null)
                }),
                switchMap(duty => {
                    return new Observable((observer: Observer<Duty>) => {
                        const s = this.dutyService.selectedDuty$.subscribe(observer)
                        this.dutyService.selectedDuty = duty
                        return s.unsubscribe.bind(s)
                    }) as Observable<Duty>
                })
            )
            .subscribe(duty => {
                this.selectedDuty = duty
                if (duty) {
                    this.location.replaceState(`/szolgaltatas/${this.providerIds.join(',')}/lista/${duty.id}`)
                } else {
                    this.location.replaceState(`/szolgaltatas/${this.providerIds.join(',')}/lista`)
                }
                this.cdr.detectChanges()
            })

        this.destruct.subscription(this.dutyService.usage.changed).subscribe(changed => {
            this.cdr.markForCheck()
        })

        this.destruct.subscription(this.dutyService.duties).subscribe(duties => {
            this.duties = duties
            this.cdr.markForCheck()
        })

        // Azért van itt a debounceTime hogy a destruct miatt ne legyen üres érték elmentve
        this.destruct.subscription(this.psIdFilter.valueChanges.pipe(debounceTime(100))).subscribe(value => {
            let psIdFilter = this._lsBucket.get("psIdFilter", {}) as any
            if (`${psIdFilter}` !== "[object Object]") {
                psIdFilter = {}
            }
            psIdFilter[this.section.id] = value
            this._lsBucket.set("psIdFilter", psIdFilter)
        })

        this.destruct.subscription(this.dutyService.date$).subscribe(dutyDate => {
            this.reportProblemsUntil = subDays(dutyDate, 1)
            this.cdr.markForCheck()
        })
    }

    public onDateChange(date: Date) {
        if (isDate(date)) {
            this.currentDate.next(date)
            this.dutyService.date = date

            if (!this.selectedDuty) {
                this.location.replaceState(`/szolgaltatas/${this.providerIds.join(',')}/lista/datum/${format(date, 'yyyy-MM-dd')}`)
            }
            this.reportProblemsUntil = subDays(startOfDay(date), 1)
        } else {
            this.currentDate.next(null)
            this.dutyService.date = null
            this.reportProblemsUntil = null

            if (!this.selectedDuty) {
                this.location.replaceState(`/szolgaltatas/${this.providerIds.join(',')}/lista`)
            }
        }
        this.cdr.detectChanges()
    }

    public setSorting(by: string, dir: string) {
        if (this.sortBy !== by || this.sortDir !== dir) {
            (this as { sortBy: string }).sortBy = by;
            (this as { sortDir: string }).sortDir = dir;
            (this as { sorter: any }).sorter = { [by]: dir }
            this._lsBucket.set("sortBy", by)
            this._lsBucket.set("sortDir", dir)
            this.cdr.markForCheck()
        }
    }

    public addClient(client: Client) {
        this.psIdFilter.setValue([])
        this.dutyService.addClient(client).subscribe()
    }

    public newMailEntry(event: Event, psId: any) {
        this.mailingSvc.newMailEntry(psId)
    }

    public fillWithBedReservations(autofill: AutofillService) {
        this.enableProblematicReportsUpdate.next(false)
        const provider_service_ids = autofill.services.map(s => s.id)
        this.bedResv
            .search({
                filter: {
                    "date": this.dutyService.date,
                    "bed.room.provider_service_id": { "in": provider_service_ids }
                },
                order: {}
            }, { loadFields: [{ client: CLIENT_FIELDS }] })
            .pipe(
                switchMap(reservations => {
                    const clients = reservations.map(r => r.client)
                    return this.dutyService.addClients(
                        clients,
                        autofill.autoTick ? autofill.providerIds : null,
                        autofill.autoTick ? autofill.services : null,
                    )
                }),
                finalize(() => {
                    this.dutyService.usage.invalidate()
                    this.enableProblematicReportsUpdate.next(true)
                })
            )
            .subscribe(res => {
                this.progress$.next(res[0])
            })
    }

    public fillWithYesterdayBed(autofill: AutofillService) {
        this.enableProblematicReportsUpdate.next(false)
        const ps = this.provider.getServicesByType("room")
        this.dutyBackend
            .get_clients({
                date: subDays(this.dutyService.date, 1),
                provider_service_ids: ps.map(s => s.id),
                section_id: this.section.id
            }, { loadFields: CLIENT_FIELDS })
            .pipe(
                switchMap(clients => {
                    return this.dutyService.addClients(
                        clients,
                        autofill.autoTick ? autofill.providerIds : null,
                        autofill.autoTick ? autofill.services : null,
                    )
                }),
                finalize(() => {
                    this.dutyService.usage.invalidate()
                    this.enableProblematicReportsUpdate.next(true)
                })
            )
            .subscribe(res => {
                this.progress$.next(res[0])
            })
    }

    public fillWithBno(autofill: AutofillService) {
        this.enableProblematicReportsUpdate.next(false)
        const provider_service_ids = autofill.services.map(s => s.id)
        this.bnoResv
            .search({
                filter: {
                    "date": this.dutyService.date,
                    "bno.provider_service_id": { "in": provider_service_ids }
                },
                order: {}
            }, { loadFields: [{ client: CLIENT_FIELDS }] })
            .pipe(
                switchMap(reservations => {
                    const clients = reservations.map(r => r.client)
                    return this.dutyService.addClients(
                        clients,
                        autofill.autoTick ? autofill.providerIds : null,
                        autofill.autoTick ? autofill.services : null,
                    )
                }),
                finalize(() => {
                    this.dutyService.usage.invalidate()
                    this.enableProblematicReportsUpdate.next(true)
                })
            )
            .subscribe(res => {
                this.progress$.next(res[0])
            })
    }

    public kenysziReport(reportable: ReportableProvider) {
        this.router.navigate(["/kenyszi/jelentes", reportable.ids, format(this.dutyService.date, "yyyy-MM-dd")])
    }

    public _dutyVehicles(duty: Duty): string {
        return duty.vehicles.map(v => v.name).join(", ")
    }

    public _dutyWorkers(duty: Duty): string {
        return duty.workers.map(worker => {
            let res = ""
            if (worker.user.name.family) {
                res += worker.user.name.family[0].toLocaleUpperCase()
            }
            if (worker.user.name.given) {
                res += worker.user.name.given[0].toLocaleUpperCase()
            }
            return res
        }).join(", ")
    }

    protected computeAutoFill(services: UsableService[]): AutofillService[] {
        let order: string[] = []
        let result: { [key: string]: AutofillService } = {}

        function addAf(svc: UsableService, title: string, handler: (af: AutofillService) => void) {
            const key = `${svc.services[0].service.id}`
            const providerIds = svc.services.map(v => v.provider_id)

            if (!result[key]) {
                order.push(key)
                result[key] = new AutofillService(title, providerIds, svc.services, true, handler)
            } else {
                result[key].providerIds = result[key].providerIds.concat(providerIds)
                result[key].services = result[key].services.concat(svc.services)
            }

            result[key].providerIds = result[key].providerIds.filter((v, i, a) => a.indexOf(v) === i)
            result[key].services = result[key].services.filter((v, i, a) => a.findIndex(av => av.id === v.id) === i)

            return result[key]
        }

        for (const service of services) {
            if (AUTOFILL_SERVICE_TYPES.indexOf(service.type) !== -1) {
                if (service.type === "room") {
                    let provider = this.provider.getById(service.services[0].provider_id)
                    let siblings = this.provider.getProviderSiblings(provider)
                    for (const sibling of siblings) {
                        if (sibling.kenyszi_type === "NAPPALI_ELLATAS_NAPPALI_ELLATAS_HAJLEKTALAN_SZEMELYEK_SZAMARA") {
                            let primary = this.provider.getPrimaryService(sibling)
                            addAf(primary, "Tegnap itt alvók", this.fillWithYesterdayBed.bind(this))
                        }
                    }

                    addAf(service, "Szállásfoglalások", this.fillWithBedReservations.bind(this))
                } else if (service.type === "bno") {
                    addAf(service, "BNO foglalások", this.fillWithBno.bind(this))
                }
            }
        }

        let autofills: AutofillService[] = []
        for (const k of order) {
            const af = result[k]
            autofills.push(af)
            if (af.title !== "Tegnap itt alvók") {
                autofills.push(new AutofillService(`${af.title} (pipa nélkül)`, af.providerIds, af.services, false, af.doAction))
            }
        }

        return autofills
    }

    public addClientEvent() {
        this.streetCareDialog.addClientEvent(this.selectedDuty, this.primaryServices[0].services[0]).subscribe(event => {
            if (event) {
                this.dutyService.realoadEvent(event.id)
            }
        })
    }

    public addNonClientEvent() {
        this.streetCareDialog.addNonClientEvent(this.selectedDuty, this.primaryServices[0].services[0]).subscribe(event => {
            if (event) {
                this.dutyService.realoadEvent(event.id)
            }
        })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}
