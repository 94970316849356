import { Component, ElementRef, Inject, Input, OnDestroy } from "@angular/core"

import { BehaviorSubject, combineLatest, ReplaySubject, Subject, takeUntil } from "rxjs"

import { VgApiService } from "@videogular/ngx-videogular/core"

import {
    FastDOM,
    IDisposable,
    LayerContainer,
    LayerContainerRef,
    MaskRef,
    MaskService,
    Rect,
    RectMutationService,
    Time
} from "@anzar/core"

@Component({
    selector: "rege-video",
    templateUrl: "./video-player.component.pug"
})
export class VideoPlayerComponent implements OnDestroy {
    @Input() public src: string
    @Input() public poster: string

    @Input()
    public set videoWidth(val: number) {
        if (this._videoWidth.value !== val) {
            this._videoWidth.next(val)
        }
    }
    public get videoWidth(): number {
        return this._videoWidth.value
    }
    private _videoWidth = new BehaviorSubject<number>(0)

    @Input()
    public set videoHeight(val: number) {
        if (this._videoHeight.value !== val) {
            this._videoHeight.next(val)
        }
    }
    public get videoHeight(): number {
        return this._videoHeight.value
    }
    private _videoHeight = new BehaviorSubject<number>(0)

    public api: VgApiService

    public enlarged: EnlargedLayer

    public playerDim$ = new ReplaySubject<{ width: number; height: number }>(1)

    private until = new Subject<void>()

    public constructor(
        @Inject(ElementRef) private readonly el: ElementRef<HTMLElement>,
        @Inject(LayerContainer) private readonly layerContainer: LayerContainer,
        @Inject(MaskService) protected readonly maskSvc: MaskService,
        @Inject(RectMutationService) protected readonly mutation: RectMutationService
    ) {
        combineLatest({
            dim: mutation.watchDimension(el.nativeElement),
            vWidth: this._videoWidth,
            vHeight: this._videoHeight
        })
            .pipe(takeUntil(this.until))
            .subscribe(({ dim, vWidth, vHeight }) => {
                const scale = dim.width / vWidth
                this.playerDim$.next({ width: vWidth * scale, height: vHeight * scale })
            })
    }

    public onPlayerReady(event: VgApiService) {
        this.api = event
    }

    public seek(time: string) {
        this.api.currentTime = Time.coerce(time).length
        this.api.play()
    }

    public enlarge() {
        if (this.enlarged) {
            return
        }

        this.api.play()

        const backdrop = this.maskSvc.show(window, { backgroundColor: "rgba(0, 0, 0, 0.3)" })
        const container = this.layerContainer.getNewOutlet()

        backdrop.show()
        this.enlarged = new EnlargedLayer(container, backdrop, this.el.nativeElement)
    }

    public collapse() {
        if (this.enlarged) {
            this.enlarged.hide()
            delete this.enlarged
        }
    }

    public ngOnDestroy() {
        if (this.enlarged) {
            this.enlarged.dispose()
            delete this.enlarged
        }
        this.until.next()
        this.until.complete()
    }
}

class EnlargedLayer implements IDisposable {
    public readonly placeholder: HTMLElement
    private readonly scale: number

    public constructor(
        public readonly container: LayerContainerRef,
        public readonly backdrop: MaskRef,
        public readonly player: HTMLElement
    ) {
        const dim = player.getBoundingClientRect()
        const viewport = Rect.viewport()
        const margin = 16
        const maxWidth = viewport.width - margin * 2
        const maxHeight = viewport.height - margin * 2

        let scale = maxWidth / dim.width
        if (scale * dim.height > maxHeight) {
            scale = maxHeight / dim.height
        }

        const newWidth = Math.round(dim.width * scale)
        const newHeight = Math.round(dim.height * scale)
        const newLeft = Math.round(maxWidth / 2 - newWidth / 2 + margin)
        const newTop = Math.round(maxHeight / 2 - newHeight / 2 + margin)

        this.scale = dim.width / newWidth

        const containerEl = container.firstElement
        containerEl.style.width = newWidth + "px"
        containerEl.style.height = newHeight + "px"
        containerEl.style.position = "absolute"
        containerEl.style.left = dim.left + "px"
        containerEl.style.top = dim.top + "px"
        containerEl.style.transform = `scale(${this.scale})`
        containerEl.style.transformOrigin = "top left"
        containerEl.style.visibility = "visible"
        containerEl.setAttribute("elevation", "15")

        this.placeholder = this._createPlaceholder(dim)
        this.player.parentElement.insertBefore(this.placeholder, this.player)
        containerEl.appendChild(player)

        FastDOM.mutate(() => {
            containerEl.style.transition = "transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1)"
            // force style recalc
            window.getComputedStyle(containerEl).getPropertyValue("transform")
            containerEl.style.transform = `translate(${newLeft - dim.left}px, ${newTop - dim.top}px) scale(1)`
        })
    }

    public hide() {
        const containerEl = this.container.firstElement

        const onDone = () => {
            this.dispose()
            containerEl.removeEventListener("transitionend", onDone)
        }

        this.backdrop.dispose()
        containerEl.addEventListener("transitionend", onDone)
        containerEl.style.transform = `translate(0px, 0px) scale(${this.scale})`
    }

    public dispose(): void {
        this.placeholder.parentElement.insertBefore(this.player, this.placeholder)
        this.placeholder.parentElement.removeChild(this.placeholder)
        this.container.dispose()
        this.backdrop.dispose()
    }

    private _createPlaceholder(dim: DOMRect) {
        const el = document.createElement("div")
        el.style.width = dim.width + "px"
        el.style.height = dim.height + "px"
        el.style.background = "#000"
        return el
    }
}
