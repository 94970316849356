import { Component, Input, Inject, ViewChild, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { take, switchMap } from "rxjs/operators"


import { DataSourceDirective } from "@anzar/core"
import { EventBackendSource, EventBackend } from "@backend/event.api"
import { SocialMentalBackend } from "@backend/service_social_mental.api"
import { Duty } from "@backend/org.api"
import { CurrentSection } from "../../../section.service/level"
import { ServiceUsage, DutyService } from "../../../duty.module"
import { UsableService } from "../../provider.service"
import { AbstractEditor } from "../abstract"


@Component({
    selector: ".rege-social-mental-service-editor",
    templateUrl: "./social-mental.component.pug"
})
export class SocialMentalEditorComponent extends AbstractEditor implements OnInit {
    @ViewChild("listSrc", { static: false }) public readonly listSrc: DataSourceDirective

    @Input()
    public set service(val: UsableService) {
        if (this._service !== val) {
            this._service = val
        }
    }
    public get service(): UsableService { return this._service }
    private _service: UsableService

    @Input()
    public set usage(val: ServiceUsage) {
        if (this._usage !== val) {
            this._usage = val
        }
    }
    public get usage(): ServiceUsage { return this._usage }
    private _usage: ServiceUsage

    public readonly text = new FormControl()
    public careEventId: number

    public constructor(
        @Inject(EventBackendSource) public readonly eventSource: EventBackendSource,
        @Inject(EventBackend) public readonly eventBackend: EventBackend,
        @Inject(CurrentSection) public readonly section: CurrentSection,
        @Inject(DutyService) protected readonly dutyService: DutyService,
        @Inject(SocialMentalBackend) protected readonly socialMentalBackend: SocialMentalBackend) {
        super()
    }

    public ngOnInit() {
        const eventUsage = this.usage.findUsage(this.service.services[0])

        if (eventUsage && eventUsage.eventId) {
            this.socialMentalBackend
                .get_care_event({ event_id: eventUsage.eventId, provider_service_id: eventUsage.providerService.id },
                    { loadFields: ["id", { richtext: ["text", "content"] as any }] })
                .subscribe(event => {
                    if (event) {
                        this.careEventId = event.id
                        this.text.setValue(event.richtext.text)
                    } else {
                        this.careEventId = null
                        this.text.reset()
                    }
                })
        } else {
            this.careEventId = null
            this.text.reset()
        }
    }

    public save() {
        this.eventBackend.save({
            data: {
                id: this.careEventId,
                type: "care",
                content: this.text.value,
                client_id: this.usage.client.id,
                section_id: this.section.id
            }
        }).subscribe(event => {
            this.careEventId = event.id
            this.listSrc.reload()
            this.dutyService.setUsage(this.usage, this.service.services[0], true, true).pipe(
                take(1),
                switchMap(res => {
                    return this.socialMentalBackend.set_care_event_id({
                        care_event_id: event.id,
                        event_id: res.eventId,
                        provider_service_id: res.providerService.id
                    })
                })
            ).subscribe()
        })
    }
}
