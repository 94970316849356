import { Component, Input, ChangeDetectionStrategy, Inject, OnChanges, SimpleChanges, ChangeDetectorRef, Optional, OnDestroy } from "@angular/core"
import { FormControl } from "@angular/forms"
import { take } from "rxjs/operators"

import { DataSourceDirective, ToastService } from "@anzar/core"
import { Event, EventBackend } from "@backend/event.api"
import { WorkerCardService } from "../../worker.module"
import { StoryEntryListPopupService } from "../../dispatcher.module/story-entry-list.popup"
import { ClientService } from "../client.service"
import { ClientSheet } from "../client-sheet.component"
import { ClosingGuardService, CloseableTracker } from "../../common"
import { AuthService } from "@pyzar/auth.module"
import { forkJoin } from "rxjs"


const EDITABLE_TYPES = ["care", "street_care"]


export const DISPLAY_SERVICES = 1
export const DISPLAY_TEXT = 2
export const DISPLAY_CLIENT = 4


@Component({
    selector: ".rege-client-feed-entry",
    templateUrl: "./client-feed-entry.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientFeedEntryComponent implements OnChanges, OnDestroy {
    @Input() public entry: Event
    @Input() public displayMode: number
    @Input() public showDateHead: boolean

    public canEdit: boolean = false
    public canDelete: boolean = false
    public isDispatcher: boolean = false

    public _isEditing: boolean = false
    public _displayClient: boolean = false
    public _displayText: boolean = false
    public _displayService: boolean = false

    public readonly textCtrl = new FormControl()

    // private currentUserId: number
    // private currentUserIsAdmin: boolean = false

    private closable: CloseableTracker

    public constructor(
        @Inject(WorkerCardService) private readonly ws: WorkerCardService,
        @Inject(DataSourceDirective) private readonly source: DataSourceDirective<any>,
        @Inject(EventBackend) private readonly eventBackend: EventBackend,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(StoryEntryListPopupService) private readonly storyEntryListSvc: StoryEntryListPopupService,
        @Inject(AuthService) private readonly authSvc: AuthService,
        @Inject(ClosingGuardService) @Optional() closingGuard: ClosingGuardService,
        @Inject(ClientSheet) @Optional() sheet: ClientSheet) {

        // authSvc.currentUser$.pipe(take(1)).subscribe(user => {
        //     this.currentUserId = user.id
        //     this.currentUserIsAdmin = user.is_admin
        // })
        this._updatePermissions()

        if (closingGuard) {
            this.closable = closingGuard.closeableTracker("Nem mentetted a gondozási bejegyzés módosításodat. Biztosan bezárod?", (close) => {
                if (!close && sheet) {
                    sheet.selectedTabIndex = ClientService.TAB_NEWS
                }
            })
            this.closable.determine = () => {
                return !this._isEditing || !this.textCtrl.dirty
            }
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if ("entry" in changes) {
            const newEntry = changes.entry.currentValue as Event
            this._updatePermissions()
            this.isDispatcher = Boolean(newEntry.type === "dispatcher" && this.entry.richtext_id)
        }

        if ("displayMode" in changes || "entry" in changes) {
            this._displayClient = (this.displayMode & DISPLAY_CLIENT) === DISPLAY_CLIENT && !!this.entry.client
            this._displayText = (this.displayMode & DISPLAY_TEXT) === DISPLAY_TEXT && this.entry.richtext?.text?.length > 0
            this._displayService = (this.displayMode & DISPLAY_SERVICES) === DISPLAY_SERVICES && this.entry.services && this.entry.services.length > 0
        }
    }

    public zeroPadded(num: number): string {
        return num < 10 ? `0${num}` : `${num}`
    }

    public showWorkerInfo(userId: number) {
        this.ws.show(userId)
    }

    public showClientSheet(clientId: number) {
        this.clientSvc.showSheet(clientId).subscribe()
    }

    public showDispatcherStory() {
        this.storyEntryListSvc.showByRichtextId(this.entry.richtext_id)
    }

    public editEntry() {
        this.textCtrl.reset(this.entry.richtext ? this.entry.richtext.text : null)
        this._isEditing = true
        this.cdr.detectChanges()
    }

    public deleteEntry() {
        this.eventBackend.remove({ id: this.entry.id }).pipe(take(1)).subscribe(() => {
            this.source.reload()
        })
    }

    public cancel() {
        this._isEditing = false
        this.cdr.detectChanges()
    }

    public save() {
        this.eventBackend
            .save({
                data: {
                    id: this.entry.id,
                    content: this.textCtrl.value
                }
            })
            .pipe(
                take(1),
                this.toastSvc.handleSave({ align: "bottom center", successMsg: "Sikeresen módosítottad a bejegyzést." })
            )
            .subscribe(res => {
                if (!this.entry.richtext) {
                    this.entry.richtext = {} as any
                }
                this.entry.richtext.text = this.textCtrl.value
                this.entry.updated_time = res.updated_time
                this._isEditing = false
                this.cdr.detectChanges()
            })
    }

    private _updatePermissions() {
        this.canEdit = false
        this.canDelete = false
        if (this.entry && EDITABLE_TYPES.indexOf(this.entry.type) !== -1) {
            const entryCreatorId = this.entry.creator.id
            forkJoin([
                this.authSvc
                    .hasPermission({
                        permission: "event.care.update",
                        mustSameUserId: entryCreatorId,
                        sameUserId: entryCreatorId
                    })
                    .pipe(take(1)),
                this.authSvc
                    .hasPermission({
                        permission: "event.care.delete",
                        mustSameUserId: entryCreatorId,
                        sameUserId: entryCreatorId
                    })
                    .pipe(take(1)),
            ]).subscribe(([canEdit, canDelete]) => {
                this.canEdit = canEdit
                this.canDelete = canDelete
                this.cdr.markForCheck()
            })
        } else {
            this.cdr.markForCheck()
        }
    }

    public ngOnDestroy() {
        this.closable?.dispose()
    }
}
