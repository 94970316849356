import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { NzModule, RICHTEXT_AUTO_COMPLETE, RICHTEXT_COMPONENT } from "@anzar/core"


import { CommandsRichtextProvider } from "./commands-provider"

import { PublicServiceComponent } from "./public-service/public-service.component"
import { PublicServiceFormComponent } from "./public-service/public-service-form.component"

import { MedicalExamComponent } from "./medical-exam/medical-exam.component"
import { MedicalExamFormComponent } from "./medical-exam/medical-exam-form.component"

import { HousingComponent } from "./housing/housing.component"
import { HousingFormComponent } from "./housing/housing-form.component"

import { TransportComponent } from "./transport/transport.component"
import { TransportFormComponent } from "./transport/transport-form.component"


const entries = [
    PublicServiceComponent,
    PublicServiceFormComponent,

    MedicalExamComponent,
    MedicalExamFormComponent,

    HousingComponent,
    HousingFormComponent,

    TransportComponent,
    TransportFormComponent,
]

@NgModule({
    imports: [
        CommonModule,
        NzModule
    ],
    declarations: entries,
    exports: entries,
    entryComponents: entries,
    providers: [
        { provide: RICHTEXT_AUTO_COMPLETE, useClass: CommandsRichtextProvider, multi: true },
        { provide: RICHTEXT_COMPONENT, useValue: { id: "public-service", component: PublicServiceComponent }, multi: true },
        { provide: RICHTEXT_COMPONENT, useValue: { id: "medical-exam", component: MedicalExamComponent }, multi: true },
        { provide: RICHTEXT_COMPONENT, useValue: { id: "housing", component: HousingComponent }, multi: true },
        { provide: RICHTEXT_COMPONENT, useValue: { id: "transport", component: TransportComponent }, multi: true },
    ]
})
export class DispatcherRTModule {

}
