import { ChangeDetectorRef, Component, forwardRef, Inject } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"

import { filter, switchMap, tap } from "rxjs/operators"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { FileUploaderService, FsService } from "@pyzar/fs.module"

import { Maintainer, MaintainerBackend } from "@backend/org.api"

import { UploadedFile } from "../../../../../lib/anzar/core"
import { MaintainerEditorService } from "./maintainer-editor.service"

@Component({
    selector: ".rege-maintainer-basic-data",
    templateUrl: "./maintainer-basic-data.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => MaintainerBasicDataComponent) },
        FileUploaderService
    ]
})
export class MaintainerBasicDataComponent extends AbstractCLEditorBlock<MaintainerEditorService> {
    public readonly maintainer: Maintainer

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: MaintainerEditorService,
        @Inject(MaintainerBackend) protected readonly maintainerBackend: MaintainerBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(FileUploaderService) private readonly uploader: FileUploaderService,
        @Inject(FsService) private readonly fs: FsService
    ) {
        super(
            editorSvc,
            new FormGroup({
                title: new FormControl(),
                logo: new FormControl()
            })
        )
    }

    public ngOnInit() {
        super.ngOnInit()
        this.destruct.subscription(this.editorSvc.maintainer$).subscribe(maintainer => {
            ;(this as { maintainer: Maintainer }).maintainer = maintainer as Maintainer
            this.form.reset({
                title: this.maintainer ? this.maintainer.title : null,
                logo: this.maintainer && this.maintainer.logo ? this.fs.newUploadedFile(this.maintainer.logo) : null
            })
            this.cdr.detectChanges()
        })
    }

    public save() {
        return this.uploader.upload("/org").pipe(
            filter(e => e.state === "done"),
            switchMap(event => {
                console.log({ event })
                const file = this.form.value.logo as UploadedFile
                const data = {
                    id: this.maintainer?.id,
                    title: this.form.value.title,
                    logo_id: file?.id
                }

                return this.maintainerBackend.save({ data }).pipe(
                    tap(level => {
                        this.editorSvc.id = level.id
                    })
                )
            })
        )
    }
}
