import { Component, Input, Inject, Output, EventEmitter } from "@angular/core"

import { LoadFields } from "@anzar/core"
import { StoryEntry } from "@backend/dispatcher.api"
import { WorkerCardService } from "../worker.module"

export const STORY_ENTRY_FIELDS: LoadFields<StoryEntry> = [
    "id",
    "story_id",
    "call_type",
    "call_type_full",
    "call_partner_type",
    "call_partner_client_id",
    "call_partner_section_id",
    "call_partner_crisis_id",
    "call_partner_user_id",
    "call_partner_name",
    "call_partner_tel",
    "call_partner_email",
    "call_partner_is_anon",
    "call_time",
    "arrive_time",
    "is_incoming",
    "is_email",
    "place_extra",
    { creator: ["id", "name", "profile_image_id"] },
    { call_partner_client: ["id", "name", "social_insurance_id", "birth_date"] },
    { call_partner_section: ["id", "title"] },
    { call_partner_user: ["id", "name"] },
    { call_partner_crisis: ["id", "work_begin", "work_end", { vehicle: ["name"] }, { workers: [{ user: ["id", "name"] }] }] },
    { place: ["city", "sublocality", "public_place", "street_number", "description"] },
    { messages: ["id", { richtext: ["text", "content"] as any }] }
]


export type ActionType = "callback" | "edit" | "remove" | "move" | "moveIntoNewStory"
export type ActionEvent = { action: ActionType, entry: StoryEntry }


@Component({
    selector: ".rege-disp-story-entry-card",
    templateUrl: "./story-entry-card.component.pug"
})
export class StoryEntryCardComponent {
    @Output() public readonly action = new EventEmitter<ActionEvent>()
    @Input() public storyEntry: StoryEntry

    @Input() public embedded: boolean

    public constructor(@Inject(WorkerCardService) public readonly workerSheet: WorkerCardService) {

    }

    public emitAction(type: ActionType) {
        this.action.next({ action: type, entry: this.storyEntry })
    }

    public callPartnerTelOrEmail() {
        const tel = this.storyEntry.call_partner_tel
        const email = this.storyEntry.call_partner_email
        if (tel && email) {
            return `${tel} ${email}`
        } else if (tel) {
            return tel
        } else if (email) {
            return email
        } else {
            return ""
        }
    }
}
