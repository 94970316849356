import { NgModule } from "@angular/core"
import { Feedback } from "./__anzar_rpc_output"

export { Feedback }


@NgModule({
    providers: [
        Feedback
    ],
})
export class FeedbackApi {}