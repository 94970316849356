import { Inject, Injectable, OnDestroy } from "@angular/core"
import { Observable, of } from "rxjs"
import { tap } from "rxjs/operators"

import { DialogService, IDisposable } from "@anzar/core"


export type CloseableTrackerHandler = (decision: boolean) => void

export class CloseableTracker implements IDisposable {
    public get closeable() {
        if (this.determine) {
            return this.determine()
        }
        return this._closeable
    }

    public determine?: () => boolean

    private _closeable: boolean = true

    public constructor(
        public readonly svc: ClosingGuardService,
        public readonly message: string,
        public readonly handler?: CloseableTrackerHandler) {
    }

    public yes() {
        this._closeable = true
    }

    public no() {
        this._closeable = false
    }

    public dispose() {
        const idx = this.svc.trackers.indexOf(this)
        if (idx !== -1) {
            this.svc.trackers.splice(idx, 1)
        }
    }
}


@Injectable()
export class ClosingGuardService implements OnDestroy {
    public readonly trackers: CloseableTracker[] = []

    public constructor(@Inject(DialogService) private readonly dialogSvc: DialogService) {

    }

    public confirm(): Observable<boolean> {
        let notClosableTracker: CloseableTracker

        for (const tracker of this.trackers) {
            if (!tracker.closeable) {
                notClosableTracker = tracker
                break
            }
        }

        if (notClosableTracker) {
            return this.dialogSvc.confirmClose("Bezárás megerősítése", notClosableTracker.message).pipe(
                tap(decision => {
                    if (notClosableTracker.handler) {
                        notClosableTracker.handler(decision)
                    }
                })
            )
        } else {
            return of(true)
        }
    }

    public closeableTracker(message: string, handler?: CloseableTrackerHandler) {
        const inst = new CloseableTracker(this, message, handler)
        this.trackers.push(inst)
        return inst
    }

    public ngOnDestroy() {
        let l = this.trackers.length
        while (l-- > 0) {
            this.trackers[l].dispose()
        }
    }
}
