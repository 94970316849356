import { Injectable, Inject } from "@angular/core"
import { ComponentType } from "@angular/cdk/portal"
import { Observable, zip } from "rxjs"
import { take, switchMap, map, filter } from "rxjs/operators"

import { FullscreenLayer, LayerService, MediaQueryService, ModalLayer, Rect } from "@anzar/core"
import { Event } from "@backend/event.api"
import { Duty, ProviderService } from "@backend/org.api"
import { IS_FULLSCREEN, IS_SIDE_BY_SIDE } from "./_tokens"
import { AddClientEventComponent } from "./add-client-event.component"
import { AddCommonEventComponent } from "./add-common-event.component"


@Injectable()
export class StreetCareDialogService {
    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(MediaQueryService) private readonly mq: MediaQueryService) {

    }

    public addClientEvent(duty: Duty, providerService: ProviderService) {
        return this._showDialog(AddClientEventComponent, duty, providerService)
    }

    public addNonClientEvent(duty: Duty, providerService: ProviderService) {
        return this._showDialog(AddCommonEventComponent, duty, providerService)
    }

    private _showDialog<T>(cmp: ComponentType<T>, duty: Duty, providerService: ProviderService): Observable<Event> {
        const dims = zip(
            this.mq.watch("xs").pipe(take(1)),
            this.mq.watch("lt-md").pipe(take(1)),
        )
        return dims
            .pipe(
                switchMap(([xs, ltMd]) => {
                    const behavior = xs.matches || __PLATFORM__ === "android"
                        ? new FullscreenLayer({})
                        : new ModalLayer({
                            rounded: 3,
                            elevation: 10,
                            position: {
                                constraint: {
                                    ref: "viewport",
                                    inset: 16
                                }
                            }
                        })

                    const isFS = behavior instanceof FullscreenLayer
                    const ref = this.layerSvc.createFromComponent(cmp, behavior, null, [
                        { provide: IS_FULLSCREEN, useValue: isFS },
                        { provide: IS_SIDE_BY_SIDE, useValue: !ltMd.matches },
                        { provide: Duty, useValue: duty },
                        { provide: ProviderService, useValue: providerService },
                    ])
                    ref.show()
                    return ref.output
                }),
                filter(event => event.type === "save" || event.type === "hide"),
                take(1),
                map(event => event.data)
            )
    }
}
