import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { RouterModule, Route } from "@angular/router"
import { NzModule } from "@anzar/core"

import { ComponentsModule } from "../components"
import { ClientModule } from "../client.module"
import { ClientFeddScreen } from "./client-feed.screen"

export const CLIENT_FEED_ROUTES: Route[] = [
    { path: "", component: ClientFeddScreen, pathMatch: "full" }
]


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        ClientModule,
        ComponentsModule
    ],
    declarations: [
        ClientFeddScreen
    ],
    exports: [
        ClientFeddScreen
    ]
})
export class ClientFeddScreenModule {

}
