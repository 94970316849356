import { Component, Inject, OnDestroy, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"

import { forkJoin } from "rxjs"
import { take } from "rxjs/operators"

import { Destruct, LayerRef, RectMutationService, StaticSource, ToastService } from "@anzar/core"
import { uuidv4 } from "@anzar/core/src/form.module/input/richtext/util"

import { Client } from "@backend/client.api"
import { EventBackend, EventBackendSource } from "@backend/event.api"
import { Duty, ProviderService } from "@backend/org.api"

import { ClientService } from "../../client.module"
import {
    DISPLAY_CLIENT,
    DISPLAY_SERVICES,
    DISPLAY_TEXT
} from "../../client.module/client-feed/client-feed-entry.component"
import { ProviderServices, UsableService } from "../provider.service"
import { IS_FULLSCREEN, IS_SIDE_BY_SIDE } from "./_tokens"

@Component({
    selector: "rege-add-client-event-dialog",
    templateUrl: "./add-client-event.component.pug",
    providers: [ProviderServices]
})
export class AddClientEventComponent implements OnDestroy {
    public readonly destruct = new Destruct()

    @ViewChild("editor", { static: false }) public readonly editor: any
    // @ViewChild("contentHeight", { static: false, read: SyncHeightFromDirective }) public readonly contentHeight: SyncHeightFromDirective

    public readonly clients: Client[] = []
    public readonly clientIds: number[] = []

    public services: UsableService[] = []
    public servicesSrc: StaticSource<any>

    public readonly providerIds: number[] = []
    public serviceData: any = {}
    public _providerIds: string
    public feedDisplayMode: number = DISPLAY_TEXT
    public showFeedInSidebar: boolean = false

    public readonly addServices = new FormControl()
    public readonly selectClient = new FormControl()

    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(EventBackendSource) public readonly eventSource: EventBackendSource,
        @Inject(ProviderServices) private readonly providerServices: ProviderServices,
        @Inject(EventBackend) private readonly eventBackend: EventBackend,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(Duty) public readonly duty: Duty,
        @Inject(ProviderService) public readonly providerService: ProviderService,
        @Inject(RectMutationService) public readonly mutation: RectMutationService,
        @Inject(IS_SIDE_BY_SIDE) public readonly isSideBySide: boolean,
        @Inject(IS_FULLSCREEN) public readonly isFullscreen: boolean
    ) {
        this.destruct.subscription(this.providerServices.services).subscribe(s => {
            this.services = s
            this.servicesSrc = new StaticSource(
                ProviderService as any,
                s
                    .filter(v => !v.isPrimary && v.services[0].is_active && !v.services[0].service.without_client)
                    .map(v => v.services[0]) as any
            )
        })
    }

    public addClient(client: Client) {
        if (!client) {
            return
        }
        this.selectClient.setValue(null)

        const idx = this.clients.findIndex(c => c.id === client.id)
        if (idx === -1) {
            this.clients.push(client)
        }
        ;(this.clientIds as number[]) = this.clients.map(c => c.id)

        if (this.clientIds.length > 1) {
            this.feedDisplayMode = DISPLAY_TEXT | DISPLAY_CLIENT | DISPLAY_SERVICES
        } else {
            this.feedDisplayMode = DISPLAY_TEXT | DISPLAY_SERVICES
        }
    }

    public delClient(event: Event, client: Client) {
        event.preventDefault()
        const idx = this.clients.findIndex(c => c.id === client.id)
        if (idx !== -1) {
            this.clients.splice(idx, 1)
        }
        ;(this.clientIds as number[]) = this.clients.map(c => c.id)
    }

    public showClientSheet(event: Event, client: Client) {
        if (!event.defaultPrevented) {
            this.clientSvc.showSheet(client.id).subscribe()
        }
    }

    public cancel() {
        this.layerRef.hide()
    }

    public save() {
        const serviceData = this.serviceData

        const queries = this.clientIds.map(clientId =>
            this.eventBackend
                .save_street_care({
                    client_id: clientId,
                    place_id: serviceData.place_id || null,
                    place_extra: serviceData.place_extra || null,
                    text: this.replaceRichtextComponentUid(serviceData.description),
                    duty_id: this.duty.id,
                    event_id: null,
                    provider_service_id: this.providerService.id,
                    service_ids: this.addServices.value,
                    begin: serviceData.begin_time ? serviceData.begin_time.toJSON() : null,
                    end: serviceData.end_time ? serviceData.end_time.toJSON() : null,
                    type: this.providerService.service.type.toLowerCase()
                })
                .pipe(take(1))
        )

        forkJoin(queries)
            .pipe(this.toast.handleSave({ align: "bottom center" }))
            .subscribe(res => {
                if (res) {
                    this.layerRef.emit({ type: "save", data: res })
                    this.layerRef.hide()
                }
            })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    public replaceRichtextComponentUid(text?: string): string | null {
        if (text == null || text.length === 0) {
            return null
        }

        return text.replace(/id=".{8}-.{4}-.{4}-.{4}-.{12}"/g, () => `id="${uuidv4()}"`)
    }
}
