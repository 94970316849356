import { Component, ChangeDetectionStrategy } from "@angular/core"

import { shareReplay, map } from "rxjs/operators"

import { TaskLogRecord } from "@backend/pyzar.api"
import { TaskDetailsPart } from "./task-details.component"



export interface TaskLogRecordWithIndex {
    index: number
    record: TaskLogRecord | null
}


@Component({
    selector: ".pz-task-log",
    templateUrl: "./task-log.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskLogComponent extends TaskDetailsPart {
    public readonly logs = this.task.logs.pipe(
        map(logs => logs
            .map((record, index) => ({ record, index } as TaskLogRecordWithIndex))
            .reverse()
        ),
        shareReplay(1)
    )

    public trackLogRecord(index: number, value: TaskLogRecordWithIndex) {
        return value.index
    }
}
