import { Inject, Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

import { RichtextAcProvider, RichtextAcItem, RichtextAcSession } from "@anzar/core"
import { HashtagBackend, Hashtag } from "@backend/richtext.api"

const poundSvg = require("mdi/pound.svg")


@Injectable()
export class HashtagRichtextProvider extends RichtextAcProvider {
    public readonly trigger = /^#/
    public readonly terminate: any = /\s/
    public readonly mustSelect = true
    public readonly minChars = 1

    public constructor(@Inject(HashtagBackend) protected readonly hashtagBackend: HashtagBackend) {
        super()
    }

    public query(value: string): Observable<RichtextAcItem[]> {
        value = value.substr(1)
        const q = {
            filter: { "value": { startsWith: value } },
            order: { "value": "asc" },
            begin: 0,
            count: 5
        }
        return this.hashtagBackend
            .search(q)
            .pipe(map(hashtags => hashtags.map(hashtag => {
                return new RichtextAcItem({
                    id: `hashtag:${hashtag.id}`,
                    label: hashtag.value,
                    icon: poundSvg
                })
            })))
    }

    public onSelect(sess: RichtextAcSession, item: RichtextAcItem): void {
        this.replaceWithComponent(sess, "hashtag", {
            hashtag_id: Number((item.id as String).split(":").pop()),
            content: item.label
        })
    }

    public onTerminate(sess: RichtextAcSession, text: string): boolean {
        let endSpaces = text.match(/\s+$/)
        text = text.replace(/^\s+|\s+$/, "").substr(1)
        if (text.length) {
            this.replaceWithComponent(sess, "hashtag", {
                hashtag_id: null,
                content: text
            })
            if (endSpaces && endSpaces.length) {
                sess.content.insertText(endSpaces[0])
            }
            return true
        } else {
            return false
        }
    }
}
