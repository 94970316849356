import { Component, Inject, ElementRef, Input, Output, EventEmitter } from "@angular/core"
import { FormControl, NgControl, AbstractControl } from "@angular/forms"


import { InputComponent, INPUT_MODEL, InputModel, LoadFields } from "@anzar/core"
import { ClientBackendSource, Client } from "@backend/client.api"
import { CurrentSection } from "../../section.service/level"
import { ClientService } from "../client.service"
import { ClientWithAlertsSource } from "./data-source"


const CLIENT_FIELDS: LoadFields<Client> = [
    "id", "name", "social_insurance_id", "birth_date"
]


@Component({
    selector: ".rege-client-select",
    templateUrl: "./client-select.component.pug",
    providers: [...INPUT_MODEL, ClientWithAlertsSource]
})
export class ClientSelectComponent extends InputComponent<number> {
    public readonly loadFields = CLIENT_FIELDS

    @Input() public sectionId: number
    @Input() public usageDate: Date
    @Input() public autoTrigger: boolean
    @Input() public markTypes: string[] | "all" = "all" // ["ban"]
    @Output() public selectionChange = new EventEmitter<Client>()

    public constructor(
        @Inject(InputModel) public readonly model: InputModel<number>,
        @Inject(ElementRef) public readonly el: ElementRef,
        @Inject(ClientWithAlertsSource) public readonly clientSrc: ClientWithAlertsSource,
        @Inject(CurrentSection) protected readonly section: CurrentSection,
        @Inject(ClientService) private readonly clientSvc: ClientService) {
        super(model)

        this.monitorFocus(el.nativeElement)

        // this.destruct.subscription(control.valueChanges).subscribe(v => {
        //     this.selectControl.setValue(v)
        // })
    }

    protected _renderValue(val: number) {
        // this.selectControl.setValue(val)
    }

    public onDetailedSearch() {
        this.clientSvc.createClient(true).subscribe(client => {
            if (client) {
                this.model.value = client.id
                this.model.renderValueChanges.next(client.id)
                this.model.emitValue(client.id)
            }
        })
    }
}
