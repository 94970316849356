import { Component, ContentChild, TemplateRef, Input, Optional, Inject } from "@angular/core"

import { DragService, LayerRef, FullscreenLayer } from "@anzar/core"


@Component({
    selector: ".rege-panel",
    templateUrl: "./panel.component.pug",
    providers: [DragService]
})
export class PanelComponent {
    @ContentChild("regePanelContent", { static: true, read: TemplateRef }) public readonly content: TemplateRef<any>
    // @ContentChild("regePanelLeftSide", { static: true, read: TemplateRef }) public readonly leftSide: TemplateRef<any>
    // @ContentChild("regePanelRightSide", { static: false, read: TemplateRef }) public readonly rightSide: TemplateRef<any>
    @ContentChild("regePanelButtons", { static: true, read: TemplateRef }) public readonly buttons: TemplateRef<any>
    @ContentChild("regePanelHeader", { static: true, read: TemplateRef }) public readonly header: TemplateRef<any>

    @Input() public panelTitle: string

    public readonly isFullscreen: boolean = false

    public constructor(
        @Inject(LayerRef) @Optional() private readonly layerRef: LayerRef,
        @Inject(DragService) private readonly dragSvc: DragService) {

        if (layerRef) {
            this.isFullscreen = layerRef.behavior instanceof FullscreenLayer
            dragSvc.draggable = this.isFullscreen ? null : layerRef.container

            layerRef.destruct.subscription(dragSvc.dragging).subscribe(event => {
                if (event.type === "begin") {
                    layerRef.behavior.levitate.suspend()
                }
            })
        } else {
            dragSvc.draggable = null
        }
    }

    public close() {
        this.layerRef?.close()
    }
}
