import { Component, Inject, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Subject } from "rxjs"
import { switchMap, shareReplay, map, debounceTime, filter } from "rxjs/operators"

import { Destructible, LayerRef, ToastService } from "@anzar/core"
import { RoomBackend } from "@backend/service_room.api"
import { AuthService } from "@pyzar/auth.module"


class Entry {
    public constructor(
        public readonly providerId: number,
        public readonly providerServiceId: number,
        public readonly title: string,
        public readonly capacity: number,
        public readonly usage: FormControl,
        public readonly editable: boolean
    ) { }
}


@Component({
    selector: "rege-capacity-usage-editor",
    templateUrl: "./usage-editor.component.pug"
})
export class CapacityUsageEditorComponent extends Destructible implements OnInit {
    public set day(val: Date) {
        if (this._day !== val) {
            this._day = val
            this._dayChanges.next(val)
        }
    }
    public get day(): Date { return this._day }
    private _day: Date

    private _dayChanges = new Subject<Date>()

    public readonly entries$ = this._dayChanges.pipe(
        switchMap(day => {
            return this.roomBackend.get_capacity_usages({ day })
        }),
        shareReplay(1)
    )

    public readonly entries: Entry[] = []

    public readonly hasAccessToEdit = this.auth.hasPermission({
        permission: "org.set_capacity_usage",
        extra: { level_id: "any" }
    })
    public statusText: string

    private disableChangeDetection: boolean = false

    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(RoomBackend) private readonly roomBackend: RoomBackend,
        @Inject(AuthService) private readonly auth: AuthService) {
        super()

        this.destruct.subscription(this.entries$).subscribe(entries => {
            if (this.entries.length === 0) {
                for (const entry of entries) {
                    const providerServiceId = entry[1].id
                    const value = new Entry(
                        entry[0].id,
                        providerServiceId,
                        entry[0].extended_title,
                        entry[0].capacity || 0,
                        new FormControl(entry[2]),
                        entry[1].is_manual_usage)
                    this.entries.push(value)
                    this.destruct.subscription(value.usage.valueChanges)
                        .pipe(
                            filter(v => !this.disableChangeDetection),
                            debounceTime(200)
                        )
                        .subscribe(newUsage => {
                            this._onValueChanged(entry[0].extended_title, providerServiceId, this._day, newUsage)
                        })
                }
            } else {
                this.disableChangeDetection = true
                for (const entry of entries) {
                    const existing = this.entries.find(v => v.providerId === entry[0].id)
                    if (existing) {
                        existing.usage.setValue(entry[2])
                    }
                }
                this.disableChangeDetection = false
            }

            this.entries.sort((a, b) => a.title.localeCompare(b.title))
        })
    }

    public ngOnInit() {
        this.day = new Date()
    }

    public cancel() {
        this.layerRef.hide()
    }

    protected _onValueChanged(providerTitle: string, providerServiceId: number, day: Date, value: number) {
        this.roomBackend
            .set_capacity_usage({
                provider_service_id: providerServiceId,
                day: day,
                value: value
            })
            // TODO: Más megoldás kell
            // .pipe(this.toastSvc.handleSave({ align: "bottom center", successMsg: `${providerTitle} kihasználtság beállítva ${value}-ra` }))
            .subscribe(_ => {
                this.statusText = `${providerTitle} kihasználtság beállítva ${value}-ra`
            })
    }
}
