import { Component, Input, Inject, Optional } from "@angular/core"
import { Subject, merge } from "rxjs"
import { map, shareReplay } from "rxjs/operators"

import { LoadFields, DataSourceDirective, StackItemRef, Destruct, MediaQueryService } from "@anzar/core"
import { MailingBackend, MailingBackendSource, Mailing } from "@backend/service_mailing.api"
import { Client } from "@backend/client.api"
import { ClientService } from "../../../client.module"
import { UsableService } from "../../provider.service"
import { MailingService } from "./mailing.service"


const MAILING_FIELDS: LoadFields<Mailing> = [
    "id", "note", "arrived_date", "deadline_date", "given_time", "deleted_time",
    { type_value: ["id", "label"] },
    { client: ["id", "name", "social_insurance_id", "birth_date"] }
]


@Component({
    selector: ".rege-mailing-service-editor",
    templateUrl: "./mailing.component.pug",
    providers: [
        DataSourceDirective
    ]
})
export class MailingEditorComponent {
    public readonly destruct = new Destruct()

    @Input()
    public set service(val: UsableService) {
        if (this._service !== val) {
            this._service = val
            this._service$.next(val)
        }
    }
    public get service(): UsableService { return this._service }
    private _service: UsableService
    private _service$ = new Subject<UsableService>()

    public providerServiceIds$ = this.destruct.subscription(this._service$).pipe(
        map(service => {
            if (service) {
                return service.services
                    .map(s => s.id)
                    .filter((v, i, a) => a.indexOf(v) === i)
            } else {
                return []
            }
        }),
        shareReplay(1)
    )

    // public readonly mails$ = merge(this.providerServiceIds$, this.mailingSvc.reload$).pipe(
    //     debounceTime(10),
    //     switchMap(v => this.providerServiceIds$),
    //     switchMap(ids => {
    //         this.busy = true
    //         return this.mailingBackend.search({
    //             filter: { provider_service_id: { "in": ids }, deleted_time: null, given_time: null },
    //             order: { created_time: "desc" },
    //             begin: 0,
    //             count: 500
    //         }, { loadFields: MAILING_FIELDS })
    //     }),
    //     tap(() => this.busy = false),
    //     shareReplay(1)
    // )

    // public busy = true
    // public mails: Mailing[] = []

    public filterReady: boolean = false

    public readonly isSm$ = this.mq.watch("lt-md").pipe(map(v => v.matches), shareReplay(1))

    public constructor(
        @Inject(MailingBackend) private readonly mailingBackend: MailingBackend,
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(MailingService) private readonly mailingSvc: MailingService,
        @Inject(MailingBackendSource) public readonly mailingSrc: MailingBackendSource,
        @Inject(DataSourceDirective) public readonly source: DataSourceDirective,
        @Inject(MediaQueryService) private readonly mq: MediaQueryService,
        @Inject(StackItemRef) @Optional() stackItem?: StackItemRef) {
        source.dataSource = mailingSrc as any
        source.sort = { arrived_date: 'desc' } as any
        source.loadFields(MAILING_FIELDS as any)

        this.destruct.subscription(this.providerServiceIds$).subscribe(providerServiceIds => {
            source.baseFilter = { provider_service_id: { 'in': providerServiceIds }, deleted_time: null } as any
            this.filterReady = true
        })

        let reloadSrc = stackItem
            ? merge(stackItem.activate, this.mailingSvc.reload$)
            : this.mailingSvc.reload$

        this.destruct.subscription(reloadSrc).subscribe(_ => {
            source.reload()
        })
    }

    public showClientSheet(event: Event, client: Client) {
        this.clientSvc.showSheet(client.id).subscribe()
        event.preventDefault()
    }

    public deleteMail(entry: Mailing) {
        this.mailingBackend.remove({ id: entry.id }).subscribe(v => {
            this.mailingSvc.reload$.next()
        })
    }

    public give(entry: Mailing) {
        this.mailingBackend.give({ mailing_id: entry.id }).subscribe(v => {
            this.mailingSvc.reload$.next()
        })
    }

    public edit(entry: Mailing) {
        this.mailingSvc.editMailEntry(entry.id)
    }
}
