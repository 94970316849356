import { NgModule } from "@angular/core"
import { Executed, PermissionCategory, Permission, PermissionGroup, PermissionGroupEntry, UserGroup, FullName, User, UserTokenType, UserToken, NodeType, Node, Directory, File, TaskProgress, TaskLogRecord, TaskResult, TaskInfo, TaskManager, PermissionGroupRepo, UserBackend, Auth, DirectoryBackend, FileBackend, DirectoryBackendSource, FileBackendSource, PermissionGroupRepoSource, UserBackendSource } from "./__anzar_rpc_output"

export { Executed, PermissionCategory, Permission, PermissionGroup, PermissionGroupEntry, UserGroup, FullName, User, UserTokenType, UserToken, NodeType, Node, Directory, File, TaskProgress, TaskLogRecord, TaskResult, TaskInfo, TaskManager, PermissionGroupRepo, UserBackend, Auth, DirectoryBackend, FileBackend, DirectoryBackendSource, FileBackendSource, PermissionGroupRepoSource, UserBackendSource }


@NgModule({
    providers: [
        DirectoryBackendSource,
        FileBackendSource,
        PermissionGroupRepoSource,
        UserBackendSource,
        Auth,
        DirectoryBackend,
        FileBackend,
        PermissionGroupRepo,
        TaskManager,
        UserBackend
    ],
})
export class PyzarApi {}