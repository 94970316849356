import { HttpClientModule } from "@angular/common/http"
import { ModuleWithProviders, NgModule } from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"

import { NzModule } from "@anzar/core"

import { CloseableTracker, ClosingGuardService } from "./closing-guard.service"
import { LocalStorageDirective } from "./ds-local-storage.directive"
import { FormatNamePipe } from "./format-name.pipe"
import { SentryRpcBreadcrumb, SentryService } from "./sentry.service"
import { validateEqual, ValidateEqualDirective } from "./validate-equal.directive"

export { SentryService, SentryRpcBreadcrumb }

export { ClosingGuardService, CloseableTracker }

export { validateEqual }

@NgModule({
    imports: [BrowserModule, HttpClientModule, NzModule],
    declarations: [ValidateEqualDirective, LocalStorageDirective, FormatNamePipe],
    exports: [ValidateEqualDirective, LocalStorageDirective, FormatNamePipe]
})
export class MaltaCommonModule {
    public static forRoot(): ModuleWithProviders<MaltaCommonModule> {
        return {
            ngModule: MaltaCommonModule,
            providers: [
                SentryService
                // { provide: ErrorHandler, useClass: SentryErrorHandler }
            ]
        }
    }
}
