import { Inject, Injectable } from "@angular/core"
import { Observable, of, zip } from "rxjs"
import { map, take } from "rxjs/operators"

import { RichtextAcProvider, RichtextAcItem, RichtextAcSession } from "@anzar/core"
import { PublicServiceType } from "@backend/dispatcher.api"
import { CurrentSection } from "../../section.service/level"
import { RichtextDispAcItem } from "./abstract"


const transportIcon = require("mdi/car-side.svg")
const publicServiceIcon = require("mdi/alarm-light.svg")
const housingIcon = require("mdi/home.svg")
const serviceIcon = require("mdi/blur-radial.svg")
const medicalIcon = require("mdi/hospital-box-outline.svg")


const COMMANDS: RichtextDispAcItem[] = [
    new RichtextDispAcItem({
        id: "disp:medical-exam",
        label: "Orvosi vizsgálat",
        icon: medicalIcon,
        componentId: "medical-exam"
    }),
    new RichtextDispAcItem({
        id: "disp:housing",
        label: "Elhelyezés",
        icon: housingIcon,
        componentId: "housing"
    }),
    new RichtextDispAcItem({
        id: "disp:transport",
        label: "Szállítás",
        icon: transportIcon,
        componentId: "transport"
    })
]


for (const pst of PublicServiceType.DATA.data) {
    COMMANDS.push(new RichtextDispAcItem({
        id: `disp:public-service:${pst.id}`,
        label: pst.label,
        icon: publicServiceIcon,
        componentId: "public-service"
    }))
}


@Injectable()
export class CommandsRichtextProvider extends RichtextAcProvider {
    public readonly trigger = /^\//
    public readonly terminate: any = null
    public readonly mustSelect = true
    public readonly minChars = 0

    public constructor(@Inject(CurrentSection) private readonly currentSvc: CurrentSection) {
        super()
    }

    public query(value: string): Observable<RichtextAcItem[]> {
        value = value.substr(1).toLowerCase()

        const qSvc = this.currentSvc.services.pipe(
            map(services => {
                return services
                    .filter(svc => svc.service.title.toLowerCase().startsWith(value))
                    .map(svc => {
                        return new RichtextDispAcItem({
                            id: `provider-service:${svc.id}`,
                            label: svc.service.title,
                            icon: serviceIcon,
                            componentId: "provider-service"
                        })
                    })
            }),
            take(1)
        )

        const qCommands = of(COMMANDS).pipe(
            map(res => res.filter(item => item.label.toLowerCase().startsWith(value))),
            take(1)
        )

        return zip(qSvc, qCommands).pipe(map(vals => {
            let result: RichtextAcItem[] = []
            for (const v of vals) {
                result = result.concat(v)
            }
            return result
        }))
    }

    public onSelect(sess: RichtextAcSession, item: RichtextDispAcItem): void {
        let params = { content: item.label } as any
        const idParts = item.id.split(/:/g)
        const subtype = idParts[2]
        if (subtype) {
            params.subtype = subtype
        }

        if (idParts[0] === "provider-service") {
            params.provider_service_id = Number(idParts[1])
        }

        this.replaceWithComponent(sess, item.componentId, params)
    }

    public onTerminate(sess: RichtextAcSession, text: string): boolean {
        return false
    }
}
