import { Component, Input, Output, Inject, Optional, OnInit } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { Observable } from "rxjs"
import { catchError, take, switchMap } from "rxjs/operators"

import { ToastService } from "@anzar/core"
import { PlaceBackendSource } from "@backend/place.api"
import { DutyBackend, ProviderService } from "@backend/org.api"
import { EventBackend } from "@backend/event.api"
import { Duty } from "@backend/org.api"
import { ServiceUsage, DutyService } from "../../../duty.module"
import { AbstractEditor } from "../abstract"


export type CommunalCareData = { [key: string]: any }


@Component({
    selector: ".rege-communal-service-editor",
    exportAs: "regeCommunalEditor",
    templateUrl: "./communal.component.pug"
})
export class CommunalEditorComponent extends AbstractEditor implements OnInit {
    @Input()
    public set usage(val: ServiceUsage) {
        if (this._usage !== val) {
            this._usage = val
            this.fillFromUsage()
        }
    }
    public get usage(): ServiceUsage { return this._usage }
    private _usage: ServiceUsage

    @Input()
    public providerService: ProviderService

    public readonly form = new FormGroup({
        place_id: new FormControl(),
        place_extra: new FormControl(),
        begin_time: new FormControl(new Date()),
        end_time: new FormControl(),
        description: new FormControl(),
    })

    @Input()
    public set data(val: CommunalCareData) { this.form.setValue(val) }
    public get data(): CommunalCareData { return this.form.value }

    @Input() public duty: Duty

    @Output("data")
    public onData: Observable<CommunalCareData> = this.form.valueChanges

    public constructor(
        @Inject(PlaceBackendSource) protected readonly placeSource: PlaceBackendSource,
        @Inject(EventBackend) private readonly eventBackend: EventBackend,
        @Inject(DutyService) @Optional() private readonly dutyService: DutyService,
        @Inject(ToastService) private readonly toastService: ToastService,
        @Inject(DutyBackend) private readonly dutyBackend: DutyBackend) {
        super()
    }

    public ngOnInit() {
        if (!this.duty) {
            const dutyId = this.usage.events[0].duty_id
            const duty = this.dutyService
                ? this.dutyService.getDuty(dutyId)
                : this.dutyBackend.get({ id: dutyId })

            this.destruct.subscription(duty)
                .pipe(take(1))
                .subscribe(duty => {
                    this.duty = duty
                })
        }
    }

    public save() {
        const data = this.form.value

        this.eventBackend
            .save({
                data: {
                    id: this.usage.events[0].id,
                    place_id: data.place_id,
                    place_extra: data.place_extra,
                    begin: data.begin_time,
                    end: data.end_time,
                    content: data.description
                }
            })
            .pipe(
                this.toastService.handleSave({ align: "bottom center", beginMsg: "Mentés...", successMsg: "Sikeresen módosítottad a bejegyzést" }),
                switchMap(res => {
                    return this.dutyService.setUsage(this.usage, this.providerService, true, true).pipe(take(1))
                })
            )
            .subscribe(v => {
                this.dutyService && this.dutyService.realoadEvent(v.eventId)
            })
    }

    public cancel() {
        this.fillFromUsage()
    }

    private fillFromUsage() {
        if (this.usage && this.usage.events.length === 1) {
            const event = this.usage.events[0]
            this.form.reset({
                place_id: event.place,
                place_extra: event.place_extra,
                begin_time: event.begin,
                end_time: event.end,
                description: event.richtext ? event.richtext.text : null,
            })
        } else {
            throw new Error("Invalid number of events in usage")
        }
    }
}
