import { Inject, Injectable } from "@angular/core"
import { Observable, Subject, of, merge, zip, } from "rxjs"
import { startWith, shareReplay, map, switchMap, take, tap, distinctUntilChanged, } from "rxjs/operators"


import { Destructible, LoadFields } from "@anzar/core"
import { Section, ProviderBackend, Provider, LevelBackend, SectionBackend, ProviderService } from "@backend/org.api"
import { SectionService } from "./section.service"


export abstract class CurrentLevel extends Destructible {
    public readonly id$ = this.destruct.subscription(of(null)).pipe(
        switchMap(this._idWatcher.bind(this)),
        distinctUntilChanged(),
        tap(id => {
            (this as { id: number }).id = id
            // console.log(`new ${this.constructor.name} id`, id)
        }),
        shareReplay(1)
    )

    public readonly id: number

    protected abstract _idWatcher(): Observable<number>
}


export const PROVIDER_SERVICE_FIELDS: LoadFields<ProviderService> = [
    "id", "provider_id", "service_id", "is_primary", "is_active", "auto_tick", "is_pinned", "is_detailed", "is_manual_usage",
    { service: ["id", "title", "type", "without_client"] }
]


export const PROVIDER_FIELDS: LoadFields<Provider> = [
    "id", "parent_id", "title", "type", "kenyszi_type", "kenyszi_subtype", "nrszh_id", "report_enabled", "report_options", "position",
    { services: PROVIDER_SERVICE_FIELDS }
]

export const MANUAL_DUTY_SERVICE_TYPES = ["street_care", "communal"]


export type UiType = "service" | "dispatcher"

@Injectable({ providedIn: "root" })
export class CurrentSection extends CurrentLevel {
    private roleCache: { [key: string]: Observable<string> } = {}

    private readonly _onlyProviders = new Subject<number[]>()
    private readonly onlyProviders = this._onlyProviders.pipe(
        startWith([]),
        distinctUntilChanged((a, b) => {
            return JSON.stringify(a.sort()) === JSON.stringify(b.sort())
        }),
        shareReplay(1))

    private readonly _providers: Observable<Provider[]> = this.destruct.subscription(this.id$).pipe(
        switchMap(sectionId => {
            if (sectionId) {
                return this.providerBackend.search({
                    filter: { parent_id: sectionId },
                    order: { position: "asc" },
                    begin: 0,
                    count: 10
                }, { loadFields: PROVIDER_FIELDS })
            } else {
                return of([])
            }
        }),
        shareReplay(1)
    )

    private _providersMap: { [key: number]: Provider } = {}
    public readonly providers: Observable<Provider[]> = merge(this.onlyProviders, this._providers).pipe(
        switchMap(_ => zip(this.onlyProviders, this._providers).pipe(take(1))),
        map(v => {
            const [only, providers] = v
            const res = providers.filter(p => !only || !only.length || only.indexOf(p.id) !== -1)
            this._providersMap = []
            for (const p of res) {
                this._providersMap[p.id] = p
            }
            return res
        }),
        shareReplay(1)
    )

    public readonly services: Observable<ProviderService[]> = this.destruct.subscription(this.providers).pipe(
        distinctUntilChanged((a, b) => {
            return JSON.stringify(a.map(v => v.id).sort()) === JSON.stringify(b.map(v => v.id).sort())
        }),
        map(providers => {
            let services = []

            for (const provider of providers) {
                for (const s of provider.services) {
                    if (s.is_active) {
                        services.push(s)
                    }
                }
            }

            return services
        }),
        shareReplay(1)
    )

    public readonly primaryMenu = this.destruct.subscription(this.services).pipe(
        map(services => {
            const group: { [key: string]: { type: UiType, providers: Provider[], title?: string, providerIds: string, url: string } } = {}
            let kk = 0

            for (const svc of services) {
                const provider = this._providersMap[svc.provider_id]
                if (svc.is_primary && provider) {
                    const type = this.getProviderUiType(provider)
                    const key = MANUAL_DUTY_SERVICE_TYPES.indexOf(svc.service.type) !== -1
                        ? `${type}-manual-${svc.provider_id}`
                        : `${type}-auto`

                    if (!group[key]) {
                        group[key] = { type: type, providers: [provider], providerIds: null, url: "/" }
                        kk++
                    } else {
                        group[key].providers.push(provider)
                    }
                }
            }

            for (const k in group) {
                if (kk > 1) {
                    group[k].title = group[k].providers.map(p => p.title).join(", ")
                }
                group[k].providerIds = group[k].providers.map(p => p.id).join(",")

                switch (group[k].type) {
                    case "dispatcher":
                        group[k].url = `/diszpecser/tortenetek`
                        break

                    case "service":
                        group[k].url = `/szolgaltatas/${group[k].providerIds}/lista`
                        break
                }
            }

            return Object.keys(group).map(k => group[k])
        }),
        shareReplay(1)
        // share()
    )

    public readonly section = this.id$.pipe(
        switchMap(id => {
            return id ? this.sectionBackend.get({ id }) : of(null)
        }),
        shareReplay(1)
    )

    public readonly manualDuty = this.destruct.subscription(this.services).pipe(
        map(services => {
            for (const svc of services) {
                if (svc.is_primary && MANUAL_DUTY_SERVICE_TYPES.indexOf(svc.service.type) !== -1) {
                    return true
                }
            }
            return false
        }),
        shareReplay(1)
    )

    public readonly uiType = this.destruct.subscription(this.providers).pipe(
        map(providers => {
            const types = providers
                .map(p => p.type)
                .filter((v, i, a) => a.indexOf(v) === i)

            if (types.indexOf("KENYSZI") !== -1) {
                return "service" as UiType
            } else if (types.indexOf("STREET_CARE") !== -1) {
                return "service" as UiType
            } else if (types.indexOf("DISPATCHER") !== -1) {
                return "dispatcher" as UiType
            }
        }),
        shareReplay(1)
    )

    public constructor(
        @Inject(SectionService) private readonly sectionSvc: SectionService,
        @Inject(ProviderBackend) private readonly providerBackend: ProviderBackend,
        @Inject(SectionBackend) private readonly sectionBackend: SectionBackend) {
        super()
    }

    protected _idWatcher(): Observable<number> {
        return this.sectionSvc.selected.pipe(map(section => section ? section.id : null))
    }

    protected getId(section: Section): number {
        return section ? section.id : null
    }

    // public userRole(userId: number): Observable<string> {
    //     if (this.roleCache[userId]) {
    //         return this.roleCache[userId]
    //     } else {
    //         return this.roleCache[userId] = this.id$.pipe(
    //             takeUntil(this.destruct.on),
    //             switchMap(sectionId => {
    //                 if (sectionId) {
    //                     return this.workerBackend.search({
    //                         filter: { section_id: sectionId, user_id: userId },
    //                         order: {},
    //                         begin: 0,
    //                         count: 1
    //                     })
    //                 } else {
    //                     return of([])
    //                 }
    //             }),
    //             map(worker => {
    //                 return worker.length === 1 ? worker[0].role : null
    //             }),
    //             shareReplay(1)
    //         )
    //     }
    // }

    // public userIsBoss(userId: number): Observable<boolean> {
    //     return this.userRole(userId).pipe(map(v => v === "boss"))
    // }

    public filterProviders(providerIds: number[]) {
        this._onlyProviders.next(providerIds)
    }

    private getProviderUiType(provider: Provider): UiType {
        switch (provider.type) {
            case "DISPATCHER":
                return "dispatcher"

            default:
                return "service"
        }
    }
}


@Injectable({ providedIn: "root" })
export class CurrentHeadquerter extends CurrentLevel {

    public readonly headquarter = this.id$.pipe(
        switchMap(id => {
            return id ? this.levelBackend.get({ id }) : of(null)
        }),
        shareReplay(1)
    )

    public constructor(
        @Inject(CurrentSection) private readonly currentSection: CurrentSection,
        @Inject(LevelBackend) private readonly levelBackend: LevelBackend) {
        super()
    }

    protected _idWatcher(): Observable<number> {
        return this.currentSection.section.pipe(map(section => section ? section.parent_id : null))
    }
}
