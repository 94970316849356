import { NgModule } from "@angular/core"
import { LevelVariant, Level, Global, LevelWorker, Maintainer, Region, Institution, Headquarter, ReportOptions, Provider, ProviderDuty, ProviderVehicle, ProviderService, ProviderReport, RC_ProviderService, SectionUiType, Section, SectionRestrictionType, SectionRestriction, ClientRequirements, Duty, DutyWorker, DutyVehicle, DutyPlace, LevelBackend, LevelWorkerBackend, MaintainerBackend, RegionBackend, InstitutionBackend, HeadquarterBackend, ProviderBackend, ProviderServiceBackend, ProviderDutyBackend, ProviderVehicleBackend, SectionBackend, SectionRestrictionBackend, ClientRequirementsBackend, DutyBackend, DutyBackendSource, HeadquarterBackendSource, InstitutionBackendSource, LevelBackendSource, LevelWorkerBackendSource, MaintainerBackendSource, ProviderBackendSource, ProviderDutyBackendSource, ProviderServiceBackendSource, ProviderVehicleBackendSource, RegionBackendSource, SectionBackendSource } from "./__anzar_rpc_output"

export { LevelVariant, Level, Global, LevelWorker, Maintainer, Region, Institution, Headquarter, ReportOptions, Provider, ProviderDuty, ProviderVehicle, ProviderService, ProviderReport, RC_ProviderService, SectionUiType, Section, SectionRestrictionType, SectionRestriction, ClientRequirements, Duty, DutyWorker, DutyVehicle, DutyPlace, LevelBackend, LevelWorkerBackend, MaintainerBackend, RegionBackend, InstitutionBackend, HeadquarterBackend, ProviderBackend, ProviderServiceBackend, ProviderDutyBackend, ProviderVehicleBackend, SectionBackend, SectionRestrictionBackend, ClientRequirementsBackend, DutyBackend, DutyBackendSource, HeadquarterBackendSource, InstitutionBackendSource, LevelBackendSource, LevelWorkerBackendSource, MaintainerBackendSource, ProviderBackendSource, ProviderDutyBackendSource, ProviderServiceBackendSource, ProviderVehicleBackendSource, RegionBackendSource, SectionBackendSource }


@NgModule({
    providers: [
        DutyBackendSource,
        HeadquarterBackendSource,
        InstitutionBackendSource,
        LevelBackendSource,
        LevelWorkerBackendSource,
        MaintainerBackendSource,
        ProviderBackendSource,
        ProviderDutyBackendSource,
        ProviderServiceBackendSource,
        ProviderVehicleBackendSource,
        RegionBackendSource,
        SectionBackendSource,
        ClientRequirementsBackend,
        DutyBackend,
        HeadquarterBackend,
        InstitutionBackend,
        LevelBackend,
        LevelWorkerBackend,
        MaintainerBackend,
        ProviderBackend,
        ProviderDutyBackend,
        ProviderServiceBackend,
        ProviderVehicleBackend,
        RegionBackend,
        SectionBackend,
        SectionRestrictionBackend
    ],
})
export class OrgApi {}