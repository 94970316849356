import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Route } from "@angular/router"
import { NzModule } from "@anzar/core"


import { PyzarApi } from "@backend/pyzar.api"
import { OrgApi } from "@backend/org.api"
import { WorkerApi } from "@backend/worker.api"
import { FsModule } from "@pyzar/fs.module"
import { UserBlockFactory } from "@pyzar/auth.module"

import { ComponentsModule } from "../components"
import { ImageModule } from "../image.module"
import { StatsModule } from "../stats.module"

import { WorkersGridComponent } from "./workers-grid.component"
import { WorkersScreen } from "./workers.screen"
import { WorkerCardComponent, WorkerCardService } from "./worker-card.component"

export { WorkersScreen, WorkersGridComponent, WorkerCardService }

import { WorkerLevelsRoComponent, WorkerLevelsRoFactory } from "./sheet/levels-ro.component"
import { WorkerLevelsComponent, WorkerLevelsFactory } from "./sheet/levels.component"

import { WhoCanDoItComponent } from "./who-can-do-it.component"
import { WorkerService } from "./worker.service"
export { WorkerService }


// import { WorkerScreen } from "./sheet/worker.screen"
// import { WorkerEdtorComponent } from "./sheet/worker-editor.component"
// import { WorkerBasicDataComponent } from "./sheet/basic-data.component"
// import { WorkerLoginDataComponent } from "./sheet/login-data.component"
// import { WorkerGrantsComponent } from "./sheet/grants.component"
// import { WorkerLevelsComponent } from "./sheet/levels.component"
// import { WorkerLevelsRoComponent } from "./sheet/levels-ro.component"
// import { WorkerProfileImageSettingComponent } from "./sheet/profile-image.component"


export const WORKER_ROUTES: Route[] = [
    { path: "lista", component: WorkersScreen },
    { path: "osszes", component: WorkersScreen, data: { all: true } },
]


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        PyzarApi,
        OrgApi,
        ComponentsModule,
        FsModule,
        ImageModule,
        WorkerApi,
        StatsModule
    ],
    declarations: [
        WorkersGridComponent,
        WorkersScreen,
        WorkerCardComponent,

        WorkerLevelsRoComponent,
        WorkerLevelsComponent,

        WhoCanDoItComponent,
    ],
    exports: [
        WorkersGridComponent,
        WorkersScreen,
        WorkerCardComponent,

        WorkerLevelsRoComponent,
        WorkerLevelsComponent,

        WhoCanDoItComponent,
    ],
    entryComponents: [WorkerCardComponent, WorkerLevelsRoComponent, WorkerLevelsComponent, WhoCanDoItComponent],
    providers: [
        WorkerCardService,
        WorkerService,
        {
            provide: UserBlockFactory,
            multi: true,
            useClass: WorkerLevelsRoFactory
        },
        {
            provide: UserBlockFactory,
            multi: true,
            useClass: WorkerLevelsFactory
        }
    ]
})
export class WorkerModule {

}
