import { Injectable, Inject } from "@angular/core"

import { LayerService, ModalLayer } from "@anzar/core"

import { CapacityUsageEditorComponent } from "./usage-editor.component"


@Injectable()
export class CapacityUsageEditorService {
    public constructor(@Inject(LayerService) private readonly layerSvc: LayerService) {

    }

    public show() {
        const behavior = new ModalLayer({
            backdrop: { type: "filled", hideOnClick: true },
            closeable: true,
            rounded: 3,
            elevation: 10,
            position: {
                constraint: {
                    ref: "viewport",
                    inset: 24
                }
            }
        })

        const layer = this.layerSvc.createFromComponent(CapacityUsageEditorComponent, behavior)
        layer.show()
    }
}
