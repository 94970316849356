import { Component, Inject, OnDestroy, ChangeDetectorRef, QueryList, ViewChildren, ViewChild, AfterViewInit } from "@angular/core"
import { Subscription, merge, zip, of, BehaviorSubject } from "rxjs"
import { startWith, debounceTime, map, shareReplay, switchMap, take } from "rxjs/operators"

import { Destruct, TabsComponent } from "@anzar/core"
import { Provider, Section } from "@backend/org.api"
import { SectionSheetService } from "./section-sheet.service"
import { AbstractCLEditor } from "@pyzar/common.module"


@Component({
    selector: ".rege-section-sheet",
    templateUrl: "./section-sheet.component.pug"
})
export class SectionSheetComponent implements OnDestroy, AfterViewInit {
    public readonly destruct = new Destruct()

    @ViewChildren(AbstractCLEditor) public readonly editors: QueryList<AbstractCLEditor>
    @ViewChild("tabs", { static: true }) public readonly tabs: TabsComponent

    public section: Section
    public providers: Provider[] = []

    private readonly editors$ = new BehaviorSubject<AbstractCLEditor[]>([])

    public isDirty$ = this._mergedEditors("isDirty$").pipe(
        map(values => values.includes(true)),
        shareReplay(1)
    )

    public isValid$ = this._mergedEditors("isValid$").pipe(
        map(values => !values.includes(false)),
        shareReplay(1)
    )

    public isBusy$ = new BehaviorSubject<boolean>(false)

    public readonly editorDirty: { [key: number]: boolean } = {}
    public readonly editorValid: { [key: number]: boolean } = {}

    public constructor(
        @Inject(SectionSheetService) private readonly sheet: SectionSheetService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {

        this.destruct.subscription(sheet.section$).subscribe(section => {
            this.section = section
            this.cdr.markForCheck()
        })

        this.destruct.subscription(sheet.providers$).subscribe(providers => {
            this.providers = providers.sort((a, b) => a.position - b.position)
            this.cdr.detectChanges()
        })
    }

    public ngAfterViewInit() {
        this.destruct.subscription(this.editors.changes).subscribe(() => {
            this.editors$.next(this.editors.toArray())
        })
    }

    public saveCurrentEditor() {
        this.isBusy$.next(true)
        this.editors$
            .pipe(
                map(editors => editors.filter(editor => editor.index === this.tabs.selectedIndex)[0]),
                switchMap(editor => {
                    if (editor) {
                        return editor.save()
                    } else {
                        return of(null)
                    }
                }),
                take(1)
            )
            .subscribe(() => {
                this.isBusy$.next(false)
            })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    public deleteProvider(provider: Provider) {
        console.log("deleteProvider", provider)
    }

    public onTabChanges(index: number) {
        this.cdr.markForCheck()
    }

    public providerTrackBy(index: number, provider: Provider) {
        return provider.pk
    }

    private _mergedEditors(property: "isDirty$" | "isValid$") {
        return this.editors$.pipe(
            switchMap(editors => {
                if (editors.length !== 0) {
                    const src = editors.map(e => e[property])
                    return merge(...src).pipe(
                        debounceTime(50),
                        switchMap(() => {
                            const zsrc = src.map(v => v.pipe(take(1)))
                            return zip(...zsrc)
                        })
                    )
                } else {
                    return of([])
                }
            })
        )
    }
}
