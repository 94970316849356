import { Component, forwardRef, Inject, Input } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { Router } from "@angular/router"
import { tap } from "rxjs/operators"


import { LayerService, ModalLayer } from "@anzar/core"
import { Provider, ProviderBackend } from "@backend/org.api"
import { ProviderType, KenysziType, KenysziSubtype } from "@backend/enums.api"

import { AuthService } from "@pyzar/auth.module"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { ProviderEditorService } from "./provider-editor.service"
import { SectionSheetService } from "../section/section-sheet.service"
import { ProviderRemoveComponent, PROVIDER_ID } from "./provider-remove.component"


@Component({
    selector: ".rege-provider-basic-data",
    templateUrl: "./provider-basic-data.component.pug",
    host: {
        "[style.padding.px]": "16"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => ProviderBasicDataComponent) }
    ]
})
export class ProviderBasicDataComponent extends AbstractCLEditorBlock<ProviderEditorService> {
    @Input() public provider: Provider

    public readonly typeSrc = ProviderType.DATA
    public readonly kenysziTypeSrc = KenysziType.DATA
    public readonly kenysziSubtypeSrc = KenysziSubtype.DATA

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: ProviderEditorService,
        @Inject(ProviderBackend) private readonly providerBackend: ProviderBackend,
        @Inject(SectionSheetService) private readonly sheet: SectionSheetService,
        @Inject(AuthService) public readonly auth: AuthService,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(Router) private readonly router: Router) {
        super(editorSvc, new FormGroup({
            title: new FormControl(),
            type: new FormControl(),
            kenyszi_type: new FormControl(),
            kenyszi_subtype: new FormControl(),
            is_active: new FormControl(),
            position: new FormControl(),
        }))

        this.destruct.subscription(this.form.get("type").valueChanges).subscribe(value => {
            this.editorSvc.isKenyszi = value === "KENYSZI"
            this.editorSvc.isStreet = value === "STREET_CARE"
            this.editorSvc.isDispatcher = value === "DISPATCHER"
            this.editorSvc.isCommunal = value === "COMMUNAL"
        })
    }

    public ngOnInit() {
        super.ngOnInit()
        this._reset()
    }

    public save() {
        let data = this.form.value
        if (this.provider && this.provider.id) {
            data.id = this.provider.id
        }
        data.parent_id = this.sheet.sectionId
        data.is_active = !!data.is_active
        data.is_leaf = true

        return this.providerBackend.save({ data }).pipe(
            tap(provider => {
                this.provider = provider
                this._reset()
                this.editorSvc.providerUpdated(provider)
            }))
    }

    public create() {
        this.save().subscribe()
    }

    public remove() {
        const behavior = new ModalLayer({
            backdrop: { type: "filled", hideOnClick: false },
            closeable: false,
            elevation: 10,
            rounded: 3,
            trapFocus: true
        })
        const ref = this.layerSvc.createFromComponent(ProviderRemoveComponent, behavior, null, [
            { provide: PROVIDER_ID, useValue: this.provider.id }
        ])
        ref.show()

        ref.subscribe(event => {
            if (event.type === "removed") {
                this.editorSvc.sheet.delProvider(this.provider.id)
            } else if (event.type === "merged") {
                this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
                    this.router.navigate(["/org/reszleg/", event.data])
                })
            }
        })
    }

    private _reset() {
        if (this.provider) {
            this.form.reset({
                title: this.provider.title,
                type: this.provider.type,
                kenyszi_type: this.provider.kenyszi_type,
                kenyszi_subtype: this.provider.kenyszi_subtype,
                is_active: !!this.provider.is_active,
                position: this.provider.position,
            })
        }
    }
}
