import { Component, Inject, Output, EventEmitter, Input, OnDestroy } from "@angular/core"
import { merge, forkJoin } from "rxjs"
import { switchMap, take, shareReplay } from "rxjs/operators"

import { Destruct, Margin, parseMargin, composeMargin, MarginParsed, DataSourceDirective } from "@anzar/core"
import { WorkerMessage } from "@backend/worker.api"
import { AuthService } from "@pyzar/auth.module"
import { CurrentHeadquerter, CurrentSection } from "../section.service/level"
import { MessageService } from "./message.service"
import { MESAGE_FIELDS } from "./message.component"
import { FormControl } from "@angular/forms"


@Component({
    selector: ".rege-message-list",
    templateUrl: "./message-list.component.pug"
})
export class MessageListComponent implements OnDestroy {
    public readonly destruct = new Destruct()

    @Output()
    public readonly pinchange: EventEmitter<boolean> = this.destruct.subject(new EventEmitter())

    @Output()
    public readonly deleted: EventEmitter<WorkerMessage> = this.destruct.subject(new EventEmitter())

    @Input()
    public set padding(val: Margin) {
        const margin = parseMargin(val)
        this._paddingStyle = composeMargin(margin)
        this._padding = margin
    }
    public _padding: MarginParsed = parseMargin(16)
    public _paddingStyle: string = composeMargin(this._padding)

    @Input()
    public set gap(val: string) {
        if (typeof val === "number") {
            this._gap = `${val}px`
        } else {
            this._gap = val
        }
        this._hasGap = !!val
    }
    public get gap(): string { return this._gap }
    public _gap: string = "16px"
    public _hasGap: boolean = false

    @Input()
    public canReply: boolean = false

    @Input()
    public canSearch: boolean = false

    @Input()
    public transparent: boolean = false

    public canDelete: boolean = false
    public canPin: boolean = false
    public canCreate: boolean = false

    public readonly searchText = new FormControl()

    public constructor(
        @Inject(DataSourceDirective) public readonly source: DataSourceDirective<WorkerMessage>,
        @Inject(MessageService) protected readonly messageSvc: MessageService,
        @Inject(AuthService) public readonly auth: AuthService,
        @Inject(CurrentHeadquerter) public readonly hq: CurrentHeadquerter,
        @Inject(CurrentSection) section: CurrentSection) {
        source.loadFields(MESAGE_FIELDS)

        this.destruct.subscription(messageSvc.invalidated).subscribe(_ => {
            source.reload()
        })

        this.destruct.subscription(merge(this.pinchange, this.deleted)).subscribe(_ => {
            messageSvc.invalidate()
        })

        this.destruct.subscription(section.id$)
            .pipe(
                switchMap(level_id => {
                    return forkJoin([
                        auth.hasPermission({ permission: "messages.delete", extra: { level_id } }).pipe(take(1)),
                        auth.hasPermission({ permission: "messages.pin", extra: { level_id } }).pipe(take(1)),
                        auth.hasPermission({ permission: "messages.create", extra: { level_id } }).pipe(take(1)),
                    ])
                })
            )
            .subscribe(([canDelete, canPin, canCreate]) => {
                console.log({ canDelete, canPin, canCreate })
                this.canDelete = canDelete
                this.canPin = canPin
                this.canCreate = canCreate
            })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    public realod() {
        this.source.reload()
    }
}
