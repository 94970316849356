import { Component } from "@angular/core"
import { Screen } from "../abstract.screen"


@Component({
    selector: "rege-workers-screen",
    templateUrl: "./workers-screen.template.pug"
})
export class WorkersScreen extends Screen {

}
