import { Component, Inject, Input, ChangeDetectorRef, NgZone, Optional } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Subject, zip, of, merge, Observable, EMPTY } from "rxjs"
import { take, tap, switchMap, shareReplay, map, debounceTime, catchError, filter, mapTo, materialize } from "rxjs/operators"
import { format, isWithinInterval, compareDesc } from "date-fns"

import { LoadFields, FileDownloadService, StackItemRef, ToastService, LayerService, ModalLayer } from "@anzar/core"
import { RpcError } from "@anzar/rpc"
import { Room, RoomBackend, Bed, BedBackend, BedReservation, BedReservationBackend } from "@backend/service_room.api"
import { BedType } from "@backend/enums.api"
import { ClientService } from "../../../client.module"
import { ProviderServices, UsableService } from "../../provider.service"
import { ServiceUsage, DutyService } from "../../../duty.module"
import { CurrentSection } from "../../../section.service/level"
import { AbstractEditor } from "../abstract"
import { RoomConflictDialogComponent, OVERLAPPING_RESERVATIONS, RESERVING_ENTRY, RESERVING_CLIENT_ID } from "./conflict-dialog.component"
import { EditorService } from "../editor.service"

declare const BACKEND_URL: string

export class ListEntry {
    public readonly begin = new FormControl()
    public readonly end = new FormControl()
    public readonly clientId = new FormControl()

    public constructor(
        public room: Room,
        public bed: Bed,
        public reservation: BedReservation,
        clientId: number
    ) {
        this.clientId.setValue(clientId)
    }
}


const ROOM_FIELDS: LoadFields<Room> = ["id", "title", "provider_service_id"]
const BED_FIELDS: LoadFields<Bed> = ["id", "room_id", "number", "type"]
const RESERVATION_FIELDS: LoadFields<BedReservation> = [
    "id", "client_id", "bed_id", "begin", "end",
    { bed: ["room_id"] },
    { client: ["id", "name"] }
]

const BED_TYPES: { [key: string]: string } = {}

for (const x of BedType.DATA.data) {
    BED_TYPES[x.id] = x.label
}


@Component({
    selector: ".rege-room-service-editor",
    templateUrl: "./room.template.pug"
})
export class RoomEditorComponent extends AbstractEditor {
    @Input()
    public set service(val: UsableService) {
        if (this._service !== val) {
            this._service = val
            this.serviceChange.next(val)
        }
    }
    public get service(): UsableService { return this._service }
    private _service: UsableService

    @Input()
    public set usage(val: ServiceUsage) {
        if (this._usage !== val) {
            this._usage = val
        }
    }
    public get usage(): ServiceUsage { return this._usage }
    private _usage: ServiceUsage

    public set date(val: Date) {
        if (this._date !== val) {
            this._date = val
            this.dateChange.next(val)
        }
    }
    public get date(): Date { return this._date }
    private _date: Date

    public set selectedRoom(val: Room) {
        if (this._selectedRoom !== val) {
            this._selectedRoom = val
            this.roomChange.next(val)
        }
    }
    public get selectedRoom(): Room { return this._selectedRoom }
    private _selectedRoom: Room

    public set reserving(val: ListEntry) {
        if (this._reserving !== val) {
            this._reserving = val

            if (val) {
                if (val.reservation) {
                    let reservation = val.reservation
                    val.begin.setValue(reservation.begin)
                    val.end.setValue(reservation.end)
                    val.clientId.setValue(reservation.client_id)
                } else {
                    val.begin.setValue(this.date)

                    if (this.usage) {
                        val.clientId.setValue(this.usage.client.id)
                    }
                }
            }
        }
    }
    public get reserving(): ListEntry { return this._reserving }
    private _reserving: ListEntry

    public roomStat: { [key: number]: { total: number, reserved: number } } = {}
    public readonly serviceChange = new Subject<UsableService>()
    public readonly roomChange = new Subject<Room>()
    public readonly dateChange = new Subject<Date>()
    public readonly bedTypesLabel = BED_TYPES

    private readonly _forceReload = new Subject<void>()
    public readonly _reload = this.dutyService.onUsageChange.pipe(
        filter(evt => {
            return evt.providerService.service.type === "room"
        })
    )

    public providerServiceIds = this.destruct.subscription(this.serviceChange).pipe(
        map(service => {
            if (service) {
                return service.services
                    .map(s => s.id)
                    .filter((v, i, a) => a.indexOf(v) === i)
            } else {
                return []
            }
        }),
        shareReplay(1)
    )

    public rooms = this.destruct.subscription(this.providerServiceIds).pipe(
        switchMap(ids => {
            return this.roomBackend.search({
                filter: { provider_service_id: { in: ids }, is_active: true },
                order: { title: "asc" }
            }, { loadFields: ROOM_FIELDS })
        }),
        tap(rooms => {
            this.roomStat = {}
            for (const r of rooms) {
                this.roomStat[r.id] = { reserved: 0, total: 0 }
            }
            this.selectedRoom = rooms[0]
        }),
        shareReplay(1)
    )

    public beds = this.destruct.subscription(this.providerServiceIds).pipe(
        switchMap(ids => {
            return this.bedBackend.search({
                filter: { "room.provider_service_id": { in: ids }, "room.is_active": true, is_active: true },
                order: { number: "asc", type: "asc" }
            }, { loadFields: BED_FIELDS })
        }),
        tap(beds => {
            let total: { [key: number]: number } = {}

            for (const bed of beds) {
                if (!total[bed.room_id]) {
                    total[bed.room_id] = 1
                } else {
                    total[bed.room_id]++
                }
            }

            for (const roomId in total) {
                this.roomStat[roomId].total = total[roomId]
            }
        }),
        shareReplay(1)
    )

    public reservations = merge(
        this.rooms, this.beds, this.dateChange, this.serviceChange, this._reload, this._forceReload
    ).pipe(
        debounceTime(10),
        switchMap(() => this.providerServiceIds),
        switchMap(ids => {
            return this.bedReservationBackend.search({
                filter: {
                    "bed.room.provider_service_id": { in: ids },
                    "bed.is_active": true,
                    "bed.room.is_active": true,
                    "date": this.date
                }
            }, { loadFields: RESERVATION_FIELDS })
        }),
        switchMap(v => zip(of(v), this.rooms, this.beds)),
        switchMap(v => {
            let [resv, rooms, beds] = v
            let reserved: { [key: number]: number } = {}

            for (const room of rooms) {
                reserved[room.id] = 0
            }

            for (const r of resv) {
                const bed = beds.find(b => b.id === r.bed_id)
                reserved[bed.room_id]++

                if (this.usage && this.usage.client.id === r.client_id) {
                    const room = rooms.find(rv => rv.id === r.bed.room_id)
                    if (room) {
                        this.selectedRoom = room
                    }
                }
            }

            for (const roomId in reserved) {
                this.roomStat[roomId] = {
                    total: this.roomStat[roomId].total,
                    reserved: reserved[roomId]
                }
            }
            this.cdr.markForCheck()
            return of(resv)
        }),
        shareReplay(1),
    )

    public listData = this.destruct.subscription(merge(this.reservations, this.roomChange))
        .pipe(
            debounceTime(10),
            switchMap(v => zip(of(this.selectedRoom), this.beds, this.reservations)),
            map(v => {
                let [room, beds, reservations] = v
                let res: ListEntry[] = []

                for (const bed of beds.filter(b => b.room_id === room.id)) {
                    let resv = reservations.find(r => r.bed_id === bed.id)
                    res.push(new ListEntry(room, bed, resv, resv ? resv.client_id : null))
                }

                return res
            }),
            shareReplay(1),
        )

    // protected entries: ListEntry[] = []

    public constructor(
        @Inject(RoomBackend) protected readonly roomBackend: RoomBackend,
        @Inject(BedBackend) protected readonly bedBackend: BedBackend,
        @Inject(BedReservationBackend) protected readonly bedReservationBackend: BedReservationBackend,
        @Inject(ChangeDetectorRef) protected cdr: ChangeDetectorRef,
        @Inject(ToastService) protected readonly toastSvc: ToastService,
        @Inject(LayerService) protected readonly layerSvc: LayerService,
        @Inject(DutyService) protected readonly dutyService: DutyService,
        @Inject(ProviderServices) protected readonly providerServices: ProviderServices,
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService,
        @Inject(EditorService) private readonly editorSvc: EditorService,
        @Inject(CurrentSection) public readonly section: CurrentSection,
        @Inject(StackItemRef) @Optional() stackItem?: StackItemRef) {
        super()

        this.destruct.subscription(this.listData)
            .pipe(
                take(1),
                switchMap(_ => {
                    if (stackItem) {
                        return stackItem.activate
                    } else {
                        return EMPTY
                    }
                })
            )
            .subscribe(v => {
                this._forceReload.next()
            })

        this.date = dutyService.date
    }

    public onDateChange(date: Date) {
        this.date = date
    }

    public reserve(entry: ListEntry) {
        let clientId = entry.clientId.value
        let usage = this.usage
            ? this.usage
            : this.dutyService.usage.data.find(u => u.client.id === clientId) as ServiceUsage

        this.bedReservationBackend.reserve({
            client_id: clientId,
            bed_id: entry.bed.id,
            begin: entry.begin.value,
            end: entry.end.value
        })
            .pipe(take(1))
            .pipe(catchError((err: RpcError) => {
                switch (err.data.reason) {
                    case "overlaps":
                        this.showConflictDialog(clientId, entry, err.data.overlapping)
                            .pipe(take(1))
                            .subscribe(() => {
                                this.reserve(entry)
                            })
                        break

                    default:
                        this.toastSvc.error(err.message)
                        break
                }
                return EMPTY
            }))
            .pipe(switchMap(result => {
                if (usage && result && this._dateInRange(this.dutyService.date, result.begin, result.end)) {
                    let pservice = this.providerServices.getProviderServiceById(entry.room.provider_service_id)
                    if (pservice) {
                        return this.dutyService.setUsage(usage, pservice, true, false).pipe(
                            materialize(),
                            take(1),
                            mapTo(result)
                        )
                    }
                }
                return of(result)
            }))
            .subscribe(x => {
                this._forceReload.next()
            })
    }

    public free(entry: ListEntry, openClientSheet: boolean) {
        this.bedReservationBackend
            .freeing_range({
                bed_id: entry.bed.id,
                client_id: entry.clientId.value,
                begin: this.date,
                end: null
            })
            .pipe(take(1))
            .subscribe(_ => {
                if (openClientSheet) {
                    this.clientSvc.showSheet(entry.clientId.value).subscribe()
                }
            })
        this._forceReload.next()
    }

    protected showConflictDialog(clientId: number, entry: ListEntry, overlapping: any): Observable<any> {
        let behavior = new ModalLayer({
            rounded: 3,
            elevation: 10
        })
        let layerRef = this.layerSvc.createFromComponent(RoomConflictDialogComponent, behavior, null, [
            { provide: OVERLAPPING_RESERVATIONS, useValue: overlapping },
            { provide: RESERVING_ENTRY, useValue: entry },
            { provide: RESERVING_CLIENT_ID, useValue: clientId },
        ])
        layerRef.show()

        return layerRef.output.pipe(filter(x => x.type === "resolved"))
    }

    private _dateInRange(date: Date, start: Date, end?: Date): boolean {
        if (end) {
            return isWithinInterval(date, { start, end })
        } else {
            return compareDesc(start, date) >= 0
        }
    }

    public showClient(clientId: number) {
        this.clientSvc.showSheet(clientId).subscribe()
    }

    public print() {
        this.destruct.subscription(this.providerServices.all)
            .pipe(
                switchMap(providers => {
                    const providerIds = providers.map(p => p.id).join(",")
                    const datef = format(this.date, "yyyy-MM-dd")
                    const url = `${BACKEND_URL}/stats/service_room/${providerIds}/${datef}`
                    return this.downloader.download(url)
                }),
                this.toastSvc.handleFileDownload({ align: "bottom center", beginMsg: "Dokumentum generálása" })
            )
            .subscribe()
    }
}
