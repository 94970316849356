import { NgModule } from "@angular/core"
import { ServiceType, Service, ReportType, Report, ReportServiceGroup, ReportService, ServiceBackend, ServiceBackendSource } from "./__anzar_rpc_output"

export { ServiceType, Service, ReportType, Report, ReportServiceGroup, ReportService, ServiceBackend, ServiceBackendSource }


@NgModule({
    providers: [
        ServiceBackendSource,
        ServiceBackend
    ],
})
export class ServiceApi {}