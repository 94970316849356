import { Component, HostListener, Inject, Input } from "@angular/core"

import { Section } from "@backend/org.api"

import { SectionInfoService } from "./section-info.component"

@Component({
    selector: "rege-section-link",
    templateUrl: "./section-link.component.pug"
})
export class SectionLinkComponent {
    @Input() public section: Section

    public constructor(@Inject(SectionInfoService) private readonly sectionInfo: SectionInfoService) {}

    @HostListener("tap", ["$event"])
    public onTap(event: Event) {
        event.preventDefault()
        this.sectionInfo.show(this.section.id)
    }
}
