import { Inject, Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { map, mapTo } from "rxjs/operators"

import { DataSource, Filter, Sorter, Meta, PrimaryKey, Model, Field, NzRange } from "@anzar/core"
import { PlaceBackend } from "@backend/place.api"


export class SubstringMatch extends Model {
    @Field() public offset: number
    @Field() public length: number
}


export class PlaceAutocomplete_SF extends Model {
    @Field() public main_text: string
    @Field() public secondary_text: string
    @Field({ listOf: SubstringMatch }) public main_text_matched_substrings: SubstringMatch[]
}


export class PlaceAutocomplete extends Model {
    @Field({ primary: true }) public id: string
    @Field() public place_id: string
    @Field() public description: string
    @Field() public structured_formatting: PlaceAutocomplete_SF
    @Field() public session: string
}


@Injectable({ providedIn: "root" })
export class PlaceAutocompleteSrc<T extends PlaceAutocomplete = PlaceAutocomplete> extends DataSource<T> {
    public readonly async = true

    public session: string

    public constructor(@Inject(PlaceBackend) public readonly backend: PlaceBackend) {
        super()
    }

    protected _search(f?: Filter<T>, s?: Sorter<T>, r?: NzRange, m?: Meta<T>): Observable<T[]> {
        let query = (f as any).query
        if (query && typeof query !== "string") {
            if (query.contains) {
                query = query.contains
            }
        }

        if (!query || !query.length) {
            return of(1).pipe(mapTo([]))
        }

        return this.backend.autocomplete({ text: query, session: this.session, near: (f as any).near }).pipe(
            map(result => {
                return result.map(v => new PlaceAutocomplete(v))
            })
        ) as any
    }

    protected _get(id: PrimaryKey, m?: Meta<T>): Observable<any> {
        return this.backend.get({ id: id as string }, m as any)
    }

    protected _save(model: T, m?: Meta<T>): Observable<T> {
        throw new Error("NOT SUPPORTED")
    }

    protected _remove(id: PrimaryKey, m?: Meta<T>): Observable<boolean> {
        throw new Error("NOT SUPPORTED")
    }

    public getPosition(id: PrimaryKey, f?: Filter<T>, s?: Sorter<T>): Observable<number> {
        return null
    }
}
