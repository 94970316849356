import { Injectable, InjectionToken, Inject, ErrorHandler } from "@angular/core"
import { Observable } from "rxjs"

import * as Sentry from "@sentry/browser"
import { RpcInterceptor, Transaction } from "@anzar/rpc"



@Injectable({ providedIn: "root" })
export class SentryService {
    public static readonly DSN = new InjectionToken<string>("Sentry.DSN")
    public static readonly ENV = new InjectionToken<string>("Sentry.ENV")
    public static readonly PLATFORM = new InjectionToken<string>("Sentry.PLATFORM")

    public constructor(
        @Inject(SentryService.DSN) public readonly dsn: string,
        @Inject(SentryService.ENV) public readonly env: string,
        @Inject(SentryService.PLATFORM) public readonly platform: string) {

        if (dsn) {
            Sentry.init({
                dsn: dsn,
                attachStacktrace: true,
                environment: env,
                release: `rege-frontend@${__VERSION__}`,
                maxBreadcrumbs: 30,
                beforeBreadcrumb(breadcrumb, hint) {
                    if (breadcrumb.category === "xhr") {
                        return null
                    }

                    if (breadcrumb.category === "ui.click") {
                        if (hint.event) {
                            const target = hint.event.target as HTMLElement
                            if (target.tagName.toUpperCase() === "INPUT") {
                                const placeholder = target.closest(".nz-placeholder")
                                const label = placeholder.querySelector("label")
                                breadcrumb.data = {}
                                if (label) {
                                    breadcrumb.data.element = breadcrumb.message
                                    breadcrumb.message = `[INPUT ${label.innerText}]`
                                }
                                breadcrumb.data = {
                                    ...breadcrumb.data,
                                    formControlName: target.getAttribute("formControlName"),
                                    formControl: target.getAttribute("formControl"),
                                }
                            } else {
                                let button: HTMLElement
                                if (target.tagName.toUpperCase() === "BUTTON" || target.classList.contains("nz-button")) {
                                    button = target
                                } else {
                                    button = target.closest("button, .nz-button") as HTMLElement
                                }
                                if (button) {
                                    const qtip = button.getAttribute("nzQtip")
                                    breadcrumb.data = {
                                        element: breadcrumb.message
                                    }
                                    breadcrumb.message = `[BUTTON ${qtip || button.innerText}]`
                                }
                            }
                        }
                        return breadcrumb
                    } else {
                        return breadcrumb
                    }
                }
            })
        }
    }

    public captureException(exception: any) {
        if (this.dsn) {
            Sentry.captureException(exception)
        } else {
            console.error(exception)
        }
    }

    // public showReportDialog() {
    //     Sentry.showReportDialog({
    //         eventId: "aaa",
    //         labelClose: "Bezárás",
    //         labelComments: "Megjegyzés",
    //         labelEmail: "E-mail",
    //         labelName: "Név",
    //         labelSubmit: "Küldés",
    //         title: "Visszajelzés",
    //         subtitle: "Visszajelzés küldése a fejlesztői csapatnak",
    //         subtitle2: null,
    //         errorFormEntry: "Az űrlap hibás mezőket tartalmaz",
    //         successMessage: "A visszajelzés sikeresn elküldve. Köszönjük!"
    //     })
    // }
}


@Injectable({ providedIn: "root" })
export class SentryErrorHandler implements ErrorHandler {
    public constructor(@Inject(SentryService) private readonly sentry: SentryService) {

    }
    public handleError(error: any) {
        if (this.sentry.dsn) {
            this.sentry.captureException(error.originalError || error)
        } else {
            console.error(error)
        }
    }
}


@Injectable({ providedIn: "root" })
export class SentryRpcBreadcrumb extends RpcInterceptor {
    public intercept(trans: Transaction<any>, source: Observable<any>): Observable<any> {
        return source
    }

    public onRequest = (trans: Array<Transaction<any>>): void => {
        let data: { [key: string]: any } = {}

        for (const t of trans) {
            data[t.id] = `${t.method}  ${JSON.stringify(t.params)}`
        }

        Sentry.addBreadcrumb({
            category: "rpc",
            level: Sentry.Severity.Info,
            data: data
        })
    }
}
