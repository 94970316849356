import { Injectable, Inject } from "@angular/core"
import { map, take } from "rxjs/operators"

import { LayerService, ModalLayer, MediaQueryService, FullscreenLayer } from "@anzar/core"
import { FeedbackComponent, IS_SMALL_SCREEN } from "./feedback.component"


@Injectable({ providedIn: "root" })
export class FeedbackService {

    public readonly isSm$ = this.mq.watch("lt-md").pipe(map(v => v.matches))

    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(MediaQueryService) private readonly mq: MediaQueryService) {
    }

    public show() {
        this.isSm$.pipe(take(1)).subscribe(isSm => {
            const behavior = isSm
                ? new FullscreenLayer({})
                : new ModalLayer({
                    backdrop: { type: "filled", hideOnClick: true },
                    elevation: 10,
                    rounded: 3
                })

            const ref = this.layerSvc.createFromComponent(FeedbackComponent, behavior, null, [
                { provide: IS_SMALL_SCREEN, useValue: isSm }
            ])
            ref.show()
        })
    }
}
