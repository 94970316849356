import { Component, Inject } from "@angular/core"
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { Router, ActivatedRoute } from "@angular/router"
import { switchMap, take, finalize } from "rxjs/operators"

import { ViewportService, ToastService } from "@anzar/core"
import { UserBackend } from "@backend/pyzar.api"
import { AuthService } from "../auth.service"
import { validateEqual } from "../../common.module"


@Component({
    templateUrl: "./change-pass.component.pug",
    providers: [
        ViewportService
    ]
})
export class ChangePassViewport {
    public readonly form = new FormGroup({
        password: new FormControl(null, [Validators.required]),
        password_re: new FormControl(null, [Validators.required, validateEqual("password")])
    })

    public submitted: Boolean = false

    public constructor(
        @Inject(AuthService) private authSvc: AuthService,
        @Inject(Router) private readonly router: Router,
        @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(UserBackend) private readonly userBackend: UserBackend) {

    }

    public doChange() {
        if (this.submitted) {
            return
        }
        this.submitted = true

        const data = this.form.value
        this.authSvc.currentUser$
            .pipe(
                switchMap(user => {
                    return this.userBackend.save({
                        data: {
                            id: user.id,
                            password: data.password,
                            password_re: data.password_re,
                        }
                    }).pipe(take(1), finalize(() => {
                        this.submitted = false
                    }))
                }),
                this.toastSvc.handleSave({ align: "bottom center", successMsg: "Sikeresen módosítottad a jelszavadat" }),
                switchMap(result => {
                    this.authSvc.invalidate()
                    return this.authSvc.currentUser$
                })
            )
            .subscribe(() => {
                this.router.navigate([this.route.snapshot.queryParamMap.get("r") || "/"], { replaceUrl: true })
            })
    }
}
