import { Component, Inject, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { throwError } from "rxjs"
import { catchError } from "rxjs/operators"

import { LayerRef, ToastService } from "@anzar/core"
import { LeaveReason } from "@backend/enums.api"
import { AgreementBackend, Agreement } from "@backend/documents.api"


@Component({
    templateUrl: "./leave-provider.component.pug"
})
export class LeaveProviderComponent {
    public readonly form = new FormGroup({
        end: new FormControl(new Date(), [Validators.required]),
        leave_reason: new FormControl(),
    })

    public readonly leaveResonSrc = LeaveReason.DATA

    public set isBusy(val: boolean) {
        if (this._isBusy !== val) {
            this._isBusy = val
            this.cdr.markForCheck()
        }
    }
    public get isBusy(): boolean { return this._isBusy }
    private _isBusy: boolean

    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(Agreement) private readonly agreement: Agreement,
        @Inject(AgreementBackend) private readonly agreementBackend: AgreementBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
    }

    public cancel() {
        this.layerRef.hide()
    }

    public save() {
        this.isBusy = true
        this.agreementBackend
            .close({
                agreement: this.agreement.id,
                end: this.form.value.end,
                leave_reason: this.form.value.leave_reason
            })
            .pipe(
                catchError(e => {
                    this.isBusy = false
                    return throwError(e)
                }),
                this.toast.handleSave({ align: "bottom center", beginMsg: "Ügyfél lezárása..." })
            )
            .subscribe(_ => {
                this.isBusy = false
                this.layerRef.hide()
            })
    }
}
