import { Inject, Injectable } from "@angular/core"
import { of, Observable, merge } from "rxjs"
import { take, switchMap, map, debounceTime } from "rxjs/operators"
import { startOfDay } from "date-fns"

import { LayerService } from "@anzar/core"

import { BedReservationBackend } from "@backend/service_room.api"
import { ResourceReservationBackend } from "@backend/service_schedule.api"
import { BNOReservationBackend } from "@backend/service_bno.api"

import { ProviderServices, UsableService } from "../provider.service"
import { DutyService, UsageInterceptor, ServiceUsageEvent, ServiceUsage } from "../../duty.module"


@Injectable()
export class EditorService {
    public constructor(
        @Inject(UsageInterceptor) protected readonly usageInterceptor: UsageInterceptor,
        @Inject(BedReservationBackend) protected readonly bedReservationBackend: BedReservationBackend,
        @Inject(BNOReservationBackend) protected readonly bnoReservationBackend: BNOReservationBackend,
        @Inject(ResourceReservationBackend) protected readonly resReservationBackend: ResourceReservationBackend,
        @Inject(ProviderServices) protected readonly provider: ProviderServices,
        @Inject(DutyService) protected readonly duty: DutyService,
        @Inject(LayerService) private readonly layerSvc: LayerService) {

        usageInterceptor.intercept(event => {
            if (event && event.enabled) {
                switch (event.providerService.service.type) {
                    case "room":
                        if (event.enabled && event.providerService.is_detailed) {
                            return this._enableRoom(event)
                        }
                        break

                    case "bno":
                        if (event.enabled) {
                            return this._enableBno(event)
                        }
                        break

                    case "scheduled":
                        if (event.enabled) {
                            return this._enableScheduled(event)
                        }
                        break

                    case "social_mental":
                        if (event.enabled) {
                            return this._enableSocialMental(event)
                        }
                        break

                    case "communal":
                        if (event.enabled) {
                            return this._enableCommunal(event)
                        }
                        break
                }
            }

            event.pending = false
            return of(event)
        })
    }

    public editableServicesOf(entry: ServiceUsage): Observable<UsableService[]> {
        return merge(this.provider.services, entry.usageStream)
            .pipe(
                debounceTime(10),
                switchMap(v => entry.usageStream),
                map(usage => {
                    let result: UsableService[] = []

                    for (const u of usage) {
                        if (u.enabled) {
                            let usable = this.provider.getServiceById(u.providerService.id)
                            if (usable && usable.isEditable && result.indexOf(usable) === -1) {
                                result.push(usable)
                            }
                        }
                    }

                    return result
                })
            )
    }

    protected _enableRoom(event: ServiceUsageEvent): Observable<ServiceUsageEvent> {
        return this.bedReservationBackend.search({
            filter: {
                client_id: event.usage.client.id,
                "bed.room.provider_service_id": event.providerService.id,
                date: startOfDay(event.duty.begin)
            },
            order: null,
            begin: 0,
            count: 1
        }).pipe(
            take(1),
            switchMap(result => {
                if (result && result.length) {
                    event.pending = false
                    return of(event)
                } else {
                    event.pending = true
                    this.duty.expand(event.usage)
                    return of(event)
                }
            })
        )
    }

    protected _enableBno(event: ServiceUsageEvent): Observable<ServiceUsageEvent> {
        return this.bnoReservationBackend.search({
            filter: {
                client_id: event.usage.client.id,
                "bno.provider_service_id": event.providerService.id,
                date: startOfDay(event.duty.begin)
            },
            order: null,
            begin: 0,
            count: 1
        }).pipe(
            take(1),
            switchMap(result => {
                if (result && result.length) {
                    event.pending = false
                    return of(event)
                } else {
                    event.pending = true
                    this.duty.expand(event.usage)
                    return of(event)
                }
            })
        )
    }

    protected _enableScheduled(event: ServiceUsageEvent): Observable<ServiceUsageEvent> {
        return this.resReservationBackend.search({
            filter: {
                client_id: event.usage.client.id,
                "resource.provider_service_id": event.providerService.id,
                date: startOfDay(event.duty.begin)
            },
            order: null,
            begin: 0,
            count: 1
        }).pipe(
            take(1),
            switchMap(result => {
                if (result && result.length) {
                    event.pending = false
                    return of(event)
                } else {
                    event.pending = true
                    this.duty.expand(event.usage)
                    return of(event)
                }
            })
        )
    }

    protected _enableSocialMental(event: ServiceUsageEvent): Observable<ServiceUsageEvent> {
        event.pending = true
        return of(event)
    }

    protected _enableCommunal(event: ServiceUsageEvent): Observable<ServiceUsageEvent> {
        event.pending = true
        return of(event)
    }
}
