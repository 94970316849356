import { Component, forwardRef, Inject, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { Observable, of } from "rxjs"
import { tap, take, switchMap } from "rxjs/operators"

import { ToastService } from "@anzar/core"
import { UserBackend } from "@backend/pyzar.api"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "../../common.module/components/card-list/abstract"
import { AuthService } from "../auth.service"
import { UserEditorService } from "./user-editor.service"


@Component({
    selector: ".pz-user-basic-data",
    templateUrl: "./basic-data.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => UserBasicDataComponent) }
    ]
})
export class UserBasicDataComponent extends AbstractCLEditorBlock<UserEditorService> {
    public set editEmail(val: boolean) {
        if (this._editEmail !== val) {
            this._editEmail = val
            this.cdr.detectChanges()
        }
    }
    public get editEmail(): boolean { return this._editEmail }
    private _editEmail: boolean = false

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: UserEditorService,
        @Inject(UserBackend) private readonly userBackend: UserBackend,
        @Inject(AuthService) private readonly authService: AuthService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(ToastService) private readonly toast: ToastService) {
        super(editorSvc, editorSvc.form)
    }

    public save(): Observable<any> {
        const data = this.form.value
        data.is_active = !!data.is_active

        delete data.email_is_verified
        delete data.pending_email

        return this.userBackend.save({ data })
            .pipe(tap(user => { this.editorSvc.resetForm(user) }))
    }

    public startEditEmail() {
        const emailCtrl = this.form.get("email")
        const pendingCtrl = this.form.get("pending_email")

        if (!pendingCtrl.value) {
            pendingCtrl.setValue(emailCtrl.value)
        }

        this.editEmail = true
    }

    public cancelEditEmail() {
        this.editEmail = false
    }

    public saveNewEmail() {
        this.userBackend
            .save({
                data: { id: this.editorSvc.id, pending_email: this.form.value.pending_email }
            })
            .pipe(this.toast.handleSave({ align: "bottom center", successMsg: "E-mail cím módosítása sikeres. Kiküldtünk egy levelet a megadott címre, hogy hitelesítsd." }))
            .pipe(take(1))
            .subscribe(success => {
                this.form.get("email_is_valid").setValue(false)
                this.editEmail = false
            })
    }

    public resendVerifyEmail() {
        this.userBackend
            .resend_email_verify({ user_id: this.editorSvc.id })
            .pipe(this.toast.handleSave({ align: "bottom center", successMsg: "E-mail cím hitelesítő levél elküldve" }))
            .subscribe()
    }
}
