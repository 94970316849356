import { Injectable, Inject } from "@angular/core"
import { Router } from "@angular/router"
import { Observable, Observer, Subject, merge, of } from "rxjs"
import { shareReplay, tap, take, switchMap, startWith, map } from "rxjs/operators"

import { SectionBackend, Section, LevelBackend } from "@backend/org.api"
import { AuthService } from "@pyzar/auth.module"
import { LoadFields } from "@anzar/core"


const SECTION_FIELDS: LoadFields<Section> = [
    "id", "parent_id", "title", "variant", "ui_type"
]


@Injectable({ providedIn: "root" })
export class SectionService {
    // public readonly selected: Observable<Section> = this.backend.selected().pipe(shareReplay(1))
    protected redirectAfterSelect: string

    public set sectionId(val: number) {
        if (this._sectionId !== val) {
            this._sectionId = val
            this._sectionIdChanged.next(val)
        }
    }
    public get sectionId(): number { return this._sectionId }
    private _sectionId: number

    private _sectionIdChanged = new Subject<number>()
    private _currentId = this._sectionIdChanged.pipe(startWith(null), shareReplay(1))

    public readonly selected: Observable<Section> = this._currentId.pipe(
        switchMap(sectionId => {
            if (sectionId) {
                return of(sectionId)
            } else {
                return this.backend.selected()
            }
        }),
        switchMap(sectionId => {
            if (sectionId) {
                return this.backend.select({ section_id: sectionId })
            } else {
                return of(null)
            }
        }),
        shareReplay(1)
    )

    public readonly ownSections$: Observable<Section[]> = this.authSvc.currentUser$.pipe(
        switchMap(user => {
            return this.levelBackend.worker_levels({ user_id: user.id, variant: "section" })
                .pipe(
                    map(result => {
                        return result.map(v => v[0]).sort((a, b) => a.title.localeCompare(b.title)) as Section[]
                    })
                )
        }),
        shareReplay(1)
    )

    public readonly ownSectionsSplitByEmptiness$ = this.ownSections$.pipe(
        switchMap(sections => {
            const section_ids = sections.map(s => s.id)
            return this.backend.has_active_clients({ section_ids }).pipe(map(has_clients => {
                const normal: Section[] = []
                const empty: Section[] = []
                for (let i = 0, l = has_clients.length; i < l; i++) {
                    if (has_clients[i]) {
                        normal.push(sections[i])
                    } else {
                        empty.push(sections[i])
                    }
                }
                return [normal, empty] as const
            }))
        }),
        shareReplay(1)
    )

    public constructor(
        @Inject(LevelBackend) public readonly levelBackend: LevelBackend,
        @Inject(SectionBackend) public readonly backend: SectionBackend,
        @Inject(Router) public readonly router: Router,
        @Inject(AuthService) private readonly authSvc: AuthService) {
    }

    public select(section: Section): Observable<Section> {
        return Observable.create((observer: Observer<Section>) => {
            const s = this.selected.subscribe(observer)
            this.sectionId = section.id
            return () => {
                s.unsubscribe()
            }
        })
    }

    // public goToSelectSection(redirectAfter?: string) {
    //     this.redirectAfterSelect = redirectAfter
    //     this.router.navigate(["reszlegvalaszto"])
    // }
}
