import { Component, Inject, Input, forwardRef } from "@angular/core"

import { Section } from "@backend/org.api"
import { SectionEditorService } from "./section-editor.service"
import { AbstractCLEditor, AbstractCLEditorService } from "@pyzar/common.module"
import { AuthService } from "@pyzar/auth.module"


@Component({
    selector: ".rege-section-editor",
    templateUrl: "./section-editor.component.pug",
    providers: [
        { provide: AbstractCLEditorService, useClass: SectionEditorService },
        { provide: AbstractCLEditor, useExisting: forwardRef(() => SectionEditorComponent) }
    ]
})
export class SectionEditorComponent extends AbstractCLEditor {
    @Input() public section: Section

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: SectionEditorService,
        @Inject(AuthService) public readonly auth: AuthService) {
        super(editorSvc)
    }
}
