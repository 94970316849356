import { Component, Input, Inject, ChangeDetectorRef } from "@angular/core"
import { take } from "rxjs/operators"


import { CaseBackend, NCaseTransfer } from "@backend/client.api"
import { NotificationService } from "./notification.service"
import { AuthService } from "@pyzar/auth.module"


@Component({
    selector: ".rege-ne-case-transfer",
    templateUrl: "./ne-case.component.pug"
})
export class NECaseTransferComponent {
    @Input() public notification: NCaseTransfer

    public get accepted(): boolean | null {
        if (this._accepted != null) {
            return this._accepted
        } else if (this.notification.case_transfer.state === "pending") {
            return null
        } else {
            return this.notification.case_transfer.state === "accepted"
        }
    }
    private _accepted: boolean | null = null

    public currentUserId: number

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(CaseBackend) private readonly caseBackend: CaseBackend,
        @Inject(NotificationService) private readonly nsvc: NotificationService,
        @Inject(AuthService) private readonly authSvc: AuthService) {

        authSvc.currentUser$.pipe(take(1)).subscribe(user => this.currentUserId = user.id)
    }

    public accept() {
        this.setDecision(true)
    }

    public decline() {
        this.setDecision(false)
    }

    private setDecision(accept: boolean) {
        this.caseBackend
            .set_decision({ case_transfer_id: this.notification.case_transfer.id, accept: accept })
            .subscribe(_ => {
                this._accepted = accept
                this.nsvc.reloadCount()
                this.cdr.detectChanges()
            })
    }
}
