import { Directive, Inject, Input, OnDestroy } from "@angular/core"
import { Subscription } from "rxjs"
import { startWith } from "rxjs/operators"

import { LocalStorageService, LocalStorageBucket, DataSourceDirective, Destructible } from "@anzar/core"


@Directive({
    selector: "[dsLocalStorage]"
})
export class LocalStorageDirective extends Destructible {
    @Input()
    public set dsLocalStorage(val: string) {
        if (this._dsLocalStorage !== val) {
            this._dsLocalStorage = val

            if (val) {
                if (!this._bucket || val !== this._bucket.id) {
                    this.replaceBucket(this.ls.newBucket(val))
                }
            } else {
                this.replaceBucket(null)
            }
        }
    }
    public get dsLocalStorage(): string { return this._dsLocalStorage }
    private _dsLocalStorage: string

    private _bucket: LocalStorageBucket
    private _changeSub: Subscription

    public constructor(
        @Inject(DataSourceDirective) private readonly dataSource: DataSourceDirective,
        @Inject(LocalStorageService) private readonly ls: LocalStorageService) {
        super()

        this.destruct.any(() => {
            this.replaceBucket(null)
        })

        this.destruct.subscription(dataSource.filterChanges).subscribe(changes => {
            if (this._bucket) {
                this._bucket.set("filter", dataSource.filter as any)
            }
        })

        this.destruct.subscription(dataSource.sorterChanges).subscribe(changes => {
            if (this._bucket) {
                this._bucket.set("sort", dataSource.sort as any)
            }
        })
    }

    public replaceBucket(newBucket: LocalStorageBucket) {
        if (this._bucket !== newBucket) {
            if (this._bucket) {
                this._bucket.dispose()
            }

            if (this._changeSub) {
                this._changeSub.unsubscribe()
            }

            this._bucket = newBucket

            if (newBucket) {
                // newBucket.set("filter", this.dataSource.filter as any)
                // newBucket.set("sort", this.dataSource.sort as any)
                this._changeSub = newBucket.changes$.pipe(startWith(newBucket)).subscribe(this._onBucketChange.bind(this))
            }
        }
    }

    private _onBucketChange(bucket: LocalStorageBucket) {
        this.dataSource.filter = bucket.get("filter") as any
        this.dataSource.sort = bucket.get("sort") as any
    }
}
