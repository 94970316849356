import { Component, Inject } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"
import { map, shareReplay } from "rxjs/operators"
import { formatDistance } from "date-fns"

import { LocaleService } from "@anzar/core"

import { TaskDetailsPart } from "./task-details.component"
import { TaskService } from "./task.service"
import { RunningTask } from "./running-task"


@Component({
    selector: ".pz-task-title",
    templateUrl: "./task-title.component.pug"
})
export class TaskTitleComponent extends TaskDetailsPart {
    public readonly percentScale = this.percent.pipe(
        map(percent => this.sanitizer.bypassSecurityTrustStyle(`scaleX(${percent}%)`)),
        shareReplay(1)
    )

    public readonly durationFormatted = this.duration.pipe(
        map(duration => {
            return formatDistance(0, duration * 1000, { includeSeconds: true, locale: this.localeSvc.dateLocale })
        }),
        shareReplay(1)
    )

    public constructor(
        @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
        @Inject(LocaleService) private readonly localeSvc: LocaleService,
        @Inject(TaskService) taskSvc: TaskService,
        @Inject(RunningTask) task: RunningTask) {
        super(taskSvc, task)
    }
}
