import {
    Component,
    ElementRef,
    Inject,
    Injectable,
    InjectionToken,
    Input,
    OnChanges,
    SimpleChanges
} from "@angular/core"

import JSONFormatter from "json-formatter-js"

import { LayerService, ModalLayer } from "@anzar/core"

@Component({
    selector: ".pz-json",
    template: ""
})
export class RenderJsonComponent implements OnChanges {
    @Input() public data: any

    private rendered: HTMLElement

    public constructor(@Inject(ElementRef) private readonly el: ElementRef<HTMLElement>) {}

    public ngOnChanges(changes: SimpleChanges) {
        if ("data" in changes) {
            if (this.rendered) {
                this.rendered.parentElement && this.rendered.parentElement.removeChild(this.rendered)
            }
            const formatter = new JSONFormatter(changes.data.currentValue)
            this.el.nativeElement.appendChild(formatter.render())
        }
    }
}

const JSON_DATA = new InjectionToken("JSON_DATA")

@Component({
    selector: "pz-json-wnd",
    templateUrl: "./render-json-window.component.pug"
})
export class RenderJsonWindowComponent {
    public constructor(@Inject(JSON_DATA) public readonly data: any) {}
}

@Injectable()
export class RenderJsonService {
    public constructor(@Inject(LayerService) private readonly layerSvc: LayerService) {}

    public show(data: any) {
        const behavior = new ModalLayer({
            backdrop: { type: "filled", hideOnClick: true },
            closeable: true,
            rounded: 3,
            elevation: 13,
            position: {
                align: {
                    horizontal: "center",
                    vertical: "stretch"
                },
                constraint: {
                    ref: "viewport",
                    inset: 16
                }
            }
        })

        const ref = this.layerSvc.createFromComponent(RenderJsonWindowComponent, behavior, null, [
            { provide: JSON_DATA, useValue: data }
        ])
        ref.show()
    }
}
