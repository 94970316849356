import { Component, Inject, InjectionToken, OnDestroy, Optional, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormControl, FormArray } from "@angular/forms"
import { take, map } from "rxjs/operators"
import { startOfDay } from "date-fns"

import { Destruct, LoadFields, LayerRef, ToastService, LayerEvent, MediaQueryService } from "@anzar/core"
import { User } from "@backend/pyzar.api"
import { WorkerBackendSource } from "@backend/worker.api"
import { DutyBackend, Duty } from "@backend/org.api"
import { ProviderDutyBackendSource, ProviderVehicleBackendSource, SectionBackendSource } from "@backend/org.api"
import { CurrentSection } from "../section.service/level"
import { ProviderServices } from "../service.module/provider.service"
import { Place } from "@backend/__anzar_rpc_output"



export const PROVIDER_ID = new InjectionToken<number>("PROVIDER_ID")
export const DUTY_DATE = new InjectionToken<Date>("DUTY_DATE")
export const DUTY_VALUE = new InjectionToken<Date>("DUTY_VALUE")


const USER_FIELDS: LoadFields<User> = [
    "id", "name"
]


@Component({
    selector: ".rege-create-duty-wnd",
    templateUrl: "./create-duty.component.pug",
    host: {
        "[style.width]": "isSm ? null : '500px'"
    },
    providers: [ProviderServices]
})
export class CreateDutyComponent implements OnDestroy {
    public readonly destruct = new Destruct()

    public readonly userFields = USER_FIELDS
    public readonly form = new FormGroup({
        providerId: new FormControl(this.providerId),
        date: new FormControl(this.date || new Date()),
        tpl: new FormControl(),
        vehicles: new FormControl(),
        places: new FormArray([this.createPlaceGroup()]),
        users: new FormArray([
            new FormGroup({
                id: new FormControl(),
                section_id: new FormControl({ value: this.section.id, disabled: true })
            })
        ])
    })

    public get userControls() { return (this.form.get("users") as FormArray).controls }
    public get placeControls() { return (this.form.get("places") as FormArray).controls }

    public set isSm(val: boolean) {
        if (this._isSm !== val) {
            this._isSm = val
        }
    }
    public get isSm(): boolean { return this._isSm }
    private _isSm: boolean

    private _inFormFilling = false


    public constructor(
        @Inject(LayerRef) protected readonly layerRef: LayerRef,
        @Inject(ToastService) protected readonly toast: ToastService,
        @Inject(CurrentSection) public readonly section: CurrentSection,
        @Inject(WorkerBackendSource) public readonly userSource: WorkerBackendSource,
        @Inject(DutyBackend) protected readonly dutyBackend: DutyBackend,
        @Inject(ProviderDutyBackendSource) public readonly tplSource: ProviderDutyBackendSource,
        @Inject(ProviderVehicleBackendSource) public readonly vehicleSrc: ProviderVehicleBackendSource,
        @Inject(SectionBackendSource) public readonly sectionSrc: SectionBackendSource,
        @Inject(ProviderServices) @Optional() protected readonly providerServices: ProviderServices,
        @Inject(MediaQueryService) private readonly mq: MediaQueryService,
        @Inject(PROVIDER_ID) @Optional() public providerId: number,
        @Inject(DUTY_DATE) @Optional() public date: Date,
        @Inject(DUTY_VALUE) @Optional() public duty: Duty) {

        const usersGroup = this.form.get("users") as FormArray

        this.destruct.subscription(usersGroup.valueChanges).subscribe(users => {
            if (users.length === 0 || this._inFormFilling) {
                return
            }

            if (users[users.length - 1].id) {
                usersGroup.push(new FormGroup({
                    id: new FormControl(),
                    section_id: new FormControl({ value: section.id, disabled: true })
                }))
            }

            for (let i = 0, l = users.length; i < l; i++) {
                const sectionIdCtrl = usersGroup.at(i).get("section_id")
                if (users[i].id) {
                    if (sectionIdCtrl.disabled) {
                        sectionIdCtrl.enable()
                    }
                } else {
                    if (sectionIdCtrl.enabled) {
                        sectionIdCtrl.disable()
                    }
                }
            }
        })

        const places = this.form.get("places") as FormArray
        this.destruct.subscription(places.valueChanges).subscribe((values: any[]) => {
            if (places.length === 0 || this._inFormFilling) {
                return
            }

            const hasEmpty = values.some(v => !v.place)
            if (!hasEmpty) {
                places.push(this.createPlaceGroup())
            } else {
                const remove = values.map((v, i) => { return { v, i } }).filter(v => !v.v.place).map(v => v.i)
                remove.pop()
                for (const idx of remove.reverse()) {
                    places.removeAt(idx)
                }
            }
        })

        if (duty) {
            this._fillFromDuty(duty)
        }

        if (!this.providerId) {
            this.destruct.subscription(providerServices.all).subscribe(providers => {
                if (providers.length === 1) {
                    this.providerId = providers[0].id
                    this.form.get("providerId").setValue(this.providerId)
                }
            })
        } else {
            this.form.get("providerId").setValue(this.providerId)
        }

        this.destruct.subscription(mq.watch("lt-md")).subscribe(v => {
            this.isSm = v.matches
        })
    }

    public save() {
        let values = this.form.value
        this.dutyBackend.save_duty({
            tpl_id: values.tpl,
            users: values.users,
            vehicle_ids: values.vehicles,
            places: (values.places as any[]).filter(value => value.place),
            date: startOfDay(values.date),
            id: this.duty ? this.duty.id : null
        })
            .pipe(take(1), this.toast.handleSave({ align: "bottom center", successMsg: "Műszak mentése sikeres" }))
            .subscribe((duty: Duty) => {
                this.layerRef.emit(new LayerEvent("duty-created", duty))
                this.layerRef.close()
            })
    }

    public removeUser(index: number): void {
        const usersGroup = this.form.get("users") as FormArray
        usersGroup.removeAt(index)
    }

    public cancel() {
        this.layerRef.close()
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    private _fillFromDuty(duty: Duty) {
        this._inFormFilling = true
        this.providerId = duty.provider_id
        this.form.reset({
            providerId: duty.provider_id,
            date: duty.begin,
            tpl: duty.provider_duty_id,
            vehicles: duty.vehicles,
        })

        const usersGroup = this.form.get("users") as FormArray
        usersGroup.removeAt(0)

        for (const user of duty.workers) {
            usersGroup.push(new FormGroup({
                id: new FormControl(user.user_id),
                section_id: new FormControl(user.section_id)
            }))
        }

        usersGroup.push(new FormGroup({
            id: new FormControl(),
            section_id: new FormControl({ value: this.section.id, disabled: true })
        }))

        const places = this.form.get("places") as FormArray
        places.clear()
        if (duty.places) {
            for (const place of duty.places) {
                places.push(this.createPlaceGroup(place))
            }
        }
        places.push(this.createPlaceGroup())


        this._inFormFilling = false
    }

    public addPlace() {
        const control = (this.form.get("places") as FormArray)
        control.push(this.createPlaceGroup())
    }

    public removePlace(index: number) {
        const control = (this.form.get("places") as FormArray)
        control.removeAt(index)
    }

    private createPlaceGroup(place?: Place) {
        return new FormGroup({
            place: new FormControl(place)
        })
    }
}
