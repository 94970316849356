import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzModule } from "@anzar/core"

import { PyzarApi } from "@backend/pyzar.api"
import { FsModule } from "../fs.module"

import { ImageCropDialogComponent } from "./image-crop/image-crop-dialog.component"
import { ImageCropComponent, CropEvent } from "./image-crop/image-crop.component"
import { ImageCropService } from "./image-crop/image-crop.service"
export { CropEvent, ImageCropService }

import { ImageTipDirective } from "./image-tip/image-tip.directive"
import { ImageTipComponent } from "./image-tip/image-tip.component"

import { ProfileImageInputComponent } from "./profile-image-input/profile-image-input.component"

@NgModule({
    imports: [
        CommonModule,
        NzModule,
        PyzarApi,
        FsModule
    ],
    declarations: [
        ImageCropDialogComponent,
        ImageCropComponent,
        ProfileImageInputComponent,

        ImageTipDirective,
        ImageTipComponent
    ],
    exports: [
        ImageCropDialogComponent,
        ImageCropComponent,
        ProfileImageInputComponent,

        ImageTipDirective,
        ImageTipComponent
    ],
    providers: [
        ImageCropService
    ],
    entryComponents: [
        ImageCropDialogComponent,
        ImageTipComponent
    ]
})
export class ImageModule {

}
