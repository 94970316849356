import { Inject, Injectable } from "@angular/core"
import { of } from "rxjs"
import { switchMap, shareReplay } from "rxjs/operators"


import { LevelBackend } from "@backend/org.api"
import { SectionSheetService } from "./section-sheet.service"
import { AbstractCLEditorService } from "@pyzar/common.module"


@Injectable()
export class SectionEditorService extends AbstractCLEditorService {
    public readonly section$ = this.sheet.section$

    public readonly parents$ = this.sheet.sectionId$.pipe(
        switchMap(sectionId => {
            if (sectionId) {
                return this.levelBackend.get_parents({ id: sectionId })
            } else {
                return of([])
            }
        }),
        shareReplay(1)
    )

    public constructor(
        @Inject(LevelBackend) private readonly levelBackend: LevelBackend,
        @Inject(SectionSheetService) private readonly sheet: SectionSheetService) {
        super()
    }
}
