import { Pipe, PipeTransform } from "@angular/core"

import { FullName } from "@backend/__anzar_rpc_output"

@Pipe({ name: "formatName" })
export class FormatNamePipe implements PipeTransform {
    transform(value: FullName) {
        const result = this._format(value)
        return result && result.length > 0 ? result : "[NINCS MEGADVA]"
    }

    _format(value: FullName) {
        if (!value) {
            return ""
        }

        if (value.formatted) {
            return value.formatted
        }

        return [value.title, value.family, value.given].filter(isNotEmpty).join(" ")
    }
}

function isNotEmpty(v: any) {
    return v && v.length > 0
}
