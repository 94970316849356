import { InjectionToken, NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzModule } from "@anzar/core"

import { FsModule } from "../fs.module"

import { ImageService } from "./image.service"
export { ImageService }

export { RpcErrorHandler } from "./rpc-interceptor"


import { ValidateEqualDirective, validateEqual } from "./validate-equal.directive"
export { validateEqual }

import { CardListComponent, CardListItemDirective, CardListSection } from "./components/card-list/card-list.component"
export { AbstractCLEditor, AbstractCLEditorBlock, AbstractCLEditorService } from "./components/card-list/abstract"

import { StatusTextComponent } from "./components/status-text.component"

import { ExpandableRowComponent } from "./components/expandable-row.component"

import { NameInputComponent } from "./components/name-input.component"

import { AvatarComponent, UserAvatar } from "./components/avatar/avatar.component"

import { RenderJsonComponent, RenderJsonService, RenderJsonWindowComponent } from "./render-json.component"
export { RenderJsonService }

import { NotFoundPageComponent } from "./not-found-page.component"
export { NotFoundPageComponent }


export const BACKEND_BASE_URL = new InjectionToken("BACKEND_BASE_URL")


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        FsModule
    ],
    declarations: [
        ValidateEqualDirective,
        StatusTextComponent,
        ExpandableRowComponent,
        NameInputComponent,
        RenderJsonComponent,
        RenderJsonWindowComponent,
        CardListComponent,
        CardListItemDirective,
        CardListSection,
        AvatarComponent,
        UserAvatar,
        NotFoundPageComponent,
    ],
    exports: [
        ValidateEqualDirective,
        StatusTextComponent,
        ExpandableRowComponent,
        NameInputComponent,
        RenderJsonComponent,
        RenderJsonWindowComponent,
        CardListComponent,
        CardListItemDirective,
        CardListSection,
        AvatarComponent,
        UserAvatar,
        NotFoundPageComponent,
    ],
    providers: [
        ImageService,
        RenderJsonService,
    ],
    entryComponents: [
        RenderJsonWindowComponent
    ]
})
export class PyzarCommonModule {

}
