import { Location } from "@angular/common"
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"

import { take } from "rxjs/operators"

import { Destructible, ToastService } from "@anzar/core"

import { AbstractCLEditor, AbstractCLEditorService } from "@pyzar/common.module"

import { Maintainer } from "@backend/org.api"

import { OrgService } from "../org.service"
import { MaintainerEditorService } from "./maintainer-editor.service"

@Component({
    templateUrl: "./maintainer.screen.pug",
    providers: [{ provide: AbstractCLEditorService, useClass: MaintainerEditorService }]
})
export class MaintainerScreen extends Destructible implements OnInit, AfterViewInit {
    @ViewChild("editor", { static: true }) public readonly editor: AbstractCLEditor
    public readonly buttonsBarVisible: boolean = true
    public readonly canSaveEditor: boolean = true
    public readonly maintainer: Maintainer

    public constructor(
        @Inject(AbstractCLEditorService) private readonly service: MaintainerEditorService,
        @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(Location) private readonly location: Location,
        @Inject(OrgService) private readonly orgService: OrgService,
        @Inject(ToastService) private readonly toast: ToastService
    ) {
        super()
    }

    public ngAfterViewInit() {
        this.destruct.subscription(this.editor.status$).subscribe(_ => {
            this.cdr.detectChanges()
        })
    }

    public ngOnInit() {
        this.destruct.subscription(this.service.maintainer$).subscribe(maintainer => {
            ;(this as { maintainer: Maintainer }).maintainer = maintainer as any
            this.cdr.detectChanges()
        })

        this.destruct.subscription(this.route.params).subscribe(params => {
            this.service.id = Number(params["id"])
        })
    }

    public save() {
        this.editor
            .save()
            .pipe(
                take(1),
                this.toast.handleSave({ align: "bottom center", successMsg: "A mentés sikeresen megtörtént" })
            )
            .subscribe(() => {
                this.location.replaceState(this.orgService.getEditorUrlFor("maintainer", this.service.id))
            })
    }
}
