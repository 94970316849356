import { Component, Inject } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { map, shareReplay, distinctUntilChanged } from "rxjs/operators"

import { MediaQueryService } from "@anzar/core"

import { WorkerMsgBackendSource } from "@backend/worker.api"
import { CurrentHeadquerter } from "../section.service/level"
import { MessageService } from "./message.service"
import { AuthService } from "@pyzar/auth.module"


@Component({
    templateUrl: "./message-board.screen.pug"
})
export class MessageBoardScreen {
    public readonly isSm$ = this.mq.watch("lt-md").pipe(map(v => v.matches), shareReplay(1))

    private readonly _searchText = new BehaviorSubject<string>(null)
    public readonly searchText$ = this._searchText.pipe(distinctUntilChanged(), shareReplay())

    public readonly canCreateMessage$ = this.auth.hasPermission("messages.create")

    public constructor(
        @Inject(WorkerMsgBackendSource) public readonly wmSource: WorkerMsgBackendSource,
        @Inject(CurrentHeadquerter) public readonly hq: CurrentHeadquerter,
        @Inject(MessageService) public readonly messageSvc: MessageService,
        @Inject(AuthService) public readonly auth: AuthService,
        @Inject(MediaQueryService) private readonly mq: MediaQueryService) {
    }

    public doSearch(text: string | null) {
        this._searchText.next(text)
    }
}
