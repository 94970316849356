import { Component, InjectionToken, Inject } from "@angular/core"
import { subDays } from "date-fns"

import { LayerRef, ToastService, LayerEvent } from "@anzar/core"
import { Section } from "@backend/org.api"
import { BNOReservationBackend, BNOReservation } from "@backend/service_bno.api"
import { BNOEntry } from "./bno.component"
import { take } from 'rxjs/operators'


export const OVERLAPPING_RESERVATIONS = new InjectionToken("OVERLAPPING_RESERVATIONS")
export const RESERVING_ENTRY = new InjectionToken("RESERVING_ENTRY")
export const RESERVING_CLIENT_ID = new InjectionToken("RESERVING_CLIENT")


@Component({
    selector: "rege-bno-conflict-dialog",
    templateUrl: "./conflict-dialog.component.pug"
})
export class BNOConflictFialogComponent {
    public constructor(
        @Inject(OVERLAPPING_RESERVATIONS) public readonly overlapping: Array<[Section, BNOReservation]>,
        @Inject(RESERVING_ENTRY) protected readonly entry: BNOEntry,
        @Inject(RESERVING_CLIENT_ID) protected readonly clientId: number,
        @Inject(LayerRef) protected readonly layerRef: LayerRef,
        @Inject(BNOReservationBackend) protected readonly resvBackend: BNOReservationBackend,
        @Inject(ToastService) protected readonly toastSvc: ToastService) {
    }

    public close() {
        this.layerRef.hide()
    }

    public resolve() {
        this.resvBackend
            .close_resv({
                bno_reservation_id: this.overlapping[0][1].id,
                client_id: this.clientId,
                end: subDays(new Date(), 1)
            })
            .pipe(
                take(1),
                this.toastSvc.handleSave({ align: "bottom center" })
            )
            .subscribe(res => {
                if (res) {
                    this.layerRef.emit(new LayerEvent("resolved"))
                }
                this.close()
            })
    }
}
