import { NgModule } from "@angular/core"
import { BNO, BNOReservation, BNOBackend, BNOReservationBackend, BNOBackendSource, BNOReservationBackendSource } from "./__anzar_rpc_output"

export { BNO, BNOReservation, BNOBackend, BNOReservationBackend, BNOBackendSource, BNOReservationBackendSource }


@NgModule({
    providers: [
        BNOBackendSource,
        BNOReservationBackendSource,
        BNOBackend,
        BNOReservationBackend
    ],
})
export class Service_bnoApi {}