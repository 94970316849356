import { Component, Inject, ViewChild, ChangeDetectorRef, Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { filter, mapTo, switchMap, take, tap } from "rxjs/operators"

import { LayerService, ModalLayer, StaticSource, CombinedSource, ExlistSwitchHandler, DialogEvent, Destructible, ToastService } from "@anzar/core"
import { Story, StoryBackendSource, StoryEntry, StoryEntryBackend } from "@backend/dispatcher.api"
import { Client } from "@backend/client.api"
import { StoriesGridComponent } from "./stories-grid.component"
import { StoriesService } from "./stories.service"
import { SwitchConfirmComponent, CAN_COPY } from "./switch-confirm.component"
import { ClientService } from "../client.module"


@Injectable()
export class SwitchHandler extends ExlistSwitchHandler<Story> {
    public constructor(
        @Inject(StoriesService) private readonly stories: StoriesService,
        @Inject(LayerService) private readonly layerSvc: LayerService) {
        super()
    }

    public canSwitch(from: Story | null, to: Story | null) {
        return this._canSwitch(from, to).pipe(tap(res => {
            if (res) {
                if (!to) {
                    this.stories.currentForm = null
                    this.stories.currentModel = null
                    this.stories.resetFormInit()
                }
            }
        }))
    }

    private _canSwitch(from: Story | null, to: Story | null) {
        if (from) {
            const cform = this.stories.currentForm
            if (cform && (cform.form.dirty || !cform.storyId)) {
                return this._question(from, to, !cform.storyEntryId && !!to)
            }
        }
        return of(true)
    }

    private _question(from: Story | null, to: Story | null, canCopy: boolean) {
        const behavior = new ModalLayer({
            backdrop: { type: "filled", hideOnClick: false },
            closeable: false,
            elevation: 10,
            rounded: 3
        })
        const ref = this.layerSvc.createFromComponent(SwitchConfirmComponent, behavior, null, [
            { provide: CAN_COPY, useValue: canCopy }
        ])
        ref.show()

        return ref.output.pipe(
            filter(event => event.type === "button"),
            switchMap((event: DialogEvent) => this._handleDecision(event.button, from, to))
        )
    }

    private _handleDecision(btn: string, from: Story | null, to: Story | null) {
        switch (btn) {
            case "cancel":
                return of(false)

            case "drop":
                this.stories.resetFormInit()
                if (from && !from.id) {
                    this.stories.removeUnsaved()
                }
                return of(true)

            case "copy":
                this.stories.copyCurrentForm()
                if (from && !from.id) {
                    this.stories.removeUnsaved()
                }
                return of(true)

            case "save":
                return this.stories.currentForm.doSave().pipe(mapTo(true))
        }
        return of(false)
    }
}


@Component({
    templateUrl: "./stories.screen.pug",
    providers: [
        StoriesService,
        { provide: ExlistSwitchHandler, useClass: SwitchHandler }
    ]
})
export class StoriesScreen extends Destructible {
    @ViewChild("grid", { static: true }) public readonly grid: StoriesGridComponent

    public readonly moveEntries: StoryEntry[] = []

    public constructor(
        @Inject(StoriesService) public readonly storiesSvc: StoriesService,
        @Inject(ExlistSwitchHandler) private readonly switchHandler: SwitchHandler,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(StoryEntryBackend) private readonly entryBackend: StoryEntryBackend,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(ClientService) private readonly clientSvc: ClientService) {
        super()

        this.destruct.subscription(storiesSvc.open$).subscribe(model => {
            this.grid.exlist.setOpened(model, "program")
        })

        this.destruct.subscription(storiesSvc.reload$).subscribe(model => {
            if (model) {
                const storage = this.grid.source.storage
                storage.reloadItem(model).pipe(take(1)).subscribe(res => {
                    if (!res) {
                        this.grid.source.reload()
                    }
                })
            } else {
                this.grid.source.reload()
            }
        })

        this.destruct.subscription(storiesSvc.move$).subscribe(entry => {
            this.startMoveEntry(entry)
        })
    }

    public newStory() {
        this.grid.exlist.scroller.service.scrollTo({ top: 0, left: 0 }, { smooth: false })
        if (!this.storiesSvc.hasNewStory()) {
            this.switchHandler.canSwitch(this.grid.exlist.opened.get()[0] as any, null)
                .pipe(take(1))
                .subscribe(result => {
                    if (result) {
                        this.storiesSvc.newStory({
                            createMode: "incoming",
                            data: this.storiesSvc.initFormWith
                        })
                    }
                })
        }
    }

    public startMoveEntry(entry: StoryEntry) {
        let idx = this.moveEntries.findIndex(v => v.pk === entry.pk)
        if (idx === -1) {
            this.moveEntries.push(entry)
            this.cdr.detectChanges()
        }
    }

    public cancelMove(entry: StoryEntry) {
        let idx = this.moveEntries.findIndex(v => v.pk === entry.pk)
        if (idx !== -1) {
            this.moveEntries.splice(idx, 1)
            this.cdr.detectChanges()
        }
    }

    public moveEntry(entry: StoryEntry, idx: string) {
        this.entryBackend.move({ story_id: idx, entry_id: entry.id })
            .pipe(take(1), this.toast.handleSave({ align: "bottom center" }))
            .subscribe(res => {
                if (res) {
                    this.cancelMove(entry)
                    this.storiesSvc.reloadStory(new Story({ id: res.old_story_id }))
                    this.storiesSvc.reloadStory(new Story({ id: res.new_story_id }))
                }
            })
    }

    public openClientSheet(client: Client) {
        this.clientSvc.showSheet(client.id).subscribe()
    }
}
