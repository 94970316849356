import { OnDestroy, OnInit, Input, Directive, Injectable } from "@angular/core"
import { FormGroup } from "@angular/forms"
import { Observable, merge, BehaviorSubject, of, zip, forkJoin, tap } from "rxjs"
import { startWith, debounceTime, shareReplay, map, switchMap, take, distinctUntilChanged } from "rxjs/operators"

import { Destruct } from "@anzar/core"


@Injectable()
export abstract class AbstractCLEditorService implements OnDestroy {
    public readonly destruct = new Destruct()

    public readonly blocks$ = new BehaviorSubject<AbstractCLEditorBlock[]>([])

    public readonly status$ = this._mergedBlocks$("status$").pipe(
        // tap(v => console.log("status$", v)),
        shareReplay(1)
    )

    public readonly isValid$ = this._mergedBlocks$("isValid$").pipe(
        // tap(v => console.log("isValid$", v)),
        map(values => !values.includes(false)),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public readonly isInvalid$ = this._mergedBlocks$("isInvalid$").pipe(
        // tap(v => console.log("isInvalid$", v)),
        map(values => values.includes(true)),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public readonly isDirty$ = this._mergedBlocks$("isDirty$").pipe(
        // tap(v => console.log("isDirty$", v)),
        map(values => values.includes(true)),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public saveBlocks(): Observable<any[]> {
        return this.blocks$.pipe(
            take(1),
            switchMap(blocks => {
                return forkJoin(blocks.map(b => b.save().pipe(take(1))))
            })
        )
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    public registerBlock(block: AbstractCLEditorBlock) {
        // TODO: esetleg fontos a sorrend
        const current = this.blocks$.value.slice(0)
        if (!current.includes(block)) {
            current.push(block)
        }
        this.blocks$.next(current)
    }

    public unregisterBlock(block: AbstractCLEditorBlock) {
        let current = this.blocks$.value.slice(0)
        const idx = current.indexOf(block)
        if (idx !== -1) {
            current.splice(idx, 1)
        }
        this.blocks$.next(current)
    }

    private _mergedBlocks$(property: "isDirty$" | "isValid$" | "isInvalid$" | "status$") {
        return this.blocks$.pipe(
            switchMap(blocks => {
                if (blocks.length !== 0) {
                    const src = blocks.map(b => b[property])
                    return merge(...src).pipe(
                        debounceTime(50),
                        switchMap(() => {
                            const zsrc = src.map(v => (v as any).pipe(take(1)))
                            return zip(...zsrc)
                        })
                    )
                } else {
                    return of([])

                }
            })
        )
    }
}


@Directive()
export abstract class AbstractCLEditorBlock<T extends AbstractCLEditorService = AbstractCLEditorService> implements OnDestroy, OnInit {
    @Input() public readonly index: number

    public readonly destruct = new Destruct()
    public readonly status$: Observable<string> = this.form.statusChanges.pipe(
        startWith(null),
        shareReplay(1)
    )

    public readonly isDirty$ = this.status$.pipe(
        startWith(null),
        map(() => this.form.dirty),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public readonly isValid$ = this.status$.pipe(
        startWith(null),
        map(() => this.form.valid),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public readonly isInvalid$ = this.status$.pipe(
        startWith(null),
        map(() => this.form.invalid),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public constructor(
        public readonly editorSvc: T,
        public readonly form: FormGroup) {
    }

    public ngOnInit() {
        this.editorSvc.registerBlock(this)
    }

    public ngOnDestroy() {
        this.editorSvc.unregisterBlock(this)
        this.destruct.run()
    }

    public abstract save(): Observable<any>
}


@Directive()
export abstract class AbstractCLEditor<T extends AbstractCLEditorService = AbstractCLEditorService> implements OnDestroy {
    public readonly destruct = new Destruct()

    @Input() public readonly index: number

    public readonly status$ = this.editorSvc.status$
    public readonly isDirty$ = this.editorSvc.isDirty$
    public readonly isValid$ = this.editorSvc.isValid$
    public readonly isInvalid$ = this.editorSvc.isInvalid$

    public constructor(public readonly editorSvc: T) {
    }

    public save() {
        return this.editorSvc.saveBlocks()
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}
