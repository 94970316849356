import { Injectable, Inject } from "@angular/core"
import { Observable, Observer, Subject } from "rxjs"
import { take, filter } from "rxjs/operators"

import { ModalLayer, LayerService, Rect, ImageRef, ImageRefSource, ToastService, ProgressEvent } from "@anzar/core"
import { CropRegion } from "./image-crop.component"

import { ImageCropDialogComponent, INITIAL_CROP } from "./image-crop-dialog.component"


@Injectable()
export class ImageCropService {
    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(ToastService) private readonly toastSvc: ToastService) {

    }

    public crop(img: ImageRefSource, crop?: CropRegion): Observable<CropRegion | null> {
        return Observable.create((observer: Observer<CropRegion | null>) => {
            const progress = new Subject<ProgressEvent>()
            this.toastSvc.progress({ align: "bottom center", progress: progress, autohide: null })
            progress.next({ message: "Kép betöltése" })

            const vp = Rect.viewport()
            const source = new ImageRef()
            source.scale("resize", Math.min(1000, vp.width * 0.9), vp.height * 0.9 - 52, 0.1, 1)

            const sub = source.scaled$.pipe(filter(v => !!v), take(1)).subscribe(value => {
                const wndWidth = value.info.dstWidth
                const wndHeight = value.info.dstHeight + 52
                const behavior = new ModalLayer({
                    backdrop: { type: "filled", hideOnClick: false },
                    closeable: false,
                    rounded: 3,
                    elevation: 10,
                    minHeight: wndHeight,
                    maxHeight: wndHeight,
                    minWidth: wndWidth,
                    maxWidth: wndWidth,
                    position: {
                        constraint: {
                            ref: "viewport",
                            inset: 0
                        }
                    }
                })

                progress.complete()

                const layer = this.layerSvc.createFromComponent(ImageCropDialogComponent, behavior, null, [
                    { provide: ImageRef, useValue: source },
                    { provide: INITIAL_CROP, useValue: crop }
                ])
                layer.show()

                layer.subscribe(event => {
                    if (event.type === "crop") {
                        observer.next(event.data)
                        observer.complete()
                    } else if (event.type === "hiding") {
                        observer.next(null)
                        observer.complete()
                    }
                })

                return () => {
                    sub.unsubscribe()
                    layer.hide()
                    source.load(null)
                    progress.complete()
                }
            })

            source.load(img)
        })
    }
}
