import { Injectable, Inject } from "@angular/core"
import { Subject } from "rxjs"
import { take, switchMap, map } from "rxjs/operators"

import { LayerService, ModalLayer, MediaQueryService, FullscreenLayer } from "@anzar/core"
import { NewMailComponent, PROVIDER_SERVICE_ID, MAIL_FORM_DATA, IS_SMALL_SCREEN, SECTION_ID } from "./new-mail.component"
import { MailingBackend } from "@backend/service_mailing.api"
import { CurrentSection } from "../../../section.service/level"


@Injectable({ providedIn: "root" })
export class MailingService {
    public readonly reload$ = new Subject<void>()

    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(MailingBackend) private readonly mailingBackend: MailingBackend,
        @Inject(MediaQueryService) private readonly mq: MediaQueryService,
        @Inject(CurrentSection) private readonly section: CurrentSection) {
    }

    public newMailEntry(providerServiceId: any) {
        this._showMailEntryWnd(providerServiceId, null)
    }

    public editMailEntry(entryId: number) {
        this.mailingBackend.get({ id: entryId })
            .subscribe(entry => {
                this._showMailEntryWnd(entry.provider_service_id, {
                    id: entry.id,
                    type: entry.type,
                    client_id: entry.client_id,
                    note: entry.note,
                    arrived_date: entry.arrived_date,
                    deadline_date: entry.deadline_date,
                })
            })
    }

    private _showMailEntryWnd(providerServiceId: number, data: any) {
        this.mq.watch("lt-sm")
            .pipe(
                take(1),
                switchMap(mq => {
                    return this.section.id$.pipe(take(1), map(sectionId => [mq, sectionId]))
                })
            )
            .subscribe(([mq, sectionId]) => {
                const behavior = mq.matches
                    ? new FullscreenLayer({})
                    : new ModalLayer({
                        backdrop: { type: "filled", hideOnClick: false },
                        elevation: 10,
                        rounded: 3
                    })

                const layer = this.layerSvc.createFromComponent(NewMailComponent, behavior, null, [
                    { provide: PROVIDER_SERVICE_ID, useValue: providerServiceId },
                    { provide: MAIL_FORM_DATA, useValue: data },
                    { provide: IS_SMALL_SCREEN, useValue: mq.matches },
                    { provide: SECTION_ID, useValue: sectionId },
                ])
                layer.show()
                layer.subscribe(event => {
                    if (event.type === "success") {
                        this.reload$.next()
                    }
                })
            })
    }
}
