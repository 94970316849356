import { Component, Input, HostListener, Inject } from "@angular/core"

import { Place } from "@backend/place.api"
import { PlaceInfoService } from "../../place.module"


@Component({
    selector: "rege-place-link",
    templateUrl: "./place-link.component.pug"
})
export class PlaceLinkComponent {
    @Input() public place: Place

    public constructor(@Inject(PlaceInfoService) private readonly placeInfo: PlaceInfoService) {

    }

    @HostListener("tap", ["$event"])
    public onTap(event: Event) {
        event.preventDefault()
        this.placeInfo.show(this.place.id)
    }
}
