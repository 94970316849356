import { Component, Inject, InjectionToken } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"

import { LayerRef } from "@anzar/core"
import { LevelBackendSource, LevelVariant } from "@backend/org.api"
import { ProviderType } from "@backend/enums.api"

const VARIANT_TITLE: { [key: string]: string } = {}

for (const v of LevelVariant.DATA.data) {
    VARIANT_TITLE[v.id] = v.label
}


export const AUTO_FILL_DATA = new InjectionToken("AUTO_FILL_DATA")


@Component({
    selector: "rege-stat-auto-fill",
    templateUrl: "./auto-fill.component.pug"
})
export class AutoFillComponent {
    public readonly form = new FormGroup({
        level_id: new FormControl(),
        provider_types: new FormControl()
    })

    public readonly variantTitle = VARIANT_TITLE
    public readonly providerTypeSrc = ProviderType.DATA

    public constructor(
        @Inject(LevelBackendSource) public readonly levelSrc: LevelBackendSource,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(AUTO_FILL_DATA) private readonly initialData: any) {
        this.form.reset(initialData || {})
    }

    public close() {
        this.layerRef.hide()
    }

    public fill() {
        this.layerRef.emit({ type: "fill", data: this.form.value })
        this.layerRef.hide()
    }
}
