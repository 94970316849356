import { Component, Inject, ChangeDetectorRef } from "@angular/core"


import { FileDownloadService, ToastService } from "@anzar/core"
import { DocumentBackend, ClientBanBackend } from "@backend/documents.api"
import { LevelBackend } from "@backend/org.api"
import { FsService } from "@pyzar/fs.module"
import { DocumentItem } from "../abstract"
import { DocumentsService } from "../documents.service"

@Component({
    selector: ".rege-doc-item-upload",
    templateUrl: "./upload-item.component.pug"
})
export class UploadItemComponent extends DocumentItem {
    public constructor(
        @Inject(DocumentBackend) docBackend: DocumentBackend,
        @Inject(DocumentsService) docSvc: DocumentsService,
        @Inject(FsService) fsService: FsService,
        @Inject(ToastService) toast: ToastService,
        @Inject(FileDownloadService) downSvc: FileDownloadService,
        @Inject(ClientBanBackend) protected readonly clientBanBackend: ClientBanBackend,
        @Inject(LevelBackend) protected readonly levelBackend: LevelBackend,
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef) {
        super(docBackend, docSvc, fsService, toast, downSvc)
    }

    public remove() {
        this.documentBackend.remove({ id: this.document.id }).subscribe(this.reload.bind(this))
    }
}
