import { Component, Injectable, Inject } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { Observable } from "rxjs"

import { DropdownLayer, LayerRef, LayerService, ToastService } from "@anzar/core"
import { PermissionGroup, PermissionGroupRepo } from "@backend/pyzar.api"


@Component({
    selector: "pz-new-user-group",
    templateUrl: "./new-group-component.pug"
})
export class NewGroupComponent {
    public readonly form = new FormGroup({
        id: new FormControl(),
        label: new FormControl(),
    })

    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(PermissionGroupRepo) private readonly permGroupRepo: PermissionGroupRepo,
        @Inject(ToastService) private readonly toast: ToastService) {

    }

    public doCancel() {
        this.layerRef.hide()
    }

    public doSave() {
        this.permGroupRepo
            .save({ data: this.form.value })
            .pipe(this.toast.catchError())
            .subscribe(group => {
                this.layerRef.emit({ type: "created", data: group })
                this.layerRef.hide()
            })
    }
}


@Injectable()
export class NewGroupService {
    public constructor(@Inject(LayerService) private readonly layerSvc: LayerService) {

    }

    public show(target: HTMLElement): Observable<PermissionGroup> {
        return new Observable(observer => {
            const behavior = new DropdownLayer({
                backdrop: { type: "filled", hideOnClick: false },
                closeable: false,
                menuLike: true,
                elevation: 10,
                rounded: 3,
                position: {
                    align: "top center",
                    anchor: {
                        ref: target,
                        align: "bottom center"
                    }
                }
            })

            const ref = this.layerSvc.createFromComponent(NewGroupComponent, behavior)
            ref.show()

            ref.subscribe(event => {
                if (event.type === "hiding") {
                    observer.complete()
                } else if (event.type === "created") {
                    observer.next(event.data)
                    observer.complete()
                }
            })

            return () => {
                ref?.hide()
            }
        })
    }
}
