import { Component, forwardRef, Inject, ChangeDetectorRef, Injectable } from "@angular/core"
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms"
import { Observable, forkJoin, of } from "rxjs"
import { switchMap, map, tap, take, filter } from "rxjs/operators"

import { WorkerRole } from "@backend/enums.api"
import { LevelBackendSource, LevelWorker, LevelWorkerBackend } from "@backend/org.api"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { UserEditorService, UserBlockFactory, CustomUserBlock, AuthService } from "@pyzar/auth.module"


@Component({
    selector: ".rege-worker-levels",
    templateUrl: "./levels.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => WorkerLevelsComponent) }
    ]
})
export class WorkerLevelsComponent extends AbstractCLEditorBlock<UserEditorService> {
    public get levelControls() { return (this.form.get("levels") as FormArray).controls }

    public readonly roleSrc = WorkerRole.DATA

    private _disableChange = false

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: UserEditorService,
        @Inject(LevelWorkerBackend) private readonly levelWorkerBackend: LevelWorkerBackend,
        @Inject(LevelBackendSource) public readonly levelSrc: LevelBackendSource,
        @Inject(AuthService) public readonly authSvc: AuthService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super(editorSvc, new FormGroup({
            levels: new FormArray([])
        }))
        this._reload()
    }

    public ngOnInit() {
        super.ngOnInit()

        const ctrl = this.form.get("levels") as FormArray
        this.destruct.subscription(ctrl.valueChanges).subscribe((values: any[]) => {
            if (this._disableChange) {
                return
            }
            this._disableChange = true

            let hasEmpty = false

            for (let i = 0, l = values.length; i < l; i++) {
                const value = values[i]
                const row = ctrl.at(i)
                if (value.section_id) {
                    row.get("role").setValidators([Validators.required])
                    row.get("role").updateValueAndValidity()
                } else {
                    row.get("role").clearValidators()
                    row.get("role").updateValueAndValidity()
                    hasEmpty = true
                }
            }

            if (!hasEmpty) {
                ctrl.push(this._levelRow())
            }

            this.form.markAsTouched()
            this.form.markAsPristine()
            this.form.markAsDirty()
            this.form.updateValueAndValidity()
            this._disableChange = false
        })
    }

    private _reload() {
        this.destruct.subscription(this.editorSvc.id$)
            .pipe(switchMap(userId => {
                return this.levelWorkerBackend.search({
                    filter: { user_id: userId }
                })
            }))
            .subscribe(levels => {
                this._setLevels(levels)
            })
    }

    public save(): Observable<any> {
        return this.editorSvc.id$.pipe(
            filter(id => !!id),
            switchMap(userId => {
                return this.levelWorkerBackend
                    .update_user({ user_id: userId, data: this.form.value.levels })
                    .pipe(
                        tap(_ => {
                            this._reload()
                        })
                    )
            })
        )
    }

    public deleteRow(index: number) {
        const ctrl = this.form.get("levels") as FormArray
        ctrl.removeAt(index)
        ctrl.markAsTouched()
        ctrl.markAsPristine()
        ctrl.markAsDirty()
        ctrl.updateValueAndValidity()
    }

    private _setLevels(levels: LevelWorker[]) {
        this._disableChange = true

        const levelIds = levels.map(level => level.level_id)
        const ctrl = this.form.get("levels") as FormArray

        if (levelIds.length === 0) {
            ctrl.push(this._levelRow())
            this._disableChange = false
            return
        }

        const hasAccess = levelIds
            .map(levelId =>
                this.authSvc
                    .hasPermission({ permission: "worker.assign", extra: { level_id: levelId } })
                    .pipe(take(1))
            )

        forkJoin(hasAccess)
            .pipe(
                map<boolean[], { [key: number]: boolean }>(access => {
                    console.log("AAA", access)
                    let result: { [key: number]: boolean } = {}

                    for (let i = 0, l = access.length; i < l; ++i) {
                        result[levelIds[i]] = access[i]
                    }

                    return result
                })
            )
            .subscribe(levelAccess => {
                ctrl.controls.length = 0
                for (const s of levels) {
                    ctrl.push(this._levelRow(s, !levelAccess[s.level_id]))
                }
                ctrl.push(this._levelRow())
                this._disableChange = false
                this.cdr.detectChanges()
            })
    }

    private _levelRow(sw?: LevelWorker, readonly = false) {
        return new FormGroup({
            readonly: new FormControl(readonly),
            level_id: new FormControl(sw ? sw.level_id : null),
            role: new FormControl(sw ? sw.role : null),
        })
    }
}



// export class WorkerLevelsFactory extends CustomUserBlock {
//     public readonly title = "Munkahelyek"
//     public readonly icon = "rege-worker-levels-icon"
//     public readonly visible = this.authSvc.hasPermission("worker.assign").pipe(map(v => v))
//     public readonly component = WorkerLevelsComponent

//     public constructor(@Inject(AuthService) private readonly authSvc: AuthService) {
//         super()
//     }
// }

@Injectable()
export class WorkerLevelsFactory extends UserBlockFactory {
    public constructor(@Inject(AuthService) private readonly authSvc: AuthService) {
        super()
    }

    public create(userId: number): CustomUserBlock {
        return {
            title: "Munkahelyek",
            icon: "rege-worker-levels-icon",
            component: WorkerLevelsComponent,
            visible: this.authSvc.currentUser$.pipe(
                switchMap(user => {
                    if (user && userId && user.id === userId) {
                        return of(false)
                    } else {
                        return this.authSvc.hasPermission("worker.assign")
                    }
                })
            )
        }
    }
}
