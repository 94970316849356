import { Component, Input, forwardRef, Inject, ChangeDetectorRef } from "@angular/core"

import { Headquarter } from "@backend/org.api"
import { AbstractCLEditor, AbstractCLEditorService } from "@pyzar/common.module"
import { AuthService } from "@pyzar/auth.module"
import { HeadquarterEditorService } from "./headquarter-editor.service"


@Component({
    selector: ".rege-headquarter-editor",
    templateUrl: "./headquarter-editor.component.pug",
    providers: [
        { provide: AbstractCLEditor, useExisting: forwardRef(() => HeadquarterEditorComponent) }
    ]
})
export class HeadquarterEditorComponent extends AbstractCLEditor {
    @Input() public headquarter: Headquarter

    public constructor(
        @Inject(AbstractCLEditorService) service: HeadquarterEditorService,
        @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
        @Inject(AuthService) public readonly auth: AuthService) {
        super(service)

        this.destruct.subscription(service.headquarter$).subscribe(headquarter => {
            this.headquarter = headquarter
            this.cdr.detectChanges()
        })
    }
}
