import { Component, Inject, ElementRef, HostBinding, Input, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { coerceBooleanProperty } from "@angular/cdk/coercion"
import { NEVER } from "rxjs"
import { catchError, take, filter } from "rxjs/operators"

import { NzRange, NzRangeList, InputComponent, INPUT_MODEL, InputModel } from "@anzar/core"
import { Place, PlaceBackend } from "@backend/place.api"
import { PlaceAutocompleteSrc, PlaceAutocomplete } from "./place-datasource"
import { CurrentLocation } from "./current-location.service"
import { MapService } from "./map.service"


@Component({
    selector: ".rege-place-input",
    templateUrl: "./place-input.component.pug",
    providers: INPUT_MODEL
})
export class PlaceInputComponent extends InputComponent<Place> implements OnInit {
    @HostBinding("attr.tabindex")
    public readonly tabIndexAttr = -1

    @Input()
    public set showTrigger(val: boolean) {
        val = coerceBooleanProperty(val)
        if (this._showTrigger !== val) {
            this._showTrigger = val
        }
    }
    public get showTrigger(): boolean { return this._showTrigger }
    public _showTrigger: boolean = false

    @Input()
    public set prefillCurrent(val: boolean) {
        val = coerceBooleanProperty(val)
        if (this._prefillCurrent !== val) {
            this._prefillCurrent = val
        }
    }
    public get prefillCurrent(): boolean { return this._prefillCurrent }
    private _prefillCurrent: boolean

    public readonly selectCtrl = new FormControl()

    public _near: { lng: number, lat: number } = null

    public constructor(
        @Inject(InputModel) public readonly model: InputModel<Place>,
        @Inject(ElementRef) public readonly el: ElementRef,
        @Inject(PlaceAutocompleteSrc) public readonly placeSrc: PlaceAutocompleteSrc,
        @Inject(CurrentLocation) private readonly currentLoc: CurrentLocation,
        @Inject(MapService) private readonly mapSvc: MapService,
        @Inject(PlaceBackend) private readonly placeBackend: PlaceBackend) {
        super(model)
        this._regenSession()
        this.monitorFocus(el.nativeElement)

        this.destruct.subscription(this.selectCtrl.valueChanges).subscribe(value => {
            this.model.emitValue(value)
        })
    }

    public ngOnInit() {
        super.ngOnInit()

        this.destruct.subscription(this.currentLoc.coords$)
            .pipe(
                catchError(err => {
                    this._near = null
                    return NEVER
                }),
                take(1)
            )
            .subscribe(loc => {
                this._near = {
                    lng: loc.longitude,
                    lat: loc.latitude
                }

                if (this._prefillCurrent && !this.selectCtrl.value) {
                    this.placeBackend
                        .decode_coords({ lat: this._near.lat, lng: this._near.lng })
                        .pipe(take(1))
                        .subscribe(res => {
                            if (res) {
                                this.selectCtrl.setValue(res)
                            }
                        })
                }
            })
    }

    public onSelect(selected: any[]) {
        if (selected.length === 0 || !selected[0] || !selected[0].session) {
            return
        }

        const session = selected[0].session
        this._regenSession()

        this.model.emitValue(selected[0])

        this.placeBackend
            .autocomplete_details({
                place_id: selected[0].place_id,
                session: session
            })
            .pipe(take(1))
            .subscribe(place => {
                this.model.emitValue(place)
            })
    }

    public showMap(event: Event) {
        event.preventDefault()
        this.mapSvc.show(this.selectCtrl.value).output
            .pipe(filter(event => event.type === "select"))
            .subscribe(event => {
                this.selectCtrl.setValue(event.data)
            })
    }

    protected _renderValue(val: Place) {
        this.selectCtrl.setValue(val)
    }

    public eachSubstring(entry: PlaceAutocomplete): Array<{ value: string, matched: boolean }> {
        if (entry.structured_formatting) {
            const text = entry.structured_formatting.main_text
            return fillMatchedSubstrGaps(entry).map(val => {
                return { value: text.substr(val.offset, val.length), matched: val.matched }
            })
        } else {
            return [{ value: entry.description, matched: false }]
        }
    }

    private _regenSession() {
        this.placeSrc.session = Math.random().toString(36)
    }
}

function fillMatchedSubstrGaps(entry: PlaceAutocomplete) {
    let result: Array<{ offset: number, length: number, matched: boolean }> = []
    const textRange = new NzRangeList()
    textRange.push(new NzRange(0, entry.structured_formatting.main_text.length))

    const matchRange = new NzRangeList()

    for (const m of entry.structured_formatting.main_text_matched_substrings) {
        matchRange.push(new NzRange(m.offset, m.offset + m.length))
        result.push({ offset: m.offset, length: m.length, matched: true })
    }

    const diff = textRange.diff(matchRange)

    for (const d of diff) {
        result.push({ offset: d.begin, length: d.end - d.begin, matched: false })
    }

    return result.sort(matchSorter)
}

function matchSorter(a: any, b: any): number {
    return a.offset - b.offset
}
