import { Component, Input, OnDestroy, OnInit, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core"
import { takeUntil } from "rxjs/operators"

import { Destructible } from "@anzar/core"
import { DocumentGroup, Document, DocumentBackend } from "@backend/documents.api"
import { DOCUMENT_FIELDS, DOCUMENT_ORDER } from "./client-document.component"
import { DocumentsService } from "./documents.service"


@Component({
    selector: ".rege-client-doc-group",
    templateUrl: "./client-doc-group.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientDocGroupComponent extends Destructible implements OnInit {
    @Input() public group: DocumentGroup

    public items: Document[]

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(DocumentBackend) private readonly docGroupBackend: DocumentBackend,
        @Inject(DocumentsService) private readonly docSvc: DocumentsService) {
        super()
        this.destruct.subscription(docSvc.reload$).subscribe(this.reload.bind(this))
    }

    public ngOnInit() {
        this.reload()
    }

    private reload() {
        this.docGroupBackend
            .search({
                filter: { group_id: this.group.id, id: { "not in": [this.group.primary_document_id] } },
                order: DOCUMENT_ORDER[this.group.primary_document.type]
            }, { loadFields: DOCUMENT_FIELDS[this.group.primary_document.type] })
            .pipe(takeUntil(this.destruct.on))
            .subscribe(result => {
                this.items = result
                this.cdr.detectChanges()
            })
    }
}
