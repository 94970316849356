import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Route } from "@angular/router"
import { NzModule } from "@anzar/core"


import { OrgApi } from "@backend/org.api"
import { WorkerApi } from "@backend/worker.api"

import { MaltaCommonModule } from "../common"
import { ComponentsModule } from "../components"
import { PlaceModule } from "../place.module"

import { CreateDutyComponent } from "./create-duty.component"
import { DutyListComponent } from "./duty-list.component"
import { DutyScreen } from "./duty.screen"


export { DutyService, ServiceUsage, ServiceUsageEvent, UsageInterceptor } from "./duty.service"


export const DUTY_ROUTES: Route[] = [
    { path: "lista", component: DutyScreen }
]


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        OrgApi,
        MaltaCommonModule,
        ComponentsModule,
        PlaceModule,
        WorkerApi,
    ],
    declarations: [
        CreateDutyComponent,
        DutyListComponent,
        DutyScreen
    ],
    exports: [
        CreateDutyComponent,
        DutyListComponent,
        DutyScreen
    ],
    entryComponents: [CreateDutyComponent]
})
export class DutyModule {

}
