import { Injectable } from "@angular/core"
import { ComponentType } from "@angular/cdk/portal"

import { TaskDetailsFactory, DefaultTaskDetailsComponent } from "@pyzar/task.module"


@Injectable()
export class KenysziImportDetailsFactory extends TaskDetailsFactory {
    public readonly name = "kenyszi-import-agreement"
    public readonly title: string = "Kenyszi ügyfelek importálása"
    public readonly component: ComponentType<any> = DefaultTaskDetailsComponent
}
