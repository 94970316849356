import { Component, Inject, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core"
import { DomSanitizer, SafeStyle } from "@angular/platform-browser"
import { FormGroup, FormControl } from "@angular/forms"
import { of, forkJoin } from "rxjs"
import { take, startWith } from "rxjs/operators"

import { Destructible, LoadFields, ColumnsComponent, ToastService } from "@anzar/core"

import { ClientBackend, Client } from "@backend/client.api"
import { ClientService } from "./client.service"


const CLIENT_FIELDS: LoadFields<Client> = [
    "id", "name", "mothers_name", "birth_place", "birth_date", "social_insurance_id", "idTevadminIgenybevevo"
]


@Component({
    templateUrl: "./duplicated.screen.pug"
})
export class DuplicatedScreen extends Destructible implements OnInit, AfterViewInit {
    @ViewChild("columns", { static: true }) public readonly columns: ColumnsComponent

    public gridTemplate: SafeStyle
    public similarGroups: Array<Array<Client>> = []

    public formGroups: FormGroup[] = []
    public busy = true

    public constructor(
        @Inject(ClientBackend) private readonly clientBackend: ClientBackend,
        @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(ToastService) private readonly toast: ToastService) {
        super()
    }

    public ngOnInit() {
        this.destruct.subscription(this.clientBackend.find_similars({}, { loadFields: CLIENT_FIELDS }))
            .pipe(take(1))
            .subscribe(result => {
                result.sort((a, b) => {
                    return (a[1] === true ? 0 : 1)
                        - (b[1] === true ? 0 : 1)
                })
                this.formGroups = result.map(similars => {
                    const [clients, merge, kenysziError] = similars
                    return new FormGroup({
                        merge: new FormControl(!kenysziError && merge),
                        primary: new FormControl(clients[0].id),
                        kenysziError: new FormControl(kenysziError),
                    })
                })
                this.similarGroups = result.map(v => v[0])
                this.busy = false
                this.cdr.detectChanges()
            })
    }

    public ngAfterViewInit() {
        this.destruct.subscription(this.columns.layoutChanged).pipe(startWith(null)).subscribe(l => {
            this.gridTemplate = this.sanitizer.bypassSecurityTrustStyle(`48px / ${this.columns.gridColTemplate}`)
            this.cdr.detectChanges()
        })
    }

    public showClientSheet(event: Event, client: Client) {
        this.clientSvc.showSheet(client.id, ClientService.TAB_BASIC_DATA).subscribe()
    }

    public doMerge() {
        const entries = this.formGroups
            .map((group, index) => {
                const similars = this.similarGroups[index]
                const similarIds = similars.map(v => v.id)
                const primaryId = group.get("primary").value
                const pIdIndex = similarIds.indexOf(primaryId)

                if (!group.get("merge").value) {
                    return this.clientBackend.set_default_merge({ client_ids: similarIds, merge: false })
                }

                if (pIdIndex === -1) {
                    throw new Error("Hibás adatok")
                } else {
                    similarIds.splice(pIdIndex, 1)
                    if (similarIds.length) {
                        return this.clientBackend.merge_similar({
                            keep_client_id: primaryId,
                            similar_client_ids: similarIds
                        })
                    } else {
                        return of(null)
                    }
                }
            })

        forkJoin(...entries)
            .pipe(this.toast.handleSave({ align: "bottom center", beginMsg: "Ügyfelek egyesítése" }))
            .subscribe(this.ngOnInit.bind(this))
    }
}
