import { NgModule } from "@angular/core"
import { ClientType, Gender, Client, SimilarMergeOptions, ClientChanges, AttributeType, Attribute, CaseTransfer, NCaseTransfer, ClientBackend, CaseBackend, ClientBackendSource } from "./__anzar_rpc_output"

export { ClientType, Gender, Client, SimilarMergeOptions, ClientChanges, AttributeType, Attribute, CaseTransfer, NCaseTransfer, ClientBackend, CaseBackend, ClientBackendSource }


@NgModule({
    providers: [
        ClientBackendSource,
        CaseBackend,
        ClientBackend
    ],
})
export class ClientApi {}