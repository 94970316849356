import { Component, Inject, OnDestroy } from "@angular/core"
import { ImageRef } from "@anzar/core"


@Component({
    selector: "rege-image-tip-component",
    templateUrl: "./image-tip.component.pug"
})
export class ImageTipComponent implements OnDestroy {
    public constructor(@Inject(ImageRef) public readonly image: ImageRef) {

    }

    public ngOnDestroy() {
        this.image.load(null)
    }
}
