import { Component, Inject, ElementRef, OnChanges, Input, Output, SimpleChanges, NgZone } from "@angular/core"
import { Subject, from, of } from "rxjs"
import { switchMap, map, filter } from "rxjs/operators"

import { Destructible, ImageRef, ImageRefSource } from "@anzar/core"

const Tinycrop = require("tinycrop")
const Smartcrop = require("smartcrop")


export interface CropRegion {
    x: number
    y: number
    width: number
    height: number
}


export interface CropEvent {
    type: "start" | "move" | "resize" | "change" | "end"
    region: CropRegion
}


type SmartCropRes = { x: number, y: number, width: number, height: number }

// TODO: smartcrop.js

@Component({
    selector: "rege-image-crop",
    template: "<div></div>"
})
export class ImageCropComponent extends Destructible implements OnChanges {
    @Input() public image: ImageRefSource
    @Input() public aspectRatio: number
    @Input() public crop: CropRegion

    @Output() public changes = new Subject<CropEvent>()

    private imageRef = new ImageRef()

    private instance: any

    public constructor(
        @Inject(ElementRef) private readonly el: ElementRef<HTMLElement>,
        @Inject(NgZone) private readonly zone: NgZone) {
        super()

        this.destruct.subscription(this.imageRef.image$)
            .pipe(
                filter(image => !!image),
                switchMap(image => {
                    if (this.crop) {
                        return of([image, this.crop] as [HTMLImageElement, SmartCropRes])
                    } else {
                        const width = Math.min(500, image.naturalWidth * 0.8)
                        const height = Math.min(500, image.naturalHeight * 0.8)
                        return from(Smartcrop.crop(image, { width, height })).pipe(
                            map((smart: any) => {
                                return [image, smart.topCrop] as [HTMLImageElement, SmartCropRes]
                            })
                        )
                    }
                })
            )
            .subscribe(([image, crop]) => {
                if (this.instance) {
                    const selection = this.instance.selectionLayer.selection
                    selection.x = crop.x
                    selection.y = crop.y
                    selection.width = crop.width
                    selection.height = crop.height
                    this.instance.setImage(image)
                } else {
                    this.createInstance(image, crop)
                }
            })
    }

    public createInstance(image: HTMLImageElement, crop: SmartCropRes) {
        this.zone.runOutsideAngular(_ => {
            this.instance = Tinycrop.create({
                image: image,
                parent: this.el.nativeElement.firstElementChild,
                backgroundColors: ["#FFF", "#FFF"],
                selection: {
                    color: "#e1bee7",
                    activeColor: "#e91e63",
                    aspectRatio: this.aspectRatio,
                    x: crop.x,
                    y: crop.y,
                    width: crop.width,
                    height: crop.height,
                }
            })

            this.instance.on("change", this._delegateEvent.bind(this, "change"))
        })
    }

    public ngOnChanges(changes: SimpleChanges) {
        if ("image" in changes) {
            this.imageRef.load(changes.image.currentValue)
        }

        if (this.instance) {
            if ("aspectRatio" in changes) {
                this.instance.selectionLayer.setAspectRatio(changes.aspectRatio.currentValue)
            }

            if ("crop" in changes) {
                const selection = this.instance.selectionLayer.selection
                const crop = changes.crop.currentValue as CropRegion
                if (crop) {
                    selection.x = crop.x
                    selection.y = crop.y
                    selection.width = crop.width
                    selection.height = crop.height
                    this.instance.revalidateAndPaint()
                }
            }
        }
    }

    private _delegateEvent(type: string, region: any) {
        this.changes.next({
            type: type as any,
            region: { x: region._x, y: region._y, width: region._width, height: region._height }
        })
    }
}
