import { NgModule } from "@angular/core"
import { Event, EventUsage, NEvent, EventBackend, EventBackendSource } from "./__anzar_rpc_output"

export { Event, EventUsage, NEvent, EventBackend, EventBackendSource }


@NgModule({
    providers: [
        EventBackendSource,
        EventBackend
    ],
})
export class EventApi {}