import { Component, Inject, Input, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef } from "@angular/core"
import { merge } from "rxjs"
import { debounceTime, take, map, shareReplay } from "rxjs/operators"


import { Destruct, ExlistComponent, LayerService, DropdownLayer, LayerRef, MediaQueryService, CordovaService } from "@anzar/core"

import { Client } from "@backend/client.api"
import { ClientService } from "../client.module"
import { DutyService, ServiceUsage } from "../duty.module"
import { UsableService, ProviderServices } from "./provider.service"
import { ServiceSummaryItem, ServiceSummaryComponent, SERVICE_SUMMARY } from "./service-summary.component"


interface Summary {
    total: number
    primary: ServiceSummaryItem[]
    services: ServiceSummaryItem[]
}


@Component({
    selector: ".rege-service-usage-list",
    templateUrl: "./service-usage-list.template.pug"
})
export class ServiceUsageListComponent implements AfterViewInit, OnDestroy {
    public readonly destruct = new Destruct()

    @Input() public primaryServices: UsableService[] = []
    @Input() public pinnedServices: UsableService[] = []
    @Input() public displayPrimary: boolean
    @Input() public filter: any
    @Input() public sorter: any
    @ViewChild("list", { read: ExlistComponent, static: false }) public readonly list: ExlistComponent

    protected _pendingExpand: ServiceUsage
    public readonly summary: Summary

    public readonly isSmallScreen$ = this.destruct.subscription(this.mq.watch("lt-md"))
        .pipe(map(v => v.matches), shareReplay(1))

    public readonly isLittleScreen$ = this.destruct.subscription(this.mq.watch("lt-sm"))
        .pipe(map(v => v.matches), shareReplay(1))

    public constructor(
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(DutyService) public readonly duty: DutyService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(ProviderServices) private readonly providerServices: ProviderServices,
        @Inject(LayerService) private readonly layerService: LayerService,
        @Inject(MediaQueryService) private readonly mq: MediaQueryService,
        @Inject(CordovaService) public readonly cordova: CordovaService) {
        this.destruct.subscription(duty.onExpand).subscribe(model => {
            this.expandRow(model)
        })

        this.destruct.subscription(merge(duty.usage.changed, duty.onUsageChange))
            .pipe(debounceTime(10))
            .subscribe(changed => {
                this._updateSummary(duty.usage.data as any)
            })

    }

    public showClientSheet(event: any, client: Client) {
        this.clientSvc.showSheet(client.id).subscribe()
    }

    public expandRow(model: ServiceUsage) {
        if (this.list) {
            this.list.setOpened(model, "program")
        } else {
            this._pendingExpand = model
        }
    }

    public ngAfterViewInit() {
        if (this._pendingExpand) {
            this.list.setOpened(this._pendingExpand, "program")
            this._pendingExpand = null
        }
    }

    public ngOnDestroy() {
        this.destruct.run()
        if (this._serviceSummaryRef) {
            this._serviceSummaryRef.hide()
        }
    }

    public deleteUsage(usage: ServiceUsage) {
        this.duty.deleteUsage(usage).subscribe()
    }

    private _serviceSummaryRef: LayerRef
    public showServiceSummary(event: Event) {
        if (this._serviceSummaryRef) {
            this._serviceSummaryRef.hide()
            return
        }

        const behavior = new DropdownLayer({
            backdrop: { type: "empty", hideOnClick: true, crop: event.target as HTMLElement },
            elevation: 5,
            rounded: 3,
            menuLike: true,
            trapFocus: true,
            position: {
                anchor: {
                    ref: event.target as HTMLElement,
                    align: "top center"
                },
                constraint: {
                    ref: "viewport",
                    inset: 8
                },
                align: "bottom center"
            }
        })
        const ref = this.layerService.createFromComponent(ServiceSummaryComponent, behavior, null, [
            { provide: SERVICE_SUMMARY, useValue: this.summary.services }
        ])

        ref.destruct.on.pipe(take(1)).subscribe(_ => {
            delete this._serviceSummaryRef
        })

        this._serviceSummaryRef = ref
        ref.show()
    }

    private _updateSummary(usages: Array<Readonly<ServiceUsage>>) {
        let total: number = 0
        let primary: { [key: string]: ServiceSummaryItem } = {}
        let services: { [key: string]: ServiceSummaryItem } = {}

        let providerTitles: { [key: number]: string[] } = {}
        let titleOrder: string[] = []
        let gtitleAfter: { [key: string]: string } = {}
        for (const primaryService of this.primaryServices) {
            const providerId = primaryService.services[0].provider_id
            const provider = this.providerServices.getById(providerId)
            const groupedTitle = this.providerServices
                .getSimilarsById(providerId)
                .map(p => p.title)
                .join(" + ")

            if (provider.title !== groupedTitle) {
                providerTitles[providerId] = [provider.title, groupedTitle]
                titleOrder.push(provider.title)
                gtitleAfter[groupedTitle] = provider.title
            } else {
                providerTitles[providerId] = [primaryService.title]
                titleOrder.push(primaryService.title)
            }
        }

        for (const groupedTitle in gtitleAfter) {
            titleOrder.splice(titleOrder.indexOf(gtitleAfter[groupedTitle]) + 1, 0, groupedTitle)
        }

        for (const item of usages) {
            total++

            for (const svc of item.usage) {
                let usableService = this.providerServices.getServiceById(svc.providerService.id)
                let target: { [key: string]: ServiceSummaryItem }
                let titles: string[]

                if (usableService.isPrimary) {
                    target = primary
                    titles = providerTitles[svc.providerService.provider_id]
                } else {
                    target = services
                    titles = [usableService.title]
                }

                for (const title of titles) {
                    if (!target[title]) {
                        target[title] = { title: title, value: 1 }
                    } else {
                        target[title].value++
                    }
                }
            }
        }

        (this as { summary: Summary }).summary = {
            total: total,
            primary: Object.keys(primary)
                .sort((a, b) => titleOrder.indexOf(a) - titleOrder.indexOf(b))
                .map(k => primary[k]),
            services: Object.keys(services)
                .sort()
                .map(k => services[k]),
        }
        this.cdr.markForCheck()
    }
}
