import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Route } from "@angular/router"
import { PortalModule } from "@angular/cdk/portal"
import { NzModule } from "@anzar/core"

import { PyzarApi } from "@backend/pyzar.api"
import { PyzarCommonModule } from "../common.module"
import { ImageModule } from "../image.module"

import { AuthService } from "./auth.service"
export { AuthService }

import { UsersScreen } from "./users.screen"
import { UsersComponent } from "./users.component"
import { UserScreen } from "./user.screen"

import { AuthorizedOnly, AuthorizedOnlyPwChange, UnauthorizedOnly } from "./guard"
export { AuthorizedOnly, AuthorizedOnlyPwChange, UnauthorizedOnly }

import { UserEdtorComponent } from "./sheet/user-editor.component"
import { UserBasicDataComponent } from "./sheet/basic-data.component"
import { UserLoginDataComponent } from "./sheet/login-data.component"
import { UserProfileImageSettingComponent } from "./sheet/profile-image.component"
import { UserGroupsComponent } from "./sheet/groups.component"
export { CustomUserBlock, UserBlockFactory } from "./sheet/user-editor.component"
export { UserEditorService } from "./sheet/user-editor.service"


import { LoginViewport } from "./login/login-viewport.component"
import { ChangePassViewport } from "./login/change-pass.component"
import { VerifyEmailViewport } from "./login/verify-email.component"
import { RegistrationViewport } from "./login/registration.component"

import { PermissionScreen } from "./permission/permission.screen"
import { PermissionEditorComponent } from "./permission/permission-editor.component"
import { NewGroupComponent, NewGroupService } from "./permission/new-group-component"


export const PROTECTED_ROUTES: Route[] = [
    { path: "users", component: UsersScreen },
    { path: "users/create", component: UserScreen },
    { path: "users/:id", component: UserScreen },
    { path: "permissions", component: PermissionScreen },
]


export const PUBLIC_ROUTES: Route[] = [
    { path: "login", canActivate: [UnauthorizedOnly], component: LoginViewport, pathMatch: "full" },
    { path: "change-pass", canActivate: [AuthorizedOnlyPwChange], component: ChangePassViewport, pathMatch: "full" },
    { path: "login/:token", component: LoginViewport, pathMatch: "full" },
    { path: "verify-email/:token", component: VerifyEmailViewport, pathMatch: "full" },
    { path: "registration/:token", component: RegistrationViewport, pathMatch: "full" },
]


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        PyzarCommonModule,
        ImageModule,
        PyzarApi,
        PortalModule,
    ],
    declarations: [
        UsersScreen,
        UsersComponent,
        UserScreen,

        UserEdtorComponent,
        UserBasicDataComponent,
        UserLoginDataComponent,
        UserProfileImageSettingComponent,
        UserGroupsComponent,

        LoginViewport,
        ChangePassViewport,
        VerifyEmailViewport,
        RegistrationViewport,

        PermissionScreen,
        PermissionEditorComponent,
        NewGroupComponent,
    ],
    exports: [
        UsersScreen,
        UsersComponent,
        UserScreen,

        UserEdtorComponent,
        UserBasicDataComponent,
        UserLoginDataComponent,
        UserProfileImageSettingComponent,
        UserGroupsComponent,

        LoginViewport,
        ChangePassViewport,
        VerifyEmailViewport,
        RegistrationViewport,

        PermissionScreen,
        PermissionEditorComponent,
        NewGroupComponent,
    ],
    providers: [
        AuthService,
        AuthorizedOnly,
        AuthorizedOnlyPwChange,
        UnauthorizedOnly,
        NewGroupService,
    ],
    entryComponents: [
        NewGroupComponent
    ]
})
export class AuthModule {

}
