import { Component, Inject, OnDestroy, Output, EventEmitter, ChangeDetectorRef, Input } from "@angular/core"
import { FormControl } from "@angular/forms"
import { FocusOrigin } from "@angular/cdk/a11y"
import { Observable } from "rxjs"
import { map, take } from "rxjs/operators"

import { Destruct, FocusChangeEvent, LayerService, DialogService, ToastService, DropdownLayer, ComponentLayerRef } from "@anzar/core"
import { WorkerMsgBackend, WorkerMessage } from "@backend/worker.api"
import { User } from "@backend/pyzar.api"
import { AuthService } from "@pyzar/auth.module"
import { CurrentHeadquerter } from "../section.service/level"
import { AliasPopupComponent } from "./alias-popup.component"


@Component({
    selector: ".rege-message-add",
    templateUrl: "./message-add.component.pug"
})
export class MessageAddComponent implements OnDestroy {
    public readonly destruct = new Destruct()

    public isExpanded: boolean = false
    public readonly content = new FormControl()
    public readonly is_global = new FormControl()
    public _selectedUser: User

    @Input() public parentId: number = null

    @Output()
    public create: Observable<WorkerMessage> = new EventEmitter()

    private userLayerRef: ComponentLayerRef<AliasPopupComponent>
    private loggedUser: User

    public set mouseIsOver(val: boolean) {
        if (this._mouseIsOver !== val) {
            this._mouseIsOver = val
            this._recalcExpanded()
        }
    }
    public get mouseIsOver(): boolean { return this._mouseIsOver }
    private _mouseIsOver: boolean
    private _hqId: number
    private _focus: FocusOrigin

    public constructor(
        @Inject(WorkerMsgBackend) public readonly workerBackend: WorkerMsgBackend,
        @Inject(DialogService) public readonly dialogSvc: DialogService,
        @Inject(ToastService) public readonly toastSvc: ToastService,
        @Inject(AuthService) public readonly authSvc: AuthService,
        @Inject(CurrentHeadquerter) public readonly hq: CurrentHeadquerter,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {

        this.destruct.subscription(authSvc.currentUser$).subscribe(cu => {
            this._selectedUser = cu
            this.loggedUser = cu
        })

        this.destruct.subscription(hq.id$).subscribe(hqId => {
            this._hqId = hqId
        })
    }

    public _handleInputFocus(event: FocusChangeEvent) {
        this._focus = event.curr
        this._recalcExpanded()
    }

    public cancel() {
        this.isExpanded = false
        this.content.setValue("")
        this._selectedUser = this.loggedUser
        if (!this.destruct.done) {
            this.cdr.detectChanges()
        }
    }

    public save() {
        let value = this.content.value as string

        if (value && value.length) {
            this.workerBackend.save({
                data: {
                    content: value,
                    alias_id: this._selectedUser.id,
                    headquarter_id: this._hqId,
                    parent_id: this.parentId,
                    is_global: !!this.is_global.value
                }
            }).pipe(
                take(1),
                this.toastSvc.handleSave({ align: "bottom center", successMsg: "Sikeresn hozzászóltál" })
            ).subscribe(content => {
                this.cancel();
                (this.create as EventEmitter<WorkerMessage>).emit(content)
            })
        } else {
            this.dialogSvc.alert("Üzenet hozzáadási hiba", "Előbb kérem adjon meg egy üzenetet.")
        }
    }

    public ngOnDestroy() {
        this.destruct.run()

        if (this.userLayerRef) {
            this.userLayerRef.hide()
            delete this.userLayerRef
        }
    }

    public changeUser(event: Event) {
        if (this.userLayerRef && this.userLayerRef.isVisible) {
            this.userLayerRef.hide()
            delete this.userLayerRef
        } else {
            const behavior = new DropdownLayer({
                backdrop: { type: "empty", crop: event.target as HTMLElement, hideOnClick: true },
                trapFocus: true,
                elevation: 10,
                menuLike: true,
                rounded: 3,
                position: {
                    align: "top left",
                    anchor: {
                        align: "bottom left",
                        ref: event.target as HTMLElement
                    }
                }
            })

            this.userLayerRef = this.layerSvc.createFromComponent(AliasPopupComponent, behavior)
            this.userLayerRef.show()

            this.userLayerRef.component.instance.userId.setValue(this._selectedUser.id)

            this.userLayerRef.subscribe(event => {
                if (event.type === "select") {
                    this._selectedUser = event.data
                    this.cdr.detectChanges()
                }
            })
        }
    }

    private _recalcExpanded() {
        const newValue = this._focus || this.content.value || this._mouseIsOver
        if (this.isExpanded !== newValue) {
            this.isExpanded = newValue
            this.cdr.detectChanges()
        }
    }
}
