import { Injectable } from "@angular/core"

import { Level } from "@backend/org.api"


export const LEVEL_ORDER = ["global", "maintainer", "region", "institution", "headquarter", "section", "provider"]

export const LEVEL_CHILD: { [key: string]: string } = {
    "global": "maintainer",
    "maintainer": "region",
    "region": "institution",
    "institution": "headquarter",
    "headquarter": "section",
    "section": "provider",
}

export const LEVEL_TITLE: { [key: string]: string } = {
    "global": "Globális",
    "maintainer": "Fenntartó",
    "region": "Régió",
    "institution": "Intézmény",
    "headquarter": "Székhely",
    "section": "Részleg",
    "provider": "Ellátás",
}

export const LEVEL_URL: { [key: string]: string } = {
    "global": "global",
    "maintainer": "fenntarto",
    "region": "regio",
    "institution": "intezmeny",
    "headquarter": "szekhely",
    "section": "reszleg",
    "provider": "ellata",
}


@Injectable({ providedIn: "root" })
export class OrgService {
    public getEditorUrlFor(level: Level): string
    public getEditorUrlFor(level: string, id?: number): string

    public getEditorUrlFor(variant: string | Level, id?: number): string {
        if (typeof variant === "string") {
            if (id) {
                return `/org/${LEVEL_URL[variant]}/${id}`
            } else {
                return `/org/${LEVEL_URL[variant]}`
            }
        } else {
            return this.getEditorUrlFor(variant.variant, variant.id)
        }
    }
}
