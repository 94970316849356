import { Component, EventEmitter, Inject, Output, Input, OnInit, ChangeDetectorRef } from "@angular/core"
import { FormControl, FormGroup, FormArray, Validators } from "@angular/forms"
import { merge, Subject, of } from "rxjs"
import { take, tap, debounceTime, map, distinctUntilChanged, startWith, switchMap } from "rxjs/operators"
import { isSameDay, format } from "date-fns"


import { Destructible, ToastService, LoadFields } from "@anzar/core"
import { CallType, CallPartnerType } from "@backend/enums.api"
import { StoryEntryBackend, StoryEntry, CrisisCarBackendSource, CrisisCar, CrisisCarBackend } from "@backend/dispatcher.api"
import { SectionBackendSource, ProviderDutyBackend, ProviderDutyBackendSource, ProviderVehicleBackendSource } from "@backend/org.api"
import { User } from "@backend/pyzar.api"
import { WorkerBackendSource } from "@backend/worker.api"
import { CurrentSection } from "../section.service/level"
import { StoriesService, StoryForm } from "./stories.service"


const QUICK_BUTTONS_CALL = [
    { label: "PISZ", call_type: "PI", call_partner_type: "sz" },
    { label: "UBM", call_type: "UB", call_partner_type: "m" },
    { label: "ELSZ", call_type: "EL", call_partner_type: "sz" },
    { label: "UBR", call_type: "UB", call_partner_type: "r" },
    { label: "ADM", call_type: "AD", call_partner_type: "m" },
    { label: "PIKR", call_type: "PI", call_partner_type: "kr" },
]

const QUICK_BUTTONS_EMAIL = [
    { label: "PISZ", call_type: "PI", call_partner_type: "sz" },
    { label: "UBM", call_type: "UB", call_partner_type: "m" },
    { label: "ELSZ", call_type: "EL", call_partner_type: "sz" },
    { label: "UBR", call_type: "UB", call_partner_type: "r" },
    { label: "ADM", call_type: "AD", call_partner_type: "m" },
]

const CALLBACK_FIELDS = [
    "call_partner_type",
    "call_partner_client_id",
    "call_partner_section_id",
    "call_partner_crisis_id",
    "call_partner_user_id",
    "call_partner_name",
    "call_partner_tel",
    "call_partner_email",
    "is_email",
]

const ENTRY_FIELDS: LoadFields<StoryEntry> = [
    "id",
    "story_id",
    "place_id",
    "place_extra",
    "arrive_time",
    "is_incoming",
    "is_email",
    "is_crisis_login",
    "call_type",
    "call_time",
    "call_partner_type",
    "call_partner_name",
    "call_partner_tel",
    "call_partner_email",
    "call_partner_is_anon",
    "call_partner_client_id",
    "call_partner_section_id",
    "call_partner_user_id",
    "call_partner_crisis_id",
    {
        call_partner_crisis: [
            "id", "tel", "work_begin", "work_end", "vehicle_id",
            { vehicle: ["name"] },
            { workers: ["crisis_car_id", "user_id"] }
        ]
    },
    { messages: ["id", { richtext: ["text", "content"] as any }] }
]

type CallPartnerTypeChoice = "r" | "m" | "eü" | "ei" | "sz" | "ü" | "kr"


@Component({
    selector: ".rege-disp-story-form",
    templateUrl: "./story-form.component.pug"
})
export class StoryFormComponent extends Destructible implements StoryForm, OnInit {
    @Output() public cancel = new EventEmitter()
    @Output() public save = new EventEmitter<StoryEntry>()

    @Input() public cancelButtonLabel: string

    @Input()
    public set storyId(val: number) {
        val = Number(val)
        if (this._storyId !== val) {
            this._storyId = val
        }
    }
    public get storyId(): number { return this._storyId }
    private _storyId: number = null

    @Input()
    public set storyEntryId(val: number) {
        if (this._storyEntryId !== val) {
            this._storyEntryId = val
            this.loadEntry(val)
        }
    }
    public get storyEntryId(): number { return this._storyEntryId }
    private _storyEntryId: number = null

    @Input()
    public set isIncoming(val: boolean) {
        if (this._isIncoming !== val) {
            this._isIncoming = val
            if (!val) {
                this.form.get("call_partner_is_anon").setValue(false)
                this.form.get("is_crisis_login").setValue(false)
            }
            this._isIncoming$.next(val)
        }
    }
    public get isIncoming(): boolean { return this._isIncoming }
    private _isIncoming: boolean = false
    private _isIncoming$ = this.destruct.subject(new Subject<boolean>())

    public get allowAnonPartner(): boolean {
        return (this.form.get('call_partner_type').value === 'r'
            || this.form.get('call_partner_type').value === 'm')
    }

    public readonly workerFilter: { [key: string]: any } = {}
    public readonly sectionFilter: { [key: string]: any } = {}

    public readonly form = new FormGroup({
        call_type: new FormControl(),
        call_partner_type: new FormControl(),
        call_partner_client_id: new FormControl(),
        call_partner_section_id: new FormControl(),
        call_partner_crisis_id: new FormControl(),
        call_partner_user_id: new FormControl(),
        call_partner_name: new FormControl(),
        call_partner_tel: new FormControl(),
        call_partner_email: new FormControl(),
        call_partner_is_anon: new FormControl(),
        is_crisis_login: new FormControl(),
        is_email: new FormControl(),
        place_id: new FormControl(),
        place_extra: new FormControl(),
        call_time: new FormControl(new Date()),
        arrive_time: new FormControl(),
        has_arrive_time: new FormControl(false),
        messages: new FormArray([
            this.createMessageControl()
        ]),
        call_partner_crisis: new FormGroup({
            id: new FormControl(),
            vehicle_id: new FormControl(),
            duty_range: new FormControl(),
            tel: new FormControl(),
            workers: new FormArray([
                this.createCrisisWorkerControl(),
                this.createCrisisWorkerControl(),
            ])
        })
    })

    public get messageControls() { return (this.form.get("messages") as FormArray).controls }
    public get workerControls() { return (this.form.get("call_partner_crisis").get("workers") as FormArray).controls }

    public readonly callTypeSrc = CallType.DATA
    public readonly callPartnerTypeSrc = CallPartnerType.DATA
    public readonly quickButtons = {
        email: QUICK_BUTTONS_EMAIL,
        call: QUICK_BUTTONS_CALL
    }

    public providerIds: number[]
    public crisisCarFilter: any
    public selectedCrisisCar: CrisisCar
    public readonly crisisCarFields: LoadFields<CrisisCar> = [
        "id", "tel", "work_begin", "work_end",
        { vehicle: ["name"] },
        { workers: [{ user: ["id", "name", "phone"] }] }
    ]

    private suspendMessagesChange: boolean = false

    public constructor(
        @Inject(StoryEntryBackend) private readonly entryBackend: StoryEntryBackend,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(SectionBackendSource) public readonly sectionSrc: SectionBackendSource,
        @Inject(WorkerBackendSource) public readonly userSrc: WorkerBackendSource,
        @Inject(StoriesService) public readonly storiesSvc: StoriesService,
        @Inject(CrisisCarBackendSource) public readonly crisisCarSvc: CrisisCarBackendSource,
        @Inject(CrisisCarBackend) protected readonly crisisCarBackend: CrisisCarBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(CurrentSection) private readonly section: CurrentSection,
        @Inject(ProviderDutyBackend) protected readonly dutyTpl: ProviderDutyBackend,
        @Inject(ProviderDutyBackendSource) public readonly dutyTplSrc: ProviderDutyBackendSource,
        @Inject(ProviderVehicleBackendSource) public readonly vehicleSrc: ProviderVehicleBackendSource,) {
        super()

        storiesSvc.currentForm = this
        this.destruct.any(() => storiesSvc.currentForm = null)

        const messages = this.form.get("messages") as FormArray
        this.destruct.subscription(messages.valueChanges.pipe(startWith(null), map(_ => messages.value))).subscribe((value: Array<{ id: number, message: string }>) => {
            if (this.suspendMessagesChange) {
                return
            }
            const hasEmpty = value.filter(v => !v.message || v.message.length === 0).length > 0
            if (!hasEmpty) {
                messages.push(this.createMessageControl())
            }
        })

        this.destruct
            .subscription(merge(
                this.form.get("call_partner_type").valueChanges,
                this.form.get("is_crisis_login").valueChanges,
                this.form.get("call_partner_is_anon").valueChanges,
                this.form.get("call_partner_section_id").valueChanges,
                this.form.get("call_partner_name").valueChanges,
                this.form.get("call_partner_user_id").valueChanges,
                this._isIncoming$,
            ))
            .pipe(
                debounceTime(10),
                map(_ => [
                    this.form.get("call_partner_type").value as CallPartnerTypeChoice,
                    this.form.get("is_crisis_login").value as boolean,
                    this.form.get("call_partner_is_anon").value as boolean,
                    this.form.get("call_partner_section_id").value as number,
                    this.form.get("call_partner_name").value as string,
                    this.form.get("call_partner_user_id").value as number,
                ]),
                distinctUntilChanged((a, b) => {
                    if (a && b) {
                        for (let i = 0, l = a.length; i < l; i++) {
                            if (a[i] !== b[i]) {
                                return false
                            }
                        }
                        return true
                    }
                    return false
                })
            )
            .subscribe(([callPartnerType, isCrisisLogin, isAnon, sectionId, partnerName, userId]) => {
                const all = [
                    "call_partner_client_id",
                    "call_partner_section_id",
                    "call_partner_crisis_id",
                    "call_partner_user_id",
                    "call_partner_name",
                    "call_partner_tel",
                ]
                let required: string[]

                switch (callPartnerType) {
                    case "r":
                    case "m":
                        required = this.allowAnonPartner && isAnon ? [] : ["call_partner_name"]
                        break

                    case "eü":
                    case "ei":
                        required = []
                        // if (sectionId || partnerName) {
                        //     required = []
                        // } else {
                        //     required = ["call_partner_section_id", "call_partner_name"]
                        // }
                        break

                    case "sz":
                        required = []
                        // if (sectionId || userId) {
                        //     required = []
                        // } else {
                        //     required = ["call_partner_section_id", "call_partner_user_id"]
                        // }
                        break

                    case "ü":
                        required = []
                        // required = ["call_partner_client_id"]
                        break

                    case "kr":
                        required = isCrisisLogin ? [] : ["call_partner_crisis_id"]
                        break

                    default:
                        required = []
                }

                for (const name of all) {
                    const ctrl = this.form.get(name)
                    if (required.indexOf(name) === -1) {
                        ctrl.clearValidators()
                    } else {
                        ctrl.setValidators([Validators.required])
                    }
                    ctrl.updateValueAndValidity()
                }
                this.cdr.markForCheck()
            })

        this.destruct.subscription(this.form.get("call_type").valueChanges).subscribe(callType => {
            if (callType !== "PI") {
                this.form.get("has_arrive_time").setValue(false)
            }
        })

        this.destruct.subscription(this.form.get("call_partner_type").valueChanges)
            .pipe(distinctUntilChanged())
            .subscribe(callPartnerType => {
                if (callPartnerType === "eü") {
                    (this as any).sectionFilter = { "provider_type": "MEDICAL" }
                } else if (callPartnerType === "ei") {
                    (this as any).sectionFilter = { "provider_type": "OTHER" }
                } else {
                    (this as any).sectionFilter = null
                }
                this.cdr.detectChanges()
            })

        const crisisCarCtrl = this.form.get("call_partner_crisis")
        this.destruct.subscription(this.form.get("is_crisis_login").valueChanges).subscribe(value => {
            const vehicleIdCtrl = crisisCarCtrl.get("vehicle_id")
            const dutyRangeCtrl = crisisCarCtrl.get("duty_range")
            vehicleIdCtrl.clearValidators()
            dutyRangeCtrl.clearValidators()
            if (value) {
                vehicleIdCtrl.setValidators([Validators.required])
                dutyRangeCtrl.setValidators([Validators.required])
            }
            vehicleIdCtrl.updateValueAndValidity()
            dutyRangeCtrl.updateValueAndValidity()
        })

        this.destruct
            .subscription(
                merge(
                    this.form.get("call_partner_type").valueChanges,
                    this.form.get("call_time").valueChanges,
                    this.form.get("is_crisis_login").valueChanges,
                )
            )
            .pipe(
                map(_ => {
                    return [
                        this.form.get("call_partner_type").value,
                        this.form.get("call_time").value,
                        this.form.get("is_crisis_login").value,
                    ]
                }),
                distinctUntilChanged((a, b) => {
                    return a && b && a[0] === b[0] && a[1] === b[1] && a[2] === b[2]
                }),
                switchMap(([callPartnerType, callTime, isCrisisLogin]) => {
                    if (callPartnerType === "kr" && !isCrisisLogin) {
                        return this.crisisCarBackend.search({ filter: { time: callTime }, begin: 0, count: 2 }, { loadFields: ["id"] }).pipe(take(1))
                    } else {
                        return of([])
                    }
                })
            )
            .subscribe(crisisCars => {
                if (crisisCars.length === 1) {
                    this.form.get("call_partner_crisis_id").setValue(crisisCars[0].id)
                }
            })

        this.destruct
            .subscription(this.form.get("has_arrive_time").valueChanges)
            .pipe(distinctUntilChanged())
            .subscribe(hasArriveTime => {
                const tCtrl = this.form.get("arrive_time")
                tCtrl.clearValidators()
                if (hasArriveTime) {
                    tCtrl.setValidators([Validators.required])
                    if (!tCtrl.value) {
                        tCtrl.setValue(this.form.get("call_time").value)
                    }
                } else {
                    tCtrl.setValue(null)
                }
                tCtrl.updateValueAndValidity()
            })

        this.destruct
            .subscription(this.form.get("arrive_time").valueChanges)
            .pipe(distinctUntilChanged())
            .subscribe(arriveTime => {
                this.form.get("has_arrive_time").setValue(!!arriveTime)
            })

        this.destruct.subscription(this.form.get("call_partner_section_id").valueChanges)
            .pipe(distinctUntilChanged())
            .subscribe((id: any) => {
                (this as { workerFilter: any }).workerFilter = (id && !id.$new) ? { section_id: id } : {}
                this.cdr.detectChanges()
            })
    }

    public ngOnInit() {
        this.destruct.subscription(this.section.providers).subscribe(providers => {
            this.providerIds = providers.map(p => p.id)
            this.cdr.detectChanges()
        })

        this.destruct.subscription(this.form.get("call_time").valueChanges)
            .pipe(
                startWith(this.form.get("call_time").value),
                distinctUntilChanged((a: Date, b: Date) => isSameDay(a, b))
            )
            .subscribe((value: Date) => {
                this.crisisCarFilter = { time: value }
                this.cdr.detectChanges()
            })

        this.destruct.subscription(this.form.statusChanges).subscribe(this.cdr.detectChanges.bind(this.cdr))
        this.form.markAsPristine()
        this.form.markAsUntouched()
    }

    public handleSave() {
        this.doSave().subscribe()
    }

    public doSave() {
        let data = this.form.value
        data.story_id = this.storyId
        data.id = this.storyEntryId
        data.is_incoming = this.isIncoming
        data.section_id = this.section.id

        this._cleanupFormData(data)

        let successMsg = this.storyEntryId
            ? "A hívás sikeresen módosítva"
            : "A hívás sikeresen rögzítve"

        return this.entryBackend
            .save({ data })
            .pipe(
                take(1),
                this.toastSvc.handleSave({ align: "bottom center", successMsg }),
                tap(storyEntry => {
                    this.save.emit(storyEntry)
                    this.reset()
                }))
    }

    public handleCancel() {
        this.cancel.emit()
        this.reset()
    }

    public reset() {
        const messages = this.form.get("messages") as FormArray
        messages.controls.length = 0
        messages.push(this.createMessageControl())
        this.storyEntryId = null
        this.form.reset({ call_time: new Date() })
    }

    public callbackIsSafe(entry: StoryEntry): boolean {
        for (const name of CALLBACK_FIELDS) {
            const ctrl = this.form.get(name)
            if (ctrl.value && ctrl.value !== (entry as any)[name]) {
                return false
            }
        }
        return true
    }

    public startCallback(entry: StoryEntry) {
        for (const name of CALLBACK_FIELDS) {
            const ctrl = this.form.get(name)
            ctrl.setValue((entry as any)[name])
        }
    }

    public setData(data: any) {
        let maybeChanged = false

        for (const k in this.form.controls) {
            if (k !== "messages" && k !== "call_partner_crisis") {
                const ctrl = this.form.controls[k]
                const val = data[k]
                if (val !== undefined) {
                    ctrl.setValue(val)
                    maybeChanged = true
                }
            }
        }

        this.suspendMessagesChange = true
        const messages = data.messages as any[]
        const msgControl = this.form.get("messages") as FormArray
        msgControl.controls.length = 0
        if (messages && messages.length) {
            for (const msg of messages) {
                // msgControl.controls.push(this.createMessageControl(msg.id, msg.message || (msg.richtext && msg.richtext.text)))
                msgControl.push(this.createMessageControl(msg.id, msg.message || (msg.richtext && msg.richtext.text)))
            }
            maybeChanged = true
        } else {
            msgControl.push(this.createMessageControl())
        }
        this.suspendMessagesChange = false

        if (data.call_partner_crisis) {
            const crisisCarCtrl = this.form.get("call_partner_crisis") as FormGroup
            for (const k in crisisCarCtrl.controls) {
                const val = data.call_partner_crisis[k]
                if (val !== undefined) {
                    if (k === "workers") {
                        const workersCtrl = crisisCarCtrl.get("workers") as FormArray
                        workersCtrl.controls.length = 0
                        for (const worker of val) {
                            workersCtrl.controls.push(this.createCrisisWorkerControl(worker.crisis_car_id, worker.user_id, worker.tel))
                        }
                        workersCtrl.updateValueAndValidity()
                    } else {
                        const ctrl = crisisCarCtrl.get(k)
                        ctrl.setValue(val)
                    }
                    maybeChanged = true
                }
            }
        }

        return maybeChanged
    }

    public loadEntry(id: number) {
        this.form.reset()
        if (id) {
            this.entryBackend.get({ id }, { loadFields: ENTRY_FIELDS })
                .pipe(
                    take(1),
                    switchMap(entry => {
                        if (entry.call_partner_crisis && entry.is_crisis_login) {
                            const { work_begin, work_end } = entry.call_partner_crisis
                            return this.dutyTpl
                                .search({
                                    filter: {
                                        "begin": format(work_begin, "HH:mm:ss"),
                                        "end": format(work_end, "HH:mm:ss"),
                                    }
                                })
                                .pipe(
                                    map(duty => {
                                        if (duty && duty.length) {
                                            (entry.call_partner_crisis as any).duty_range = duty[0].id
                                        }
                                        return entry
                                    })
                                )
                        } else {
                            return of(entry)
                        }
                    })
                )
                .subscribe(entry => {
                    this.storyId = entry.story_id
                    this.storyEntryId = entry.id
                    this.setData(entry)
                })
        }
    }

    public onCrisisWorkerSelect(items: User[], index: number) {
        const workersCtrl = this.form.get("call_partner_crisis").get("workers") as FormArray
        const telCtrl = workersCtrl.at(index).get("tel")

        if (items.length) {
            let user = items[0] as any

            if (typeof user.id === "number") {
                telCtrl.disable()
                telCtrl.setValue(user.phone)
            } else {
                telCtrl.enable()
            }
        } else {
            telCtrl.setValue(null)
            telCtrl.enable()
        }
        // console.log("onCrisisWorkerSelect", items, index)
    }

    public onCrisisCarSelect(items: CrisisCar[]) {
        this.selectedCrisisCar = items[0]
        this.cdr.detectChanges()
    }

    protected createMessageControl(id?: number, message?: string) {
        return new FormGroup({
            id: new FormControl(id),
            message: new FormControl(message)
        })
    }

    protected createCrisisWorkerControl(crisisCarId?: number, userId?: number, tel?: string) {
        return new FormGroup({
            crisis_car_id: new FormControl(crisisCarId),
            user_id: new FormControl(userId),
            tel: new FormControl(tel),
        })
    }

    private _cleanupFormData(data: { [key: string]: any }) {
        const callPartnerType: CallPartnerTypeChoice = data.call_partner_type
        const all = [
            "call_partner_client_id",
            "call_partner_section_id",
            "call_partner_crisis_id",
            "call_partner_user_id",
            "call_partner_name",
            "call_partner_tel",
            "call_partner_crisis",
            "is_crisis_login"
        ]

        let required: string[] = []

        switch (callPartnerType) {
            case "r":
            case "m":
                required = ["call_partner_name", "call_partner_tel"]
                break

            case "eü":
            case "ei":
                required = ["call_partner_section_id", "call_partner_name", "call_partner_tel"]
                break

            case "sz":
                required = ["call_partner_user_id", "call_partner_section_id"]
                break

            case "ü":
                required = ["call_partner_client_id"]
                break

            case "kr":
                required = ["call_partner_crisis_id", "is_crisis_login"]
                if (this.form.get("is_crisis_login").value) {
                    required.push("call_partner_crisis")
                }
                break
        }

        for (const name of all) {
            if (required.indexOf(name) === -1) {
                data[name] = null
            }
        }

        if (!this.isIncoming || this.form.get("call_type").value !== "PI" || !this.form.get("has_arrive_time").value) {
            data.arrive_time = null
        }
    }
}
