import { Component, Inject, ChangeDetectorRef } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { take, switchMap, filter } from "rxjs/operators"

import { LayerRef, ToastService, UploadedFile, Destructible } from "@anzar/core"
import { DocumentBackend } from "@backend/documents.api"
import { Client } from "@backend/client.api"
import { FileUploaderService } from "@pyzar/fs.module"


@Component({
    selector: "rege-doc-form-upload",
    templateUrl: "./upload-form.component.pug",
    providers: [FileUploaderService]
})
export class UploadFormComponent extends Destructible {
    public readonly form = new FormGroup({
        file: new FormControl(),
        title: new FormControl()
    })

    public set busy(val: boolean) {
        if (this._busy !== val) {
            this._busy = val
            this.cdr.detectChanges()
        }
    }
    public get busy(): boolean { return this._busy }
    private _busy: boolean

    public constructor(
        @Inject(Client) private readonly client: Client,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(FileUploaderService) private readonly uploderSvc: FileUploaderService,
        @Inject(DocumentBackend) private readonly docBackend: DocumentBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super()
    }

    public save() {
        this.destruct.subscription(this.uploderSvc.upload(`/client/${this.client.id}`))
            .pipe(
                filter(e => {
                    return e.state === "done"
                }),
                switchMap(_ => {
                    const data = this.form.value
                    const upFile = data.file as UploadedFile
                    return this.docBackend.save_upload({
                        client_id: this.client.id,
                        file_id: upFile ? upFile.id : null,
                        title: data.title || upFile.name
                    }).pipe(take(1))
                })
            )
            .subscribe(event => {
                this.layerRef.close()
            })
    }

    public cancel() {
        this.layerRef.close()
    }
}
