import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, SimpleChanges } from "@angular/core"

import { LocaleService } from "@anzar/core"

import { Client } from "@backend/client.api"

@Component({
    selector: ".rege-client-name",
    templateUrl: "./client-name.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientNameComponent implements OnChanges {
    @Input() public client: Client
    public birthDate: string

    public constructor(@Inject(LocaleService) protected readonly locale: LocaleService) {}

    public ngOnChanges(changes: SimpleChanges) {
        if ("client" in changes) {
            const bd = changes.client.currentValue.birth_date
            if (bd) {
                this.birthDate = this.locale.formatDate(bd, "yyyy.MM.dd.")
            } else {
                this.birthDate = "-"
            }
        }
    }
}
