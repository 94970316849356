import { Component, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Inject, Output, ElementRef, OnDestroy, Input, Injector } from "@angular/core"
import { Observable } from "rxjs"
import { take, filter, takeUntil } from "rxjs/operators"
import { addDays, subDays, startOfDay, isSameDay } from "date-fns"

import { Destructible, DatePickerService } from "@anzar/core"


@Component({
    selector: ".rege-date-switcher",
    templateUrl: "./date-switcher.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateSwitcherComponent extends Destructible {
    @Input()
    public set value(val: Date) {
        if (val) {
            val = startOfDay(val)
        }

        if (!isSameDay(this._value, val)) {
            this._value = val;
            (this.change as EventEmitter<Date>).emit(val)
            this.cdr.markForCheck()

            if (this.datePicker.instance) {
                this.datePicker.instance.writeValue(val)
            }
        }
    }
    public get value(): Date { return this._value }
    private _value: Date = startOfDay(new Date())

    @Output() public readonly change: Observable<Date> = new EventEmitter<Date>()

    public constructor(
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(DatePickerService) protected readonly datePicker: DatePickerService,
        @Inject(ElementRef) protected readonly el: ElementRef<HTMLElement>,
        @Inject(Injector) protected readonly injector: Injector) {
        super()
    }

    public inc(): void {
        this.value = addDays(this.value, 1)
    }

    public dec(): void {
        this.value = subDays(this.value, 1)
    }

    public isToday() {
        return isSameDay(this.value, new Date())
    }

    public showDatePicker() {
        this.datePicker
            .toggle({ anchor: { ref: this.el.nativeElement, align: "bottom center" }, align: "top center" }, this.value, null, this.injector)
            .pipe(takeUntil(this.destruct.on), filter(event => event.type === "value"), take(1))
            .subscribe(event => {
                if (event.type === "value") {
                    this.value = event.value
                }
            })
    }
}
