import { Component, Input, ViewChild, ElementRef, OnDestroy, AfterViewInit } from "@angular/core"

import { __zone_symbol__ } from "@anzar/core"

const MUTATION_OBSERVER = __zone_symbol__("MutationObserver")
const SET_TIMEOUT = __zone_symbol__("setTimeout")
const CLEAR_TIMEOUT = __zone_symbol__("clearTimeout")


@Component({
    selector: ".rege-status-text",
    templateUrl: "./status-text.component.pug"
})
export class StatusTextComponent implements AfterViewInit, OnDestroy {
    @ViewChild("content", { static: true, read: ElementRef }) public readonly contentEl: ElementRef<HTMLElement>
    @ViewChild("content1", { static: true, read: ElementRef }) public readonly content1El: ElementRef<HTMLElement>
    @ViewChild("content2", { static: true, read: ElementRef }) public readonly content2El: ElementRef<HTMLElement>

    @Input()
    public set autoHide(val: number) {
        if (this._autoHide !== val) {
            this._autoHide = val
        }
    }
    public get autoHide(): number { return this._autoHide }
    private _autoHide: number

    private _currentContent: number = 1
    private _mutation: MutationObserver
    private _changeTimer: any
    private _autohideTimer: any

    public constructor() {
        this._mutation = new window[MUTATION_OBSERVER](mutation => {
            this._switchContentBuffered(false)
        })
    }

    public ngAfterViewInit() {
        if (this.contentEl.nativeElement.innerHTML.length) {
            this._switchContentBuffered(false)
        }

        this._mutation.observe(this.contentEl.nativeElement, {
            characterData: true,
            childList: true,
            subtree: true
        })
    }

    private _switchContentBuffered(clear: boolean) {
        if (this._changeTimer) {
            window[CLEAR_TIMEOUT](this._changeTimer)
        }
        this._changeTimer = window[SET_TIMEOUT](this._switchContent.bind(this, clear), 100)
    }

    private _switchContent(clear: boolean) {
        if (this._autoHide) {
            if (this._autohideTimer) {
                window[CLEAR_TIMEOUT](this._autohideTimer)
            }
            this._autohideTimer = window[SET_TIMEOUT](this._switchContent.bind(this, true), this._autoHide)
        }

        let fromEl: HTMLElement, toEl: HTMLElement
        if (this._currentContent === 1) {
            fromEl = this.content1El.nativeElement
            toEl = this.content2El.nativeElement
            this._currentContent = 2
        } else {
            fromEl = this.content2El.nativeElement
            toEl = this.content1El.nativeElement
            this._currentContent = 1
        }

        toEl.style.transition = "none"
        toEl.style.transform = "translate(0, 100%)"
        // flush style
        toEl.getBoundingClientRect()

        if (clear) {
            toEl.innerHTML = ""
        } else {
            this._cloneInto(toEl)
        }

        toEl.style.transition = "transform 200ms cubic-bezier(0.5, 1, 0.89, 1)"
        toEl.style.transform = "translate(0, 0)"

        fromEl.style.transition = "transform 200ms cubic-bezier(0.5, 1, 0.89, 1)"
        fromEl.style.transform = "translate(0, -100%)"
    }

    private _cloneInto(container: HTMLElement) {
        container.innerHTML = ""

        const children = this.contentEl.nativeElement.childNodes
        for (let i = 0; i < children.length; i++) {
            container.appendChild(children[i].cloneNode(true))
        }
    }

    public ngOnDestroy() {
        this._mutation.disconnect()
    }
}
