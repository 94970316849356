import { Component, Directive, Input, Inject, TemplateRef, ContentChildren, QueryList, AfterContentInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, ViewChildren } from "@angular/core"
import { startWith } from "rxjs/operators"

import { Destruct, ScrollerComponent } from "@anzar/core"


@Directive({
    selector: "[pzCardListItem]"
})
export class CardListItemDirective {
    @Input("pzCardListItem") public title: string
    @Input() public icon: string

    public constructor(@Inject(TemplateRef) public readonly tpl: TemplateRef<any>) {
    }
}


@Directive({
    selector: ".pz-card-list-section"
})
export class CardListSection { }


@Component({
    selector: ".pz-card-list",
    templateUrl: "./card-list.component.pug"
})
export class CardListComponent implements AfterViewInit, AfterContentInit, OnDestroy {
    public readonly destruct = new Destruct()

    @ContentChildren(CardListItemDirective) public items: QueryList<CardListItemDirective>
    @ViewChild("scroller", { static: true }) public scroller: ScrollerComponent
    @ViewChildren(CardListSection, { read: ElementRef }) public sections: QueryList<ElementRef<HTMLDivElement>>

    @Input() public contentWidth: number


    public set visibleIndex(val: number) {
        val = Number(val)
        if (this._visibleIndex !== val) {
            this._visibleIndex = val
            this.cdr.markForCheck()
        }
    }
    public get visibleIndex(): number { return this._visibleIndex }
    private _visibleIndex: number = -1

    private _sections: Array<HTMLDivElement> = []

    public constructor(
        @Inject(ElementRef) private readonly el: ElementRef<HTMLElement>,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
    }


    public scrollTo(index: number) {
        this.visibleIndex = index

        let section = this._sections[index]
        if (section && this.scroller) {
            let rect = this.scroller.service.getElementImmediateRect(section)
            this.scroller.service.scrollTo({
                top: rect.top - 1,
                left: this.scroller.service.scrollPosition.left
            }, { smooth: true, velocity: 1 })
        }
    }

    public ngAfterContentInit() {

    }

    public ngAfterViewInit() {
        this.destruct.subscription(this.sections.changes).pipe(startWith(null)).subscribe(changes => {
            this._sections = this.sections.toArray().map(v => v.nativeElement)
            if (this._visibleIndex !== -1) {
                let tmp = this._visibleIndex
                this._visibleIndex = -1
                this.visibleIndex = tmp
            } else {
                this.visibleIndex = 0
            }
        })

        const scrollerSvc = this.scroller.service
        this.destruct.subscription(this.scroller.service.vpImmediate.scroll).subscribe(onScroll => {
            const vp = scrollerSvc.vpImmediate.visible
            let idx = 0

            for (let i = 0, l = this._sections.length; i < l; i++) {
                const section = this._sections[i]
                const rect = scrollerSvc.getElementImmediateRect(section)
                if (rect.top >= vp.top && rect.top <= vp.bottom) {
                    idx = i
                    break
                } else if (rect.bottom >= vp.top && rect.bottom <= vp.bottom) {
                    idx = i
                } else if (rect.top <= vp.top && rect.bottom >= vp.bottom) {
                    idx = i
                    break
                }
            }

            this.visibleIndex = idx
        })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}
