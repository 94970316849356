import { NgModule } from "@angular/core"
import { Notification, NotificationAssign, NotificationBackend, NotificationAssignBackend, NotificationAssignBackendSource } from "./__anzar_rpc_output"

export { Notification, NotificationAssign, NotificationBackend, NotificationAssignBackend, NotificationAssignBackendSource }


@NgModule({
    providers: [
        NotificationAssignBackendSource,
        NotificationAssignBackend,
        NotificationBackend
    ],
})
export class NotificationApi {}