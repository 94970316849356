import { Directive, forwardRef, Input, Inject } from "@angular/core"
import { Observable, of } from "rxjs"
import { map, switchMap, takeUntil } from "rxjs/operators"
import { format } from "date-fns"

import { DatePickerDayDataProvider, ExternalDayData } from "@anzar/core"

import { DutyBackend } from "@backend/org.api"
import { CurrentSection } from "../section.service/level"
import { AuthService } from "@pyzar/auth.module"


@Directive({
    selector: "[regeScDutyDayData]",
    providers: [
        { provide: DatePickerDayDataProvider, useExisting: forwardRef(() => ScDutyDayDataProvider) }
    ]
})
export class ScDutyDayDataProvider extends DatePickerDayDataProvider {

    @Input("regeScDutyDayData") public enabled: boolean

    public constructor(
        @Inject(DutyBackend) private readonly dutyBackend: DutyBackend,
        @Inject(CurrentSection) private readonly currentSection: CurrentSection,
        @Inject(AuthService) private readonly authSvc: AuthService) {
        super()
    }

    public extraData(begin: Date, end: Date): Observable<ExternalDayData> {
        if (this.enabled) {
            return this.dutyBackend
                .get_month_breakdown({
                    begin: format(begin, "yyyy-MM-dd") as any,
                    end: format(end, "yyyy-MM-dd") as any,
                    section_id: this.currentSection.id
                })
                .pipe(
                    switchMap(breakdown => {
                        return this.authSvc.currentUser$.pipe(map(currentUser => {
                            let dayData: ExternalDayData = {}

                            for (const day in breakdown) {
                                dayData[Number(day)] = {
                                    color: "info",
                                    variant: findUser(breakdown[day], currentUser.id) ? 'filled icon' : 'icon'
                                }
                            }

                            return dayData
                        }))
                    })
                )
        } else {
            return of(null)
        }
    }
}


function findUser(dutiesData: any[], userId: number): boolean {
    for (const d of dutiesData) {
        if (d[1].indexOf(userId) !== -1) {
            return true
        }
    }
    return false
}
