import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { RouterModule, Route } from "@angular/router"
import { NzModule } from "@anzar/core"

import { DispatcherApi } from "@backend/dispatcher.api"
import { OrgApi } from "@backend/org.api"
import { PyzarApi } from "@backend/pyzar.api"
import { WorkerApi } from "@backend/worker.api"

import { ComponentsModule } from "../components"
import { ClientModule } from "../client.module"
import { RichtextModule, DispatcherRTModule } from "../richtext.module"
import { WorkerModule } from "../worker.module"
import { PlaceModule } from "../place.module"

import { StoriesScreen } from "./stories.screen"
// import { StoryScreen } from "./story.screen"
import { StoryComponent } from "./story.component"
import { StoriesGridComponent } from "./stories-grid.component"
import { StoryFormComponent } from "./story-form.component"
import { StoryEntryListComponent } from "./story-entry-list.component"
import { SwitchConfirmComponent } from "./switch-confirm.component"

import { StoryEntryListPopup, StoryEntryListPopupService } from "./story-entry-list.popup"
export { StoryEntryListPopup, StoryEntryListPopupService }

import { DispatcherSharedModule } from "./shared"


export const DISPATCHER_ROUTES: Route[] = [
    { path: "tortenetek", component: StoriesScreen },
    // { path: "tortenet", component: StoryScreen },
    // { path: "tortenet/kimeno", component: StoryScreen, data: { create: "outgoing" } },
    // { path: "tortenet/bejovo", component: StoryScreen, data: { create: "incoming" } },
    // { path: "tortenet/visszahivas/:id/:entryId", component: StoryScreen, data: { create: "callback" } },
    // { path: "tortenet/:id", component: StoryScreen },
    // { path: "tortenet/:id/kimeno", component: StoryScreen, data: { create: "outgoing" } },
    // { path: "tortenet/:id/bejovo", component: StoryScreen, data: { create: "incoming" } },
]


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        RouterModule,
        ComponentsModule,
        RichtextModule,
        DispatcherRTModule,
        WorkerModule,
        PlaceModule,
        ClientModule,

        DispatcherApi,
        OrgApi,
        PyzarApi,
        DispatcherSharedModule,
        WorkerApi
    ],
    declarations: [
        StoriesScreen,
        // StoryScreen,
        StoryComponent,
        StoriesGridComponent,
        StoryFormComponent,
        StoryEntryListComponent,
        StoryEntryListPopup,
        SwitchConfirmComponent
    ],
    exports: [
        StoriesScreen,
        // StoryScreen,
        StoryComponent,
        StoriesGridComponent,
        StoryFormComponent,
        StoryEntryListComponent,
        StoryEntryListPopup,
        SwitchConfirmComponent
    ],
    providers: [
        StoryEntryListPopupService
    ],
    entryComponents: [
        SwitchConfirmComponent,
        StoryEntryListPopup
    ]
})
export class DispatcherModule {

}
