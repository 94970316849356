import { Component } from "@angular/core"


@Component({
    selector: ".rege-duty-screen",
    host: {
        "[style.height]": "'100%'",
        "[style.width]": "'100%'",
        "[style.position]": "'relative'",
    },
    templateUrl: "./duty.screen.pug"
})
export class DutyScreen {

}
