import { Component, Injectable, Inject } from "@angular/core"

import { LayerRef, LayerService, ModalLayer, Rect } from "@anzar/core"

import { Place, PlaceBackend } from "@backend/place.api"


@Injectable({ providedIn: "root" })
export class PlaceInfoService {
    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(PlaceBackend) private readonly placeBackend: PlaceBackend) {

    }

    public show(place_id: string) {
        this.placeBackend.get({ id: place_id }).subscribe(place => {
            const vp = Rect.viewport()
            const behavior = new ModalLayer({
                backdrop: { type: "filled", hideOnClick: true },
                closeable: true,
                elevation: 10,
                rounded: 3,
                minWidth: vp.width * 0.9,
                minHeight: vp.height * 0.9,
                position: {
                    align: "center"
                }
            })

            const ref = this.layerSvc.createFromComponent(PlaceInfoComponent, behavior, null, [
                { provide: Place, useValue: place }
            ])
            ref.show()
        })
    }
}


@Component({
    selector: "rege-place-info",
    templateUrl: "./place-info.component.pug"
})
export class PlaceInfoComponent {
    public readonly coords: google.maps.LatLngLiteral

    public constructor(
        @Inject(LayerRef) public readonly layerRef: LayerRef,
        @Inject(Place) public readonly place: Place) {
        this.coords = place.location
        console.log(place)
    }

    public close() {
        this.layerRef.close()
    }
}
