import { Component, Inject, InjectionToken } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { of } from "rxjs"
import { map, switchMap } from "rxjs/operators"
import { format } from "date-fns"

import { FileDownloadService, ToastService, LayerRef } from "@anzar/core"
import { SectionBackendSource } from "@backend/org.api"
import { SectionService } from "../../section.service"


export const FEED_EXPORT_TYPES = new InjectionToken("FEED_EXPORT_TYPES")
export const FEED_EXPORT_SECTION_IDS = new InjectionToken("FEED_EXPORT_SECTION_IDS")
export const FEED_EXPORT_AUTHOR = new InjectionToken("FEED_EXPORT_AUTHOR")
export const FEED_EXPORT_CLIENT_ID = new InjectionToken("FEED_EXPORT_CLIENT_ID")


@Component({
    selector: "rege-client-feed-export",
    templateUrl: "client-feed-export.component.pug"
})
export class ClientFeedExportComponent {
    public readonly form = new FormGroup({
        begin: new FormControl(),
        end: new FormControl(),
        sectionIds: new FormControl(),
        author: new FormControl(),
        types: new FormControl(),
    })

    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(SectionBackendSource) public readonly sectionSrc: SectionBackendSource,
        @Inject(SectionService) public readonly sectionService: SectionService,
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(FEED_EXPORT_TYPES) types: any,
        @Inject(FEED_EXPORT_SECTION_IDS) sectionIds: any,
        @Inject(FEED_EXPORT_AUTHOR) author: any,
        @Inject(FEED_EXPORT_CLIENT_ID) public readonly clientId: number) {
        this.form.get("types").setValue(types || ["care", "service", "data_change"])
        this.form.get("sectionIds").setValue(sectionIds)
        this.form.get("author").setValue(author || "any")
    }

    public cancel() {
        this.layerRef.close()
    }

    public export() {
        const data = this.form.value

        const sectionIds = data.sectionIds && data.sectionIds.length
            ? of(data.sectionIds)
            : this.sectionService.ownSections$.pipe(map(v => v.map(section => section.id)))

        sectionIds
            .pipe(
                switchMap(sectionIds => {
                    return this._export(
                        data.author,
                        data.begin,
                        data.end,
                        sectionIds,
                        data.types
                    )
                })
            )
            .subscribe()
    }

    protected _export(author: "own" | "colleagues" | "all", begin: Date, end: Date, sectionIds: number[], types: string[]) {
        let url = `${BACKEND_URL}/stats/events`

        if (sectionIds && sectionIds.length) {
            url += `/${sectionIds.join(',')}`
        } else {
            url += "/0"
        }

        url += `/${format(begin, "yyyy-MM-dd")}`
        url += `/${format(end, "yyyy-MM-dd")}`
        url += `/${types.join(',')}`
        url += `/${author}`

        if (this.clientId) {
            url += `?client_id=${this.clientId}`
        }

        return this.downloader.download(url)
            .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Fájl generálása" }))
    }
}
