import { Component, Input, Inject, OnDestroy, OnInit, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core"
import { FormControl } from "@angular/forms"
import { EMPTY } from "rxjs"
import { take, catchError } from "rxjs/operators"

import { Destruct, StaticSource, ToastService } from "@anzar/core"

import { SectionBackend, Section } from "@backend/org.api"
import { EventBackend, Event } from "@backend/event.api"
import { CurrentSection } from "../../section.service/level"
import { SectionService } from "../../section.service"



@Component({
    selector: ".rege-client-feed-add",
    templateUrl: "./client-feed-add.template.pug",
    providers: [CurrentSection]
})
export class ClientFeedAddComponent implements OnDestroy, OnInit {
    public readonly destruct = new Destruct()

    @ViewChild("inputEl", { read: ElementRef, static: true }) public readonly inputEl: ElementRef<HTMLElement>

    @Input() public clientId: number
    @Output() public cancel = new EventEmitter<void>()
    @Output() public save = new EventEmitter<Event>()

    public ownSectionsSrc: StaticSource<Section>
    public sectionId = new FormControl()
    public text = new FormControl()

    public constructor(
        @Inject(CurrentSection) public readonly section: CurrentSection,
        @Inject(SectionBackend) public readonly sectionBackend: SectionBackend,
        @Inject(EventBackend) public readonly eventBackend: EventBackend,
        @Inject(SectionService) private readonly sectionSvc: SectionService,
        @Inject(ToastService) private readonly toast: ToastService) {

        this.destruct.subscription(sectionSvc.ownSections$).subscribe(ownSections => {
            this.ownSectionsSrc = new StaticSource(Section, ownSections)
        })

        this.sectionId.setValue(section.id)
        this.destruct.subscription(section.id$).subscribe(sectionId => {
            this.sectionId.setValue(sectionId)
        })
    }

    public ngOnInit() {
        (this.inputEl.nativeElement.querySelector("[contenteditable]") as HTMLElement).focus()
    }

    public onCancel() {
        this.sectionId.setValue(this.section.id)
        this.text.setValue("")
        this.cancel.next()
    }

    public onSave() {
        let data = {
            content: this.text.value,
            section_id: this.sectionId.value,
            client_id: this.clientId,
            type: "care"
        }

        this.eventBackend.save({ data })
            .pipe(
                take(1),
                catchError(err => {
                    this.toast.error(err.message)
                    return EMPTY
                })
            )
            .subscribe(event => {
                this.sectionId.setValue(this.section.id)
                this.text.setValue("")
                this.save.next(event)
            })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}
