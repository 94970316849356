import { Directive, Input, forwardRef } from "@angular/core"
import { AbstractControl, Validator, ValidationErrors, NG_VALIDATORS } from "@angular/forms"


export function validateEqual(otherControlName: string): (control: AbstractControl) => ValidationErrors | null {
    return (control) => {
        const otherControl = control.root.get(otherControlName)
        if (!otherControl) {
            return null
        }
        return validateControlsEq(control, otherControl)
    }
}


@Directive({
    selector: "[validateEqual][formControlName],[validateEqual][formControl]",
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidateEqualDirective) }
    ]
})
export class ValidateEqualDirective implements Validator {
    @Input("validateEqual")
    public set otherField(val: string | AbstractControl) {
        if (this._otherField !== val) {
            this._otherField = val
            if (this._onChange) {
                this._onChange
            }
        }
    }
    public get otherField(): string | AbstractControl { return this._otherField }
    private _otherField: string | AbstractControl

    private _onChange: () => void

    public validate(control: AbstractControl): ValidationErrors | null {
        const otherControl = this._otherField instanceof AbstractControl
            ? this._otherField
            : control.root.get(this._otherField)
        return validateControlsEq(control, otherControl)
    }

    public registerOnValidatorChange(fn: () => void): void {
        this._onChange = fn
    }
}


function validateControlsEq(a: AbstractControl, b: AbstractControl): ValidationErrors | null {
    return a.value === b.value ? null : { validateEqual: true }
}
