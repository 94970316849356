import { Component, Inject, AfterViewInit } from "@angular/core"

import { CordovaService, DoubleBackExitService } from "@anzar/core"
import { CurrentSection, CurrentHeadquerter } from "./section.service/level"


@Component({
    selector: "app",
    templateUrl: "./app.template.pug",
    providers: [
        CurrentSection, CurrentHeadquerter
    ]
})
export class AppComponent implements AfterViewInit {
    public constructor(
        @Inject(CordovaService) private readonly cordova: CordovaService,
        @Inject(DoubleBackExitService) private readonly dbe: DoubleBackExitService) {

    }

    public ngAfterViewInit() {
        // this.cordova.enterFullScreen()
        // this.cordova.hideSplashScreen(500)

        if (__PLATFORM__ !== "browser") {
            this.dbe.install()
        }
    }
}
