import { NgModule } from "@angular/core"
import { Hashtag, Richtext, RichtextCType, RichtextComponent, RC_ClientLink, RC_WorkerLink, RC_Hashtag, HashtagBackend, HashtagBackendSource } from "./__anzar_rpc_output"

export { Hashtag, Richtext, RichtextCType, RichtextComponent, RC_ClientLink, RC_WorkerLink, RC_Hashtag, HashtagBackend, HashtagBackendSource }


@NgModule({
    providers: [
        HashtagBackendSource,
        HashtagBackend
    ],
})
export class RichtextApi {}