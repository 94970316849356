import { Component, forwardRef, Inject } from "@angular/core"
import { Observable, of } from "rxjs"

import { PermissionGroupRepo, PermissionGroupRepoSource } from "@backend/pyzar.api"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "../../common.module/components/card-list/abstract"
import { UserEditorService } from "./user-editor.service"


@Component({
    selector: ".pz-user-groups",
    templateUrl: "./groups.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => UserGroupsComponent) }
    ]
})
export class UserGroupsComponent extends AbstractCLEditorBlock<UserEditorService> {
    public readonly groups$ = this.permGroupRepo.search()

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: UserEditorService,
        @Inject(PermissionGroupRepo) private readonly permGroupRepo: PermissionGroupRepo,
        @Inject(PermissionGroupRepoSource) public readonly groupSrc: PermissionGroupRepoSource) {
        super(editorSvc, editorSvc.form)

        this.destruct.subscription(this.form.get("groups").valueChanges).subscribe(value => {
            this.form.markAsDirty()
        })
    }

    public save(): Observable<any> {
        return of(null)
    }
}
