import { Inject, Injectable } from "@angular/core"
import { Subject, of } from "rxjs"
import { shareReplay, switchMap, tap, take } from "rxjs/operators"

import { LevelBackend, Global } from "@backend/org.api"
import { LoadFields } from "@anzar/core"
import { AbstractCLEditorService } from "@pyzar/common.module"


const FIELDS: LoadFields<Global> = [
    "id", "parent_id", "title", "variant"
]


@Injectable()
export class GlobalEditorService extends AbstractCLEditorService {
    public set id(val: number) {
        if (this._id !== val) {
            this._idChange.next(this._id = val)
        }
    }
    public get id(): number { return this._id }
    private _id: number

    private _idChange = new Subject<number>()
    public readonly id$ = this._idChange.pipe(shareReplay(1))

    public readonly global$ = this.id$.pipe(
        switchMap(id => {
            if (id) {
                return this.levelbackend.get({ id }, { loadFields: FIELDS })
                    .pipe(
                        take(1),
                        tap(val => (this as { global: Global }).global = val)
                    )
            } else {
                return of(null)
            }
        }),
        shareReplay(1)
    )

    public readonly global: Global

    public constructor(
        @Inject(LevelBackend) private readonly levelbackend: LevelBackend) {
        super()
    }
}
