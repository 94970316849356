import { Component, forwardRef, Inject, OnInit, Input, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormArray, FormControl, Validators } from "@angular/forms"
import { of, Subject, merge, zip } from "rxjs"
import { take, startWith, switchMap, shareReplay, debounceTime, map, tap } from "rxjs/operators"

import { LoadFields, StaticSource } from "@anzar/core"
import { Provider, ProviderServiceBackend, ProviderService, ProviderBackend } from "@backend/org.api"


import { Service, ServiceBackend, ServiceType } from "@backend/service.api"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { ProviderEditorService, ServiceItem, SERVICES_WITH_EDITOR } from "../provider/provider-editor.service"


const SERVICE_TYPE_TITLE: { [key: string]: string } = {}

for (const v of ServiceType.DATA.data) {
    SERVICE_TYPE_TITLE[v.id] = v.title
}


@Component({
    selector: ".rege-provider-service-editor",
    templateUrl: "./service-editor.component.pug",
    host: {
        "[style.padding]": "'16px 0'"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => ServiceEditorComponent) }
    ]
})
export class ServiceEditorComponent extends AbstractCLEditorBlock<ProviderEditorService> {
    public get serviceControls() { return (this.form.get("services") as FormArray).controls }

    public providerId: number
    public services: Service[] = []
    public servicesSrc = new StaticSource(Service as any, [])

    private _reload = this.destruct.subject(new Subject<void>())

    private services$ = this.serviceBackend.search().pipe(shareReplay(1))
    private providerService$ = this._reload.pipe(
        switchMap(_ => {
            return this.pserviceBackend.search({ filter: { provider_id: this.providerId } })
        }),
        shareReplay(1)
    )

    private items$ = merge(this.services$, this.providerService$).pipe(
        debounceTime(10),
        switchMap(v => zip(this.services$, this.providerService$)),
        map(([services, pservices]) => {
            return services
                .map(service => {
                    return new ServiceItem(service, pservices.find(ps => ps.service_id === service.id))
                })
                .sort((a, b) => {
                    let idx = 0
                    if (a.pservice && a.pservice.is_active && b.pservice && b.pservice.is_active) {
                        idx = 0
                    } else if (a.pservice && a.pservice.is_active) {
                        idx = -1
                    } else if (b.pservice && b.pservice.is_active) {
                        idx = 1
                    }
                    if (idx === 0) {
                        return a.service.title.localeCompare(b.service.title)
                    } else {
                        return idx
                    }
                })
        }),
        tap(items => {
            this._items = items
            this.services = items.map(i => i.service)
            this.servicesSrc.replace(this.services as any)
            this._updateServices(this.form.get("services").value)
        }),
        shareReplay(1)
    )

    private _items: ServiceItem[] = []

    public readonly stTtitle = SERVICE_TYPE_TITLE

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: ProviderEditorService,
        @Inject(ProviderBackend) private readonly providerBackend: ProviderBackend,
        @Inject(ServiceBackend) private readonly serviceBackend: ServiceBackend,
        @Inject(ProviderServiceBackend) private readonly pserviceBackend: ProviderServiceBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super(editorSvc, new FormGroup({
            services: new FormArray([]),
            primary: new FormControl()
        }))

        this.destruct.subscription(this.form.get("primary").valueChanges).subscribe(primary => {
            const controls = this.serviceControls

            for (const ctrl of controls) {
                let is_primary = ctrl.get("service_id").value === primary
                let isActiveCtrl = ctrl.get("is_active")
                let isPinnedCtrl = ctrl.get("is_pinned")
                ctrl.get("is_primary").setValue(is_primary)
                if (is_primary) {
                    isActiveCtrl.setValue(true)
                    isActiveCtrl.disable()
                    isPinnedCtrl.disable()
                } else {
                    isActiveCtrl.enable()
                    isPinnedCtrl.enable()
                }
            }
        })

        const servicesCtrl = this.form.get("services") as FormArray
        this.destruct.subscription(servicesCtrl.valueChanges).pipe(debounceTime(20)).subscribe(values => {
            this._updateServices(values)
            servicesCtrl.markAsTouched()
            servicesCtrl.markAsDirty()
        })

        this.destruct.subscription(this.items$).subscribe(items => {
            let primary: number
            servicesCtrl.clear()

            for (const item of items) {
                servicesCtrl.controls.push(new FormGroup({
                    id: new FormControl(item.pservice ? item.pservice.id : null),
                    service_id: new FormControl(item.service.id),
                    is_active: new FormControl(item.pservice ? item.pservice.is_active : false),
                    is_pinned: new FormControl(item.pservice ? item.pservice.is_pinned : false),
                    auto_tick: new FormControl(item.pservice ? item.pservice.auto_tick : false),
                    is_primary: new FormControl(item.pservice ? item.pservice.is_primary : false),
                }))
                if (item.pservice && item.pservice.is_primary) {
                    primary = item.service.id
                }
            }

            (servicesCtrl as any)._setUpControls();
            (servicesCtrl as any)._onCollectionChange()
            servicesCtrl.updateValueAndValidity()
            this.form.get("primary").reset(primary)
            this.cdr.markForCheck()
        })

        this.destruct.subscription(this.editorSvc.changes).pipe(startWith(null)).subscribe(changes => {
            if (this.providerId !== this.editorSvc.providerId) {
                this.providerId = this.editorSvc.providerId
                this.realod()
            }
        })
    }

    public save() {
        const servicesCtrl = this.form.get("services") as FormArray
        return this.providerBackend.set_services({ provider_id: this.providerId, services: servicesCtrl.value }).pipe(
            tap(res => {
                this.realod()
            })
        )
    }

    public realod() {
        this._reload.next()
    }

    private _updateServices(values: Array<{ [key: string]: any }>) {
        for (let i = 0, l = values.length; i < l; i++) {
            const item = this._items[i]
            if (SERVICES_WITH_EDITOR.indexOf(item.service.type) !== -1) {
                const value = values[i]
                if (value.is_active || value.is_primary) {
                    this.editorSvc.addEditableService(item)
                } else {
                    this.editorSvc.delEditableService(item)
                }
            }
        }
    }
}
