import { Component, Inject, InjectionToken } from "@angular/core"
import { Observable, of } from "rxjs"
import { map, tap } from "rxjs/operators"

import { LoadFields, LayerRef } from "@anzar/core"
import { User } from "@backend/pyzar.api"
import { WorkerBackend } from "@backend/worker.api"
import { FsService } from "@pyzar/fs.module"


export const WCDI_MESSAGE = new InjectionToken("WCDI_MESSAGE")
export const WCDI_CREATOR_ID = new InjectionToken("WCDI_CREATOR_ID")
export const WCDI_CREATED_TIME = new InjectionToken("WCDI_CREATED_TIME")
export const WCDI_PERMISSION = new InjectionToken("WCDI_PERMISSION")
export const WCDI_LEVEL_ID = new InjectionToken("WCDI_LEVEL_ID")


const USER_FIELDS: LoadFields<User> = [
    "id", "name", "email", "phone", "profile_image_id"
]


@Component({
    templateUrl: "who-can-do-it.component.pug"
})
export class WhoCanDoItComponent {
    public readonly creator$ = this.workerBackend
        .get({ id: this.creatorId }, { loadFields: USER_FIELDS })
        .pipe(
            tap(user => {
                if (user && user.profile_image_id) {
                    this.creatorImage = this.fsService.getImageUrl(user.profile_image_id, 100, 100)
                } else {
                    this.creatorImage = null
                }
            })
        )

    public readonly others$: Observable<User[]>
    public creatorImage: string

    public constructor(
        @Inject(WCDI_MESSAGE) public readonly message: string,
        @Inject(WCDI_CREATOR_ID) public readonly creatorId: number,
        @Inject(WCDI_CREATED_TIME) public readonly createdTime: Date,
        @Inject(WCDI_PERMISSION) public readonly permission: string,
        @Inject(WCDI_LEVEL_ID) public readonly levelId: number,
        @Inject(WorkerBackend) private readonly workerBackend: WorkerBackend,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(FsService) private readonly fsService: FsService) {

        if (levelId && permission) {
            this.others$ = this.workerBackend
                .search(
                    {
                        filter: {
                            level_id: this.levelId,
                            permission: this.permission
                        },
                        order: { name: "asc" }
                    },
                    { loadFields: USER_FIELDS }
                )
                .pipe(
                    map(users => {
                        return users.filter(u => u.id !== creatorId)
                    })
                )
        } else {
            this.others$ = of([])
        }
    }

    public close() {
        this.layerRef.hide()
    }
}
