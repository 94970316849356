import { NgModule } from "@angular/core"
import { Room, Bed, BedReservation, CapacityUsage, RoomBackend, BedBackend, BedReservationBackend, BedBackendSource, BedReservationBackendSource, RoomBackendSource } from "./__anzar_rpc_output"

export { Room, Bed, BedReservation, CapacityUsage, RoomBackend, BedBackend, BedReservationBackend, BedBackendSource, BedReservationBackendSource, RoomBackendSource }


@NgModule({
    providers: [
        BedBackendSource,
        BedReservationBackendSource,
        RoomBackendSource,
        BedBackend,
        BedReservationBackend,
        RoomBackend
    ],
})
export class Service_roomApi {}