import { Inject, InjectionToken } from "@angular/core"

import { exhaustMap, map, Observable, scan, shareReplay, Subject, takeUntil, tap, timer } from "rxjs"

import { ToastService } from "@anzar/core"

import { TaskLogRecord, TaskManager } from "@backend/pyzar.api"

export const RUNNING_TASK_ID = new InjectionToken<string>("RUNNING_TASK_ID")

export class RunningTask {
    private readonly executed = new Subject<void>()
    private readonly tick = timer(0, 500).pipe(takeUntil(this.executed))

    private lastLogIndex = 0

    public readonly info = this.tick.pipe(
        exhaustMap(() =>
            this.taskmgr.info({ id: this.taskId, log_begin: this.lastLogIndex }).pipe(this.toast.catchError())
        ),
        tap(info => {
            if (!info) {
                return
            }

            if (info.status === "success" || info.status === "failure") {
                this.executed.next()
            }

            this.lastLogIndex += info.logs.length
        }),
        shareReplay(1)
    )

    public readonly logs: Observable<Array<TaskLogRecord | null>> = this.info.pipe(
        map(info => info.logs.filter(v => !!v)),
        scan((logs, current) => logs.concat(current), []),
        shareReplay(1)
    )

    public constructor(
        @Inject(TaskManager) private readonly taskmgr: TaskManager,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(RUNNING_TASK_ID) public readonly taskId: string
    ) {}
}
