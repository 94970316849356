import { Component, Inject, forwardRef, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { tap } from "rxjs/operators"

import { InstitutionBackend, Institution, LevelBackendSource } from "@backend/org.api"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { InstitutionEditorService } from "./institution-editor.service"


@Component({
    selector: ".rege-institution-basic-data",
    templateUrl: "./institution-basic-data.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => InstitutionBasicDataComponent) }
    ]
})
export class InstitutionBasicDataComponent extends AbstractCLEditorBlock<InstitutionEditorService> {
    public readonly institution: Institution

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: InstitutionEditorService,
        @Inject(InstitutionBackend) protected readonly institutionBackend: InstitutionBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(LevelBackendSource) public readonly levelSrc: LevelBackendSource) {
        super(editorSvc, new FormGroup({
            parent_id: new FormControl(),
            title: new FormControl()
        }))
    }

    public ngOnInit() {
        super.ngOnInit()
        this.destruct.subscription(this.editorSvc.institution$).subscribe(institution => {
            (this as { institution: Institution }).institution = institution
            this.form.reset({
                parent_id: institution ? institution.parent_id : this.editorSvc.parentId,
                title: institution ? institution.title : null,
            })
            this.cdr.detectChanges()
        })
    }

    public save() {
        let data = this.form.value

        if (this.institution) {
            data.id = this.institution.id
        }

        return this.institutionBackend.save({ data }).pipe(tap(level => {
            this.editorSvc.id = level.id
        }))
    }
}
