import { Component, Inject, InjectionToken } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { of } from "rxjs"
import { take } from "rxjs/operators"

import { LayerRef, ClosingGuarded, Destructible, ToastService } from "@anzar/core"
import { AuthService } from "@pyzar/auth.module"
import { ClosingGuardService } from "../common"
import { Feedback } from "@backend/feedback.api"


export const IS_SMALL_SCREEN = new InjectionToken("IS_SMALL_SCREEN")


@Component({
    selector: "rege-feedback",
    host: {
        "[style.width]": "isSm ? null : '500px'"
    },
    templateUrl: "./feedback.component.pug",
    providers: [ClosingGuardService]
})
export class FeedbackComponent extends Destructible implements ClosingGuarded {
    public readonly form = new FormGroup({
        name: new FormControl(),
        email: new FormControl(),
        title: new FormControl(),
        text: new FormControl(),
    })

    public constructor(
        @Inject(LayerRef) private readonly ref: LayerRef,
        @Inject(AuthService) private readonly auth: AuthService,
        @Inject(Feedback) private readonly feedback: Feedback,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(ClosingGuardService) private readonly closingSvc: ClosingGuardService,
        @Inject(IS_SMALL_SCREEN) private readonly isSm: boolean) {
        super()
        this.destruct.subscription(auth.currentUser$).subscribe(user => {
            this.form.reset({
                name: user.name.formatted,
                email: user.email
            })
        })

        const closeable = closingSvc.closeableTracker("Biztosan bezárod?")
        this.destruct.subscription(this.form.statusChanges).subscribe(v => {
            if (this.form.dirty) {
                closeable.no()
            } else {
                closeable.yes()
            }
        })
    }

    public cancel() {
        this.ref.hide()
    }

    public send() {
        const data = this.form.value

        this.feedback
            .submit({ email: data.email, user: data.name, text: data.text, title: data.title })
            .pipe(take(1), this.toastSvc.handleSave({ align: "bottom center", successMsg: "Köszönjük a visszajelzésedet ❤️!" }))
            .subscribe(res => {
                this.ref.hide()
            })
    }

    public canClose(ref: LayerRef) {
        return this.closingSvc.confirm()
    }
}
