import { NgModule } from "@angular/core"
import { WorkerMessage, WorkerMsgBackend, WorkerBackend, WorkerBackendSource, WorkerMsgBackendSource } from "./__anzar_rpc_output"

export { WorkerMessage, WorkerMsgBackend, WorkerBackend, WorkerBackendSource, WorkerMsgBackendSource }


@NgModule({
    providers: [
        WorkerBackendSource,
        WorkerMsgBackendSource,
        WorkerBackend,
        WorkerMsgBackend
    ],
})
export class WorkerApi {}