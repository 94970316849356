import { Component, Inject, Input } from "@angular/core"
import { FormGroup, FormControl, Validators } from "@angular/forms"

import { StatsService, DispatcherStatType } from "../stats.service"


@Component({
    selector: ".rege-stats-rsztop",
    templateUrl: "./rsztop.component.pug"
})
export class RsztopComponent {
    public readonly form = new FormGroup({
        date_from: new FormControl(new Date(), [Validators.required]),
        date_to: new FormControl(new Date(), [Validators.required]),
    })

    public constructor(
        @Inject(StatsService) private readonly stats: StatsService) {
    }

    public download() {
        this.stats.rsztop(this.form.get("date_from").value, this.form.get("date_to").value)
    }
}
