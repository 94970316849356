import { OnDestroy, Inject, Injectable } from "@angular/core"
import { Subject, forkJoin, of } from "rxjs"
import { shareReplay, switchMap, tap, take } from "rxjs/operators"

import { Destruct } from "@anzar/core"
import { ProviderBackend, Provider, SectionBackend, Section } from "@backend/org.api"


@Injectable()
export class SectionSheetService implements OnDestroy {
    public readonly destruct = new Destruct()

    public parentId: number

    public set sectionId(val: number) {
        if (this._sectionId !== val) {
            this._sectionId = val
            this._sectionIdChanged.next(val)
        }
    }
    public get sectionId(): number { return this._sectionId }
    private _sectionId: number
    private _sectionIdChanged = this.destruct.subject(new Subject<number>())
    public readonly sectionId$ = this._sectionIdChanged.pipe(shareReplay(1))


    private readonly _section = this.destruct.subject(new Subject<Section>())
    public readonly section$ = this._section.pipe(shareReplay(1))

    private _providers: Provider[] = []
    private _providersChange = new Subject<Provider[]>()

    public readonly providers$ = this.destruct.subscription(this._providersChange).pipe(
        shareReplay(1)
    )

    public constructor(
        @Inject(ProviderBackend) private readonly providerBackend: ProviderBackend,
        @Inject(SectionBackend) private readonly sectionBackend: SectionBackend) {
        // this.destruct.subscription(this.section$).subscribe()
        // this.destruct.subscription(this.providers$).subscribe()

        this.destruct.subscription(this.sectionId$)
            .pipe(
                switchMap(sectionId => {
                    if (sectionId) {
                        return forkJoin(
                            this.sectionBackend.get({ id: sectionId }),
                            this.providerBackend.search({ filter: { parent_id: sectionId } })
                        )
                    } else {
                        return of([null, null])
                    }
                })
            )
            .subscribe(([section, providers]) => {
                if (section) {
                    this.parentId = section.parent_id
                }
                this._section.next(section)
                this._providers = providers || []
                this._providersChange.next(this._providers)
            })
    }

    public addProvider(provider: Provider) {
        let idx = this._providers.findIndex(p => p.pk === provider.pk)
        if (idx === -1) {
            this._providers.push(provider)
        } else {
            this._providers[idx] = provider
        }
        this._providersChange.next(this._providers)
    }

    public delProvider(id: number) {
        let idx = this._providers.findIndex(p => p.id === id)
        if (idx !== -1) {
            this._providers.splice(idx, 1)
            this._providersChange.next(this._providers)
        }
    }

    public realodSection() {
        if (this._sectionId) {
            return this.sectionBackend.get({ id: this._sectionId })
                .pipe(
                    take(1),
                    tap(section => {
                        this._section.next(section)
                    })
                )
        } else {
            return of(null)
        }
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}
