import { Component, Inject, Input } from "@angular/core"
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { startOfMonth, endOfMonth } from "date-fns"

import { StatsService, DispatcherStatType } from "../stats.service"


@Component({
    selector: ".rege-stats-kenyszi-marked",
    templateUrl: "./kenyszi-marked.component.pug"
})
export class KenysziMarkedComponent {
    public readonly form = new FormGroup({
        date_from: new FormControl(startOfMonth(new Date()), [Validators.required]),
        date_to: new FormControl(endOfMonth(new Date()), [Validators.required]),
    })

    public constructor(
        @Inject(StatsService) private readonly stats: StatsService) {
    }

    public download() {
        this.stats.kenysziMarked(this.form.get("date_from").value, this.form.get("date_to").value)
    }
}
