import { Component, Inject, Input } from "@angular/core"
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms"
import { switchMap } from "rxjs/operators"
import { startOfMonth, endOfMonth } from "date-fns"

import { Destructible } from "@anzar/core"
import { StatsService, DispatcherStatType } from "../stats.service"
import { ProviderBackendSource, Provider } from "@backend/org.api"
import { StatSettingsBackend } from "@backend/stats.api"
import { LoadFields } from "@anzar/core"


const PROVIDER_FIELDS: LoadFields<Provider> = ["id", "extended_title"]


@Component({
    selector: ".rege-stats-dispatcher-capacity",
    templateUrl: "./dispatcher-capacity.component.pug"
})
export class DispatcherCapacityComponent extends Destructible {
    public readonly form = new FormGroup({
        date_from: new FormControl(startOfMonth(new Date()), [Validators.required]),
        date_to: new FormControl(endOfMonth(new Date()), [Validators.required]),
        groups: new FormArray([])
    })

    public get groupControls() {
        return (this.form.get('groups') as FormArray).controls
    }

    public readonly providerFields = PROVIDER_FIELDS

    public constructor(
        @Inject(StatsService) public readonly stats: StatsService,
        @Inject(StatSettingsBackend) public readonly statSettings: StatSettingsBackend,
        @Inject(ProviderBackendSource) public readonly providerSrc: ProviderBackendSource) {
        super()

        this.destruct.subscription(stats.sectionIds$)
            .pipe(
                switchMap(sectionIds => {
                    return statSettings.get({ name: "dispatcher_capacity", level_ids: sectionIds })
                })
            )
            .subscribe(groups => {
                const groupsCtrl = this.form.get("groups") as FormArray
                groupsCtrl.clear()

                if (groups) {
                    for (const group of groups) {
                        groupsCtrl.push(this._createGroup(group))
                    }
                }
            })
    }

    public download() {
        this.statSettings
            .save({
                name: "dispatcher_capacity",
                level_ids: this.stats.sectionIds,
                data: this.form.get('groups').value
            })
            .subscribe(settingsId => {
                this.stats.dispatcherCapacity(settingsId, this.form.get("date_from").value, this.form.get("date_to").value)
            })
    }

    public addGroup() {
        const ctrl = this.form.get("groups") as FormArray
        ctrl.push(this._createGroup())
    }

    public removeGroup(index: number) {
        const ctrl = this.form.get("groups") as FormArray
        ctrl.removeAt(index)
    }

    public addProvider(groupIndex: number) {
        const groupsCtrl = this.form.get("groups") as FormArray
        const providersCtrl = groupsCtrl.at(groupIndex).get('providers') as FormArray
        providersCtrl.push(this._createProvider())
    }

    public removeProvider(groupIndex: number, providerIndex: number) {
        const groupsCtrl = this.form.get("groups") as FormArray
        const providersCtrl = groupsCtrl.at(groupIndex).get('providers') as FormArray
        providersCtrl.removeAt(providerIndex)
    }

    private _createGroup(data: any = null) {
        return new FormGroup({
            title: new FormControl(data ? data.title : null),
            providers: new FormArray(data && data.providers ? data.providers.map(this._createProvider.bind(this)) : [])
        })
    }

    private _createProvider(data: any = null) {
        return new FormGroup({
            title: new FormControl(data ? data.title : null),
            provider_id: new FormControl(data ? data.provider_id : null)
        })
    }
}
