import { Component, forwardRef, Inject, OnInit, Input, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormArray, FormControl } from "@angular/forms"
import { Subject, merge, zip, forkJoin, of, throwError } from "rxjs"
import { take, switchMap, map, shareReplay, debounceTime, tap, mapTo } from "rxjs/operators"

import { MarkBackend, OrgMarkBackend, OrgMarkAlertBackend, OrgMark, OrgMarkAlert, Mark, AlertLevel } from "@backend/mark.api"
import { Level, LevelBackend } from "@backend/org.api"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { LEVEL_ORDER } from "../org.service"


class MarkItem {
    public mark: Mark
    public orgMark: OrgMark
    public alerts: OrgMarkAlert[]
    public level: Level
    public editable: boolean
}


@Component({
    selector: ".rege-mark-editor",
    templateUrl: "./mark-editor.component.pug",
    host: {
        "[style.padding]": "'16px 0'"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => MarkEditorComponent) }
    ]
})
export class MarkEditorComponent extends AbstractCLEditorBlock {
    @Input() public level: Level

    public get markControls() { return (this.form.get("marks") as FormArray).controls }

    private readonly level$ = new Subject<Level>()
    private readonly parents$ = this.level$.pipe(
        switchMap(level => {
            if (level) {
                return this.levelBackend.get_parents({ id: level.id })
            } else {
                return of([])
            }
        }),
        shareReplay(1)
    )

    public readonly marks$ = this.markBackend.search().pipe(shareReplay(1))

    public readonly orgMarks$ = this.parents$.pipe(
        map(parents => {
            if (this.level) {
                return parents.concat([this.level])
            } else {
                return parents
            }
        }),
        switchMap(parents => {
            const queries = parents.map(parent => {
                return this.orgMarkBackend.search({ filter: { level_id: parent.id } }).pipe(
                    map(orgMarks => {
                        return orgMarks.map(orgMark => {
                            let res = new MarkItem()
                            res.orgMark = orgMark
                            res.level = parent
                            return res
                        })
                    })
                )
            })
            return queries.length === 0 ? of([]) : forkJoin(queries)
        }),
        map(result => {
            let marks: MarkItem[] = []
            for (const res of result) {
                marks = marks.concat(res)
            }
            return marks
        }),
        switchMap(orgMarks => {
            const queries = orgMarks.map(orgMark => {
                return this.orgMarkAlertBackend.search({ filter: { org_mark_id: orgMark.orgMark.id } }).pipe(
                    tap(alerts => {
                        orgMark.alerts = alerts.sort((a, b) => a.alert_level - b.alert_level)
                    }),
                    mapTo(orgMarks)
                )
            })
            return queries.length === 0 ? of([]) : forkJoin(queries)
        }),
        map(result => {
            let marks: MarkItem[] = []
            for (const res of result) {
                marks = marks.concat(res)
            }
            return marks
        }),
        shareReplay(1)
    )

    public items$ = merge(this.marks$, this.orgMarks$).pipe(
        debounceTime(10),
        switchMap(v => zip(this.marks$, this.orgMarks$)),
        map(v => {
            const [marks, orgMarks] = v

            return marks
                .map(mark => {
                    let existing = orgMarks.find(om => om.orgMark.mark_id === mark.id)
                    if (!existing) {
                        existing = new MarkItem()
                        existing.alerts = []
                    }
                    existing.mark = mark
                    existing.editable = !mark.is_technical && (!existing.level || !this.level || this.level.id === existing.level.id)
                    return existing
                })
                .sort((a, b) => {
                    let idx = 0
                    if (a.level && b.level) {
                        idx = LEVEL_ORDER.indexOf(a.level.variant) - LEVEL_ORDER.indexOf(b.level.variant)
                    } else if (a.level) {
                        idx = -1
                    } else if (b.level) {
                        idx = 1
                    }

                    if (idx === 0) {
                        return a.mark.position - b.mark.position
                    } else {
                        return idx
                    }
                })
        })
    )

    public items: MarkItem[] = []
    public readonly alertLevelSrc = AlertLevel.DATA

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: AbstractCLEditorService,
        @Inject(MarkBackend) private readonly markBackend: MarkBackend,
        @Inject(OrgMarkBackend) private readonly orgMarkBackend: OrgMarkBackend,
        @Inject(OrgMarkAlertBackend) private readonly orgMarkAlertBackend: OrgMarkAlertBackend,
        @Inject(LevelBackend) private readonly levelBackend: LevelBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super(editorSvc, new FormGroup({
            marks: new FormArray([])
        }))
    }

    public save() {
        if (!this.level || !this.level.id) {
            return throwError("Hiányzik a level")
        }

        let marks = this.form.value.marks as Array<{ id: number, mark_id: number, enabled: boolean, alerts: any[] }>
        const queries = marks
            .map(mark => {
                if (mark.enabled) {
                    let data = {} as any
                    if (mark.id) {
                        data.id = mark.id
                    } else {
                        data.level_id = this.level.id
                        data.mark_id = mark.mark_id
                    }
                    data.alerts = mark.alerts
                    return this.orgMarkBackend.save({ data })
                } else if (mark.id) {
                    return this.orgMarkBackend.remove({ id: mark.id })
                }
            })
            .filter(v => !!v)

        if (queries.length === 0) {
            return of(true)
        } else {
            return forkJoin(queries).pipe(
                tap(() => {
                    this.level$.next(this.level)
                })
            )
        }
    }

    public ngOnInit() {
        super.ngOnInit()

        this.destruct.subscription(this.items$).subscribe(items => {
            this.items = items
            const marksCtrl = this.form.get("marks") as FormArray
            marksCtrl.controls.length = 0

            for (const item of items) {
                const group = this._createMarkGroup(item)
                if (!item.editable) {
                    group.disable()
                }
                marksCtrl.push(group)
            }
            this.cdr.markForCheck()
        })
        this.level$.next(this.level)
    }

    public addAlert(itemIndex: number) {
        const marksCtrl = this.form.get("marks") as FormArray
        const alertsCtrl = marksCtrl.at(itemIndex).get("alerts") as FormArray
        alertsCtrl.push(this._createAlertGroup())
    }

    public removeAlert(itemIndex: number, alertIndex: number) {
        const marksCtrl = this.form.get("marks") as FormArray
        const alertsCtrl = marksCtrl.at(itemIndex).get("alerts") as FormArray
        alertsCtrl.removeAt(alertIndex)
        alertsCtrl.markAsDirty()
    }

    public _createMarkGroup(item: MarkItem) {
        return new FormGroup({
            id: new FormControl(item.orgMark ? item.orgMark.id : undefined),
            mark_id: new FormControl(item.mark.id),
            enabled: new FormControl(!!item.level),
            alerts: new FormArray(item.alerts.map(this._createAlertGroup.bind(this)))
        })
    }

    private _createAlertGroup(alert?: OrgMarkAlert) {
        return new FormGroup({
            id: new FormControl(alert ? alert.id : null),
            alert_level: new FormControl(alert ? alert.alert_level : null),
            check_count: new FormControl(alert ? alert.check_count : null),
            check_count_interval: new FormControl(alert ? alert.check_count_interval : null),
            check_date: new FormControl(alert ? alert.check_date : null),
            check_exists: new FormControl(alert ? alert.check_exists : false),
        })
    }
}
