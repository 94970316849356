import { Component, forwardRef, Inject, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { Observable, of, forkJoin } from "rxjs"
import { distinctUntilChanged, switchMap, filter, take, tap, map } from "rxjs/operators"

import { ToastService, UploadedFile, ImageRef, FileUploadEvent } from "@anzar/core"
import { UserBackend, FileBackend, File as FsFile } from "@backend/pyzar.api"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "../../common.module/components/card-list/abstract"
import { ImageCropService } from "../../image.module"
import { UserEditorService } from "./user-editor.service"
import { FsService, FileUploaderService } from "../../fs.module"


@Component({
    selector: ".pz-user-profile-image-setting",
    templateUrl: "./profile-image.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => UserProfileImageSettingComponent) },
        FileUploaderService
    ]
})
export class UserProfileImageSettingComponent extends AbstractCLEditorBlock<UserEditorService> {
    private originalImageId: number

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: UserEditorService,
        @Inject(UserBackend) private readonly userBackend: UserBackend,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(ImageCropService) private readonly cropSvc: ImageCropService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(FileUploaderService) private readonly uploader: FileUploaderService,
        @Inject(FileBackend) private readonly fileBackend: FileBackend) {
        super(editorSvc, new FormGroup({
            image: new FormControl()
        }))

        this.destruct.subscription(editorSvc.user$)
            .subscribe(user => {
                this.originalImageId = user ? user.profile_image_id : null
                this.form.get("image").setValue(this.originalImageId)
            })
    }

    public save(): Observable<any> {
        return this.editorSvc.id$.pipe(
            switchMap(userId => {
                return this.uploader.upload(`/profile-image/${userId}`).pipe(map(event => {
                    return { event, userId }
                }))
            }),
            filter(e => e.event.state === "done"),
            switchMap(({ event, userId }) => {
                const originalId = this.originalImageId
                const profileImageId = this.form.get("image").value
                const save = this.userBackend.save({
                    data: {
                        id: userId,
                        profile_image_id: profileImageId
                    }
                }).pipe(tap(res => {
                    this.originalImageId = profileImageId
                }))

                if (originalId && originalId !== profileImageId) {
                    return forkJoin(save, this.fileBackend.remove({ id: originalId }))
                } else {
                    return save
                }
            })
        )
    }
}
