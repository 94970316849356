import { Component, Inject, OnDestroy, InjectionToken, ChangeDetectorRef } from "@angular/core"
import { switchMap } from "rxjs/operators"
import { FormGroup, FormControl } from '@angular/forms'
import { addWeeks, differenceInDays } from "date-fns"

import { Destruct, LayerRef, ToastService, LayerEvent, ModalLayer, LayerService, LoadFields } from "@anzar/core"
import { MailingType } from "@backend/enums.api"
import { MailingBackend } from "@backend/service_mailing.api"
import { Contract, ContractBackend } from "@backend/documents.api"
import { ClientBackendSource, Client, ClientBackend } from "@backend/client.api"
import { ContractFormComponent, DOCUMENT_FORM_DATA, CONTRACT_TYPE } from "../../../client.module"
import { CurrentSection } from "../../../section.service/level"


export const PROVIDER_SERVICE_ID = new InjectionToken<any>("PROVIDER_SERVICE_ID")
export const MAIL_FORM_DATA = new InjectionToken<any>("MAIL_FORM_DATA")
export const IS_SMALL_SCREEN = new InjectionToken("IS_SMALL_SCREEN")
export const SECTION_ID = new InjectionToken("SECTION_ID")
const CLIENT_FIELDS: LoadFields<Client> = ["id", "name", "social_insurance_id", "birth_date"]


@Component({
    selector: "rege-new-mail-dialog",
    host: {
        "[style.width]": "isSm ? null : '500px'"
    },
    templateUrl: "./new-mail.component.pug"
})
export class NewMailComponent implements OnDestroy {
    public readonly destruct = new Destruct()

    public readonly form = new FormGroup({
        id: new FormControl(),
        type: new FormControl(),
        client_id: new FormControl(),
        note: new FormControl(),
        arrived_date: new FormControl(new Date()),
        deadline_date: new FormControl(addWeeks(new Date(), 2))
    })

    public readonly typeSrc = MailingType.DATA
    public readonly clientFields = CLIENT_FIELDS
    public contract: Contract
    public contractExpired: boolean = false
    public contractIsLoading: boolean = true
    private contractSectionId: number = null

    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(MailingBackend) private readonly mailingBackend: MailingBackend,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(ClientBackend) private readonly clientBackend: ClientBackend,
        @Inject(ClientBackendSource) public readonly clientSrc: ClientBackendSource,
        @Inject(ContractBackend) private readonly contractBackend: ContractBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(SECTION_ID) public readonly sectionId: number,
        @Inject(PROVIDER_SERVICE_ID) private readonly providerServiceId: any,
        @Inject(MAIL_FORM_DATA) private readonly initialData: any,
        @Inject(IS_SMALL_SCREEN) public readonly isSm: boolean) {

        if (initialData) {
            this.form.reset(initialData)
        }

        this.destruct.subscription(this.form.get("arrived_date").valueChanges).subscribe(val => {
            this.form.get("deadline_date").setValue(val ? addWeeks(val, 2) : null)
            if (this.contract) {
                this.contractExpired = differenceInDays(val, this.contract.end) > 0
                this.cdr.markForCheck()
            }
        })

        this.destruct.subscription(this.form.get("client_id").valueChanges).subscribe(clientId => {
            clientId && this._loadContract(clientId)
        })
    }

    public save() {
        const data = { ...this.form.value, provider_service_id: this.providerServiceId }
        const successMsg = data.id
            ? "Sikeresen módosítottad a küldemény adatait"
            : "Sikeresen átvetted a küldeményt"
        this.mailingBackend.save({ data })
            .pipe(this.toastSvc.handleSave({ align: "bottom center", successMsg }))
            .subscribe(v => {
                this.layerRef.emit(new LayerEvent("success", v))
                this.layerRef.close()
            })
    }

    public cancel() {
        this.layerRef.close()
    }

    public extendContract() {
        this._showContractForm({
            section_id: this.contract.section_id,
            begin: this.contract.end,
            description: this.contract.description,
        })
    }

    public newContract() {
        this._showContractForm({
            begin: new Date(),
            section_id: this.contractSectionId
        })
    }

    private _showContractForm(data: any) {
        const clientId = this.form.get("client_id").value
        this.clientBackend
            .get({ id: clientId })
            .pipe(switchMap(client => {
                const behavior = new ModalLayer({
                    backdrop: { type: "filled", hideOnClick: false },
                    elevation: 10,
                    rounded: 3
                })
                let ref = this.layerSvc.createFromComponent(ContractFormComponent, behavior, null, [
                    { provide: Client, useValue: client },
                    { provide: CONTRACT_TYPE, useValue: "mailing" },
                    { provide: DOCUMENT_FORM_DATA, useValue: data }
                ])
                ref.show()

                return ref.output
            }))
            .subscribe(event => {
                if (event.type === "hiding") {
                    this._loadContract(clientId)
                }
            })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    private _loadContract(clientId: number) {
        this.contract = null
        this.contractExpired = false
        this.contractIsLoading = true
        this.contractSectionId = null
        this.cdr.markForCheck()

        this.contractBackend
            .get_last_mailing({ client_id: clientId, provider_service_id: this.providerServiceId })
            .subscribe(([contract, sectionId]) => {
                this.contract = contract
                this.contractIsLoading = false
                this.contractSectionId = sectionId

                if (contract) {
                    const arrivedDate = this.form.get("arrived_date").value || new Date()
                    this.contractExpired = differenceInDays(arrivedDate, contract.end) > 0
                }

                this.cdr.detectChanges()
            })
    }
}
