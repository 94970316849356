import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Route } from "@angular/router"
import { NzModule } from "@anzar/core"

import { KenysziApi } from "@backend/kenyszi.api"

import { ComponentsModule } from "../components"
import { ClientModule } from "../client.module"
import { MarkModule } from "../mark.module"
import { PyzarTaskModule, TaskDetailsFactory } from "@pyzar/task.module"


import { ReportScreen } from "./report.screen"
import { KenysziGridComponent } from "./grid.component"
import { KenysziReportDetailsFactory } from "./report-task-detail.component"
import { KenysziStatPopupComponent } from "./stat-popup.component"

import { ProblematicReportsComponent } from "./problematic-reports.component"


export const KENYSZI_ROUTES: Route[] = [
    { path: "jelentes/:providerIds/:date", component: ReportScreen }
]


@NgModule({
    imports: [
        CommonModule,
        NzModule,

        KenysziApi,

        ComponentsModule,
        ClientModule,
        MarkModule,
        PyzarTaskModule
    ],
    declarations: [
        ReportScreen,
        KenysziGridComponent,
        KenysziStatPopupComponent,
        ProblematicReportsComponent
    ],
    exports: [
        ReportScreen,
        KenysziGridComponent,
        KenysziStatPopupComponent,
        ProblematicReportsComponent
    ],
    entryComponents: [
        KenysziStatPopupComponent
    ],
    providers: [
        { provide: TaskDetailsFactory, useClass: KenysziReportDetailsFactory, multi: true }
    ]
})
export class KenysziModule {

}
