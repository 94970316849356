import { NgModule } from "@angular/core"
import { Mailing, MailingBackend, MailingTypeBackend, MailingBackendSource, MailingTypeBackendSource } from "./__anzar_rpc_output"

export { Mailing, MailingBackend, MailingTypeBackend, MailingBackendSource, MailingTypeBackendSource }


@NgModule({
    providers: [
        MailingBackendSource,
        MailingTypeBackendSource,
        MailingBackend,
        MailingTypeBackend
    ],
})
export class Service_mailingApi {}