import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core"
import { Router, NavigationEnd } from "@angular/router"
import { interval, of } from "rxjs"
import { switchMap, startWith, shareReplay } from "rxjs/operators"

import { ViewportService, Destruct } from "@anzar/core"
import { User } from "@backend/pyzar.api"
import { AuthService } from "@pyzar/auth.module"


import { Section } from "@backend/org.api"
import { CriticalMessageBackend } from "@backend/critical_message.api"
import { SectionService } from "../../section.service"
import { CurrentSection } from "../../section.service/level"
import { NotificationService } from "../../notification.module"
import { FeedbackService } from "../../feedback.module"
import { MarkdownPopupService } from "../../components"
import { CapacityUsageEditorService } from "../../service.module"
import { HelpService } from "../../help.module"


const changelog = require("CHANGELOG.md")


@Component({
    templateUrl: "./authorized-viewport.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ViewportService
    ]
})
export class AuthorizedViewport {
    public readonly destruct = new Destruct()

    public currentUser: User
    public sections: Section[]
    public emptySections: Section[]
    public selectedSection: Section
    public ncount: number
    public manualDuty: boolean
    public primaryMenu: any[] = []
    public displayCapacityEditor: boolean = false
    // public uiType: UiType

    private _currentUrl: string
    private _autoRedirect: boolean = false

    public readonly canViewOrgSettings$ = this.sectionSvc.selected.pipe(
        switchMap(section => {
            if (section) {
                return this.auth.hasPermission({ permission: "org.settings", extra: { level_id: section.id } })
            } else {
                return of(false)
            }
        }),
        shareReplay(1)
    )
    public readonly canViewStats$ = this.auth.hasPermission("stat")
    public readonly canViewMessages$ = this.auth.hasPermission("messages.read")
    public readonly canViewPlaces$ = this.auth.hasPermission("place.read")
    public readonly canViewOrg$ = this.auth.hasPermission("org.manage")
    public readonly canViewServices$ = this.auth.hasPermission("admin.services")
    public readonly canViewUsers$ = this.auth.hasPermission("auth.user.manage")
    public readonly canViewPerms$ = this.auth.hasPermission("auth.perm.edit")
    public readonly canViewClientMerge$ = this.auth.hasPermission("client.merge")


    public constructor(
        @Inject(AuthService) public readonly auth: AuthService,
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(SectionService) protected readonly sectionSvc: SectionService,
        @Inject(CurrentSection) protected readonly currentSection: CurrentSection,
        @Inject(Router) protected readonly router: Router,
        @Inject(ViewportService) protected readonly vps: ViewportService,
        @Inject(NotificationService) public readonly notificationSvc: NotificationService,
        @Inject(FeedbackService) private readonly feedback: FeedbackService,
        @Inject(CriticalMessageBackend) private readonly cmMessage: CriticalMessageBackend,
        @Inject(MarkdownPopupService) private readonly mdPopup: MarkdownPopupService,
        @Inject(CapacityUsageEditorService) private readonly capacityUsageEditor: CapacityUsageEditorService,
        @Inject(HelpService) private readonly helpSvc: HelpService) {

        vps.right.width = 400
        vps.right.disabled = true

        this.destruct.subscription(auth.currentUser$).subscribe(user => {
            this.currentUser = user
            cdr.markForCheck()
        })

        this.destruct.subscription(sectionSvc.selected).subscribe(section => {
            this.selectedSection = section
            vps.right.disabled = !section
            cdr.markForCheck()
        })

        // this.destruct.subscription(sectionSvc.ownSections$).subscribe(sections => {
        //     this.sections = sections
        //     cdr.markForCheck()
        // })

        this.destruct.subscription(sectionSvc.ownSectionsSplitByEmptiness$).subscribe(groups => {
            this.sections = groups[0]
            this.emptySections = groups[1]
            cdr.markForCheck()
        })

        this.destruct.subscription(router.events).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._currentUrl = event.url
            }
        })

        this.destruct.subscription(notificationSvc.count$).subscribe(ncount => {
            if (this.ncount !== ncount) {
                this.ncount = ncount
                this.cdr.markForCheck()
            }
        })

        this.destruct.subscription(currentSection.manualDuty).subscribe(manualDuty => {
            this.manualDuty = manualDuty
            this.cdr.markForCheck()
        })

        this.destruct.subscription(currentSection.primaryMenu).subscribe(primaryMenu => {
            this.primaryMenu = primaryMenu
            if (this._autoRedirect && primaryMenu && primaryMenu[0]) {
                this.router.navigate([primaryMenu[0].url])
            }
            this.displayCapacityEditor = primaryMenu.filter(v => v.type === "dispatcher").length > 0
            this.cdr.markForCheck()
        })

        this.destruct.subscription(interval(60000).pipe(startWith(0)))
            .pipe(
                switchMap(_ => {
                    return this.cmMessage.list({ client_version: __VERSION__ })
                })
            )
            .subscribe(cm => {
                if (cm && cm.length) {
                    vps.criticalMessage = {
                        id: cm[0].id,
                        message: cm[0].message,
                        expire: cm[0].expire_time
                    }
                } else {
                    vps.criticalMessage = null
                }
                this.cdr.markForCheck()
            })
    }

    public routeIsActive(prefix: string): boolean {
        return this._currentUrl && this._currentUrl.startsWith(prefix)
    }

    public selectSection(section: Section) {
        this.auth.invalidate()
        this._autoRedirect = true
        this.destruct.subscription(this.sectionSvc.select(section))
            .subscribe(s => {
                this.selectedSection = s
                this.cdr.markForCheck()
            })
    }

    public logout() {
        this.auth.logout()
    }

    public showFeedback() {
        this.feedback.show()
    }

    public showHelp() {
        this.helpSvc.show()
    }

    public showChangelog() {
        this.mdPopup.show(changelog)
    }

    public showCapacityUsageEditor() {
        this.capacityUsageEditor.show()
    }

    public throwError() {
        throw new Error("TEST ERROR")
    }
}
