import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzModule } from "@anzar/core"

import { OrgApi } from "@backend/org.api"
import { ServiceApi } from "@backend/service.api"

import { ComponentsModule } from "../components"


import { SectionGuard } from "./section.guard"
import { SectionService } from "./section.service"
export { SectionGuard, SectionService }


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        OrgApi,
        ServiceApi,
        ComponentsModule,
    ],
    declarations: [

    ],
    exports: [

    ],
    providers: [
        SectionGuard,
        SectionService
    ]
})
export class SectionServiceModule {

}
