import { Component, Input, Inject, ChangeDetectorRef } from "@angular/core"

import { NEvent } from "@backend/event.api"


@Component({
    selector: ".rege-ne-event",
    templateUrl: "./ne-event.component.pug"
})
export class NEEventComponent {
    @Input() public notification: NEvent

    public constructor(@Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
    }
}
