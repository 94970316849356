import { Component, Directive, Inject, ViewContainerRef, ElementRef, Input, Optional } from "@angular/core"
import { FormGroup, FormControl, Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from "@angular/forms"


import { Destructible, LayerService, FormFieldComponent, InputGroupModel, InputModel, INPUT_MODEL_VALUE_CMP, FocusGroup } from "@anzar/core"


export interface AddressInputValue {
    city?: string
    // sublocality?: string
    public_place?: string
    street_number?: string
    postal_code?: string
}


const FIELD_NAMES = ["city", "public_place", "street_number", "postal_code"]


@Directive({
    selector: ".rege-address-input[required]",
    providers: [
        { provide: NG_VALIDATORS, useExisting: AddressInputRequiredValidator, multi: true }
    ]
})
export class AddressInputRequiredValidator implements Validator {
    private _onChange: () => void

    @Input("requiredFields")
    public set fields(val: string) {
        if (this._fields !== val) {
            this._fields = val
            this._onChange && this._onChange()
        }
    }
    public get fields(): string { return this._fields }
    private _fields: string

    public validate(ctrl: AbstractControl): ValidationErrors | null {
        const fields: string[] = this._fields ? this._fields.split(",") : FIELD_NAMES
        let isValid = true

        for (const fieldName of FIELD_NAMES) {
            const subField = ctrl.get(fieldName)
            if (fields.indexOf(fieldName) !== -1 && !subField.value) {
                subField.setErrors({ required: true })
                isValid = false
            } else {
                subField.setErrors({ required: false })
            }
        }

        return isValid ? null : { required: true }
    }

    public registerOnValidatorChange(fn: () => void): void {
        this._onChange = fn
    }
}


export class AddressInputModel extends InputGroupModel<AddressInputValue> {
    public get isEmpty(): boolean {
        const val = this.value
        return !(val && (val.city || val.public_place || val.street_number || val.postal_code))
    }
}


export function addressEqComparator(a: AddressInputValue, b: AddressInputValue): boolean {
    return (a && b
        && a.city === b.city
        // && a.sublocality == b.sublocality
        && a.public_place === b.public_place
        && a.street_number === b.street_number
        && a.postal_code === b.postal_code)
        || (!a && !b)
}


@Component({
    selector: ".rege-address-input",
    templateUrl: "./address-input.component.pug",
    providers: [
        { provide: INPUT_MODEL_VALUE_CMP, useValue: addressEqComparator },
        { provide: InputGroupModel, useClass: AddressInputModel },
        { provide: InputModel, useExisting: InputGroupModel },
        { provide: FocusGroup, useClass: FocusGroup },
    ]
})
export class AddressInputComponent extends Destructible {
    public static formGroup() {
        return new FormGroup(
            {
                city: new FormControl(),
                // sublocality: new FormControl(),
                public_place: new FormControl(),
                street_number: new FormControl(),
                postal_code: new FormControl(null, validatePostalCode),
            },
            (ctrl: FormGroup) => {
                let hasValue = false
                for (const k in ctrl.controls) {
                    if (ctrl.controls.hasOwnProperty(k)) {
                        if (ctrl.controls[k].value != null && ctrl.controls[k].value.length) {
                            hasValue = true
                            break
                        }
                    }
                }

                if (hasValue) {
                    for (const k in ctrl.controls) {
                        if (ctrl.controls.hasOwnProperty(k)) {
                            const value = ctrl.controls[k].value
                            if (value == null || value.length === 0) {
                                ctrl.controls[k].setErrors({ required: true })
                            }
                        }
                    }
                } else {
                    for (const k in ctrl.controls) {
                        ctrl.controls[k].setErrors(null)
                    }
                }

                return null
            }
        )
    }

    public get group(): FormGroup {
        return this.igm.control as FormGroup
    }

    public constructor(
        @Inject(LayerService) protected readonly layerSvc: LayerService,
        @Inject(ViewContainerRef) protected readonly vcr: ViewContainerRef,
        @Inject(InputGroupModel) protected readonly igm: InputGroupModel<AddressInputValue>,
        @Inject(FormFieldComponent) @Optional() protected readonly ffc: FormFieldComponent,) {
        super()
    }
}


function validatePostalCode(ctrl: AbstractControl): ValidationErrors | null {
    if (ctrl.value && ctrl.value.length > 0) {
        const isValid = /^[1-9]\d{3}$/.test(ctrl.value)
        return isValid ? null : { format: true }
    } else {
        return null
    }
}
