import { Component, Inject, HostListener, Input, Optional, Injector } from "@angular/core"

import { RichtextComponentRef } from "@anzar/core"
import { Client } from "@backend/client.api"


@Component({
    selector: "rege-client-link",
    templateUrl: "./client-link.component.pug"
})
export class ClientLinkComponent {
    public content: string
    public clientId: number

    @Input()
    public set client(val: Client) {
        if (this._client !== val) {
            if (this._client = val) {
                this.content = val.name.formatted
                this.clientId = val.id
            } else {
                this.content = null
                this.clientId = null
            }
        }
    }
    public get client(): Client { return this._client }
    private _client: Client

    public constructor(
        @Inject(RichtextComponentRef) @Optional() protected cmpRef: RichtextComponentRef,
        @Inject(Injector) protected readonly injector: Injector) {
        if (cmpRef) {
            const params = cmpRef.params
            this.content = params.content as string
            this.clientId = params.client_id as number
        }
    }

    @HostListener("tap", ["$event"])
    public onTap(event: Event) {
        event.preventDefault()

        import("../../client.module/client.service").then(({ ClientService }) => {
            this.injector.get(ClientService).showSheet(this.clientId).subscribe()
        })
    }
}
