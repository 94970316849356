import { Component, Injectable, Inject, InjectionToken, ChangeDetectionStrategy } from "@angular/core"
import { forkJoin } from "rxjs"
import { switchMap, map } from "rxjs/operators"

import { SectionBackend, LevelWorkerBackend, LevelWorker, Section, LevelBackend, Level, Headquarter, HeadquarterBackend } from "@backend/org.api"

import { LayerRef, LayerService, LoadFields, ModalLayer } from "@anzar/core"
import { WorkerCardService } from "../../worker.module/worker-card.component"


const WORKER_FIELDS: LoadFields<LevelWorker> = [
    { rolev: ["label"] },
    { user: ["id", "name", "profile_image_id"] }
]


const SECTION_FIELDS: LoadFields<Section> = [
    "id", "parent_id", "title"
]


const HQ_FIELDS: LoadFields<Headquarter> = [
    "id", "title", "place", "phone_number"
]

const PARAM_SECTION = new InjectionToken("PARAM_SECTION")
const PARAM_PARENTS = new InjectionToken("PARAM_PARENTS")
const PARAM_HQ = new InjectionToken("PARAM_HQ")
const PARAM_WORKERS = new InjectionToken("PARAM_WORKERS")


@Injectable({ providedIn: "root" })
export class SectionInfoService {
    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(SectionBackend) private readonly sectionBackend: SectionBackend,
        @Inject(LevelBackend) private readonly levelBackend: LevelBackend,
        @Inject(HeadquarterBackend) private readonly hqBackend: HeadquarterBackend,
        @Inject(LevelWorkerBackend) private readonly levelWorker: LevelWorkerBackend) {

    }

    public show(sectionId: number) {
        const q1 = this.sectionBackend.get({ id: sectionId }, { loadFields: SECTION_FIELDS })
        // const q2 = this.levelBackend.get_parents({ id: sectionId })
        // const q2 = this.hqBackend.get({ id: sectionId }, { loadFields: HQ_FIELDS })
        const q3 = this.levelWorker.search({
            filter: { level_id: sectionId },
            order: { "rolev.position": "asc", "user.name": "asc" }
        }, { loadFields: WORKER_FIELDS })

        forkJoin([q1, q3])
            .pipe(
                switchMap(([section, workers]) => {
                    return this.hqBackend.get({ id: section.parent_id }, { loadFields: HQ_FIELDS }).pipe(
                        map(hq => [section, hq, workers])
                    )
                })
            )
            .subscribe(([section, hq, workers]) => {
                const behavior = new ModalLayer({
                    backdrop: { type: "filled", hideOnClick: true },
                    closeable: true,
                    elevation: 20,
                    rounded: 5,
                    position: {
                        align: "center",
                        constraint: {
                            ref: "viewport",
                            inset: 16
                        }
                    }
                })

                const ref = this.layerSvc.createFromComponent(SectionInfoComponent, behavior, null, [
                    { provide: PARAM_SECTION, useValue: section },
                    { provide: PARAM_HQ, useValue: hq },
                    { provide: PARAM_WORKERS, useValue: workers },
                ])

                ref.show()
            })
    }
}


@Component({
    templateUrl: "./section-info.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [WorkerCardService]
})
export class SectionInfoComponent {

    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(PARAM_SECTION) public readonly section: Section,
        @Inject(PARAM_HQ) public readonly hq: Headquarter,
        @Inject(PARAM_WORKERS) public readonly workers: LevelWorker[],
        @Inject(WorkerCardService) public readonly workerCrad: WorkerCardService) {
    }

    public close() {
        this.layerRef.close()
    }

    public showWorkerInfo(userId: number) {
        setTimeout(() => this.workerCrad.show(userId), 100)
    }
}
