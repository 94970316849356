import { Injectable } from "@angular/core"

import { NzTouchEvent } from "@anzar/core"
import type { HashtagComponent } from "./hashtag.component"


@Injectable()
export abstract class HashtagClickHandler {
    public abstract handle(hashtag: HashtagComponent, event: NzTouchEvent): void
}
