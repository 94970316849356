import { Component, forwardRef, Inject } from "@angular/core"

import { AbstractCLEditor, AbstractCLEditorService } from "@pyzar/common.module"
import { ServiceSettingsService } from "./service-settings.service"


@Component({
    selector: ".rege-service-settings",
    templateUrl: "./service-settings.component.pug",
    providers: [
        { provide: AbstractCLEditor, useExisting: forwardRef(() => ServiceSettingsComponent) }
    ]
})
export class ServiceSettingsComponent extends AbstractCLEditor {
    public constructor(@Inject(AbstractCLEditorService) service: ServiceSettingsService) {
        super(service)
    }
}
