import { Directive } from "@angular/core"

import { FileUploaderService } from "./file-uploader.service"

@Directive({
    selector: "[fileUploader]",
    providers: [FileUploaderService]
})
export class FileUploaderDirective {

}
