import { Component, Inject } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"

import { endOfMonth, startOfMonth } from "date-fns"

import { StatsService } from "../stats.service"

@Component({
    selector: ".rege-stats-room-usage",
    templateUrl: "./room-usage.component.pug"
})
export class RoomUsageComponent {
    public readonly form = new FormGroup({
        date_from: new FormControl(startOfMonth(new Date()), [Validators.required]),
        date_to: new FormControl(endOfMonth(new Date()), [Validators.required])
    })

    public constructor(@Inject(StatsService) private readonly stats: StatsService) {}

    public download() {
        this.stats.roomUsage(this.form.value.date_from, this.form.value.date_to)
    }
}
