import { Component } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"

import { AbstractForm } from "../abstract"


@Component({
    selector: "rege-rt-public-service-form",
    templateUrl: "./public-service-form.component.pug"
})
export class PublicServiceFormComponent extends AbstractForm {
    public readonly form = new FormGroup({
        contact_name: new FormControl(),
        contact_id: new FormControl(),
        plate_number: new FormControl(),
        transport: new FormControl(),
        text: new FormControl(),
    })
}
