import { Component, forwardRef, Inject, Input, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core"
import { FormGroup, FormArray, FormControl } from "@angular/forms"
import { take, tap } from "rxjs/operators"

import { Provider, ProviderBackend } from "@backend/org.api"
import { BNO, BNOBackend } from "@backend/service_bno.api"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { ProviderEditorService, ServiceItem } from "../provider/provider-editor.service"


@Component({
    selector: ".rege-provider-bno-editor",
    templateUrl: "./bno-editor.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[style.padding]": "'16px 0'"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => BNOEditorComponent) }
    ]
})
export class BNOEditorComponent extends AbstractCLEditorBlock<ProviderEditorService> {
    @Input() public provider: Provider

    @Input()
    public set service(val: ServiceItem) {
        if (this._service !== val) {
            this._service = val
        }
    }
    public get service(): ServiceItem { return this._service }
    private _service: ServiceItem

    public get bnoControls() { return (this.form.get("bnos") as FormArray).controls }

    private _disableChange: boolean = false

    public constructor(
        @Inject(AbstractCLEditorService) providerEditor: ProviderEditorService,
        @Inject(ProviderBackend) private readonly providerBackend: ProviderBackend,
        @Inject(BNOBackend) private readonly bnoBackend: BNOBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super(providerEditor, new FormGroup({
            bnos: new FormArray([])
        }));

        (this.form.get("bnos") as FormArray).push(this._createBNOGroup())

        const ctrl = this.form.get("bnos") as FormArray
        this.destruct.subscription(ctrl.valueChanges).subscribe(values => {
            if (this._disableChange) {
                return
            }
            this._disableChange = true

            let hasEmpty = false
            for (let i = 0, l = values.length; i < l; i++) {
                if (!values[i].title) {
                    hasEmpty = true
                    break
                }
            }

            if (!hasEmpty) {
                ctrl.push(this._createBNOGroup())
            }

            this._disableChange = false
        })
    }

    public ngOnInit() {
        super.ngOnInit()
        this.reload()
    }

    public save() {
        return this.bnoBackend
            .update({
                bnos: this.form.value.bnos,
                provider_service_id: this.service.pservice.id
            })
            .pipe(
                take(1),
                tap(_ => {
                    this.reload()
                })
            )
    }

    public reload() {
        this.bnoBackend
            .search({
                filter: { provider_service_id: this.service.pservice.id },
                order: { title: "asc" }
            })
            .pipe(take(1))
            .subscribe(bnos => {
                this._disableChange = true
                this.form.reset()

                const ctrl = this.form.get("bnos") as FormArray
                ctrl.controls.length = 0

                for (const bno of bnos) {
                    ctrl.push(this._createBNOGroup(bno))
                }

                ctrl.push(this._createBNOGroup())

                this._disableChange = false
                this.cdr.detectChanges()
            })
    }

    private _createBNOGroup(bno?: BNO) {
        return new FormGroup({
            id: new FormControl(bno ? bno.id : null),
            is_active: new FormControl(bno ? bno.is_active : true),
            title: new FormControl(bno ? bno.title : null)
        })
    }
}
