import { Component, ViewChild, Inject, ChangeDetectorRef } from "@angular/core"
import { take } from "rxjs/operators"

import { ToastService, Destructible } from "@anzar/core"
import { ServiceSettingsService } from "./service-settings.service"
import { AbstractCLEditor, AbstractCLEditorService } from "@pyzar/common.module"


@Component({
    templateUrl: "./service-settings.screen.pug",
    providers: [
        { provide: AbstractCLEditorService, useClass: ServiceSettingsService }
    ]
})
export class ServiceSettingsScreen extends Destructible {
    @ViewChild("editor", { static: true }) public readonly editor: AbstractCLEditor

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(ToastService) private readonly toast: ToastService) {
        super()
    }

    public ngAfterViewInit() {
        this.destruct.subscription(this.editor.status$).subscribe(status => {
            this.cdr.detectChanges()
        })
    }

    public save() {
        return this.editor
            .save()
            .pipe(
                take(1),
                this.toast.handleSave({ align: "bottom center", successMsg: "A mentés sikeresen megtörtént" })
            )
            .subscribe()
    }
}
