import { Injectable } from "@angular/core"
import { Observable, Observer } from "rxjs"


export const enum GeolocationError {
    PERMISSION_DENIED = 1,
    UNAVAILABLE = 2,
    TIMEOUT = 3,
    UNSUPPORTED = 1000
}


@Injectable({ providedIn: "root" })
export class CurrentLocation {
    public readonly coords$: Observable<GeolocationCoordinates> = new Observable((observer: Observer<GeolocationCoordinates>) => {
        const success = (pos: GeolocationPosition) => {
            observer.next(pos.coords)
            observer.complete()
        }

        const error = (err: any) => {
            if (err.code === GeolocationError.PERMISSION_DENIED) {
                observer.complete()
            } else {
                observer.error(err)
            }
        }

        if (this.geolocIsAvailable) {
            navigator.geolocation.getCurrentPosition(success, error, {
                enableHighAccuracy: true
            })
        } else {
            observer.error({ code: GeolocationError.UNSUPPORTED })
        }
    })

    public get geolocIsAvailable(): boolean {
        return navigator.geolocation != null
    }
}
