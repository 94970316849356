import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, Routes } from "@angular/router"

import { AuthorizedOnly, PROTECTED_ROUTES, PUBLIC_ROUTES } from "@pyzar/auth.module"
import { NotFoundPageComponent } from "@pyzar/common.module"

import { CLIENT_FEED_ROUTES } from "./client-feed.screen"
import { CLIENT_ROUTES } from "./client.module"
import { CONNECT_ROUTES } from "./connect.module"
import { DISPATCHER_ROUTES } from "./dispatcher.module"
import { DUTY_ROUTES } from "./duty.module"
import { KENYSZI_ROUTES } from "./kenyszi.module"
import { MESSAGE_ROUTES } from "./message.module"
import { ORG_ROUTES } from "./org.module"
import { PLACE_ROUTES } from "./place.module"
import { SectionGuard } from "./section.service"
import { SERVICE_ROUTES, SERVICE_SETTINGS_ROUTES, ServiceUsageScreen } from "./service.module"
import { STATS_ROUTES } from "./stats.module"
import { AuthorizedViewport } from "./viewport/authorized.viewport"
import { PublicViewport } from "./viewport/public.viewport"
import { WORKER_ROUTES } from "./worker.module"

// import { TestScreenModule } from "./screens/test.screen"

// HACK: import submodules https://github.com/angular/angular/issues/10958

export const ROUTES: Routes = [
    ...PUBLIC_ROUTES,
    // { path: "login", canActivate: [UnauthorizedOnly], component: LoginViewport, pathMatch: "full" },
    // { path: "change-pass", canActivate: [AuthorizedOnlyPwChange], component: ChangePassViewport, pathMatch: "full" },
    // { path: "login/:token", component: LoginViewport, pathMatch: "full" },
    // { path: "verify-email/:token", component: VerifyEmailViewport, pathMatch: "full" },
    // { path: "registration/:token", component: RegistrationViewport, pathMatch: "full" },
    {
        path: "",
        canActivate: [AuthorizedOnly],
        canActivateChild: [AuthorizedOnly],
        component: AuthorizedViewport,
        children: [
            {
                path: "",
                canActivate: [SectionGuard, AuthorizedOnly],
                canActivateChild: [SectionGuard, AuthorizedOnly],
                children: [
                    { path: "ugyfel", children: CLIENT_ROUTES },
                    { path: "szolgaltatas", children: SERVICE_ROUTES },
                    { path: "uzenofal", children: MESSAGE_ROUTES },
                    { path: "helyek", children: PLACE_ROUTES },
                    { path: "muszak", children: DUTY_ROUTES },
                    { path: "kenyszi", children: KENYSZI_ROUTES },
                    { path: "org", children: ORG_ROUTES },
                    { path: "diszpecser", children: DISPATCHER_ROUTES },
                    { path: "statisztika", children: STATS_ROUTES }
                ]
            },
            { path: "munkatars", children: WORKER_ROUTES },
            // { path: "reszlegvalaszto", children: SELECT_SECTION_ROUTES },
            { path: "hirfolyam", children: CLIENT_FEED_ROUTES },
            { path: "szolgaltatasok", children: SERVICE_SETTINGS_ROUTES },
            // { path: "auth", children: AUTH_ROUTES },
            ...PROTECTED_ROUTES
        ]
    },
    {
        path: "info",
        component: PublicViewport,
        children: [...CONNECT_ROUTES]
    },
    {
        path: "**",
        component: NotFoundPageComponent
    }
]

export class RegeRouteReuseStrategy extends RouteReuseStrategy {
    public shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false
    }

    public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {}

    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false
    }

    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null
    }

    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (future.component === ServiceUsageScreen && curr.component === ServiceUsageScreen) {
            return future.params["providerIds"] === curr.params["providerIds"]
        }
        return future.component === curr.component
    }
}
