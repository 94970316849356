import { Component, InjectionToken, Inject, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from "@angular/forms"
import { Router } from "@angular/router"
import { BehaviorSubject, forkJoin, of } from "rxjs"
import { switchMap, mapTo, map } from "rxjs/operators"

import { LoadFields, LayerRef, Destructible, ToastService } from "@anzar/core"

import { SectionBackendSource, SectionBackend, Section, HeadquarterBackendSource } from "@backend/org.api"
import { SectionBackend_remove_Params } from "@backend/__anzar_rpc_output"


export const SECTION_ID = new InjectionToken("SECTION_ID")

const SECTION_FIELDS: LoadFields<Section> = ["id", "title"]
const HQ_FIELDS: LoadFields<Section> = ["id", "title"]


function nameValidator(eqWith: string): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
        if (control.value === eqWith) {
            return null
        } else {
            return { notEq: true }
        }
    }
}


@Component({
    selector: "rege-section-remove-dialog",
    templateUrl: "./section-remove.component.pug"
})
export class SectionRemoveComponent extends Destructible {
    public readonly form = new FormGroup({
        mode: new FormControl("delete", [Validators.required]),
        mergeIntoId: new FormControl(null),
        moveIntoId: new FormControl(null),
        confirmText: new FormControl(null, [Validators.required, nameValidator("\0")]),
    })

    public readonly sectionFields = SECTION_FIELDS
    public readonly hqFields = HQ_FIELDS
    public busy = new BehaviorSubject(false)

    public readonly canDoAction$ = this.form.statusChanges
        .pipe(switchMap(() => {
            console.log(this.form.invalid)
            if (this.form.invalid) {
                return of(false)
            } else {
                return this.busy.pipe(map(v => !v))
            }
        }))

    public constructor(
        @Inject(SECTION_ID) private readonly sectionId: number,
        @Inject(SectionBackend) public readonly sectionBackend: SectionBackend,
        @Inject(SectionBackendSource) public readonly sectionSrc: SectionBackendSource,
        @Inject(HeadquarterBackendSource) public readonly hqSrc: HeadquarterBackendSource,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(Router) private readonly router: Router,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super()

        this.destruct.subscription(this.form.get("mode").valueChanges).subscribe(value => {
            const mergeIntoId = this.form.get("mergeIntoId")
            const moveIntoId = this.form.get("moveIntoId")

            switch (value) {
                case "delete":
                    mergeIntoId.clearValidators()
                    moveIntoId.clearValidators()
                    break

                case "move":
                    mergeIntoId.clearValidators()
                    moveIntoId.setValidators([Validators.required])
                    break

                case "merge":
                    mergeIntoId.setValidators([Validators.required])
                    moveIntoId.clearValidators()
                    break
            }
            mergeIntoId.updateValueAndValidity()
            moveIntoId.updateValueAndValidity()
        })

        this.destruct.subscription(this.sectionBackend.get({ id: sectionId })).subscribe(section => {
            const confirm = this.form.get("confirmText")
            confirm.setValidators([Validators.required, nameValidator(section.title)])
            confirm.updateValueAndValidity()
        })

    }

    public doAction() {
        this.busy.next(true)
        const data = this.form.value
        let beginMessages: { [key: string]: string } = {
            merge: "Részlegek összevonása folyamatban",
            move: "Részleg áthelyezése folyamatban",
            delete: "Részleg törlése folyamatban"
        }

        const params: SectionBackend_remove_Params = { action: data.mode, id: this.sectionId }

        switch (params.action) {
            case "move":
                params.move_into_id = data.moveIntoId
                break

            case "merge":
                params.merge_into_id = data.mergeIntoId
                break
        }

        this.sectionBackend
            .remove(params)
            .pipe(this.toast.handleSave({
                align: "bottom center",
                beginMsg: beginMessages[params.action],
                onError: () => this.busy.next(false)
            }))
            .subscribe(res => {
                this.layerRef.hide()
                switch (params.action) {
                    case "move":
                        this.router.navigate(["/org/reszleg/", params.move_into_id])
                        break

                    case "merge":
                        this.router.navigate(["/org/reszleg/", params.merge_into_id])
                        break

                    case "delete":
                        this.router.navigate(["/org/beallitasok/"])
                        break
                }
            })
    }

    public cancel() {
        this.layerRef.hide()
    }
}
