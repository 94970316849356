import { Component, forwardRef, Inject, Input, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core"
import { FormGroup, FormArray, FormControl, } from "@angular/forms"
import { of, Subject, forkJoin, Observable } from "rxjs"
import { take, shareReplay, switchMap, map, debounceTime, tap } from "rxjs/operators"

import { Provider, ProviderBackend, ProviderServiceBackend } from "@backend/org.api"
import { BedType } from "@backend/enums.api"
import { Room, RoomBackend, Bed, BedBackend } from "@backend/service_room.api"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { ProviderEditorService, ServiceItem } from "../provider/provider-editor.service"


class RoomEntry {
    public constructor(
        public readonly room: Room,
        public readonly beds: Bed[]
    ) {

    }
}


@Component({
    selector: ".rege-provider-room-editor",
    templateUrl: "./room-editor.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[style.padding]": "'16px 0'"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => RoomEditorComponent) }
    ]
})
export class RoomEditorComponent extends AbstractCLEditorBlock {
    @Input() public provider: Provider

    @Input()
    public set service(val: ServiceItem) {
        if (this._service !== val) {
            this._service = val
            this.providerServiceId = val && val.pservice ? val.pservice.id : null
        }
    }
    public get service(): ServiceItem { return this._service }
    private _service: ServiceItem

    public set providerServiceId(val: number) {
        if (this._providerServiceId !== val) {
            this._providerServiceId = val
            this._psiChanges.next(val)
        }
    }
    public get providerServiceId(): number { return this._providerServiceId }
    private _providerServiceId: number


    private _psiChanges = new Subject<number>()
    private providerServiceId$ = this._psiChanges.pipe(shareReplay(1))

    private rooms$ = this.providerServiceId$.pipe(
        switchMap(providerServiceId => {
            if (providerServiceId) {
                return this.roomBackend.search({ filter: { provider_service_id: providerServiceId } })
            } else {
                return of([])
            }
        }),
        switchMap(rooms => {
            if (rooms.length === 0) {
                return of([])
            } else {
                const queries = rooms.map(room => {
                    return this.bedBackend.search({ filter: { room_id: room.id } }).pipe(
                        map(beds => new RoomEntry(room, beds))
                    )
                })
                return forkJoin(queries)
            }
        }),
        map(entries => {
            let result: RoomEntry[] = []
            for (const entry of entries) {
                result = result.concat(entry)
            }
            return result
        }),
        shareReplay(1)
    )

    public get roomControls() { return (this.form.get("rooms") as FormArray).controls }

    public readonly bedTypeSrc = BedType.DATA
    public total = {
        normal: 0,
        barrier_free: 0,
        crisis: 0
    }

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: ProviderEditorService,
        @Inject(ProviderBackend) private readonly providerBackend: ProviderBackend,
        @Inject(RoomBackend) private readonly roomBackend: RoomBackend,
        @Inject(BedBackend) private readonly bedBackend: BedBackend,
        @Inject(ProviderServiceBackend) private readonly pserviceBackend: ProviderServiceBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super(editorSvc, new FormGroup({
            rooms: new FormArray([]),
            capacity: new FormControl(),
            is_detailed: new FormControl(),
            is_manual_usage: new FormControl(),
        }))

        this.destruct.subscription(this.form.get("rooms").valueChanges).pipe(debounceTime(10)).subscribe(value => {
            this.total = { normal: 0, barrier_free: 0, crisis: 0 }
            for (const room of value) {
                if (room.is_active) {
                    for (const bed of room.beds) {
                        if (bed.is_active) {
                            (this.total as any)[bed.type as any]++
                        }
                    }
                }
            }

            this.cdr.markForCheck()
        })

        this.destruct.subscription(this.rooms$).subscribe(rooms => {
            const roomsCtrl = this.form.get("rooms") as FormArray
            roomsCtrl.controls.length = 0

            for (const room of rooms) {
                const roomCtrl = this._createRoomGroup(room.room)
                const bedsCtrl = roomCtrl.get("beds") as FormArray
                for (const bed of room.beds) {
                    bedsCtrl.push(this._createBedGroup(bed))
                }
                roomsCtrl.push(roomCtrl)
            }
        })

        this.destruct.subscription(this.providerServiceId$)
            .pipe(
                switchMap(providerServiceId => {
                    return this.pserviceBackend.get({ id: providerServiceId })
                })
            )
            .subscribe(pservice => {
                this.form.get("is_detailed").setValue(pservice.is_detailed)
                this.form.get("is_manual_usage").setValue(pservice.is_manual_usage)
            })
    }

    public ngOnInit() {
        if (this.provider) {
            this.form.get("capacity").reset(this.provider.capacity)
        }
        super.ngOnInit()
    }

    public save() {
        const rooms = this.form.value.rooms as any[]
        const queries: Array<Observable<any>> = rooms.map(room => {
            room.provider_service_id = this.providerServiceId
            return this.roomBackend.save({ data: room })
        })

        if (this.provider) {
            queries.push(this.providerBackend.save({ data: { id: this.provider.id, capacity: this.form.value.capacity } }))
        }

        queries.push(this.pserviceBackend.save({
            data: {
                id: this.providerServiceId,
                is_detailed: !!this.form.get("is_detailed").value,
                is_manual_usage: !!this.form.get("is_manual_usage").value,
            }
        }))

        return forkJoin(queries).pipe(
            tap(() => {
                this.reload()
            })
        )
    }

    public reload() {
        this._psiChanges.next(this._providerServiceId)
    }

    public addRoom() {
        const roomsCtrl = this.form.get("rooms") as FormArray
        roomsCtrl.push(this._createRoomGroup())
        roomsCtrl.markAsDirty()
    }

    public addBed(roomIndex: number) {
        const roomsCtrl = this.form.get("rooms") as FormArray
        const bedsCtrl = roomsCtrl.at(roomIndex).get("beds") as FormArray
        bedsCtrl.push(this._createBedGroup())
        bedsCtrl.markAsDirty()
    }

    public resetCapacity() {
        this.form.get("capacity").setValue(this.total.normal + this.total.barrier_free + this.total.crisis)
    }

    private _createRoomGroup(room?: Room) {
        return new FormGroup({
            id: new FormControl(room ? room.id : null),
            is_active: new FormControl(room ? room.is_active : true),
            title: new FormControl(room ? room.title : null),
            beds: new FormArray([])
        })
    }

    private _createBedGroup(bed?: Bed) {
        return new FormGroup({
            id: new FormControl(bed ? bed.id : null),
            is_active: new FormControl(bed ? bed.is_active : true),
            number: new FormControl(bed ? bed.number : null),
            type: new FormControl(bed ? bed.type : "normal"),
        })
    }
}
