import { Directive, Component, Input, Inject, HostBinding, ChangeDetectorRef, OnInit, OnChanges, Injector, SimpleChanges, InjectFlags } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Observable, Observer, of, EMPTY } from "rxjs"
import { take, switchMap, tap, mapTo, filter, catchError } from "rxjs/operators"
import { isToday, compareAsc, startOfDay, differenceInDays, parseISO } from "date-fns"

import { ModalLayer, LayerService, LocaleService, LoadFields, ToastService } from "@anzar/core"
import { Client } from "@backend/client.api"
import { OrgMark, ClientMark, ClientMarkBackend } from "@backend/mark.api"
import { ProviderBackend, ProviderServiceBackend, SectionBackend } from "@backend/org.api"
import { StoryEntryBackend, StoryEntry, StoryBackend } from "@backend/dispatcher.api"
import { MailingBackend, Mailing } from "@backend/service_mailing.api"
import { ContractType, ClientField } from "@backend/enums.api"
import { Contract } from "@backend/documents.api"
import { MarkService, EDITABLE_MARK_TYPES } from "./mark.service"
import { STORY_ENTRY_FIELDS } from "../dispatcher.module/story-entry-card.component"
import { CurrentSection, PROVIDER_SERVICE_FIELDS } from "../section.service/level"
import { AuthService } from "@pyzar/auth.module"


@Directive()
export abstract class MarkEntryComponent {
    @HostBinding("attr.variant")
    public variant = "filled"

    @HostBinding("attr.color")
    public get color(): string {
        return this.editMode
            ? "base"
            : this.clientMark
                ? (this.markSvc.getAlertColor(this._alertLevel, "light-confirm"))
                : "base"
    }

    // @HostBinding("attr.elevation")
    // public get elevation(): number {
    //     return this.editMode ? 5 : 1
    // }

    @HostBinding("attr.editing")
    public get editing(): string {
        return this.editMode ? "" : null
    }

    @Input()
    public mark: OrgMark

    @Input()
    public clientMark: ClientMark

    @Input()
    public clientId: number

    @Input()
    public condition: any

    @Input()
    public set alertLevel(val: number) {
        if (this._alertLevel !== val) {
            this._alertLevel = val
            this.alertColor = this.markSvc.getAlertColor(val)
        }
    }
    public get alertLevel(): number { return this._alertLevel }
    private _alertLevel: number

    public readonly text = new FormControl()

    public alertColor: string = "base"

    public get isEditable(): boolean {
        return EDITABLE_MARK_TYPES.indexOf(this.mark.mark.type) !== -1
    }

    public get cmIsToday(): boolean {
        if (this.clientMark) {
            return isToday(this.clientMark.created_time)
        } else {
            return false
        }
    }

    public editMode: boolean = false

    public constructor(
        @Inject(MarkService) protected readonly markSvc: MarkService,
        @Inject(LocaleService) protected readonly locale: LocaleService) {

    }

    public markClient() {
        if (this.isEditable) {
            this.editMode = true
        } else {
            this._markClient()
        }
    }

    public unmarkClient(clientMark: ClientMark) {
        this.markSvc.unmarkClient(clientMark).pipe(take(1)).subscribe()
    }

    public abstract _markClient(): void
}


@Component({
    selector: ".rege-mark-entry[type=daily]",
    templateUrl: "./mark-entry-daily.component.pug"
})
export class MarkEntryDailyComponent extends MarkEntryComponent {
    public _markClient() {
        this.markSvc.markClient(this.mark, this.clientId, {})
            .pipe(take(1))
            .subscribe(cm => {
                console.log("client marked", cm)
            })
    }
}


@Component({
    selector: ".rege-mark-entry[type=deadline]",
    templateUrl: "./mark-entry-deadline.component.pug"
})
export class MarkEntryDeadlineComponent extends MarkEntryComponent {
    public readonly deadline = new FormControl()
    public readonly today = startOfDay(new Date())

    public constructor(
        @Inject(MarkService) markSvc: MarkService,
        @Inject(LocaleService) locale: LocaleService,
        @Inject(ToastService) private readonly toast: ToastService) {
        super(markSvc, locale)
    }

    public get deadlineText(): string {
        let deadline = startOfDay(this.clientMark.deadline)
        let formatted = this.locale.formatDate(deadline, "short")
        let diff = Math.abs(differenceInDays(this.today, deadline))

        switch (compareAsc(this.today, deadline)) {
            case -1:
                return `lejár ${diff} nap múlva (${formatted})`

            case 0:
                return `lejár a mai napon (${formatted})`

            case 1:
                return `lejárt ${diff} napja (${formatted})`
        }
    }

    public markClient() {
        if (this.clientMark) {
            this.deadline.setValue(this.clientMark.deadline)
        } else {
            this.deadline.setValue(null)
        }
        return super.markClient()
    }

    public _markClient() {
        if (!this.deadline.value) {
            this.toast.error("Dátum megadása kötelező")
        } else {
            this.markSvc.markClient(this.mark, this.clientId, { deadline: this.deadline.value })
                .pipe(
                    take(1),
                    catchError(err => {
                        this.toast.error(err.message)
                        return EMPTY
                    })
                )
                .subscribe()
        }
    }
}


@Component({
    selector: ".rege-mark-entry[type=lung_screening]",
    templateUrl: "./mark-entry-lung_screening.component.pug"
})
export class MarkEntryLungScreeningComponent extends MarkEntryDeadlineComponent implements OnChanges {
    public readonly deadlineType = new FormControl("valid")

    public ngOnChanges(changes: SimpleChanges) {
        if ("clientMark" in changes) {
            const cm = changes.clientMark.currentValue as ClientMark
            if (cm) {
                this.deadlineType.setValue(cm.deadline_type)
            } else {
                this.deadlineType.setValue("valid")
            }
        }
    }

    public _markClient() {
        this.markSvc.markClient(this.mark, this.clientId, { deadline: this.deadline.value, deadline_type: this.deadlineType.value })
            .pipe(take(1))
            .subscribe()
    }
}


const MAILING_FIELDS: LoadFields<Mailing> = [
    "id", "client_id", "note", "arrived_date", "deadline_date", "given_time", "deleted_time", "provider_service_id",
    { type_value: ["id", "label"] },
    { provider_service: ["id", "provider_id"] }
]


@Component({
    selector: ".rege-mark-entry[type=mailing]",
    templateUrl: "./mark-entry-mailing.component.pug"
})
export class MarkEntryMailingComponent extends MarkEntryComponent {
    public mails: Mailing[] = []

    public providerNamesByServiceId: { [key: string]: string } = {}
    public canGiveByServiceId: { [key: string]: boolean } = {}

    public constructor(
        @Inject(MarkService) markSvc: MarkService,
        @Inject(LocaleService) locale: LocaleService,
        @Inject(MailingBackend) private readonly mailingbackend: MailingBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(ProviderBackend) private readonly providerBackend: ProviderBackend,
        @Inject(SectionBackend) private readonly sectionBackend: SectionBackend,
        @Inject(CurrentSection) private readonly section: CurrentSection,
        @Inject(Injector) private readonly injector: Injector,
        @Inject(ProviderServiceBackend) private readonly providerServiceBackend: ProviderServiceBackend) {
        super(markSvc, locale)

        // console.log({ dutySvc })
    }

    public markClient() {
        this.editMode = !this.editMode

        if (this.editMode) {
            this.realodEntries()
        } else {
            this.mails = []
        }
    }

    public give(entry: Mailing) {
        this.setUsage(entry.client_id, entry.provider_service_id).subscribe()
        this.mailingbackend
            .give({ mailing_id: entry.id })
            .subscribe(v => {
                this.condition.count -= 1
                if (this.condition.count <= 0) {
                    this.markSvc.reload(this.clientMark)
                } else {
                    this.realodEntries()
                }
            })
    }

    protected realodEntries() {
        this.mailingbackend
            .search({
                filter: {
                    client_id: this.clientId,
                    deleted_time: null,
                    given_time: null,
                },
                order: { arrived_date: "asc" },
                begin: 0,
                count: 20
            }, { loadFields: MAILING_FIELDS })
            .pipe(
                take(1),
                switchMap(mails => {
                    let psidMap: { [key: number]: number } = {}
                    let providerMap: { [key: number]: number } = {}

                    for (const mail of mails) {
                        psidMap[mail.provider_service.id] = mail.provider_service.provider_id
                        providerMap[mail.provider_service.provider_id] = mail.provider_service.id
                    }

                    let providerIds = Object.keys(providerMap).map(Number)

                    return this.providerBackend
                        .search({
                            filter: { id: { in: providerIds } },
                            order: {},
                            begin: 0,
                            count: 100
                        }, { loadFields: ["id", "parent_id"] })
                        .pipe(
                            switchMap(providers => {
                                return this.sectionBackend
                                    .search({
                                        filter: {
                                            id: { in: providers.map(p => p.parent_id) }
                                        },
                                        order: {},
                                        begin: 0,
                                        count: 1000
                                    }, { loadFields: ["id", "title"] })
                                    .pipe(
                                        tap(sections => {
                                            for (const section of sections) {
                                                const provider = providers.find(p => p.parent_id === section.id)
                                                this.providerNamesByServiceId[providerMap[provider.id]] = section.title
                                                this.canGiveByServiceId[providerMap[provider.id]] = !this.section.id || this.section.id === section.id
                                            }
                                        })
                                    )
                            }),
                            // map()
                            mapTo(mails)
                        )
                }),
            )
            .subscribe(v => {
                this.mails = v
                this.cdr.markForCheck()
            })
    }

    public _markClient() {

    }

    protected setUsage(clientId: number, providerServiceId: number): Observable<any> {
        return Observable.create((observer: Observer<any>) => {
            import("../duty.module").then(({ DutyService }) => {
                const dutySvc = this.injector.get(DutyService, null, InjectFlags.Optional)
                if (dutySvc) {
                    const s = this.providerServiceBackend
                        .get({ id: providerServiceId }, { loadFields: PROVIDER_SERVICE_FIELDS })
                        .pipe(
                            switchMap(providerService => {
                                return dutySvc.addClient(clientId, false, true, [providerService])
                            }),
                            take(1)
                        )
                        .subscribe(observer)

                    return s.unsubscribe.bind(s)
                } else {
                    observer.complete()
                }
            })
        })
    }
}


@Component({
    selector: ".rege-mark-entry[type=lookfor]",
    templateUrl: "./mark-entry-lookfor.component.pug"
})
export class MarkEntryLookforComponent extends MarkEntryComponent {
    public storyEntries: StoryEntry[]

    public constructor(
        @Inject(MarkService) markSvc: MarkService,
        @Inject(LocaleService) locale: LocaleService,
        @Inject(StoryEntryBackend) private readonly storyEntryBackend: StoryEntryBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super(markSvc, locale)
    }

    public markClient() {
        this.editMode = !this.editMode

        if (this.editMode) {
            this._reloadList()
        } else {
            this.storyEntries = []
        }
    }

    public notified() {
        this.storyEntryBackend
            .done_lookfor({ client_id: this.clientMark.client_id, story_entry_ids: this.condition.story_entry_ids })
            .pipe(take(1))
            .subscribe(() => {
                this.markSvc.reload(this.clientMark)
            })
    }

    protected _reloadList() {
        const ids = this.condition.story_entry_ids
        if (!ids || !ids.length) {
            return
        }

        this.storyEntryBackend
            .search({
                filter: { id: { in: ids } },
                order: { call_time: "desc" }
            }, { loadFields: STORY_ENTRY_FIELDS })
            .pipe(take(1))
            .subscribe(entries => {
                this.storyEntries = entries
                this.cdr.detectChanges()
            })
    }

    public _markClient() {

    }
}


@Component({
    selector: ".rege-mark-entry[type=ban]",
    templateUrl: "./mark-entry-ban.component.pug"
})
export class MarkEntryBanComponent extends MarkEntryComponent implements OnInit {
    public deadlineText: string

    public constructor(
        @Inject(MarkService) markSvc: MarkService,
        @Inject(LocaleService) locale: LocaleService) {
        super(markSvc, locale)
    }

    public ngOnInit() {
        if (this.condition && this.condition.client_ban) {
            const ban = this.condition.client_ban
            const begin = parseISO(ban.begin)
            const end = ban.end ? parseISO(ban.end) : null

            this.deadlineText = this.locale.formatDateRange(begin, end, true)
        }
    }

    public _markClient() {

    }
}


@Component({
    selector: ".rege-mark-entry[type=dead]",
    templateUrl: "./mark-entry-dead.component.pug"
})
export class MarkEntryDeadComponent extends MarkEntryComponent implements OnInit {
    public contentText: string

    public constructor(
        @Inject(MarkService) markSvc: MarkService,
        @Inject(LocaleService) locale: LocaleService) {
        super(markSvc, locale)
    }

    public ngOnInit() {
        if (this.condition && this.condition.section_id) {
            this.contentText = `${this.condition.section_title} ${this.locale.formatDate(parseISO(this.condition.subject_date), "short")}`
        }
    }

    public _markClient() {

    }
}


@Component({
    selector: ".rege-mark-entry[type=contract]",
    templateUrl: "./mark-entry-contract.component.pug"
})
export class MarkEntryContractComponent extends MarkEntryComponent implements OnInit {
    public readonly deadline = new FormControl()
    public readonly contractType: string
    public readonly contractTitle: string

    public get deadlineText(): string { return formatDeadline(this.locale, this.clientMark.deadline) }

    public constructor(
        @Inject(MarkService) markSvc: MarkService,
        @Inject(LocaleService) locale: LocaleService,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(ClientMarkBackend) private readonly cmBackend: ClientMarkBackend,
        @Inject(AuthService) private readonly authSvc: AuthService) {
        super(markSvc, locale)
    }

    public ngOnInit() {
        const contractType = this.condition ? this.condition.contract_type : this.mark.mark.contract_type
        const ct = ContractType.DATA.data.find(ct => ct.id === contractType);
        (this as { contractTitle: string }).contractTitle = ct ? ct.label : null;
        (this as { contractType: string }).contractType = contractType
    }

    public extend() {
        const contract = this.condition.contract as Contract
        this.editContract({
            begin: contract.end,
            payed_amount: contract.payed_amount,
            section_id: contract.section_id,
            creator_id: this.authSvc.userId,
            created_time: new Date()
        })
    }

    public markClient() {
        this.editContract()
    }

    public _markClient() {
    }

    private editContract(data?: any) {
        this.showContractForm(data)
            .pipe(
                filter(contract => !!(contract && contract.client_mark_id)),
                switchMap(contract => {
                    if (this.clientMark && this.clientMark.id === contract.client_mark_id) {
                        return of(this.clientMark)
                    } else if (contract.client_mark_id) {
                        return this.cmBackend.get({ id: contract.client_mark_id })
                    }
                })
            )
            .subscribe(clientMark => {
                this.markSvc.reload(clientMark)
            })
    }

    private showContractForm(data: any): Observable<Contract> {
        const behavior = new ModalLayer({
            rounded: 3,
            elevation: 10
        })

        return new Observable((observer: Observer<Contract>) => {
            import("../client.module/documents/documents.service").then(({ DOCUMENT_FORM_DATA }) => {
                import("../client.module/documents/contract/contract-form.component").then(({ ContractFormComponent, CONTRACT_TYPE }) => {
                    const ref = this.layerSvc.createFromComponent(ContractFormComponent, behavior, null, [
                        { provide: DOCUMENT_FORM_DATA, useValue: data },
                        { provide: CONTRACT_TYPE, useValue: this.contractType },
                        { provide: Client, useValue: new Client({ id: this.clientId }) },
                    ])
                    ref.show()

                    const s = ref.output.subscribe(event => {
                        if (event.type === "saved") {
                            observer.next(event.data)
                            observer.complete()
                        } else if (event.type === "hiding") {
                            s.unsubscribe()
                            observer.next(null)
                            observer.complete()
                        }
                    })
                })
            })
        })
    }
}


@Component({
    selector: ".rege-mark-entry[type=required_fields]",
    templateUrl: "./mark-entry-required-fields.component.pug"
})
export class MarkEntryRequiredFieldsComponent extends MarkEntryComponent implements OnInit {
    public fieldLabels: string = ""

    public constructor(
        @Inject(MarkService) markSvc: MarkService,
        @Inject(LocaleService) locale: LocaleService,
        @Inject(Injector) private readonly injector: Injector) {
        super(markSvc, locale)
    }

    public ngOnInit() {
        const rf = this.condition.field_names
        if (rf) {
            let labels = []
            for (const name of rf) {
                const value = ClientField.DATA.getSync(name)
                labels.push(value.label)
            }
            this.fieldLabels = labels.join(", ")
        }
    }

    public openClientBasicData() {
        this._showClientSheet(this.clientMark.client_id)
            .pipe(filter(event => event.type === "hiding"), take(1))
            .subscribe(event => {
                if (event.type === "hiding") {
                    this.markSvc.reload(this.clientMark)
                }
            })
    }

    public _markClient() {
    }

    private _showClientSheet(clientId: number): Observable<any> {
        return Observable.create((observer: Observer<any>) => {
            import("../client.module").then(({ ClientService, ClientSheet }) => {
                const sheet = this.injector.get(ClientSheet, null)
                if (!sheet || !sheet.client || sheet.client.id !== clientId) {
                    this.injector.get(ClientService).showSheet(clientId, ClientService.TAB_BASIC_DATA).subscribe(observer)
                } else {
                    sheet.selectedTabIndex = ClientService.TAB_BASIC_DATA
                }
            })
        })
    }
}


@Component({
    selector: ".rege-mark-entry[type=toggle_wdate]",
    templateUrl: "./mark-entry-toggle-wdate.component.pug"
})
export class MarkEntryToggleWdateComponent extends MarkEntryComponent {
    public readonly start = new FormControl()
    public readonly deadline = new FormControl()

    public constructor(
        @Inject(MarkService) markSvc: MarkService,
        @Inject(LocaleService) locale: LocaleService,
        @Inject(ToastService) protected readonly toastSvc: ToastService) {
        super(markSvc, locale)
    }

    public _markClient() {
        this.markSvc.markClient(this.mark, this.clientId, { start: this.start.value, text: this.text.value })
            .pipe(
                take(1),
                catchError(err => {
                    this.toastSvc.error(err.message)
                    return EMPTY
                })
            )
            .subscribe()
    }

    public _unmarkClient() {
        let params: any = { deadline: this.deadline.value, text: this.text.value }
        this.markSvc.unmarkClient(this.clientMark, params)
            .pipe(
                take(1),
                catchError(err => {
                    this.toastSvc.error(err.message)
                    return EMPTY
                })
            )
            .subscribe()
    }

    public beginSet() {
        this.text.setValue(this.clientMark?.text)
        this.start.setValue(new Date())
        this.editMode = true
    }

    public beginUnset() {
        this.text.setValue(this.clientMark?.text)
        this.deadline.setValue(new Date())
        this.editMode = true
    }
}


@Component({
    selector: ".rege-mark-entry[type=covid_vaccination]",
    templateUrl: "./mark-entry-covid_vaccination.component.pug"
})
export class MarkEntryCovidVaccinationComponent extends MarkEntryToggleWdateComponent {
    public _unmarkClient() {
        let params: any = { deadline: this.deadline.value, text: this.text.value }
        if (this.mark.mark.name === "covid_vaccination") {
            params.is_active = true
        }
        this.markSvc.unmarkClient(this.clientMark, params)
            .pipe(
                take(1),
                catchError(err => {
                    this.toastSvc.error(err.message)
                    return EMPTY
                })
            )
            .subscribe()
    }

    public beginEdit() {
        this.text.setValue(this.clientMark?.text)
        this.start.setValue(this.start.value || this.clientMark?.start || new Date())
        this.deadline.setValue(this.deadline.value || this.clientMark?.deadline)
        this.editMode = true
    }

    public updateMark() {
        let params = {
            start: this.start.value,
            deadline: this.deadline.value,
            text: this.text.value,
            is_active: true,
        }
        this.markSvc.updateClientMark(this.clientMark, params)
            .pipe(
                take(1),
                catchError(err => {
                    this.toastSvc.error(err.message)
                    return EMPTY
                })
            )
            .subscribe()
    }

    public deleteMark() {
        this.markSvc.deleteClientMark(this.clientMark).subscribe()
    }
}


@Component({
    selector: ".rege-mark-entry[type=covid_vaccination_n]",
    templateUrl: "./mark-entry-covid_vaccination_n.component.pug"
})
export class MarkEntryCovidVaccinationNComponent extends MarkEntryToggleWdateComponent {
    public _unmarkClient() {

    }

    public beginEdit() {
        this.text.setValue(this.clientMark?.text)
        this.start.setValue(this.start.value || this.clientMark?.start || new Date())
        this.editMode = true
    }

    public updateMark() {
        let params = {
            start: this.start.value,
            text: this.text.value,
            is_active: true,
        }
        this.markSvc.updateClientMark(this.clientMark, params)
            .pipe(
                take(1),
                catchError(err => {
                    this.toastSvc.error(err.message)
                    return EMPTY
                })
            )
            .subscribe()
    }

    public deleteMark() {
        this.markSvc.deleteClientMark(this.clientMark).subscribe()
    }
}


@Component({
    selector: ".rege-mark-entry[type=schedule]",
    templateUrl: "./mark-entry-schedule.component.pug"
})
export class MarkEntryScheduleComponent extends MarkEntryComponent implements OnInit {
    public deadlineText: string

    public ngOnInit() {
        if (this.condition.end) {
            this.deadlineText = formatDeadline(this.locale, parseISO(this.condition.end))
        }
    }

    public _markClient(): void {
        throw new Error("Method not implemented.")
    }

}


@Component({
    selector: ".rege-mark-entry[type=date_range]",
    templateUrl: "./mark-entry-date_range.component.pug"
})
export class MarkEntryDateRangeComponent extends MarkEntryComponent implements OnInit {
    public readonly start = new FormControl()
    public readonly deadline = new FormControl()
    public dateRangeText: string

    public constructor(
        @Inject(MarkService) markSvc: MarkService,
        @Inject(LocaleService) locale: LocaleService,
        @Inject(ToastService) protected readonly toastSvc: ToastService) {
        super(markSvc, locale)
    }

    public ngOnInit() {
        if (this.clientMark) {
            this.dateRangeText = this.locale.formatDateRange(this.clientMark.start, this.clientMark.deadline, true)
        }
    }

    public _markClient() {
        this.markSvc.markClient(this.mark, this.clientId, { start: this.start.value, deadline: this.deadline.value })
            .pipe(
                take(1),
                catchError(err => {
                    this.toastSvc.error(err.message)
                    return EMPTY
                })
            )
            .subscribe()
    }

    public beginEdit() {
        this.start.setValue(this.start.value || this.clientMark?.start || new Date())
        this.deadline.setValue(this.deadline.value || this.clientMark?.deadline)
        this.editMode = true
    }

    public updateMark() {
        let params = {
            start: this.start.value,
            deadline: this.deadline.value,
            is_active: true,
        }
        this.markSvc.updateClientMark(this.clientMark, params)
            .pipe(
                take(1),
                catchError(err => {
                    this.toastSvc.error(err.message)
                    return EMPTY
                })
            )
            .subscribe()
    }

    public deleteMark() {
        this.markSvc.deleteClientMark(this.clientMark).subscribe()
    }
}


function formatDeadline(locale: LocaleService, deadline: Date): string {
    deadline = startOfDay(deadline)
    const today = startOfDay(new Date())
    const formatted = locale.formatDate(deadline, "short")
    const diff = Math.abs(differenceInDays(today, deadline))

    switch (compareAsc(today, deadline)) {
        case -1:
            return `lejár ${diff} nap múlva (${formatted})`

        case 0:
            return `lejár a mai napon (${formatted})`

        case 1:
            return `lejárt ${diff} napja (${formatted})`
    }
}
