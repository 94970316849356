import { Component, Input, Output, Inject, Optional, OnInit } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { BehaviorSubject, Observable, combineLatest, of } from "rxjs"
import { catchError, filter, map, shareReplay, switchMap, take } from "rxjs/operators"

import { ObservableSource, ToastService } from "@anzar/core"
import { PlaceBackendSource } from "@backend/place.api"
import { DutyBackend, ProviderService } from "@backend/org.api"
import { EventBackend } from "@backend/event.api"
import { Duty } from "@backend/org.api"
import { ServiceUsage, DutyService } from "../../../duty.module"
import { DUTY_FIELDS } from "../../../duty.module/duty.service"
import { AbstractEditor } from "../abstract"


export type StreetCareData = { [key: string]: any }


@Component({
    selector: ".rege-street-service-editor",
    exportAs: "regeStreetEditor",
    templateUrl: "./street.component.pug"
})
export class StreetEditorComponent extends AbstractEditor implements OnInit {
    @Input()
    public set usage(val: ServiceUsage) {
        if (this._usage !== val) {
            this._usage = val
            this.fillFromUsage()
        }
    }
    public get usage(): ServiceUsage { return this._usage }
    private _usage: ServiceUsage

    public readonly form = new FormGroup({
        place_id: new FormControl(),
        place_extra: new FormControl(),
        begin_time: new FormControl(),
        end_time: new FormControl(),
        description: new FormControl(),
    })

    @Input()
    public set data(val: StreetCareData) { this.form.setValue(val) }
    public get data(): StreetCareData { return this.form.value }

    @Input()
    public set duty(val: Duty) {
        this._duty.next(val)
    }
    public get duty() {
        return this._duty.value
    }
    private _duty = new BehaviorSubject<Duty>(null)

    @Input() public providerService: ProviderService

    @Output("data")
    public onData: Observable<StreetCareData> = this.form.valueChanges

    public readonly duty$ = this._duty.pipe(
        switchMap(duty => {
            if (duty) {
                return of(duty)
            } else {
                const dutyId = this.usage.events[0].duty_id
                if (!dutyId) {
                    return of(null)
                }

                if (!this.dutyService) {
                    return this.dutyService.getDuty(dutyId).pipe(take(1))
                } else {
                    return this.dutyBackend.get({ id: dutyId }, {loadFields: DUTY_FIELDS}).pipe(take(1))
                }
            }
        }),
        shareReplay(1)
    )

    public readonly plannedPlaces$ = this.duty$.pipe(
        filter(v => !!v),
        map(duty => duty.places),
        shareReplay(1)
    )

    public hasPlannedPlace$ = this.plannedPlaces$.pipe(map(v => v && v.length > 0))

    public readonly plannedPlaceSrc$ = new ObservableSource(this.plannedPlaces$)

    public isPlannedPlace = true

    public constructor(
        @Inject(PlaceBackendSource) protected readonly placeSource: PlaceBackendSource,
        @Inject(EventBackend) private readonly eventBackend: EventBackend,
        @Inject(DutyService) @Optional() private readonly dutyService: DutyService,
        @Inject(ToastService) private readonly toastService: ToastService,
        @Inject(DutyBackend) private readonly dutyBackend: DutyBackend) {
        super()
    }

    public ngOnInit() {
        this.destruct.subscription(this.duty$)
            .pipe(
                filter(value => !!value),
                take(1),
                switchMap(duty => this.eventBackend.search({
                    filter: { duty_id: duty.id, type: this.providerService.service.type, end: { "neq": null } },
                    order: { end: "desc" },
                    begin: 0,
                    count: 1
                }, { loadFields: ["end"] }))
            )
            .subscribe(lastEvent => {
                if (lastEvent.length) {
                    const beginControl = this.form.get("begin_time")
                    if (!beginControl.value) {
                        beginControl.setValue(lastEvent[0].end)
                    }
                }
            })

        this.destruct.subscription(this.hasPlannedPlace$).pipe(take(1)).subscribe(hasPlanned => {
            this.switchPlannedPlace(hasPlanned)
        })

    }

    public save() {
        const data = this.form.value

        console.log(data)

        this.eventBackend
            .save({
                data: {
                    id: this.usage.events[0].id,
                    place_id: data.place_id,
                    place_extra: data.place_extra,
                    begin: data.begin_time,
                    end: data.end_time,
                    content: data.description,

                }
            })
            .pipe(this.toastService.handleSave({ align: "bottom center", beginMsg: "Mentés...", successMsg: "Sikeresen módosítottad a bejegyzést" }))
            .subscribe(v => {
                this.dutyService && this.dutyService.realoadEvent(v.id)
            })
    }

    public cancel() {
        this.fillFromUsage()
    }

    private fillFromUsage() {
        if (this.usage && this.usage.events.length === 1) {
            const event = this.usage.events[0]
            this.form.reset({
                place_id: event.place,
                place_extra: event.place_extra,
                begin_time: event.begin,
                end_time: event.end,
                description: event.richtext ? event.richtext.text : null,
            })
        } else {
            throw new Error("Invalid number of events in usage")
        }
    }

    public switchPlannedPlace(isPlanned: boolean) {
        if (this.isPlannedPlace === isPlanned) {
            return
        }
        this.isPlannedPlace = isPlanned
    }
}
