import { Inject, Injectable } from "@angular/core"
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router"
import { Observable, of } from "rxjs"
import { tap, map, catchError } from "rxjs/operators"

import { User } from "@backend/pyzar.api"
import { AuthService } from "./auth.service"


@Injectable()
export class AuthorizedOnly implements CanActivate, CanActivateChild {
    public readonly allowPasswordChange: boolean = false

    constructor(
        @Inject(AuthService) protected readonly authSvc: AuthService) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authSvc.currentUser$
            .pipe(
                catchError(err => of(null)),
                map(user => {
                    if (user instanceof User) {
                        if (user.password_expired) {
                            if (this.allowPasswordChange) {
                                return true
                            } else {
                                this.authSvc.gotoChangePw(state.url)
                                return false
                            }
                        } else {
                            return true
                        }
                    } else {
                        this.authSvc.gotoLogin(state.url)
                        return false
                    }
                })
            )
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(childRoute, state)
    }
}


@Injectable()
export class AuthorizedOnlyPwChange extends AuthorizedOnly {
    public readonly allowPasswordChange: boolean = true
}


@Injectable()
export class UnauthorizedOnly implements CanActivate, CanActivateChild {
    constructor(
        @Inject(AuthService) protected readonly authSvc: AuthService,
        @Inject(Router) protected readonly router: Router) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authSvc.currentUser$.pipe(
            catchError(err => of(null)),
            map(user => !user),
            tap(val => {
                if (!val) {
                    this.router.navigate([""])
                }
            }))
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(childRoute, state)
    }
}
