import { Component, Inject } from "@angular/core"
import { Router } from "@angular/router"


@Component({
    templateUrl: "./users.screen.pug"
})
export class UsersScreen {
    public constructor(
        @Inject(Router) private readonly router: Router) {
    }

    public newUser() {
        this.router.navigate(["/users/create"])
    }
}
