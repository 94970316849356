import { Directive, Input, Inject, ElementRef, Optional } from "@angular/core"
import { of } from "rxjs"
import { switchMap } from "rxjs/operators"

import { ImageRefSource, ImageRef, LayerService, QtipManager, Destructible, QtipAlignDirective, LayerRef, Align, QtipBehavior } from "@anzar/core"
import { FileBackend } from "@backend/pyzar.api"
import { FsService } from "@pyzar/fs.module"
import { ImageTipComponent } from "./image-tip.component"


@Directive({
    selector: "[regeImageTip]"
})
export class ImageTipDirective extends Destructible {
    @Input("regeImageTip") public src: ImageRefSource | number

    public readonly image = new ImageRef()

    private _layerRef: LayerRef

    public constructor(
        @Inject(ElementRef) private readonly el: ElementRef<HTMLElement>,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(QtipManager) private readonly qtipManager: QtipManager,
        @Inject(QtipAlignDirective) @Optional() private readonly align: QtipAlignDirective,
        @Inject(FileBackend) private readonly fileBackend: FileBackend,
        @Inject(FsService) private readonly fsService: FsService) {
        super()

        this.destruct.subscription(qtipManager.watch(el.nativeElement, 200))
            .pipe(
                switchMap(visible => {
                    let src = this.src
                    if (typeof src === "number") {
                        src = this.fsService.getImageUrl(src, 200, 200)
                    }
                    if (visible) {
                        this.image.load(src)
                        return this.image.scaled$
                    } else {
                        return of(null)
                    }
                })
            )
            .subscribe(image => {
                if (image) {
                    this.show()
                } else {
                    this.hide()
                }
            })
    }

    public show() {
        if (this.destruct.done) {
            return
        }

        if (!this._layerRef || !this._layerRef.isVisible) {
            let tAlign: Align
            let lAlign: Align
            if (this.align) {
                tAlign = this.align.targetAlign
                lAlign = this.align.levitateAlign
            } else {
                tAlign = { horizontal: "left", vertical: "center" }
                lAlign = { horizontal: "right", vertical: "center" }
            }

            let behavior = new QtipBehavior({
                elevation: 7,
                rounded: 4,
                position: {
                    align: lAlign,
                    anchor: {
                        ref: this.el.nativeElement,
                        align: tAlign,
                        margin: 16
                    }
                },
                maxWidth: 200,
                maxHeight: 200,
                minWidth: 200,
                minHeight: 200,
            })
            this._layerRef = this.layerSvc.createFromComponent(ImageTipComponent, behavior, null, [
                { provide: ImageRef, useValue: this.image }
            ])
            this._layerRef.show()
        }
    }

    public hide() {
        if (this._layerRef) {
            this._layerRef.hide()
            delete this._layerRef
        }
    }

    public ngOnDestroy() {
        super.ngOnDestroy()
        this.hide()
    }
}
