import { Component, forwardRef, Inject, OnInit, Input } from "@angular/core"
import { FormGroup, FormArray, FormControl, Validators } from "@angular/forms"
import { take } from "rxjs/operators"

import { Section, LevelBackendSource, SectionRestrictionBackend, LevelVariant } from "@backend/org.api"
import { } from "@backend/enums.api"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"


const VARIANT_TITLE: { [key: string]: string } = {}

for (const v of LevelVariant.DATA.data) {
    VARIANT_TITLE[v.id] = v.label
}


@Component({
    selector: ".rege-section-restriction-editor",
    templateUrl: "./section-restriction.component.pug",
    host: {
        "[style.padding.px]": "16"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => SectionRestrictionComponent) }
    ]
})
export class SectionRestrictionComponent extends AbstractCLEditorBlock {
    @Input() public section: Section
    @Input() public type: string

    public get levelControls() { return (this.form.get("levels") as FormArray).controls }

    public readonly variantTitle = VARIANT_TITLE

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: AbstractCLEditorService,
        @Inject(LevelBackendSource) public readonly levelSrc: LevelBackendSource,
        @Inject(SectionRestrictionBackend) private readonly restrictBackend: SectionRestrictionBackend) {
        super(editorSvc, new FormGroup({
            levels: new FormArray([
                new FormControl()
            ])
        }))

        this.destruct.subscription(this.form.get("levels").valueChanges).subscribe((values: number[]) => {
            let hasEmpty = values.filter(v => !v).length > 0
            if (!hasEmpty) {
                this.addLevel()
            }
        })
    }

    public ngOnInit() {
        super.ngOnInit()
        this.restrictBackend
            .search({ section_id: this.section.id, type: this.type })
            .pipe(
                take(1)
            ).
            subscribe(result => {
                const ctrl = this.form.get("levels") as FormArray
                ctrl.controls.length = 0

                for (const item of result) {
                    ctrl.controls.push(new FormControl(item.level_id))
                }

                ctrl.updateValueAndValidity()
            })
    }

    public save() {
        let levelIds = (this.form.get("levels") as FormArray).controls
            .map(ctrl => ctrl.value)
            .filter(v => !!v)
        return this.restrictBackend.update({ section_id: this.section.id, type: this.type, level_ids: levelIds })
    }

    public addLevel() {
        const ctrl = this.form.get("levels") as FormArray
        ctrl.push(new FormControl())
    }

    public removeLevel(index: number) {
        const ctrl = this.form.get("levels") as FormArray
        ctrl.removeAt(index)
    }
}
