import { Component, ChangeDetectionStrategy } from "@angular/core"


const CHANGELOG = require("CHANGELOG.md")


@Component({
    selector: ".rege-changelog",
    templateUrl: "./changelog.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangelogComponent {
    public readonly changelog = CHANGELOG.default
}
