import { Inject, Injectable } from "@angular/core"
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { Subject, of, Observable, merge } from "rxjs"
import { shareReplay, switchMap, tap, take, distinctUntilChanged, map, first } from "rxjs/operators"

import { LoadFields } from '@anzar/core'
import { User, UserBackend } from "@backend/pyzar.api"
import { AbstractCLEditorService } from "../../common.module/components/card-list/abstract"
import { validateEqual } from "../../common.module"
import { AuthService } from "../auth.service"


const FIELDS: LoadFields<User> = [
    "id", "name", "email", "email_is_valid", "pending_email", "phone", "username", "is_active", "profile_image_id", "groups",
]


@Injectable()
export class UserEditorService extends AbstractCLEditorService {
    public readonly form = new FormGroup({
        id: new FormControl(),
        name: new FormGroup({
            title: new FormControl(),
            family: new FormControl(),
            given: new FormControl()
        }),
        email: new FormControl(),
        phone: new FormControl(),
        username: new FormControl(null, [Validators.required]),
        password: new FormControl(),
        password_re: new FormControl(null, [validateEqual("password")]),
        is_active: new FormControl(),
        email_is_valid: new FormControl(),
        pending_email: new FormControl(),
        groups: new FormControl(),
    })

    public set id(val: number) {
        if (this._id !== val) {
            this._idChange.next(this._id = val)
        }
    }
    public get id(): number { return this._id }
    private _id: number

    private _idChange = new Subject<number>()
    public readonly id$ = this._idChange.pipe(shareReplay(1))

    // public isAdmin: boolean = false
    // public isMySheet: boolean = false

    public readonly user$: Observable<User> = this.id$.pipe(
        switchMap(id => {
            if (id) {
                return this.userBackend.get({ id }, { loadFields: FIELDS })
            } else {
                return of(null)
            }
        }),
        shareReplay(1)
    )

    public readonly isSelf$ = this.id$.pipe(
        switchMap(userId => {
            return this.authService.currentUser$.pipe(
                map(user => {
                    return !userId || userId === user?.id
                })
            )
        }),
        shareReplay(1)
    )

    public readonly isNotSelf$ = this.id$.pipe(
        switchMap(userId => {
            return this.authService.currentUser$.pipe(
                map(user => {
                    return user && userId !== user.id
                })
            )
        }),
        shareReplay(1)
    )

    public readonly canManage$ = this.isSelf$.pipe(
        switchMap(isSelf => {
            if (isSelf) {
                return of(true)
            } else {
                return this.authService.hasPermission("auth.user.manage")
            }
        }),
        shareReplay(1)
    )

    public readonly canEditGroups$ = this.isNotSelf$.pipe(
        switchMap(isNotSelf => {
            if (isNotSelf) {
                return this.user$.pipe(switchMap(user => {
                    if (user && (user.username === "admin" || user.username === "system")) {
                        return of(false)
                    } else {
                        return this.authService.hasPermission("auth.user.groups")
                    }
                }))
            } else {
                return of(false)
            }
        }),
        shareReplay(1)
    )

    public readonly canEditLogin$ = this.isSelf$.pipe(
        switchMap(isSelf => {
            if (isSelf) {
                return of(true)
            } else {
                return this.canManage$.pipe(switchMap(canManage => {
                    if (canManage) {
                        return this.authService.hasPermission("auth.user.login")
                    } else {
                        return of(false)
                    }
                }))
            }
        }),
        shareReplay(1)
    )

    public readonly canEditStatus$ = this.isNotSelf$.pipe(
        switchMap(isNotSelf => {
            return isNotSelf
                ? this.authService.hasPermission("user.status")
                : of(false)
        }),
        shareReplay(1)
    )

    public readonly canEditBasicData$ = this.canManage$

    public readonly canChangePass$ = this.isSelf$.pipe(
        switchMap(isSelf => {
            if (isSelf) {
                return of(true)
            } else {
                return this.canEditLogin$
            }
        }),
        shareReplay(1)
    )

    public readonly canResetPass$ = this.isNotSelf$.pipe(
        switchMap(isNotSelf => {
            if (isNotSelf) {
                return this.canEditLogin$
            } else {
                return of(false)
            }
        }),
        shareReplay(1)
    )

    public readonly canEditUsername$ = this.user$.pipe(
        switchMap(user => {
            if (user && (user.username === "admin" || user.username === "system")) {
                return of(false)
            } else {
                return this.isSelf$.pipe(
                    switchMap(isSelf => {
                        if (isSelf) {
                            return of(true)
                        } else {
                            return this.authService.hasPermission("user.login.username")
                        }
                    })
                )
            }
        }),
        shareReplay(1)
    )

    public constructor(
        @Inject(UserBackend) private readonly userBackend: UserBackend,
        @Inject(AuthService) private readonly authService: AuthService) {
        super()

        this.destruct.subscription(this.user$)
            .pipe(
                tap(user => {
                    if (user) {
                        this._fillFrom(this.form, user)
                    } else {
                        this.form.reset({
                            is_active: true,
                            email_is_valid: false,
                        })
                    }
                }),
                switchMap(() => {
                    return this.canEditGroups$.pipe(
                        tap(canEditGroups => {
                            if (!canEditGroups) {
                                this.form.get("groups").setValue(null)
                            }
                        })
                    )
                })
            )
            .subscribe()
    }

    public resetForm(user: User) {
        this.form.reset()
        if (user) {
            this._id = null
            this.id = user.id
        } else {
            this.id = null
        }
    }

    private _fillFrom(group: FormGroup, user: { [key: string]: any }) {
        const controls = group.controls
        for (const k in controls) {
            const control = controls[k]
            const value = user[k]

            if (control instanceof FormGroup) {
                value && this._fillFrom(control, value)
            } else {
                control.setValue(value)
            }
        }
    }
}
