import { NgModule } from "@angular/core"
import { KenysziReport, EntryAction, EntryStatus, KenysziReportEntry, ProviderContract, BlockedIgenybevevo, Transaction, TransactionLog, KenysziReportBackend, KenysziReportEntryBackend, KenysziBackend, KenysziReportBackendSource, KenysziReportEntryBackendSource } from "./__anzar_rpc_output"

export { KenysziReport, EntryAction, EntryStatus, KenysziReportEntry, ProviderContract, BlockedIgenybevevo, Transaction, TransactionLog, KenysziReportBackend, KenysziReportEntryBackend, KenysziBackend, KenysziReportBackendSource, KenysziReportEntryBackendSource }


@NgModule({
    providers: [
        KenysziReportBackendSource,
        KenysziReportEntryBackendSource,
        KenysziBackend,
        KenysziReportBackend,
        KenysziReportEntryBackend
    ],
})
export class KenysziApi {}