import { Inject, Injector, Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { map, take } from "rxjs/operators"

import { RichtextAcProvider, RichtextAcItem, RichtextAcSession, LoadFields, LocaleService } from "@anzar/core"
import { ClientBackend, Client } from "@backend/client.api"
import { FullName } from "@backend/pyzar.api"

const babySvg = require("mdi/baby.svg")
const CLIENT_FIELDS: LoadFields<Client> = ["id", "name", "mothers_name", "birth_date"]


@Injectable()
export class ClientRichtextProvider extends RichtextAcProvider {
    public readonly trigger = /^@/
    public readonly terminate: any = null
    public readonly mustSelect = true
    public readonly minChars = 1

    public constructor(
        @Inject(ClientBackend) private readonly clientBackend: ClientBackend,
        @Inject(LocaleService) private readonly localeSvc: LocaleService,
        @Inject(Injector) private readonly injector: Injector) {
        super()
    }

    public query(value: string): Observable<RichtextAcItem[]> {
        value = value.substr(1)
        const base: Observable<RichtextAcItem[]> = value.length < 1
            ? of([])
            : this.clientBackend
                .search({
                    filter: { "search": value },
                    order: { "name.formatted": "asc" },
                    begin: 0,
                    count: 20
                }, { loadFields: CLIENT_FIELDS })
                .pipe(
                    map(clients => clients.map(client => {
                        let name = this.formatClientName(client.name)
                        return new RichtextAcItem({
                            id: `client:${client.id}`,
                            label: name,
                            note: `ANYJA: ${client.mothers_name ? this.formatClientName(client.mothers_name) : "-"} SZÜL: ${client.birth_date ? this.localeSvc.formatDate(client.birth_date, "short") : "-"}`,
                            icon: babySvg
                        })
                    }))
                )

        return base.pipe(
            map(clients => {
                clients.push(new RichtextAcItem({
                    id: "client:new",
                    label: "Új ügyfél felvétele",
                    isAction: true,
                    icon: babySvg
                }))
                return clients
            })
        )
    }

    public onSelect(sess: RichtextAcSession, item: RichtextAcItem): void {
        if (item.id === "client:new") {
            this.persistAnchor(sess)

            import("../../client.module/client.service").then(({ ClientService }) => {
                this.injector.get(ClientService).createClient(true).pipe(take(1)).subscribe(client => {
                    if (client) {
                        this.replaceWithComponent(sess, "client-link", {
                            client_id: client.id,
                            content: this.formatClientName(client.name)
                        })
                    } else {
                        this.removeAnchor(sess)
                    }
                })
            })
        } else {
            this.replaceWithComponent(sess, "client-link", {
                client_id: Number((item.id as String).split(":").pop()),
                content: item.label
            })
        }
    }

    public onTerminate(sess: RichtextAcSession, text: string): boolean {
        return false
    }

    protected formatClientName(name: FullName): string {
        return [name.title, name.family, name.given].filter(v => !!v).join(" ")
    }
}
