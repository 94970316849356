import { Inject, Injectable } from "@angular/core"

import { of, Subject } from "rxjs"
import { shareReplay, switchMap, take, tap } from "rxjs/operators"

import { LoadFields } from "@anzar/core"

import { AbstractCLEditorService } from "@pyzar/common.module"

import { LevelBackend, Maintainer } from "@backend/org.api"

const FIELDS: LoadFields<Maintainer> = ["id", "parent_id", "title", "variant", "logo"]

@Injectable()
export class MaintainerEditorService extends AbstractCLEditorService {
    public set id(val: number) {
        if (this._id !== val) {
            this._idChange.next((this._id = val))
        }
    }
    public get id(): number {
        return this._id
    }
    private _id: number

    private _idChange = new Subject<number>()
    public readonly id$ = this._idChange.pipe(shareReplay(1))

    public readonly maintainer$ = this.id$.pipe(
        switchMap(id => {
            if (id) {
                return this.levelbackend.get({ id }, { loadFields: FIELDS }).pipe(
                    take(1),
                    tap(val => ((this as { maintainer: Maintainer }).maintainer = val as any))
                )
            } else {
                return of(null)
            }
        }),
        shareReplay(1)
    )

    public readonly maintainer: Maintainer

    public constructor(@Inject(LevelBackend) private readonly levelbackend: LevelBackend) {
        super()
    }
}
