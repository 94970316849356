import { Component, Input } from "@angular/core"

import { LoadFields } from "@anzar/core"
import { Document } from "@backend/documents.api"


export const DOCUMENT_FIELDS: { [key: string]: LoadFields<Document> } = {
    ban: [
        "id", "type", "title",
        {
            client_ban: [
                "id", "begin", "end", "description", "policy_violation", "creator_id", "created_time",
                { level: ["id", "parent_id", "variant", "title"] }
            ]
        },
        {
            file: [
                "id", "title", "size", "mime_type"
            ]
        },
    ],
    contract: [
        "id", "type", "title",
        {
            contract: [
                "id", "begin", "end", "type", "description", "payed_amount", "creator_id", "created_time",
                { section: ["id", "title"] },
            ]
        },
        {
            file: [
                "id", "title", "size", "mime_type"
            ]
        },
    ],
    fill: [
        "id", "type", "title",
        {
            fill: [
                "id",
                { section: ["id", "title"] }
            ]
        },
        {
            file: [
                "id", "title", "size", "mime_type"
            ]
        },
    ],
    upload: [
        "id", "type", "title",
        {
            file: [
                "id", "title", "size", "mime_type"
            ]
        },
    ],
    utilization: [
        "id", "type", "title",
        {
            agreement: [
                "id", "begin", "end", "type", "leave_reason", "idTevadminIgenyles", "error",
                { providers: ["id", "extended_title", "parent_id", "nrszh_id", "kenyszi_type"] },
                { kenyszi_subtypev: ["label"] },
                { leave_reasonv: ["label"] }
            ]
        }
    ]
}

DOCUMENT_FIELDS["kenyszi"] = DOCUMENT_FIELDS["utilization"]


export const DOCUMENT_ORDER: { [key: string]: any } = {
    ban: {
        "client_ban.end": "desc"
    },
    contract: {
        created_time: "desc"
    },
    fill: {
        created_time: "desc"
    },
    upload: {
        created_time: "desc"
    },
    utilization: {
        "agreement.begin": "desc",
        "agreement.end": "desc"
    },
    kenyszi: {
        "agreement.begin": "desc",
        "agreement.end": "desc"
    }
}

@Component({
    selector: ".rege-client-document",
    templateUrl: "./client-document.component.pug"
})
export class ClientDocumentComponent {
    @Input() public document: Document
}
