import { PortalModule } from "@angular/cdk/portal"
import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"

import { VgBufferingModule } from "@videogular/ngx-videogular/buffering"
import { VgControlsModule } from "@videogular/ngx-videogular/controls"
import { VgCoreModule } from "@videogular/ngx-videogular/core"
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play"

import { NzModule } from "@anzar/core"

import { COMPONENTS, PAGES } from "../../_generated"
import { HelpMenuComponent } from "./help-menu.component"
import { HELP_PAGE_META, HelpPageMeta, HelpPageService } from "./help-page.service"
import { HelpComponent } from "./help.component"
import { VideoPlayerComponent } from "./video-player.component"

export { VideoPlayerComponent }

export { HelpPageService, HELP_PAGE_META, HelpPageMeta }

@NgModule({
    imports: [
        CommonModule,
        NzModule,
        PortalModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule
    ],
    declarations: [VideoPlayerComponent, HelpComponent, HelpMenuComponent, ...COMPONENTS],
    exports: [VideoPlayerComponent, HelpComponent, HelpMenuComponent],
    providers: PAGES,
    entryComponents: COMPONENTS
})
export class HelpPagesModule {}
