import { Component, Inject } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"


@Component({
    templateUrl: "./not-found-page.component.pug"
})
export class NotFoundPageComponent {
    public readonly path = this.route.snapshot.url.map(v => v.path).join("/")

    public constructor(
        @Inject(Router) private readonly router: Router,
        @Inject(ActivatedRoute) private readonly route: ActivatedRoute) {
    }

    public gotoRege() {
        this.router.navigate(["/hirfolyam"])
    }
}
