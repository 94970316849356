import { Component } from "@angular/core"

import { RichtextComponentParams } from "@anzar/core"
import { AbstractInlineComponent } from "../abstract"
import { HousingFormComponent } from "./housing-form.component"


const DISPLAY_IN_CONTENT = ["section_title"]


@Component({
    selector: "rege-rt-housing",
    templateUrl: "./housing.component.pug"
})
export class HousingComponent extends AbstractInlineComponent<HousingFormComponent> {
    public readonly formComponent = HousingFormComponent
    public readonly mustFill = true
    public readonly paramsText: string[] = []

    public onParamsUpdate(params: RichtextComponentParams) {
        (this as { paramsText: string[] }).paramsText = this.renderParams(params)
        super.onParamsUpdate(params)
    }

    public renderParams(params: RichtextComponentParams): string[] {
        let parts: any[] = []

        for (const name of DISPLAY_IN_CONTENT) {
            const v = params[name]
            if (v) {
                parts.push(v)
            }
        }

        return parts
    }

    protected isParamsComplete(params: RichtextComponentParams): boolean {
        return !!params.section_id
    }
}
