import { Directive, Inject, Self, OnDestroy, Input, OnChanges, SimpleChanges } from "@angular/core"

import { InputModel } from "@anzar/core"
import { FileUploaderService } from "./file-uploader.service"


@Directive({
    selector: "[fileUploaderFile]"
})
export class FileUploaderFileDirective implements OnDestroy, OnChanges {
    @Input("fileUploaderFile") public readonly meta: any

    public constructor(
        @Inject(FileUploaderService) private readonly service: FileUploaderService,
        @Inject(InputModel) @Self() private readonly input: InputModel<File>) {
        service.register(input)
    }

    public ngOnChanges(changes: SimpleChanges) {
        if ("meta" in changes) {
            this.service.register(this.input, this.meta)
        }
    }

    public ngOnDestroy() {
        this.service.unregister(this.input)
    }
}
