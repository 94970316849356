import { Component, Inject, ChangeDetectorRef, OnChanges, SimpleChanges } from "@angular/core"
import { take } from "rxjs/operators"
import { startOfDay } from "date-fns"

import { ToastService, FileDownloadService, LayerService, ModalLayer } from "@anzar/core"
import { DocumentBackend, Document, AgreementBackend, Agreement } from "@backend/documents.api"
import { LevelBackend, Provider } from "@backend/org.api"
import { DocumentItem } from "../abstract"
import { DocumentsService } from "../documents.service"
import { FsService } from "@pyzar/fs.module"
import { AuthService } from "@pyzar/auth.module"
import { LeaveProviderComponent } from "./leave-provider.component"
import { WorkerService } from "../../../worker.module"


@Component({
    selector: ".rege-doc-item-agreement",
    templateUrl: "./agreement-item.component.pug"
})
export class AgreementItemComponent extends DocumentItem implements OnChanges {
    public canRevoke: boolean
    public sectionTitle: string
    public isActive: boolean

    public constructor(
        @Inject(DocumentBackend) docBackend: DocumentBackend,
        @Inject(DocumentsService) docSvc: DocumentsService,
        @Inject(FsService) fsService: FsService,
        @Inject(ToastService) toast: ToastService,
        @Inject(FileDownloadService) downSvc: FileDownloadService,
        @Inject(AgreementBackend) protected readonly agreementBackend: AgreementBackend,
        @Inject(LevelBackend) protected readonly levelBackend: LevelBackend,
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(LayerService) protected readonly layerSvc: LayerService,
        @Inject(AuthService) public readonly auth: AuthService,
        @Inject(WorkerService) private readonly workerSvc: WorkerService) {
        super(docBackend, docSvc, fsService, toast, downSvc)
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ("document" in changes) {
            const newDoc = changes.document.currentValue as Document
            const newEnd = startOfDay(new Date())
            const agreement = newDoc.agreement
            this.canRevoke = !agreement.end || newEnd < startOfDay(agreement.end)
            this.isActive = !agreement.end || newEnd < startOfDay(agreement.end)
            this.sectionTitle = titleFromProviders(agreement.providers)
        }
    }

    public leaveSection() {
        this.docSvc.popup(LeaveProviderComponent, [
            { provide: Agreement, useValue: this.document.agreement }
        ])
    }

    public leaveInfo() {
        const agreement = this.document.agreement
        this.workerSvc.whoCanDoIt("Jogviszony lezárása", null, null, "client.agreement.close", agreement.provider_id)
    }

    public remove() {
        this.agreementBackend.remove({ agreement: this.document.agreement.id }).subscribe(_ => {
            this.docSvc.reload()
        })
    }
}


function titleFromProviders(providers: Provider[]): string {
    let bySection: { [key: string]: string[] } = {}

    for (const provider of providers) {
        const [sectionTitle, providerTitle] = provider.extended_title.split(/\s*\/\s*/)
        if (bySection[sectionTitle]) {
            if (bySection[sectionTitle].indexOf(providerTitle) === -1) {
                bySection[sectionTitle].push(providerTitle)
            }
        } else {
            bySection[sectionTitle] = [providerTitle]
        }
    }

    let res: string[] = []
    for (const sectionTitle in bySection) {
        if (bySection[sectionTitle].length === 1 && bySection[sectionTitle][0] === sectionTitle) {
            res.push(sectionTitle)
        } else {
            res.push(`${sectionTitle} (${bySection[sectionTitle].join(", ")})`)
        }
    }

    return res.join(", ")
}
