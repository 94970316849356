import { Inject, Injectable } from "@angular/core"

import { LayerService, ModalLayer } from "@anzar/core"
import { WhoCanDoItComponent, WCDI_CREATED_TIME, WCDI_CREATOR_ID, WCDI_LEVEL_ID, WCDI_PERMISSION, WCDI_MESSAGE } from "./who-can-do-it.component"


@Injectable()
export class WorkerService {
    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService) {
    }

    public whoCanDoIt(message: string, creatorId: number = null, createdTime: Date = null, permission: string = null, levelId: number = null) {
        const behavior = new ModalLayer({
            backdrop: { type: "filled", hideOnClick: true },
            rounded: 5,
            elevation: 20
        })

        const ref = this.layerSvc.createFromComponent(WhoCanDoItComponent, behavior, null, [
            { provide: WCDI_MESSAGE, useValue: message },
            { provide: WCDI_CREATOR_ID, useValue: creatorId },
            { provide: WCDI_CREATED_TIME, useValue: createdTime },
            { provide: WCDI_PERMISSION, useValue: permission },
            { provide: WCDI_LEVEL_ID, useValue: levelId },
        ])
        ref.show()
    }
}
