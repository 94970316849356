import { Component, Inject, ViewChild, AfterViewInit, ChangeDetectorRef, OnInit } from "@angular/core"
import { DomSanitizer, SafeStyle } from "@angular/platform-browser"
import { Router } from "@angular/router"
import { startWith, take } from "rxjs/operators"

import { DataSourceDirective, ColumnsComponent, Destructible, LoadFields, ExlistComponent, ListFilterService } from "@anzar/core"
import { Story, StoryBackend, StoryEntry } from "@backend/dispatcher.api"
import { CurrentSection } from "../section.service/level"
import { StoriesService } from "./stories.service"


const STORY_FIELDS: LoadFields<Story> = [
    "id", "entry_count", "status",
    {
        starter_entry: [
            "call_time",
            "call_type",
            "call_partner_type",
            "call_type_full",
            {
                messages: [{ richtext: ["text", "content"] as any }]
            },
            {
                place: ["city", "sublocality", "name"]
            }
        ]
    },
    {
        clients: ["id", "name"]
    }
]



@Component({
    selector: ".rege-disp-stories-grid",
    templateUrl: "./stories-grid.component.pug",
    providers: [
        ListFilterService
    ]
})
export class StoriesGridComponent extends Destructible implements AfterViewInit, OnInit {
    @ViewChild("columns", { static: true }) public readonly columns: ColumnsComponent
    @ViewChild("exlist", { static: true }) public readonly exlist: ExlistComponent

    public gridTemplate: SafeStyle

    public rowMenuStory: Story
    public listInited: boolean = false

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
        @Inject(DataSourceDirective) public readonly source: DataSourceDirective<any>,
        @Inject(StoryBackend) private readonly storyBackend: StoryBackend,
        @Inject(Router) private readonly router: Router,
        @Inject(StoriesService) private readonly storiesSvc: StoriesService,
        @Inject(CurrentSection) private readonly section: CurrentSection) {
        super()
    }

    public ngOnInit() {
        this.source.loadFields(STORY_FIELDS)
        this.source.sort = { "starter_entry.call_time": "desc" }
        this.source.filter = { section_id: null }

        this.destruct.subscription(this.section.id$).subscribe(sectionId => {
            this.source.filter = { section_id: sectionId }
            this.cdr.markForCheck()
        })
    }

    public ngAfterViewInit() {
        this.destruct.subscription(this.columns.layoutChanged).pipe(startWith(null)).subscribe(l => {
            this.gridTemplate = this.sanitizer.bypassSecurityTrustStyle(`48px / ${this.columns.gridColTemplate}`)
            this.cdr.detectChanges()
        })
    }

    public closeStory(story: Story) {
        this._setStoryStatus(story, "done")
    }

    public openStory(story: Story) {
        this._setStoryStatus(story, "progress")
    }

    public doCallback(entry: StoryEntry) {
        this.router.navigate(["/diszpecser/tortenet/visszahivas", entry.story_id, entry.id])
    }

    private _setStoryStatus(story: Story, status: "progress" | "done") {
        this.storyBackend
            .set_status({ id: story.id, status: status })
            .pipe(take(1))
            .subscribe(result => {
                story.status = result
                this.cdr.detectChanges()
            })
    }
}
