import { Component, forwardRef, Inject, InjectionToken, ViewContainerRef, Injector, ComponentFactoryResolver, Optional } from "@angular/core"
import { ComponentType, ComponentPortal } from "@angular/cdk/portal"
import { Observable, forkJoin, of } from "rxjs"
import { switchMap, shareReplay, map, filter } from "rxjs/operators"

import { AbstractCLEditor, AbstractCLEditorService } from "../../common.module/components/card-list/abstract"
import { UserEditorService } from "./user-editor.service"


export interface CustomUserBlock {
    readonly title: string
    readonly icon: string
    readonly visible: Observable<boolean>
    readonly component: ComponentType<any>
}


export abstract class UserBlockFactory {
    public abstract create(userId: number): CustomUserBlock
}


interface BoundCustomUserBlock {
    readonly title: string
    readonly icon: string
    readonly portal: ComponentPortal<any>
    readonly visible: Observable<boolean>
}


@Component({
    selector: ".pz-user-editor",
    templateUrl: "./user-editor.component.pug",
    providers: [
        { provide: AbstractCLEditor, useExisting: forwardRef(() => UserEdtorComponent) }
    ]
})
export class UserEdtorComponent extends AbstractCLEditor<UserEditorService> {
    public readonly customBlocks$ = this.editorSvc.id$.pipe(
        map(userId => {
            const factories = this.blockFactories
            if (factories && factories.length > 0) {
                return factories.map(block => {
                    const inst = block.create(userId)
                    return this.bindBlock(inst, this.vcr, this.injector, this.cfr)
                })
            } else {
                return []
            }
        }),
        shareReplay(1)
    )

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: UserEditorService,
        @Inject(ViewContainerRef) private readonly vcr: ViewContainerRef,
        @Inject(Injector) private readonly injector: Injector,
        @Inject(ComponentFactoryResolver) private readonly cfr: ComponentFactoryResolver,
        @Inject(UserBlockFactory) @Optional() private readonly blockFactories: UserBlockFactory[]) {
        super(editorSvc)
    }

    private bindBlock(block: CustomUserBlock, vcr: ViewContainerRef, injector: Injector, cfr: ComponentFactoryResolver): BoundCustomUserBlock {
        return {
            title: block.title,
            icon: block.icon,
            visible: block.visible.pipe(shareReplay(1)),
            portal: new ComponentPortal(block.component, vcr, injector, cfr),
        }
    }
}
