import { Component, Inject } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Router } from "@angular/router"

import { BehaviorSubject } from "rxjs"

import { FORM_FIELD_DEFAULT_VARIANT, ToastService } from "@anzar/core"

import { PartnerConnectRepo } from "@backend/__anzar_rpc_output"

import { FORM_FIELD_STYLE } from "./connect-form.component"

@Component({
    selector: "rege.connect-info",
    providers: [{ provide: FORM_FIELD_DEFAULT_VARIANT, useValue: FORM_FIELD_STYLE }],
    templateUrl: "./connect-info.component.pug"
})
export class ConnectInfoComponent {
    readonly email = new FormControl()

    readonly busy = new BehaviorSubject(false)

    constructor(
        @Inject(Router) private router: Router,
        @Inject(PartnerConnectRepo) private pc: PartnerConnectRepo,
        @Inject(ToastService) private readonly toast: ToastService
    ) {}

    doConnect() {
        this.email.updateValueAndValidity()
        this.email.markAllAsTouched()
        if (this.email.invalid) {
            return
        }

        this.busy.next(true)
        this.pc
            .begin({ email: this.email.value })
            .pipe(this.toast.catchError(() => this.busy.next(false)))
            .subscribe(connect => {
                this.router.navigate(["/info", connect.token])
            })
    }
}
