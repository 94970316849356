import { Component, forwardRef, Inject, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormArray, FormControl, Validators } from "@angular/forms"
import { of, Subject, forkJoin } from "rxjs"
import { take, shareReplay, switchMap, tap } from "rxjs/operators"
import { startOfWeek } from "date-fns"

import { LoadFields } from "@anzar/core"
import { Provider } from "@backend/org.api"
import { Resource, ResourceOpen, ResourceBackend } from "@backend/service_schedule.api"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { ProviderEditorService, ServiceItem } from "../provider/provider-editor.service"


const RESOURCE_FIELDS: LoadFields<Resource> = [
    "id", "title", "provider_service_id", "is_active",
    {
        opening_hours: [
            "id", "week_begin",
            { days: ["id", "begin", "end", "day", "interval"] }
        ]
    }
]


@Component({
    selector: ".rege-provider-scheduled-editor",
    templateUrl: "./scheduled-editor.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[style.padding]": "'16px 0'"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => ScheduledEditorComponent) }
    ]
})
export class ScheduledEditorComponent extends AbstractCLEditorBlock<ProviderEditorService> {
    @Input() public provider: Provider

    @Input()
    public set service(val: ServiceItem) {
        if (this._service !== val) {
            this._service = val
            this.providerServiceId = val && val.pservice ? val.pservice.id : null
        }
    }
    public get service(): ServiceItem { return this._service }
    private _service: ServiceItem

    public set providerServiceId(val: number) {
        if (this._providerServiceId !== val) {
            this._providerServiceId = val
            this._psiChanges.next(val)
        }
    }
    public get providerServiceId(): number { return this._providerServiceId }
    private _providerServiceId: number


    private _psiChanges = new Subject<number>()
    private providerServiceId$ = this._psiChanges.pipe(shareReplay(1))

    private resources$ = this.providerServiceId$.pipe(
        switchMap(providerServiceId => {
            if (providerServiceId) {
                return this.resourceBackend.search(
                    { filter: { provider_service_id: providerServiceId } },
                    { loadFields: RESOURCE_FIELDS })
            } else {
                return of([])
            }
        }),
        tap((resources: Resource[]) => {
            for (const r of resources) {
                r.opening_hours.sort((a, b) => {
                    if (a.week_begin && b.week_begin) {
                        return a.week_begin.getTime() - b.week_begin.getTime()
                    } else if (a.week_begin) {
                        return 1
                    } else if (b.week_begin) {
                        return -1
                    }
                    return 0
                })
            }
            this.resources = resources
        }),
        shareReplay(1)
    )

    public resources: Resource[] = []

    public readonly days = [
        { id: "monday", title: "HÉ" },
        { id: "tuesday", title: "KE" },
        { id: "wednesday", title: "SZE" },
        { id: "thursday", title: "CSÜ" },
        { id: "friday", title: "PÉ" },
        { id: "saturday", title: "SZO" },
        { id: "sunday", title: "VAS" }
    ]

    public get resourceControls() { return (this.form.get("resources") as FormArray).controls }

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: ProviderEditorService,
        @Inject(ResourceBackend) private readonly resourceBackend: ResourceBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super(editorSvc, new FormGroup({
            resources: new FormArray([])
        }))

        this.destruct.subscription(this.resources$).subscribe(resources => {
            const resCtrl = this.form.get("resources") as FormArray
            resCtrl.controls.length = 0

            for (const res of resources) {
                resCtrl.push(this._createResourceGroup(res))
            }

            this.cdr.detectChanges()
        })
    }

    public save() {
        const resources = this.form.value.resources as any[]
        const queries = resources.map(res => {
            res.provider_service_id = this.providerServiceId
            return this.resourceBackend.save({ data: res })
        })

        if (queries.length === 0) {
            return of(true)
        }

        return forkJoin(queries).pipe(
            tap(() => {
                this.reload()
            })
        )
    }

    public reload() {
        this._psiChanges.next(this._providerServiceId)
    }

    public addResource() {
        const resourcesCtrl = this.form.get("resources") as FormArray
        resourcesCtrl.push(this._createResourceGroup())
        resourcesCtrl.markAsPristine()

        this.addOpen(resourcesCtrl.length - 1)
    }

    public addOpen(resourceIndex: number) {
        const resourcesCtrl = this.form.get("resources") as FormArray
        const resource = resourcesCtrl.at(resourceIndex)
        const opensCtrl = resource.get("opens") as FormArray
        const weekBegin = opensCtrl.length === 0 ? null : startOfWeek(new Date(), { weekStartsOn: 1 })

        opensCtrl.push(this._createOpenGroup(null, weekBegin))
        opensCtrl.markAsPristine()
    }

    public delOpen(resourceIndex: number, openIndex: number) {
        const resourcesCtrl = this.form.get("resources") as FormArray
        const resource = resourcesCtrl.at(resourceIndex)
        const opensCtrl = resource.get("opens") as FormArray
        opensCtrl.removeAt(openIndex)
        opensCtrl.markAsPristine()
    }

    private _createResourceGroup(res?: Resource) {
        return new FormGroup({
            id: new FormControl(res ? res.id : null),
            is_active: new FormControl(res ? res.is_active : true),
            title: new FormControl(res ? res.title : null),
            opens: new FormArray(res ? res.opening_hours.map(this._createOpenGroup.bind(this)) : [])
        })
    }

    private _createOpenGroup(open?: ResourceOpen, weekBegin?: Date) {
        let ctrls = {
            id: new FormControl(open ? open.id : null),
            week_begin: new FormControl(open ? open.week_begin : weekBegin)
        } as any
        let days = open ? open.days : []

        for (const d of this.days) {
            const day = days.find(v => v.day === d.id)
            ctrls[d.id] = new FormGroup({
                id: new FormControl(day ? day.id : null),
                begin: new FormControl(day ? day.begin : null),
                end: new FormControl(day ? day.end : null),
                interval: new FormControl(day ? day.interval : null),
            })
        }

        return new FormGroup(ctrls)
    }
}
