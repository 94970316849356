import { Component, Inject, InjectionToken } from "@angular/core"

export const SERVICE_SUMMARY = new InjectionToken("SERVICE_SUMMARY")


export interface ServiceSummaryItem {
    title: string
    value: number
}


@Component({
    selector: "rege-service-summary-popup",
    templateUrl: "./service-summary.component.pug"
})
export class ServiceSummaryComponent {
    public constructor(
        @Inject(SERVICE_SUMMARY) public readonly summary: ServiceSummaryItem[]) {
    }
}
