import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzModule } from "@anzar/core"

import { PyzarApi } from "@backend/pyzar.api"

import { FsService, FILE_DOWNLOAD_ENDPOINT, FILE_UPLOAD_ENDPOINT, FILE_INLINE_ENDPOINT, FILE_IMAGE_ENDPOINT } from "./fs.service"
export { FsService, FILE_DOWNLOAD_ENDPOINT, FILE_UPLOAD_ENDPOINT, FILE_INLINE_ENDPOINT, FILE_IMAGE_ENDPOINT }

import { FileUploaderService } from "./file-uploader/file-uploader.service"
import { FileUploaderDirective } from "./file-uploader/file-uploader.directive"
import { FileUploaderFileDirective } from "./file-uploader/file-uploader-file.directive"
export { FileUploaderService, FileUploaderDirective, FileUploaderFileDirective }


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        PyzarApi
    ],
    declarations: [
        FileUploaderDirective,
        FileUploaderFileDirective
    ],
    exports: [
        FileUploaderDirective,
        FileUploaderFileDirective
    ],
    providers: [
        FsService
    ]
})
export class FsModule {

}
