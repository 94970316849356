import { NgModule } from "@angular/core"
import { NzModule } from "@anzar/core"

import { FeedbackApi } from "@backend/feedback.api"
import { ComponentsModule } from "../components"

import { FeedbackService } from "./feedback.service"
import { FeedbackComponent } from "./feedback.component"

export { FeedbackService }


@NgModule({
    imports: [
        NzModule,
        FeedbackApi,
        ComponentsModule
    ],
    declarations: [
        FeedbackComponent
    ],
    exports: [
        FeedbackComponent
    ],
    entryComponents: [
        FeedbackComponent
    ],
    providers: [
        FeedbackService
    ]
})
export class FeedbackModule {

}
