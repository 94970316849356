import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from "@angular/core"
import { Place } from "@backend/place.api"
import { MapComponent } from "./map.component"


export interface PlaceCoords {
    location: google.maps.LatLngLiteral
    // radius: number
}


@Component({
    selector: ".rege-place-editor",
    templateUrl: "./place-editor.component.pug"
})
export class PlaceEditorComponent implements OnChanges, AfterViewInit {
    @ViewChild("map", { static: true }) public readonly map: MapComponent

    @Input()
    public set editable(val: boolean) {
        if (this._editable !== val) {
            this._editable = val
        }
    }
    public get editable(): boolean { return this._editable }
    private _editable: boolean

    @Input() public place: Place
    @Input() public extraMarkers: Place[]

    @Output() public placeChange = new EventEmitter<PlaceCoords>()

    public ngAfterViewInit() {
        this.map.input.setValue(this.place)
    }

    public ngOnChanges(changes: SimpleChanges) {
        if ("place" in changes) {
            if (this.map) {
                this.map.input.setValue(changes.place.currentValue)
            }
        }
    }

    public onPlaceChange(place: Place) {
        this.placeChange.next(place)
    }

    public addOrEditPlace(event: google.maps.MapMouseEvent) {

    }
}
