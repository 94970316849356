import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Subscription } from "rxjs"

import { Destruct, ScrollableDirective } from "@anzar/core"
import { Duty } from "@backend/org.api"
import { ServiceUsage, DutyService } from "../duty.module"
import { ProviderServices, UsableService } from "./provider.service"
import { EditorService } from "./editor/editor.service"



@Component({
    selector: ".rege-service-editor",
    templateUrl: "./service-editor.template.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceEditorComponent implements OnDestroy, OnInit {
    public readonly destruct = new Destruct()

    @ViewChild("tabButtons", { static: false, read: ScrollableDirective }) public readonly tabButtons: ScrollableDirective

    public set selectedIndex(val: number) {
        if (this._selectedIndex !== val) {
            this._selectedIndex = val

            if (this.tabButtons) {
                const label = this.tabButtons.el.nativeElement.querySelector(`button:nth-of-type(${val + 1})`)
                label && this.tabButtons.scroller.service.scrollIntoViewport(label)
            }

            this.cdr.markForCheck()
        }
    }
    public get selectedIndex(): number { return this._selectedIndex }
    private _selectedIndex: number = 0

    @Input()
    public set usage(val: ServiceUsage) {
        if (this._usage !== val) {
            this._usage = val
            if (this._serviceS) {
                this._serviceS.unsubscribe()
            }

            if (val) {
                this._serviceS = this.editor.editableServicesOf(val).subscribe(editables => {
                    this.editables = editables
                    this._updateEditableServices()
                })
            }
        }
    }
    public get usage(): ServiceUsage { return this._usage }
    private _usage: ServiceUsage
    private _serviceS: Subscription

    public editables: UsableService[]
    public providerServices: UsableService[]
    public commonServices: UsableService[] = []
    public duty: Duty
    public inited: boolean = false
    public baseOffset: number = 0
    private _updateSelectedIndex = true

    public constructor(
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(ProviderServices) protected readonly provider: ProviderServices,
        @Inject(EditorService) protected readonly editor: EditorService,
        @Inject(DutyService) protected readonly dutySvc: DutyService) {

        this.destruct.subscription(provider.services).subscribe(services => {
            this.providerServices = services
            this._updateEditableServices()
        })
    }

    public ngOnInit() {
        this.destruct.subscription(this.dutySvc.selectedDuty$).subscribe(duty => {
            this.duty = duty
            this.cdr.detectChanges()
        })
    }

    protected _updateEditableServices() {
        const editables = this.editables
        const providerServices = this.providerServices

        if (!editables || !providerServices) {
            return
        }

        this.baseOffset = 1
        this.commonServices = providerServices.filter(s => !s.isPrimary && !s.services[0].service.without_client && s.services[0].is_active)
        if (this.commonServices.length) {
            this.baseOffset += 1
        }

        let pendingIndex = -1
        let mostRelevantIdx = -1
        let mostRelevantServices = ["street_care", "communla"]
        let offset = this.usage && this.usage.client ? this.baseOffset : 0

        for (const usage of this.usage.usage) {
            for (let i = 0, l = editables.length; i < l; i++) {
                const editable = editables[i]
                if (editable.services.find(x => x.id === usage.providerService.id)) {
                    if (usage.pending && usage.enabled) {
                        pendingIndex = i + offset
                    }

                    if (mostRelevantServices.indexOf(usage.providerService.service.type) !== -1) {
                        mostRelevantIdx = i + offset
                    }
                }
            }
        }

        this.inited = true
        this.cdr.detectChanges()

        if (pendingIndex !== -1) {
            this.selectedIndex = pendingIndex
        } else if (this._updateSelectedIndex) {
            this._updateSelectedIndex = false
            if (this.usage.alerts && this.usage.alerts.length > 0) {
                this.selectedIndex = 0
            } else if (mostRelevantIdx !== -1) {
                this.selectedIndex = mostRelevantIdx
            } else {
                this.selectedIndex = Math.min(offset, 1)
            }
        }

        this.cdr.detectChanges()
    }

    public ngOnDestroy() {
        this.destruct.run()
        this.usage = null
    }
}
