import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzModule } from "@anzar/core"

import { ClientApi } from "@backend/client.api"
import { MarkApi } from "@backend/mark.api"
import { OrgApi } from "@backend/org.api"
import { Service_mailingApi } from "@backend/service_mailing.api"
import { DispatcherApi } from "@backend/dispatcher.api"

import { DispatcherSharedModule } from "../dispatcher.module/shared"
import { ComponentsModule } from "../components"

import { MarkEntryRowComponent } from "./mark-entry-row.component"

import { MarkListComponent } from "./mark-list.component"
import {
    MarkEntryDailyComponent,
    MarkEntryDeadlineComponent,
    MarkEntryLungScreeningComponent,
    MarkEntryMailingComponent,
    MarkEntryLookforComponent,
    MarkEntryBanComponent,
    MarkEntryDeadComponent,
    MarkEntryContractComponent,
    MarkEntryRequiredFieldsComponent,
    MarkEntryToggleWdateComponent,
    MarkEntryScheduleComponent,
    MarkEntryCovidVaccinationComponent,
    MarkEntryCovidVaccinationNComponent,
    MarkEntryDateRangeComponent
} from "./mark-entry.component"

export { MarkService, ClientAlertLevel, ICON_COLOR_BY_ALERT_LEVEL, COLOR_BY_ALERT_LEVEL, Alert } from "./mark.service"

import { AlertIconComponent } from "./alert-icon.component"
export { AlertIconComponent }


export const MARK_REQUIRED_APIS = [
    ClientApi,
    MarkApi,
    OrgApi,
    Service_mailingApi,
    DispatcherApi
]


export const MARK_COMPONENTS: any[] = [
    MarkListComponent,
    MarkEntryDailyComponent,
    MarkEntryDeadlineComponent,
    MarkEntryLungScreeningComponent,
    MarkEntryMailingComponent,
    MarkEntryLookforComponent,
    MarkEntryBanComponent,
    MarkEntryDeadComponent,
    MarkEntryContractComponent,
    MarkEntryRequiredFieldsComponent,
    MarkEntryToggleWdateComponent,
    MarkEntryScheduleComponent,
    MarkEntryCovidVaccinationComponent,
    MarkEntryCovidVaccinationNComponent,
    MarkEntryDateRangeComponent,
    AlertIconComponent
]


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        ClientApi,
        MarkApi,
        OrgApi,
        Service_mailingApi,
        DispatcherApi,

        ComponentsModule,
        DispatcherSharedModule
    ],
    declarations: MARK_COMPONENTS.concat([MarkEntryRowComponent]),
    exports: MARK_COMPONENTS
})
export class MarkModule {

}
