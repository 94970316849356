import { Component } from "@angular/core"

import { RichtextComponentParams } from "@anzar/core"
import { AbstractInlineComponent } from "../abstract"
import { TransportFormComponent } from "./transport-form.component"


const DISPLAY_IN_CONTENT = ["section_title", "other"]


@Component({
    selector: "rege-rt-transport",
    templateUrl: "./transport.component.pug"
})
export class TransportComponent extends AbstractInlineComponent<TransportFormComponent> {
    public readonly formComponent = TransportFormComponent
    public readonly mustFill = true
    public readonly paramsText: string[] = []

    public onParamsUpdate(params: RichtextComponentParams) {
        (this as { paramsText: string[] }).paramsText = this.renderParams(params)
        super.onParamsUpdate(params)
    }

    public renderParams(params: RichtextComponentParams): string[] {
        let parts: any[] = []

        for (const name of DISPLAY_IN_CONTENT) {
            const v = params[name]
            if (v) {
                parts.push(v)
            }
        }

        return parts
    }

    protected isParamsComplete(params: RichtextComponentParams): boolean {
        console.log(params, !!(params.section_id || params.other))
        return !!(params.section_id || params.other)
    }
}
