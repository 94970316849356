import { Component, Inject, InjectionToken } from "@angular/core"

import { LayerRef, DialogEvent } from "@anzar/core"

export const CAN_COPY = new InjectionToken<boolean>("CAN_COPY")


@Component({
    selector: "rege-disp-switch-confirm",
    templateUrl: "./switch-confirm.component.pug"
})
export class SwitchConfirmComponent {
    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(CAN_COPY) public readonly canCopy: boolean) {

    }

    public onCancel() {
        this.decision("cancel")
    }

    public onDrop() {
        this.decision("drop")
    }

    public onCopy() {
        this.decision("copy")
    }

    public onSave() {
        this.decision("save")
    }

    private decision(button: string) {
        this.layerRef.emit(new DialogEvent("button", button))
        this.layerRef.hide()
    }
}
