import { Component, Inject, Output, EventEmitter } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { shareReplay, distinctUntilChanged } from "rxjs/operators"

import { WorkerMsgBackendSource } from "@backend/worker.api"
import { CurrentHeadquerter } from "../section.service/level"
import { AuthService } from "@pyzar/auth.module"
import { MessageService } from "./message.service"
import { MESAGE_FIELDS } from "./message.component"
import { FormControl } from "@angular/forms"


@Component({
    selector: ".rege-messages-narrow",
    templateUrl: "./messages-narrow.component.pug"
})
export class MessagesNarrowComponent {
    public readonly fields = MESAGE_FIELDS

    @Output()
    public readonly pinchange: EventEmitter<boolean> = new EventEmitter()

    public readonly searchTextCtrl = new FormControl()
    private readonly _searchText = new BehaviorSubject<string>(null)
    public readonly searchText$ = this._searchText.pipe(
        distinctUntilChanged(),
        shareReplay(1)
    )

    public readonly canCreateMessage$ = this.auth.hasPermission("messages.create")

    public constructor(
        @Inject(WorkerMsgBackendSource) public readonly wmSource: WorkerMsgBackendSource,
        @Inject(CurrentHeadquerter) public readonly hq: CurrentHeadquerter,
        @Inject(MessageService) public readonly messageSvc: MessageService,
        @Inject(AuthService) public readonly auth: AuthService) {
    }

    public doSearch() {
        this._searchText.next(this.searchTextCtrl.value)
    }

    public doResetSearch() {
        this.searchTextCtrl.reset()
        this._searchText.next(null)
    }
}
