import { Component, Inject } from "@angular/core"

import { LayerRef, ClosingGuarded, LayerEvent } from "@anzar/core"
import { Observable } from "rxjs"
import { ClosingGuardService } from "../common"


@Component({
    selector: "rege-help-window",
    templateUrl: "./help-window.component.pug",
    providers: [ClosingGuardService]
})
export class HelpWindowComponent implements ClosingGuarded {
    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ClosingGuardService) private closeable: ClosingGuardService) {

    }

    public close() {
        this.layerRef.close()
    }

    public canClose(layerRef: LayerRef<LayerEvent<any>>): Observable<boolean> {
        return this.closeable.confirm()
    }
}
