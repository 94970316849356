import { Injectable, Inject } from "@angular/core"

import { LayerService, ModalLayer, FullscreenLayer, Rect } from "@anzar/core"
import { MapComponent } from "./map.component"
import { Place } from "@backend/place.api"


@Injectable({ providedIn: "root" })
export class MapService {
    public get displayFullscreen() { return true }

    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService) {
    }

    public show(place?: Place) {
        const vpRect = Rect.viewport()
        const behavior = this.displayFullscreen
            ? new FullscreenLayer({})
            : new ModalLayer({
                backdrop: { type: "filled", hideOnClick: true },
                closeable: true,
                elevation: 10,
                rounded: 3,
                trapFocus: true,
                position: {
                    align: "center",
                    anchor: {
                        ref: "viewport",
                        align: "center"
                    }
                },
                minWidth: vpRect.width * 0.9,
                minHeight: vpRect.height * 0.9,
            })

        const ref = this.layerSvc.createFromComponent(MapComponent, behavior, null, [
            { provide: Place, useValue: place }
        ])
        ref.show()
        return ref
    }
}
