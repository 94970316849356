import { Inject, StaticProvider, InjectionToken, Injectable } from "@angular/core"
import { ComponentType } from "@angular/cdk/portal"
import { Subject, Observable } from "rxjs"

import { Destructible, ComponentLayerRef, LayerService, ModalLayer } from "@anzar/core"
import { Client } from "@backend/client.api"


export const DOCUMENT_FORM_DATA = new InjectionToken<any>("FORM_DATA")


@Injectable()
export class DocumentsService extends Destructible {
    public reload$: Observable<void> = this.destruct.subject(new Subject<void>())

    public client: Client

    public constructor(
        @Inject(LayerService) private readonly layer: LayerService) {
        super()
    }

    public reload() {
        (this.reload$ as Subject<void>).next()
    }

    public popup<T>(cmp: ComponentType<T>, provides: StaticProvider[] = []): ComponentLayerRef<T> {
        const behavior = new ModalLayer({
            backdrop: { type: "filled", hideOnClick: false },
            elevation: 10,
            rounded: 3
        })
        let ref = this.layer.createFromComponent(cmp, behavior, null, [
            { provide: Client, useValue: this.client },
            ...provides
        ])
        ref.show()
        const s = ref.subscribe(event => {
            if (event.type === "hiding") {
                this.reload()
                s.unsubscribe()
            }
        })
        return ref
    }
}
