import { Component, forwardRef, Inject } from "@angular/core"
import { Observable, of } from "rxjs"

import { ToastService } from "@anzar/core"
import { UserBackend } from "@backend/pyzar.api"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "../../common.module/components/card-list/abstract"
import { UserEditorService } from "./user-editor.service"


@Component({
    selector: ".pz-user-login-data",
    templateUrl: "./login-data.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => UserLoginDataComponent) }
    ]
})
export class UserLoginDataComponent extends AbstractCLEditorBlock<UserEditorService> {
    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: UserEditorService,
        @Inject(UserBackend) private readonly userBackend: UserBackend,
        @Inject(ToastService) private readonly toast: ToastService) {
        super(editorSvc, editorSvc.form)
    }

    public save(): Observable<any> {
        return of(null)
    }

    public resetPassword() {
        this.userBackend
            .reset_password({ id: this.editorSvc.id })
            .pipe(this.toast.handleSave({ align: "bottom center", successMsg: "Új jelszó e-mail sikeresen kiküldve" }))
            .subscribe()
    }
}
