import { HELP_PAGE_META } from "../src/core/help-page.service"
import type { HelpPageMeta } from "../src/core/help-page.service"
import { HelpPage_0 } from "./page-0.component"
import { HelpPage_1 } from "./page-1.component"
import { HelpPage_2 } from "./page-2.component"
import { HelpPage_3 } from "./page-3.component"
import { HelpPage_4 } from "./page-4.component"
import { HelpPage_5 } from "./page-5.component"
import { HelpPage_6 } from "./page-6.component"
import { HelpPage_7 } from "./page-7.component"
import { HelpPage_8 } from "./page-8.component"
export const PAGES: Array<{ provide: any, multi: true, useValue: HelpPageMeta }> = [
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Regisztráció", path: [], component: HelpPage_0, extra: {"id":"registration"} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Ügyfélkeresés", path: [], component: HelpPage_1, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Szolgáltatásnyújtás", path: [], component: HelpPage_2, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Ügyfelek Ablak", path: [], component: HelpPage_3, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Szállásfoglalás", path: [], component: HelpPage_4, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Kenyszi jelentés", path: [], component: HelpPage_5, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Ügyfelek oldal", path: [], component: HelpPage_6, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Utcai szolgálat", path: [], component: HelpPage_7, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Diszpécserszolgálat", path: [], component: HelpPage_8, extra: {} }}
]
export const COMPONENTS = [HelpPage_0, HelpPage_1, HelpPage_2, HelpPage_3, HelpPage_4, HelpPage_5, HelpPage_6, HelpPage_7, HelpPage_8]
