import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core"


import { ViewportService, Destruct } from "@anzar/core"






@Component({
    templateUrl: "./public-viewport.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ViewportService
    ]
})
export class PublicViewport {
}
