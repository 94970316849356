import { Inject, Injectable } from "@angular/core"
import { Subject } from "rxjs"
import { debounceTime, shareReplay, switchMap, startWith } from "rxjs/operators"

import { Provider, ProviderService } from "@backend/org.api"
import { Service } from "@backend/service.api"
import { AuthService } from "@pyzar/auth.module"
import { AbstractCLEditorService } from "@pyzar/common.module"
import { SectionSheetService } from "../section/section-sheet.service"


export class ServiceItem {
    public constructor(
        public readonly service: Service,
        public pservice: ProviderService) {

    }
}


export const SERVICES_WITH_EDITOR = ["room", "scheduled", "bno"]


@Injectable()
export class ProviderEditorService extends AbstractCLEditorService {
    public set providerId(val: number) {
        if (this._providerId !== val) {
            this._providerId = val
            this._changes.next()
        }
    }
    public get providerId(): number { return this._providerId }
    private _providerId: number

    public set isKenyszi(val: boolean) {
        if (this._isKenyszi !== val) {
            this._isKenyszi = val
            this._changes.next()
        }
    }
    public get isKenyszi(): boolean { return this._isKenyszi }
    private _isKenyszi: boolean = false

    public set isStreet(val: boolean) {
        if (this._isStreet !== val) {
            this._isStreet = val
            this._changes.next()
        }
    }
    public get isStreet(): boolean { return this._isStreet }
    private _isStreet: boolean = false

    public set isDispatcher(val: boolean) {
        if (this._isDispatcher !== val) {
            this._isDispatcher = val
            this._changes.next()
        }
    }
    public get isDispatcher(): boolean { return this._isDispatcher }
    private _isDispatcher: boolean = false

    public set isCommunal(val: boolean) {
        if (this._isCommunal !== val) {
            this._isCommunal = val
            this._changes.next()
        }
    }
    public get isCommunal(): boolean { return this._isCommunal }
    private _isCommunal: boolean

    public editableServices: ServiceItem[] = []

    private readonly _changes = new Subject<void>()
    public changes = this._changes.pipe(debounceTime(10), shareReplay(1))

    public readonly canEditBasicData$ = this._changes.pipe(
        startWith(null),
        switchMap(_ => {
            return this.authSvc.hasPermission({ permission: "org.settings", extra: { level_id: this._providerId } })
        }),
        shareReplay(1)
    )

    public constructor(
        @Inject(AuthService) public readonly authSvc: AuthService,
        @Inject(SectionSheetService) public readonly sheet: SectionSheetService) {
        super()
    }

    public providerUpdated(provider: Provider) {
        this.sheet.addProvider(provider)
    }

    public getEditableService(item: ServiceItem) {
        return this.editableServices.find(s => s.service.id === item.service.id)
    }

    public addEditableService(item: ServiceItem) {
        let idx = this.editableServices.findIndex(s => s.service.id === item.service.id)
        if (idx === -1) {
            this.editableServices.push(item)
            this._changes.next()
        }
    }

    public delEditableService(item: ServiceItem) {
        let idx = this.editableServices.findIndex(s => s.service.id === item.service.id)
        if (idx !== -1) {
            this.editableServices.splice(idx, 1)
            this._changes.next()
        }
    }
}
