import { Component, Inject } from "@angular/core"
import { Router } from "@angular/router"
import { LoadFields } from "@anzar/core"


import { User, UserBackendSource } from "@backend/pyzar.api"


const USER_FIELDS: LoadFields<User> = [
    "id", "is_active", "name", "username", "email", "phone"
]


@Component({
    selector: ".pz-users",
    templateUrl: "./users.component.pug"
})
export class UsersComponent {
    public readonly fields = USER_FIELDS

    public constructor(
        @Inject(UserBackendSource) public readonly source: UserBackendSource,
        @Inject(Router) private readonly router: Router) {

    }

    public onRowTap(user: User) {
        this.router.navigate(["users", user.id])
    }
}
