import { Injectable } from "@angular/core"
import { ComponentType } from "@angular/cdk/portal"

import { TaskDetailsFactory, DefaultTaskDetailsComponent } from "@pyzar/task.module"


@Injectable()
export class KenysziReportDetailsFactory extends TaskDetailsFactory {
    public readonly name = "kenyszi-report"
    public readonly title: string = "Kenyszi jelentés"
    public readonly component: ComponentType<any> = DefaultTaskDetailsComponent
}
