import { Component, Inject, OnInit } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { Router, ActivatedRoute } from "@angular/router"
import { NEVER, of } from "rxjs"
import { catchError, switchMap } from "rxjs/operators"

import { ViewportService, ToastService, Destructible } from "@anzar/core"
import { Auth } from "@backend/pyzar.api"
import { AuthService } from "../auth.service"


@Component({
    templateUrl: "./login-viewport.template.pug",
    providers: [
        ViewportService
    ]
})
export class LoginViewport extends Destructible implements OnInit {
    public readonly form = new FormGroup({
        username: new FormControl(),
        password: new FormControl()
    })

    public submitted: Boolean = false

    public constructor(
        @Inject(Auth) protected auth: Auth,
        @Inject(AuthService) protected authSvc: AuthService,
        @Inject(Router) protected readonly router: Router,
        @Inject(ActivatedRoute) protected readonly route: ActivatedRoute,
        @Inject(ToastService) protected readonly toastSvc: ToastService) {
        super()
    }

    public ngOnInit() {
        this.destruct.subscription(this.route.params)
            .pipe(
                switchMap(params => {
                    if (params["token"]) {
                        return this.auth.handle_token({ token: params["token"] })
                    } else {
                        return of(false)
                    }
                }),
                catchError(err => {
                    this.toastSvc.error(err.message)
                    return of(false)
                })
            )
            .subscribe(isLogined => {
                if (isLogined) {
                    this.authSvc.invalidate()
                    this.router.navigateByUrl("/")
                }
            })
    }

    public doLogin() {
        if (this.submitted) {
            return
        }

        this.submitted = true

        this.auth
            .login({
                username: this.form.get("username").value,
                password: this.form.get("password").value
            })
            .pipe(catchError(err => {
                this.toastSvc.error(err.message)
                this.submitted = false
                return NEVER
            }))
            .subscribe(res => {
                this.submitted = false
                if (!res) {
                    this.toastSvc.error("Hibás felhasználónév vagy jelszó")
                } else {
                    this.authSvc.invalidate()
                    this.authSvc.currentUser$.subscribe(() => {
                        this.router.navigate([this.route.snapshot.queryParamMap.get("r") || "/"], { replaceUrl: true })
                    })
                }
            })
    }
}
