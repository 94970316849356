import { NgModule } from "@angular/core"
import { StatSettings, StatSettingsBackend } from "./__anzar_rpc_output"

export { StatSettings, StatSettingsBackend }


@NgModule({
    providers: [
        StatSettingsBackend
    ],
})
export class StatsApi {}