import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { Route } from "@angular/router"

import { NzModule } from "@anzar/core"

import { AuthModule } from "@pyzar/auth.module"
import { FsModule } from "@pyzar/fs.module"

import { ClientApi } from "@backend/client.api"
import { DocumentsApi } from "@backend/documents.api"
import { EventApi } from "@backend/event.api"
import { OrgApi } from "@backend/org.api"
import { WorkerApi } from "@backend/worker.api"

import { MaltaCommonModule } from "../common"
import { ComponentsModule } from "../components"
import { ImageModule } from "../image.module"
import { MarkModule } from "../mark.module"
import { PlaceModule } from "../place.module"
import { RichtextModule } from "../richtext.module"
import { SectionServiceModule } from "../section.service"
import { WorkerModule } from "../worker.module"
import { ClientActivityComponent } from "./client-activity.component"
import { ClientBasicDataComponent } from "./client-basic-data.component"
import { ClientDocumentsComponent } from "./client-documents.component"
import { ClientFeedAddComponent } from "./client-feed/client-feed-add.component"
import { ClientFeedEntryComponent } from "./client-feed/client-feed-entry.component"
import { ClientFeedExportComponent } from "./client-feed/client-feed-export.component"
import { ClientFeedListComponent } from "./client-feed/client-feed-list.component"
import { ClientFeedComponent } from "./client-feed/client-feed.component"
import { ClientFeedService } from "./client-feed/client-feed.service"
import { ClientSheet } from "./client-sheet.component"
import { ClientService } from "./client.service"
import { ClientsGridComponent } from "./clients-grid.component"
import { ClientsScreen } from "./clients-screen.component"
import { ClientNameComponent } from "./common/client-name.component"
import { ClientSearchComponent } from "./common/client-search.component"
import { ClientSelectComponent } from "./common/client-select.component"
import { CreateClientComponent } from "./create-client.component"
// import { UsageItemComponent } from "./documents/usage/usage-item.component"
import { AgreementItemComponent } from "./documents/agreement/agreement-item.component"
import { LeaveProviderComponent } from "./documents/agreement/leave-provider.component"
import { ClientBanFormComponent } from "./documents/ban/ban-form.component"
import { ClientBanItemComponent } from "./documents/ban/ban-item.component"
import { ClientDocGroupComponent } from "./documents/client-doc-group.component"
import { ClientDocumentComponent } from "./documents/client-document.component"
import { CONTRACT_TYPE, ContractFormComponent } from "./documents/contract/contract-form.component"
import { ContractItemComponent } from "./documents/contract/contract-item.component"
import { DOCUMENT_FORM_DATA } from "./documents/documents.service"
import { FillFormComponent } from "./documents/fill/fill-form.component"
import { FillItemComponent } from "./documents/fill/fill-item.component"
import { UploadFormComponent } from "./documents/upload/upload-form.component"
import { UploadItemComponent } from "./documents/upload/upload-item.component"
import { DuplicatedScreen } from "./duplicated.screen"

export { ClientFeedService }

export { DOCUMENT_FORM_DATA }

export { ContractFormComponent, CONTRACT_TYPE }

export { ClientsScreen, ClientsGridComponent, ClientService, ClientSheet }

export const CLIENT_ROUTES: Route[] = [
    { path: "lista", component: ClientsScreen },
    { path: "esetek/:userId", component: ClientsScreen },
    { path: "hasonloak", component: DuplicatedScreen }
]

@NgModule({
    imports: [
        CommonModule,

        NzModule,
        ClientApi,
        EventApi,
        OrgApi,
        DocumentsApi,

        MaltaCommonModule,
        AuthModule,
        ComponentsModule,
        SectionServiceModule,
        MarkModule,
        WorkerModule,
        RichtextModule,
        FsModule,
        PlaceModule,
        ImageModule,
        WorkerApi
    ],
    declarations: [
        ClientsGridComponent,
        ClientsScreen,
        ClientSheet,
        CreateClientComponent,
        ClientBasicDataComponent,
        ClientFeedAddComponent,
        ClientFeedEntryComponent,
        ClientFeedListComponent,
        ClientFeedComponent,
        ClientFeedExportComponent,
        ClientDocumentsComponent,
        ClientDocumentComponent,
        ClientDocGroupComponent,
        ClientBanFormComponent,
        ClientBanItemComponent,
        ClientActivityComponent,
        UploadFormComponent,
        UploadItemComponent,
        ContractFormComponent,
        ContractItemComponent,
        // UsageItemComponent,
        AgreementItemComponent,
        LeaveProviderComponent,
        FillFormComponent,
        FillItemComponent,

        DuplicatedScreen,

        // common...
        ClientNameComponent,
        ClientSearchComponent,
        ClientSelectComponent
    ],
    exports: [
        ClientsGridComponent,
        ClientsScreen,
        ClientSheet,
        CreateClientComponent,
        ClientBasicDataComponent,
        ClientFeedAddComponent,
        ClientFeedEntryComponent,
        ClientFeedListComponent,
        ClientFeedComponent,
        ClientFeedExportComponent,
        ClientDocumentsComponent,
        ClientDocumentComponent,
        ClientDocGroupComponent,
        ClientActivityComponent,
        ContractFormComponent,
        ContractItemComponent,
        // UsageItemComponent,
        AgreementItemComponent,
        LeaveProviderComponent,
        FillFormComponent,
        FillItemComponent,

        DuplicatedScreen,

        // common...
        ClientNameComponent,
        ClientSearchComponent,
        ClientSelectComponent
    ],
    entryComponents: [
        ClientSheet,
        CreateClientComponent,
        ClientBanFormComponent,
        UploadFormComponent,
        ContractFormComponent,
        LeaveProviderComponent,
        FillFormComponent,
        ClientFeedExportComponent
    ],
    providers: [ClientService, ClientFeedService]
})
export class ClientModule {}
