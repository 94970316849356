import { Injectable, Inject } from "@angular/core"
import { Observable, Subject, merge, interval, of, forkJoin } from "rxjs"
import { debounceTime, switchMap, shareReplay, bufferTime, filter } from "rxjs/operators"

import { Destructible, LayerService, LayerRef, DropdownLayer } from "@anzar/core"
import { NotificationBackend, NotificationAssign } from "@backend/notification.api"
import { AuthService } from "@pyzar/auth.module"
import { NotificationPopupComponent } from "./notification-popup.component"


@Injectable({ providedIn: "root" })
export class NotificationService extends Destructible {
    private _reloadCount = new Subject<void>()

    public readonly count$: Observable<number> = merge(this.authSvc.currentUser$, interval(60000), this._reloadCount)
        .pipe(
            debounceTime(10),
            switchMap(_ => this.authSvc.currentUser$),
            switchMap(user => {
                if (user) {
                    return this.notificationBackend.get_unread_count({ user_id: user.id })
                } else {
                    return of(0)
                }
            }),
            shareReplay(1)
        )

    private _markAsSeen$ = new Subject<NotificationAssign>()

    private layerRef: LayerRef

    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(AuthService) private readonly authSvc: AuthService,
        @Inject(NotificationBackend) private readonly notificationBackend: NotificationBackend) {
        super()

        this.destruct.subscription(this._markAsSeen$)
            .pipe(
                bufferTime(100),
                filter(v => v.length !== 0),
                switchMap(assigns => {
                    // TODO: esetleg egy kérésben küldeni
                    return forkJoin(...assigns.map(assign => {
                        return this.notificationBackend.set_seen({
                            user_id: assign.user_id,
                            notification_id: assign.notification_id
                        })
                    }))
                })
            )
            .subscribe(() => {
                this.reloadCount()
            })
    }

    public toggle(target: HTMLElement) {
        if (!this.hide()) {
            this.show(target)
        }
    }

    public show(target: HTMLElement) {
        const behavior = new DropdownLayer({
            backdrop: { crop: target, hideOnClick: true, type: "empty" },
            elevation: 10,
            rounded: 3,
            minWidth: 200,
            position: {
                anchor: {
                    ref: target,
                    align: "bottom right"
                },
                align: "top right"
            }
        })
        this.layerRef = this.layerSvc.createFromComponent(NotificationPopupComponent, behavior)
        this.layerRef.show()
    }

    public hide() {
        if (this.layerRef && this.layerRef.isVisible) {
            this.layerRef.hide()
            delete this.layerRef
            return true
        } else {
            return false
        }
    }

    public reloadCount() {
        this._reloadCount.next()
    }

    public markAsSeen(assignment: NotificationAssign) {
        this._markAsSeen$.next(assignment)
    }
}
