import { Component, Inject, forwardRef } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { of, EMPTY } from "rxjs"
import { take, tap, switchMap, map } from "rxjs/operators"

import { LevelBackend, LevelBackendSource, Section, SectionBackend } from "@backend/org.api"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { SectionSheetService } from "./section-sheet.service"
import { LayerService, ModalLayer } from "@anzar/core"
import { SectionRemoveComponent, SECTION_ID } from "./section-remove.component"
import { AuthService } from "@pyzar/auth.module"


@Component({
    selector: ".rege-section-basic-data",
    templateUrl: "./section-basic-data.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => SectionBasicDataComponent) }
    ]
})
export class SectionBasicDataComponent extends AbstractCLEditorBlock {
    public section: Section

    public readonly canDelete$ = this.sheet.providers$.pipe(map(providers => providers.length === 0))

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: AbstractCLEditorService,
        @Inject(LevelBackend) protected readonly levelBackend: LevelBackend,
        @Inject(SectionBackend) protected readonly sectionBackend: SectionBackend,
        @Inject(LevelBackendSource) public readonly levelSrc: LevelBackendSource,
        @Inject(SectionSheetService) private readonly sheet: SectionSheetService,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(AuthService) public readonly auth: AuthService) {
        super(editorSvc, new FormGroup({
            parent_id: new FormControl(),
            title: new FormControl(),
            official_title: new FormControl(),
        }))
    }

    public ngOnInit() {
        super.ngOnInit()

        this.destruct.subscription(this.sheet.section$).subscribe(section => {
            this.section = section
            this.form.reset({
                parent_id: section ? section.parent_id : this.sheet.parentId,
                title: section ? section.title : null,
                official_title: section ? section.official_title : null,
            })
        })
    }

    public save() {
        let data = this.form.value

        if (this.section && this.section.id) {
            data.id = this.section.id
        }

        return this.sectionBackend.save({ data }).pipe(
            switchMap(section => {
                const reload = this.section && this.section.id === section.id
                this.section = section
                if (reload) {
                    return this.sheet.realodSection()
                } else {
                    this.sheet.sectionId = section.id
                    return of(section)
                }
            })
        )
    }

    public remove() {
        const behavior = new ModalLayer({
            backdrop: { type: "filled", hideOnClick: false },
            closeable: false,
            elevation: 10,
            rounded: 3,
            trapFocus: true
        })
        const ref = this.layerSvc.createFromComponent(SectionRemoveComponent, behavior, null, [
            { provide: SECTION_ID, useValue: this.section.id }
        ])
        ref.show()

        // ref.subscribe(event => {
        //     if (event.type === "removed") {
        //         this.editorSvc.sheet.delProvider(this.provider.id)
        //     } else if (event.type === "merged") {
        //         this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        //             this.router.navigate(["/org/reszleg/", event.data])
        //         })
        //     }
        // })
    }
}
