import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Route } from "@angular/router"
import { NzModule } from "@anzar/core"

import { SelectSectionScreen } from "./select-section.screent"

export const SELECT_SECTION_ROUTES: Route[] = [
    { path: "", component: SelectSectionScreen, pathMatch: "full" }
]


@NgModule({
    imports: [
        CommonModule,
        NzModule
    ],
    declarations: [
        SelectSectionScreen
    ],
    exports: [
        SelectSectionScreen
    ]
})
export class SelectSectionScreenModule {

}
