import { Component, Inject, Input, OnInit, ChangeDetectionStrategy } from "@angular/core"
import { ComponentType } from "@angular/cdk/portal"
import { forkJoin } from "rxjs"
import { map, switchMap, startWith, shareReplay } from "rxjs/operators"


import { LoadFields, LayerService, ModalLayer, DataSourceDirective, Destructible, StaticSource, SingleSelection } from "@anzar/core"
import { DocumentGroup, DocumentGroupBackendSource, DocumentGroupBackend } from "@backend/documents.api"
import { DocumentType } from "@backend/enums.api"
import { Client } from "@backend/client.api"

import { DOCUMENT_FIELDS, DOCUMENT_ORDER } from "./documents/client-document.component"
import { ClientBanFormComponent } from "./documents/ban/ban-form.component"
import { UploadFormComponent } from "./documents/upload/upload-form.component"
import { DocumentsService } from "./documents/documents.service"
import { ContractFormComponent, ContractType, CONTRACT_TYPE } from "./documents/contract/contract-form.component"
import { FillFormComponent } from "./documents/fill/fill-form.component"


const DOCUMENT_GROUP_ORDER: { [key: string]: "asc" | "desc" } = {
    "primary_document.typev.priority": "desc"
}

// TODO: sorrend megtartása + ne inner joinnal csinálja
// for (const k in DOCUMENT_ORDER) {
//     const orders = DOCUMENT_ORDER[k]
//     for (const field in orders) {
//         dOCUMENT_GROUP_ORDER[`primary_document.${field}`] = orders[field]
//     }
// }

const DOCUMENT_GROUPS = DocumentType.DATA.data
    .map(v => v)
    .sort((a, b) => b.priority - a.priority)

@Component({
    selector: ".rege-client-documents",
    templateUrl: "./client-documents.component.pug",
    providers: [DataSourceDirective, DocumentsService]
})
export class ClientDocumentsComponent extends Destructible implements OnInit {
    @Input() public client: Client
    public readonly order = DOCUMENT_GROUP_ORDER

    public readonly groups$ = this.docSvc.reload$.pipe(
        startWith(null),
        switchMap(_ => {
            return forkJoin(
                DOCUMENT_GROUPS.map(v => {
                    let order: { [key: string]: any } = {}
                    let loadFields: LoadFields<DocumentGroup> = [
                        "id", "primary_document_id",
                        { primary_document: DOCUMENT_FIELDS[v.id] }
                    ]

                    for (const field in DOCUMENT_ORDER[v.id]) {
                        order[`primary_document.${field}`] = DOCUMENT_ORDER[v.id][field]
                    }

                    return this.documentGroupBackend.search({
                        filter: { "primary_document.type": v.id, client_id: this.client.id },
                        order: order
                    }, { loadFields })
                }))
        }),
        map((groups: DocumentGroup[][]) => {
            return groups
                .filter(v => v.length > 0)
                .map(items => {
                    return {
                        type: DocumentType.DATA.getSync(items[0].primary_document.type),
                        src: new StaticSource(DocumentGroup as any, items as any)
                    }
                })
        }),
        shareReplay(1)
    )

    public opened: { [key: string]: boolean } = {}

    public readonly selection = new SingleSelection()

    public constructor(
        @Inject(DocumentGroupBackend) private readonly documentGroupBackend: DocumentGroupBackend,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(DocumentsService) private readonly docSvc: DocumentsService) {
        super()
    }

    public ngOnInit() {
        this.docSvc.client = this.client
    }

    public newBan() {
        this.docSvc.popup(ClientBanFormComponent)
    }

    public newUpload() {
        this.docSvc.popup(UploadFormComponent)
    }

    public newFill() {
        this.docSvc.popup(FillFormComponent)
    }

    public newContract(type: ContractType) {
        this.docSvc.popup(ContractFormComponent, [
            { provide: CONTRACT_TYPE, useValue: type }
        ])
    }
}
