import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Optional, Input, Output } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Subject } from "rxjs"
import { take } from "rxjs/operators"

import { Destructible, LayerRef, LayerEvent, ToastService } from "@anzar/core"
import { Place, PlaceBackend } from "@backend/place.api"
import { CurrentLocation } from "./current-location.service"

type LatLngLiteral = google.maps.LatLngLiteral
type MapMouseEvent = google.maps.MapMouseEvent

const DEFAULT_CENTER: LatLngLiteral = { lat: 47.518844, lng: 19.0437999 }


@Component({
    selector: ".rege-map",
    templateUrl: "./map.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent extends Destructible implements OnInit {
    public readonly input = new FormControl()
    public readonly selected: LatLngLiteral
    public readonly center: LatLngLiteral = DEFAULT_CENTER

    @Input() public displayButtons: boolean = true

    @Output() public readonly locationChange = this.destruct.subject(new Subject<LatLngLiteral>())
    @Output() public readonly placeChange = this.destruct.subject(new Subject<Place>())

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(CurrentLocation) private readonly currentLoc: CurrentLocation,
        @Inject(PlaceBackend) private readonly placeBackend: PlaceBackend,
        @Inject(LayerRef) @Optional() private readonly layerRef: LayerRef,
        @Inject(Place) @Optional() private readonly initialPlace: Place,
        @Inject(ToastService) private readonly toastSvc: ToastService) {
        super()
    }

    public ngOnInit() {
        this.destruct.subscription(this.input.valueChanges).subscribe((value: Place) => {
            this.placeChange.next(value)
            if (value && value.location) {
                (this.center as LatLngLiteral) = value.location
                this._setSelected(value.location)
            } else {
                (this.center as LatLngLiteral) = DEFAULT_CENTER
                this._setSelected(null)
            }
        })

        if (this.initialPlace) {
            this.input.setValue(this.initialPlace)
        } else {
            this.destruct.subscription(this.currentLoc.coords$)
                .pipe(take(1))
                .subscribe(loc => {
                    (this.center as LatLngLiteral) = {
                        lat: loc.latitude,
                        lng: loc.longitude
                    }
                    this.cdr.detectChanges()
                })
        }
    }

    public onMapClick(event: MapMouseEvent) {
        this.placeBackend
            .decode_coords({
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            })
            .subscribe(res => {
                if (res) {
                    this.input.setValue(res)
                } else {
                    this.toastSvc.error("A megadott koordinát nem lehet feloldani")
                }
            })
    }

    public cancel() {
        if (this.layerRef) {
            this.layerRef.hide()
        }
    }

    public select() {
        if (this.layerRef) {
            this.layerRef.emit(new LayerEvent("select", this.input.value))
            this.layerRef.hide()
        }
    }

    private _setSelected(loc: LatLngLiteral) {
        (this.selected as LatLngLiteral) = loc
        this.locationChange.next(loc)
        this.cdr.detectChanges()
    }
}
