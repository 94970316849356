import { Component, Inject, forwardRef, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { tap } from "rxjs/operators"

import { LevelBackend, Global } from "@backend/org.api"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { GlobalEditorService } from "./global-editor.service"


@Component({
    selector: ".rege-global-basic-data",
    templateUrl: "./global-basic-data.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => GlobalBasicDataComponent) }
    ]
})
export class GlobalBasicDataComponent extends AbstractCLEditorBlock<GlobalEditorService> {
    public readonly global: Global

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: GlobalEditorService,
        @Inject(LevelBackend) protected readonly globalBackend: LevelBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super(editorSvc, new FormGroup({
            title: new FormControl()
        }))
    }

    public ngOnInit() {
        super.ngOnInit()
        this.destruct.subscription(this.editorSvc.global$).subscribe(global => {
            (this as { global: Global }).global = global
            this.form.reset({
                title: global ? global.title : null
            })
            this.cdr.detectChanges()
        })
    }

    public save() {
        let data = this.form.value

        if (this.global) {
            data.id = this.global.id
        }

        return this.globalBackend.save({ data }).pipe(tap(level => {
            this.editorSvc.id = level.id
        }))
    }
}
