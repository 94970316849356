import { Component, Inject, InjectionToken } from "@angular/core"
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { format } from "date-fns"

import { FileDownloadService, Destructible, ToastService, LayerRef } from "@anzar/core"

declare const BACKEND_URL: string

export const PROVIDER_IDS = new InjectionToken("PROVIDER_IDS")
export const REPORT_DATE = new InjectionToken("REPORT_DATE")


@Component({
    selector: "kenyszi-stat-popup",
    templateUrl: "./stat-popup.component.pug"
})
export class KenysziStatPopupComponent extends Destructible {
    public readonly form = new FormGroup({
        date_from: new FormControl(this.reportDate, [Validators.required]),
        date_to: new FormControl(this.reportDate, [Validators.required]),
    })

    public constructor(
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(PROVIDER_IDS) private readonly providerIds: number[],
        @Inject(REPORT_DATE) private readonly reportDate: Date,
        @Inject(LayerRef) private readonly layerRef: LayerRef) {
        super()
    }

    public download() {
        const beginf = format(this.form.value.date_from, "yyyy-MM-dd")
        const endf = format(this.form.value.date_to, "yyyy-MM-dd")
        const url = `${BACKEND_URL}/stats/kenyszi/${this.providerIds.join(",")}/${beginf}/${endf}`
        this.destruct.subscription(this.downloader.download(url))
            .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Statisztika generálása" }))
            .subscribe()
    }

    public close() {
        this.layerRef.close()
    }
}
