import { Component, Inject, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { race } from "rxjs"
import { mapTo, filter, map, shareReplay } from "rxjs/operators"
import { isSameDay, format } from "date-fns"


import { LayerService, ModalLayer, LoadFields, DataStorage, MediaQueryService } from "@anzar/core"
import { DutyBackendSource, Duty } from "@backend/org.api"
import { CurrentSection } from "../section.service/level"
import { CreateDutyComponent, DUTY_VALUE } from "./create-duty.component"


export const DUTY_FIELDS: LoadFields<Duty> = [
    "id", "begin", "end", "provider_id", "provider_duty_id",
    { workers: ["user_id", "section_id", { user: ["name"] }, { section: ["title"] }] },
    { vehicles: ["id", "name"] },
    { places: ["id", "description"] },
]


@Component({
    selector: ".rege-duty-list",
    templateUrl: "./duty-list.component.pug"
})
export class DutyListComponent {
    @ViewChild("dutySrc", { static: true }) public readonly storage: DataStorage<Duty>
    public readonly fields = DUTY_FIELDS

    public readonly isSm$ = this.mq.watch("lt-md").pipe(map(v => v.matches), shareReplay(1))

    public constructor(
        @Inject(DutyBackendSource) public readonly source: DutyBackendSource,
        @Inject(CurrentSection) public readonly section: CurrentSection,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(Router) private readonly router: Router,
        @Inject(MediaQueryService) private readonly mq: MediaQueryService) { }

    public time(duty: Duty): string {
        if (isSameDay(duty.begin, duty.end)) {
            return `${format(duty.begin, 'yyyy. MM. dd. HH:mm')} - ${format(duty.end, 'HH:mm')}`
        } else {
            return `${format(duty.begin, 'yyyy. MM. dd. HH:mm')} - ${format(duty.end, 'yyyy. MM. dd. HH:mm')}`
        }
    }

    public vehicles(duty: Duty) {
        return duty.vehicles.map(v => v.name).join(", ")
    }

    public workers(duty: Duty) {
        return duty.workers.map(v => {
            if (!v.section_id || this.section.id === v.section_id) {
                return v.user.name.formatted
            } else {
                return `${v.user.name.formatted} (${v.section.title})`
            }
        }).join(", ")
    }

    public createDuty() {
        this.openDutyEditor(null)
    }

    public editDuty(duty: Duty) {
        this.openDutyEditor(duty)
    }

    public enterDuty(duty: Duty) {
        this.router.navigate(["szolgaltatas", duty.provider_id, "lista", duty.id])
    }

    public openDutyEditor(duty: Duty) {
        let behavior = new ModalLayer({
            elevation: 10,
            rounded: 3,
            position: {
                align: "top center",
                constraint: {
                    ref: "viewport",
                    inset: 24
                }
            }
        })
        let wnd = this.layerSvc.createFromComponent(CreateDutyComponent, behavior, null, [
            { provide: CurrentSection, useValue: this.section },
            { provide: DUTY_VALUE, useValue: duty },
        ])
        wnd.show()
        race(
            wnd.destruct.on.pipe(mapTo(null)),
            wnd.output.pipe(
                filter(event => event.type === "duty-created"),
                map(event => event.data as Duty)
            )
        ).subscribe(duty => {
            if (duty) {
                this.storage.reload()
            }
        })
    }
}
