import { ChangeDetectorRef, Component, forwardRef, Inject, Input } from "@angular/core"

import { AuthService } from "@pyzar/auth.module"
import { AbstractCLEditor, AbstractCLEditorService } from "@pyzar/common.module"

import { Maintainer } from "@backend/org.api"

import { MaintainerEditorService } from "./maintainer-editor.service"

@Component({
    selector: ".rege-maintainer-editor",
    templateUrl: "./maintainer-editor.component.pug",
    providers: [{ provide: AbstractCLEditor, useExisting: forwardRef(() => MaintainerEditorComponent) }]
})
export class MaintainerEditorComponent extends AbstractCLEditor {
    @Input() public maintainer: Maintainer

    public constructor(
        @Inject(AbstractCLEditorService) service: MaintainerEditorService,
        @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
        @Inject(AuthService) public readonly auth: AuthService
    ) {
        super(service)

        this.destruct.subscription(service.maintainer$).subscribe(maintainer => {
            this.maintainer = maintainer as any
            this.cdr.detectChanges()
        })
    }
}
