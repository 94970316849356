import { Component, Inject, OnInit, ChangeDetectorRef } from "@angular/core"
import { FormControl } from "@angular/forms"
import { merge, zip, of, NEVER } from "rxjs"
import { debounceTime, map, switchMap, mapTo, tap, shareReplay } from "rxjs/operators"


import { DataSourceDirective, Destructible, StaticSource, NzTouchEvent } from "@anzar/core"
import { EventBackendSource, EventBackend } from "@backend/event.api"
import { Section } from "@backend/org.api"
import { Client } from "@backend/client.api"
import { AuthService } from "@pyzar/auth.module"
import { ClientService, ClientFeedService } from "../client.module"
import { DISPLAY_CLIENT, DISPLAY_SERVICES, DISPLAY_TEXT } from "../client.module/client-feed/client-feed-entry.component"
import { CurrentSection } from "../section.service/level"
import { HashtagClickHandler, HashtagComponent } from "../richtext.module"


class FilterByHashtag extends HashtagClickHandler {
    public screen: ClientFeddScreen

    public handle(hashtag: HashtagComponent, event: NzTouchEvent): void {
        this.screen.search.setValue(`#${hashtag.content}`)
    }
}


@Component({
    templateUrl: "./client-feed.screen.pug",
    providers: [
        DataSourceDirective,
        { provide: HashtagClickHandler, useClass: FilterByHashtag }
    ]
})
export class ClientFeddScreen extends Destructible implements OnInit {
    public readonly scope = new FormControl()
    public readonly author = new FormControl()
    public readonly type = new FormControl()
    public readonly search = new FormControl()
    public readonly section = new FormControl()

    public clientContractDate = new Date()
    public clientWorkerId: number
    public displayMode: number = 0

    public readonly sectionSrc = new StaticSource(Section as any, [])

    private readonly search$ = this.search.valueChanges.pipe(debounceTime(500))

    private readonly basicFilter$ = merge(this.scope.valueChanges, this.author.valueChanges, this.type.valueChanges, this.search$)
        .pipe(
            map(_ => {
                let types: string[] = this.type.value || []

                const filter = {
                    scope: this.scope.value,
                    author: this.author.value,
                    types: types,
                    client_id: { "neq": null }
                } as any

                if (this.search.value) {
                    filter["richtext.plain"] = { "contains": this.search.value }
                }

                this.displayMode = DISPLAY_CLIENT
                if (types.indexOf("care") > -1 || types.indexOf("data_change") > -1) {
                    this.displayMode |= DISPLAY_TEXT
                }
                if (types.indexOf("service") > -1) {
                    this.displayMode |= DISPLAY_SERVICES
                }

                return filter
            }),
            switchMap(filter => {
                return this.eventBackend.get_sections({ filter }).pipe(
                    tap(sections => {
                        let existing = sections.find(s => s.id === this.section.value)
                        if (!existing) {
                            this.section.setValue(null)
                        }
                        this.sectionSrc.replace(sections.sort((a, b) => a.title.localeCompare(b.title)) as any)
                    }),
                    mapTo(filter)
                )
            }),
            shareReplay(1)
        )

    private readonly sectionFilter$ = this.section.valueChanges.pipe(shareReplay(1))

    private readonly fullFilter$ = this.destruct
        .subscription(merge(this.basicFilter$, this.sectionFilter$))
        .pipe(
            debounceTime(20),
            switchMap(_ => zip(this.basicFilter$, this.sectionFilter$)),
            map(([basic, section]) => {
                if (section) {
                    basic.section_id = section
                } else {
                    delete basic.section_id
                }
                return basic
            })
        )

    public inited = false

    public constructor(
        @Inject(DataSourceDirective) public readonly source: DataSourceDirective<any>,
        @Inject(EventBackendSource) eventSrc: EventBackendSource,
        @Inject(EventBackend) private readonly eventBackend: EventBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(AuthService) private readonly authSvc: AuthService,
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(ClientFeedService) private readonly clientFeedSvc: ClientFeedService,
        @Inject(CurrentSection) private readonly currentSection: CurrentSection,
        @Inject(HashtagClickHandler) htClickHandler: FilterByHashtag) {
        super()
        htClickHandler.screen = this
        source.dataSource = eventSrc as any
        source.sort = { subject_time: "desc" }

        this.destruct.subscription(this.fullFilter$).subscribe(filter => {
            source.filter = filter
            this.inited = true
        })

        this.destruct.subscription(this.authSvc.hasPermission("client.searchall")).subscribe(searchall => {
            this.clientWorkerId = searchall ? null : this.authSvc.userId
        })

        // this.destruct.subscription(this.currentSection.primaryMenu)
        //     .pipe(
        //         switchMap(pm => {
        //             // a diszpécser láthat mindenkit
        //             if (pm && pm[0] && pm[0].type === "dispatcher") {
        //                 this.clientWorkerId = null
        //                 return NEVER
        //             } else {
        //                 return this.authSvc.currentUser$
        //             }
        //         })
        //     ).
        //     subscribe(user => {
        //         if (user) {
        //             this.clientWorkerId = user.is_admin ? null : user.id
        //         } else {
        //             this.clientWorkerId = -1
        //         }
        //         this.cdr.markForCheck()
        //     })

        this.destruct.subscription(source.storage.empty).subscribe(() => {
            this.cdr.detectChanges()
        })
    }

    public ngOnInit() {
        this.scope.setValue("own_clients")
        this.author.setValue("any")
        this.type.setValue(["care"])
    }

    public openClientSheet(client: Client) {
        this.clientSvc.showSheet(client.id).subscribe()
    }

    public export() {
        this.clientFeedSvc.export(this.type.value, this.section.value, this.author.value)
    }
}
