import { Directive, OnDestroy } from "@angular/core"

import { Destruct } from "@anzar/core"


@Directive()
export abstract class AbstractEditor implements OnDestroy {
    public destruct = new Destruct()

    public ngOnDestroy() {
        this.destruct.run()
    }
}
