import { Component, Inject, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, Input } from "@angular/core"
import { SafeStyle, DomSanitizer } from "@angular/platform-browser"
import { Observable } from "rxjs"
import { startWith, shareReplay, map } from "rxjs/operators"

import { Destruct, DataSourceDirective, StaticSource, ListFilterService, ColumnsComponent } from "@anzar/core"
import { Client } from "@backend/client.api"
import { Provider } from "@backend/org.api"
import { KenysziReportEntry, KenysziReportEntryBackend, EntryStatus } from "@backend/kenyszi.api"
import { ClientService } from "../client.module"
import { MarkService } from "../mark.module"


const ENTRY_STATUS_LABELS = EntryStatus.DATA.data.reduce((dict: { [key: string]: string }, el) => { dict[el.id] = el.label; return dict }, {})


@Component({
    selector: ".rege-kenyszi-grid",
    templateUrl: "./grid.component.pug",
    providers: [ListFilterService, MarkService]
})
export class KenysziGridComponent implements AfterViewInit, OnDestroy {
    public readonly destruct = new Destruct()

    @ViewChild("columns", { static: true }) public readonly columns: ColumnsComponent
    @Input() public readonly providerSrc: StaticSource<Provider>
    @Input() public displayProvider: boolean

    public rowsTemplate: SafeStyle
    public gridTemplate: SafeStyle
    public readonly statusLabels = ENTRY_STATUS_LABELS
    private markCache: { [key: string]: Observable<boolean> } = {}

    public constructor(
        @Inject(DataSourceDirective) public readonly source: DataSourceDirective,
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(KenysziReportEntryBackend) private readonly entryBackend: KenysziReportEntryBackend,
        @Inject(MarkService) private readonly markSvc: MarkService) {
    }

    public ngAfterViewInit() {
        this.destruct.subscription(this.columns.layoutChanged).pipe(startWith(null)).subscribe(l => {
            this.gridTemplate = this.sanitizer.bypassSecurityTrustStyle(`52px / ${this.columns.gridColTemplate}`)
            this.cdr.markForCheck()
        })

        this.destruct.subscription(this.source.storage.items).subscribe(c => {
            this.rowsTemplate = this.sanitizer.bypassSecurityTrustStyle(`repeat(${this.source.storage.lastIndex}, 52px) / 1fr`)
            this.cdr.markForCheck()
        })
    }

    public showClientSheet(event: Event, client: Client) {
        event.preventDefault()
        this.clientSvc.showSheet(client.id).subscribe()
    }

    public showClientSheetData(client: Client) {
        this.clientSvc.showSheet(client.id, ClientService.TAB_BASIC_DATA).subscribe()
    }

    public toggleAction(item: KenysziReportEntry) {
        let action = item.action === "add" ? "remove" : "add"
        this.entryBackend.save({ data: { id: item.id, action: action } }).subscribe(entry => {
            item.action = entry.action
            item.status = entry.status
            item.error = entry.error
            this.cdr.markForCheck()
        })
    }

    public updateProvider(item: KenysziReportEntry, provider_id: number) {
        if (item.provider_id !== provider_id) {
            item.provider_id = provider_id
            item.status = "pending"
            this.entryBackend.save({ data: { id: item.id, provider_id: item.provider_id, status: "pending" } }).subscribe()
        }
    }

    public hasSheetError(clientId: number) {
        if (this.markCache[clientId]) {
            return this.markCache[clientId]
        } else {
            return this.markCache[clientId] = this.destruct.subscription(this.markSvc.getClientAlertLevel(clientId, ["required_fields"])).pipe(
                map(alerts => {
                    if (alerts && alerts.length) {
                        for (const alert of alerts) {
                            if (alert.icon === "mdi/clipboard-alert-outline") {
                                return true
                            }
                        }
                    }
                    return false
                }),
                shareReplay(1)
            )
        }
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}
