import { NgModule } from "@angular/core"
import { SocialMentalBackend } from "./__anzar_rpc_output"

export { SocialMentalBackend }


@NgModule({
    providers: [
        SocialMentalBackend
    ],
})
export class Service_social_mentalApi {}