import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from "@angular/core"

import { Destructible } from "@anzar/core"

import { HelpPageService, MenuItem } from "./help-page.service"

@Component({
    selector: ".rege-help-menu",
    templateUrl: "./help-menu.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpMenuComponent extends Destructible {
    @Input() public entry: MenuItem
    @Input() public level: number = 0

    public constructor(
        @Inject(HelpPageService) private readonly helpPage: HelpPageService,
        @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef
    ) {
        super()

        this.destruct.subscription(helpPage.activeMenu$).subscribe(cdr.markForCheck.bind(cdr))
    }

    public setActive(item: MenuItem) {
        this.helpPage.setActiveMenu(item)
    }
}
