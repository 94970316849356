import { Component, Inject, ChangeDetectorRef, OnChanges, SimpleChanges } from "@angular/core"
import { of } from "rxjs"
import { take, switchMap } from "rxjs/operators"
import { startOfDay } from "date-fns"

import { ToastService, FileDownloadService, LayerService } from "@anzar/core"
import { DocumentBackend, ContractBackend, Document } from "@backend/documents.api"
import { LevelBackend } from "@backend/org.api"
import { DocumentItem } from "../abstract"
import { DocumentsService, DOCUMENT_FORM_DATA } from "../documents.service"
import { FsService } from "@pyzar/fs.module"
import { ContractFormComponent, CONTRACT_TYPE } from "./contract-form.component"
import { AuthService } from "@pyzar/auth.module"
import { WorkerService } from "../../../worker.module"


@Component({
    selector: ".rege-doc-item-contract",
    templateUrl: "./contract-item.component.pug"
})
export class ContractItemComponent extends DocumentItem implements OnChanges {
    public canRevoke: boolean

    public constructor(
        @Inject(DocumentBackend) docBackend: DocumentBackend,
        @Inject(DocumentsService) docSvc: DocumentsService,
        @Inject(FsService) fsService: FsService,
        @Inject(ToastService) toast: ToastService,
        @Inject(FileDownloadService) downSvc: FileDownloadService,
        @Inject(ContractBackend) protected readonly contractBackend: ContractBackend,
        @Inject(LevelBackend) protected readonly levelBackend: LevelBackend,
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(LayerService) protected readonly layerSvc: LayerService,
        @Inject(AuthService) private readonly authSvc: AuthService,
        @Inject(WorkerService) private readonly workerSvc: WorkerService) {
        super(docBackend, docSvc, fsService, toast, downSvc)
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ("document" in changes) {
            const newDoc = changes.document.currentValue as Document
            const newEnd = startOfDay(new Date())
            this.canRevoke = !newDoc.contract.end || newEnd < startOfDay(newDoc.contract.end)
        }
    }

    public revoke() {
        this.contractBackend
            .revoke({ contract_id: this.document.contract.id })
            .pipe(take(1), this.toast.handleSave({ align: "bottom center" }))
            .subscribe(this.docSvc.reload.bind(this.docSvc))
    }

    public remove() {
        this.authSvc.hasPermission({ permission: "client.contract.delete", sameUserId: this.document.contract.creator_id, extra: { level_id: this.document.contract.section.id } })
            .pipe(
                switchMap(v => {
                    if (v) {
                        return this.contractBackend
                            .remove({ contract_id: this.document.contract.id })
                            .pipe(take(1), this.toast.handleSave({ align: "bottom center" }))
                    } else {
                        this.workerSvc.whoCanDoIt("Szerződés törlése", this.document.contract.creator_id, this.document.contract.created_time, "client.contract.delete", this.document.contract.section.id)
                        return of(false)
                    }
                })
            )
            .subscribe(v => {
                if (v) {
                    this.docSvc.reload()
                }
            })
    }

    public extend() {
        this.docSvc.popup(ContractFormComponent, [
            { provide: CONTRACT_TYPE, useValue: this.document.contract.type },
            {
                provide: DOCUMENT_FORM_DATA,
                useValue: {
                    begin: this.document.contract.end,
                    description: this.document.contract.description,
                    section_id: this.document.contract.section.id,
                    payed_amount: this.document.contract.payed_amount,
                    creator_id: this.authSvc.userId,
                    created_time: new Date()
                }
            }
        ])
    }

    public edit() {
        this.docSvc.popup(ContractFormComponent, [
            { provide: CONTRACT_TYPE, useValue: this.document.contract.type },
            {
                provide: DOCUMENT_FORM_DATA, useValue: {
                    id: this.document.contract.id,
                    begin: this.document.contract.begin,
                    end: this.document.contract.end,
                    description: this.document.contract.description,
                    section_id: this.document.contract.section.id,
                    payed_amount: this.document.contract.payed_amount,
                    creator_id: this.document.contract.creator_id,
                    created_time: this.document.contract.created_time,
                }
            }
        ])
    }
}
