import { Component, Input, Inject, OnChanges, SimpleChanges } from "@angular/core"
import { coerceBooleanProperty } from "@angular/cdk/coercion"
import { format } from "date-fns"


import { LocaleService } from "@anzar/core"


@Component({
    selector: ".rege-date-range",
    template: "{{ display }}"
})
export class DateRangeComponent implements OnChanges {
    @Input() public begin: Date
    @Input() public end: Date
    @Input()
    public set onlyDate(val: boolean) { this._onlyDate = coerceBooleanProperty(val) }
    public get onlyDate(): boolean { return this._onlyDate }
    private _onlyDate: boolean = true

    public display: string

    public constructor(@Inject(LocaleService) private readonly localeService: LocaleService) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (("begin" in changes || "end" in changes) && this.begin) {
            this.display = this.localeService.formatDateRange(this.begin, this.end, this.onlyDate)
        }
    }
}
