import { Component, ChangeDetectionStrategy, Inject, OnDestroy, ChangeDetectorRef, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { FormControl } from "@angular/forms"
import { Screen } from "../abstract.screen"

import { Destruct, FileDownloadService, ToastService } from "@anzar/core"
import { ClientsGridComponent } from "./clients-grid.component"
import { CurrentSection } from "../section.service/level"


declare const BACKEND_URL: string


@Component({
    templateUrl: "./clients-screen.template.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsScreen extends Screen implements OnDestroy, OnInit {
    public readonly destruct = new Destruct()

    @ViewChild("grid", { static: true }) public readonly grid: ClientsGridComponent

    public userId: number

    public set total(val: number) {
        if (this._total !== val) {
            this._total = val
            this.cdr.markForCheck()
        }
    }
    public get total(): number { return this._total }
    private _total: number = 0

    public set todayActive(val: number) {
        if (this._todayActive !== val) {
            this._todayActive = val
            this.cdr.markForCheck()
        }
    }
    public get todayActive(): number { return this._todayActive }
    private _todayActive: number

    public readonly providerIdFilter = new FormControl()

    public constructor(
        @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(CurrentSection) public readonly currentSection: CurrentSection) {
        super()

        this.destruct.subscription(this.providerIdFilter.valueChanges).subscribe(_ => {
            cdr.detectChanges()
        })
    }

    public ngOnInit() {
        this.destruct.subscription(this.route.params).subscribe(params => {
            const userId = Number(params["userId"])
            if (userId !== this.userId) {
                this.userId = userId
                this.cdr.detectChanges()
            }
        })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    public onTotalChange(total: [number, number]) {
        this.total = total[0]
        this.todayActive = total[1]
    }

    public print() {
        const filter = this.grid.dataSource.filter
        const sorter = this.grid.dataSource.sort
        const url = `${BACKEND_URL}/stats/clients/${JSON.stringify(filter)}/${JSON.stringify(sorter)}`

        this.downloader.download(url)
            .pipe(
                this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Ügyféllista előállítása" })
            )
            .subscribe()
    }
}
