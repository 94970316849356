import { NgModule } from "@angular/core"
import { PartnerConnect, PartnerConnectDocumentType, PartnerConnectDocument, PartnerConnectRepo, PartnerConnectRepoSource } from "./__anzar_rpc_output"

export { PartnerConnect, PartnerConnectDocumentType, PartnerConnectDocument, PartnerConnectRepo, PartnerConnectRepoSource }


@NgModule({
    providers: [
        PartnerConnectRepoSource,
        PartnerConnectRepo
    ],
})
export class ConnectApi {}