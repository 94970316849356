import { Component, Inject, Input, Output, EventEmitter, OnDestroy } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { take, debounceTime } from "rxjs/operators"

import { Destruct, SelectionEvent, DataSourceDirective } from "@anzar/core"
import { PlaceBackend, PlaceBackendSource, Place } from "@backend/place.api"


@Component({
    selector: ".rege-place-list",
    templateUrl: "./place-list.component.pug",
    providers: [DataSourceDirective]
})
export class PlaceListComponent implements OnDestroy {
    public readonly destruct = new Destruct()

    public readonly searchBy = new FormControl()

    public readonly placeForm = new FormGroup({
        place: new FormControl(null, Validators.required),
        title: new FormControl(null, Validators.required),
    })

    @Input()
    public set activePanel(val: number) {
        if (this._activePanel !== val) {
            this._activePanel = val
        }
    }
    public get activePanel(): number { return this._activePanel }
    private _activePanel: number = 0

    public formMode: "create" | "edit"

    @Output() public readonly startEdit = new EventEmitter<Place>()
    @Output() public readonly stopEdit = new EventEmitter<void>()
    @Output() public readonly selectionChanges = new EventEmitter<Place[]>()

    private _savedSelection: Place[] = []

    public constructor(
        @Inject(PlaceBackendSource) protected readonly placeSource: PlaceBackendSource,
        @Inject(PlaceBackend) protected readonly placeBackend: PlaceBackend,
        @Inject(DataSourceDirective) public readonly source: DataSourceDirective<any>) {
        source.dataSource = placeSource as any
        source.sort = { description: "asc" }
        source.baseFilter = { type: "common" }

        this.destruct.subscription(this.startEdit).subscribe(_ => {
            this.selectionChanges.emit([])
        })

        this.destruct.subscription(this.stopEdit).subscribe(_ => {
            this.selectionChanges.emit(this._savedSelection)
        })

        this.destruct.subscription(this.searchBy.valueChanges).pipe(debounceTime(300)).subscribe(value => {
            if (value && value.length) {
                source.filter = { "or": [{ description: { contains: value } }, { address: { contains: value } }] }
            } else {
                source.filter = null
            }
        })
    }

    public addEntry() {
        this.formMode = "create"
        this.placeForm.reset()
        this.activePanel = 1
        this.startEdit.emit(null)
    }

    public editEntry(place: Place) {
        this.formMode = "edit"
        this.placeForm.reset({
            place: place,
            title: place.description
        })
        this.activePanel = 1
        this.startEdit.emit(place)
    }

    public cancelEdit() {
        this.activePanel = 0
        this.stopEdit.emit()
    }

    public saveEntry() {
        this.placeForm.updateValueAndValidity()

        if (this.placeForm.valid) {
            const data = this.placeForm.value
            this.placeBackend
                .save({
                    data: {
                        place: data.place,
                        name: data.title,
                        description: data.title,
                    }
                })
                .pipe(take(1))
                .subscribe(place => {
                    this.stopEdit.emit()
                    this.source.reload()
                    this.activePanel = 0
                })
        }
    }

    public onSelection(event: SelectionEvent<Place>) {
        this.selectionChanges.emit(this._savedSelection = event)
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}
