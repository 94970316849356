import { Component, Inject, ChangeDetectorRef, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { NEVER } from "rxjs"
import { catchError, switchMap } from "rxjs/operators"

import { ViewportService, ToastService } from "@anzar/core"
import { Auth } from "@backend/pyzar.api"
import { AuthService } from "../auth.service"


@Component({
    templateUrl: "./verify-email.component.pug",
    providers: [
        ViewportService
    ]
})
export class VerifyEmailViewport implements OnInit {
    public set isBusy(val: boolean) {
        if (this._isBusy !== val) {
            this._isBusy = val
            this.cdr.detectChanges()
        }
    }
    public get isBusy(): boolean { return this._isBusy }
    private _isBusy: boolean = true

    public set isOk(val: boolean) {
        if (this._isOk !== val) {
            this._isOk = val
        }
    }
    public get isOk(): boolean { return this._isOk }
    private _isOk: boolean = false

    public error: string

    public constructor(
        @Inject(Auth) protected auth: Auth,
        @Inject(AuthService) protected authSvc: AuthService,
        @Inject(Router) protected readonly router: Router,
        @Inject(ActivatedRoute) protected readonly route: ActivatedRoute,
        @Inject(ToastService) protected readonly toastSvc: ToastService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {

    }

    public ngOnInit() {
        this.isBusy = true
        this.route.params
            .pipe(
                switchMap(params => {
                    return this.auth.handle_token({ token: params["token"] })
                }),
                catchError(err => {
                    this.error = err.message
                    this.isBusy = false
                    this.isOk = false
                    return NEVER
                })
            )
            .subscribe(_ => {
                this.isBusy = false
                this.isOk = true
            })
    }

    public nextStep() {
        this.router.navigate(["/"])
    }
}
