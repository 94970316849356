import { Component, Inject, InjectionToken, Injectable } from "@angular/core"
import { take } from "rxjs/operators"

import { LayerService, ModalLayer, LayerRef } from "@anzar/core"
import { StoryBackend } from "@backend/dispatcher.api"


const STORY_ID = new InjectionToken<number>("STORY_ID")


@Injectable()
export class StoryEntryListPopupService {
    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(StoryBackend) private readonly storyBackend: StoryBackend) {
    }

    public show(storyId: number) {
        const behavior = new ModalLayer({
            rounded: 3,
            elevation: 10
        })
        const ref = this.layerSvc.createFromComponent(StoryEntryListPopup, behavior, null, [
            { provide: STORY_ID, useValue: storyId }
        ])
        ref.show()
        return ref
    }

    public showByRichtextId(richtextId: number) {
        this.storyBackend
            .get_id_by_richtext_id({ richtext_id: richtextId })
            .pipe(take(1))
            .subscribe(storyId => {
                this.show(storyId)
            })
    }
}



@Component({
    selector: "rege-disp-sotry-entry-list-popup",
    templateUrl: "./story-entry-list.popup.pug"
})
export class StoryEntryListPopup {
    public constructor(@Inject(STORY_ID) public readonly storyId: number,
        @Inject(LayerRef) public readonly layerRef: LayerRef) {
    }
}
