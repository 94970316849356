import { Inject, Injectable } from "@angular/core"
import { Subject, of, merge } from "rxjs"
import { shareReplay, switchMap, tap, take } from "rxjs/operators"

import { LevelBackend, Institution } from "@backend/org.api"
import { LoadFields } from "@anzar/core"
import { AbstractCLEditorService } from "@pyzar/common.module"


const FIELDS: LoadFields<Institution> = [
    "id", "parent_id", "title", "variant"
]


@Injectable()
export class InstitutionEditorService extends AbstractCLEditorService {
    public parentId: number

    public set id(val: number) {
        if (this._id !== val) {
            this._idChange.next(this._id = val)
        }
    }
    public get id(): number { return this._id }
    private _id: number

    private _idChange = new Subject<number>()
    public readonly id$ = this._idChange.pipe(shareReplay(1))

    private _reload = new Subject<void>()

    public readonly institution$ = merge(this.id$, this._reload).pipe(
        switchMap(_ => this.id$),
        switchMap(id => {
            if (id) {
                return this.levelbackend.get({ id }, { loadFields: FIELDS })
                    .pipe(
                        take(1),
                        tap(val => {
                            if (val) {
                                (this as { institution: Institution }).institution = val
                                this.parentId = val.parent_id
                            }
                        })
                    )
            } else {
                return of(null)
            }
        }),
        shareReplay(1)
    )

    public readonly institution: Institution

    public constructor(
        @Inject(LevelBackend) private readonly levelbackend: LevelBackend) {
        super()
    }

    public reloadInstitution() {
        this._reload.next()
    }
}
