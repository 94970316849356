import { Directive, Inject, HostListener, OnInit, ChangeDetectorRef } from "@angular/core"
import { FormGroup } from "@angular/forms"
import { ComponentType } from "@angular/cdk/portal"
import { Observable, Observer } from "rxjs"
import { take, startWith } from "rxjs/operators"

import {
    Destructible, RICHTEXT_EDITABLE, Field, LayerService, ComponentLayerRef, ModalLayer, LayerRef, LayerEvent,
    RichtextComponentRef, RichtextAcItem, RichtextComponentParams, NzTouchEvent
} from "@anzar/core"


@Directive()
export abstract class AbstractInlineComponent<T extends AbstractForm> extends Destructible implements OnInit {
    public abstract readonly formComponent: ComponentType<T>
    public abstract readonly mustFill: boolean
    public content: string

    protected layerRef: ComponentLayerRef<T>

    public constructor(
        @Inject(RichtextComponentRef) protected readonly cmpRef: RichtextComponentRef,
        @Inject(LayerService) protected readonly layerSvc: LayerService,
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(RICHTEXT_EDITABLE) protected readonly rtEditable: boolean) {
        super()

        this.destruct.any(() => {
            if (this.layerRef) {
                this.layerRef.hide()
                delete this.layerRef
            }
        })
    }

    public ngOnInit() {
        this.destruct.subscription(this.cmpRef.paramsChange)
            .pipe(startWith(this.cmpRef.params))
            .subscribe(params => {
                this.onParamsUpdate(params)
            })

        if (this.rtEditable && this.mustFill && !this.isParamsComplete(this.cmpRef.params)) {
            this.displayForm()
                .pipe(take(1))
                .subscribe(() => {
                    if (!this.isParamsComplete(this.cmpRef.params)) {
                        this.cmpRef.dispose()
                    }
                })
        }
    }

    @HostListener("tap", ["$event"])
    public onTap(event: NzTouchEvent) {
        event.preventDefault()
        this.handleTap(event)
    }

    protected handleTap(event: NzTouchEvent) {
        if (this.formComponent) {
            this.displayForm().pipe(take(1)).subscribe()
        }
    }

    protected onParamsUpdate(params: RichtextComponentParams) {
        this.content = params.content as string
        this.cdr.detectChanges()
    }

    protected isParamsComplete(params: RichtextComponentParams): boolean {
        return true
    }

    public displayForm() {
        const behavior = new ModalLayer({
            backdrop: { type: "filled", hideOnClick: false },
            elevation: 10,
            rounded: 3,
            closeable: true,
            position: {
                anchor: {
                    ref: "viewport",
                    align: "center"
                },
                align: "center"
            }
        })
        const ref = this.layerSvc.createFromComponent(this.formComponent, behavior, null, [
            { provide: RichtextComponentRef, useValue: this.cmpRef },
            { provide: RICHTEXT_EDITABLE, useValue: this.rtEditable },
        ])
        this.layerRef = ref

        return Observable.create((observer: Observer<RichtextComponentParams>) => {
            ref.show()

            const s = ref.output.subscribe(event => {
                if (event.type === "save" || event.type === "cancel") {
                    observer.next(event.data)
                    observer.complete()
                } else if (event.type === "hiding") {
                    observer.next(null)
                    observer.complete()
                    delete this.layerRef
                }
            })

            return () => {
                if (this.layerRef) {
                    this.layerRef.hide()
                }
                s.unsubscribe()
            }
        })
    }
}


@Directive()
export abstract class AbstractForm extends Destructible implements OnInit {
    public abstract readonly form: FormGroup

    public constructor(
        @Inject(LayerRef) public readonly layerRef: LayerRef,
        @Inject(RichtextComponentRef) public readonly rtRef: RichtextComponentRef<AbstractForm>,
        @Inject(RICHTEXT_EDITABLE) public readonly rtEditable: boolean) {
        super()
    }

    public ngOnInit() {
        this.loadParams(this.rtRef.params)
    }

    public onCancel() {
        this.layerRef.emit(new LayerEvent("cancel", this.collectParams()))
        this.layerRef.hide()
    }

    public onSave() {
        const params = this.collectParams()
        this.rtRef.updateParams(params)
        this.layerRef.emit(new LayerEvent("save", params))
        this.layerRef.hide()
    }

    protected collectParams(): RichtextComponentParams {
        return this.form.value
    }

    protected loadParams(params: RichtextComponentParams) {
        this.form.reset(params)
    }
}


export class RichtextDispAcItem extends RichtextAcItem {
    @Field() public componentId: string
}
