import { Component, forwardRef, Inject, OnInit, Input } from "@angular/core"
import { FormGroup, FormArray, FormControl, Validators } from "@angular/forms"
import { concat, forkJoin } from "rxjs"
import { take, tap } from "rxjs/operators"

import { Section, ClientRequirementsBackend, SectionBackend } from "@backend/org.api"
import { ClientField } from "@backend/enums.api"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"


@Component({
    selector: ".rege-client-requirements-editor",
    templateUrl: "./client-requirements.component.pug",
    host: {
        "[style.padding]": "'16px 0'"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => ClientRequirementsComponent) }
    ]
})
export class ClientRequirementsComponent extends AbstractCLEditorBlock {
    @Input() public section: Section
    @Input() public type: string

    public readonly fields = ClientField.DATA

    public get fieldControls() { return (this.form.get("fields") as FormArray).controls }

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: AbstractCLEditorService,
        @Inject(ClientRequirementsBackend) private readonly crBackend: ClientRequirementsBackend,
        @Inject(SectionBackend) private readonly sectionBackend: SectionBackend) {
        super(editorSvc, new FormGroup({
            allow_partial_client_data: new FormControl(),
            fields: new FormArray([])
        }))

        for (const f of this.fields.data) {
            this.fieldControls.push(new FormGroup({
                field: new FormControl(f.id),
                normal: new FormControl(),
                unidentifiable: new FormControl(),
            }))
        }
    }

    public ngOnInit() {
        super.ngOnInit()
        this.reload()
    }

    public save() {
        const data: any[] = this.fieldControls.map(ctrl => ctrl.value)

        return forkJoin([
            this.sectionBackend.save({ data: { id: this.section.id, allow_partial_client_data: !!this.form.get("allow_partial_client_data").value } }).pipe(take(1)),
            this.crBackend.update({ section_id: this.section.id, data: data })
                .pipe(take(1), tap(() => {
                    this.reload()
                }))
        ])
    }

    public reload() {
        const fields = this.fields.data.map(f => {
            return { field: f.id }
        })
        this.form.reset({ fields })

        this.sectionBackend.get({ id: this.section.id })
            .pipe(take(1))
            .subscribe(section => {
                this.form.get("allow_partial_client_data").setValue(section.allow_partial_client_data)
            })

        this.crBackend.search({ section_id: this.section.id })
            .pipe(take(1))
            .subscribe(result => {
                const ctrl = this.form.get("fields") as FormArray
                for (const item of result) {
                    const idx = this.fields.data.findIndex(v => v.id === item.field)
                    if (idx > -1) {
                        ctrl.at(idx).setValue({
                            field: item.field,
                            normal: item.normal,
                            unidentifiable: item.unidentifiable,
                        })
                    }
                }
            })
    }
}
