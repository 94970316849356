import { Component, Inject, InjectionToken, Optional } from "@angular/core"

import { LayerRef, ImageRef } from "@anzar/core"

import { CropEvent, CropRegion } from "./image-crop.component"

export const INITIAL_CROP = new InjectionToken("INITIAL_CROP")


@Component({
    selector: "pz-image-crop-dialog",
    templateUrl: "./image-crop-dialog.component.pug"
})
export class ImageCropDialogComponent {
    public cropInfo: CropRegion

    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ImageRef) public readonly image: ImageRef,
        @Inject(INITIAL_CROP) @Optional() public initialCrop?: CropRegion) {
    }

    public onChanges(event: CropEvent) {
        this.cropInfo = event.region
    }

    public cancel() {
        this.layerRef.hide()
    }

    public crop() {
        this.layerRef.emit({ type: "crop", data: this.cropInfo })
        this.layerRef.hide()
    }
}
