import { Component, forwardRef, Inject, Input } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { NEVER } from "rxjs"
import { tap, switchMap } from "rxjs/operators"

import { FileUploadService, ToastService } from "@anzar/core"
import { Provider, ProviderBackend } from "@backend/org.api"
import { KenysziBackend } from "@backend/kenyszi.api"

import { FsService } from "@pyzar/fs.module"
import { TaskService } from "@pyzar/task.module"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { ProviderEditorService } from "../provider/provider-editor.service"


@Component({
    selector: ".rege-provider-kenyszi-editor",
    templateUrl: "./kenyszi.component.pug",
    host: {
        "[style.padding.px]": "16"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => KenysziComponent) }
    ]
})
export class KenysziComponent extends AbstractCLEditorBlock<ProviderEditorService> {
    @Input() public provider: Provider

    public readonly importAgreementFile = new FormControl()

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: ProviderEditorService,
        @Inject(ProviderBackend) private readonly providerBackend: ProviderBackend,
        @Inject(KenysziBackend) private readonly kenysziBackend: KenysziBackend,
        @Inject(FileUploadService) private uploadSvc: FileUploadService,
        @Inject(FsService) private readonly fs: FsService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(TaskService) private readonly taskSvc: TaskService) {
        super(editorSvc, new FormGroup({
            nrszh_id: new FormControl(),
            login_name: new FormControl(),
            login_pass: new FormControl(),
            report_enabled: new FormControl(),
            kenyszi_agreement_deadline: new FormControl(),
            kenyszi_utilization_deadline: new FormControl(),
            kenyszi_optimal_reported_count: new FormControl(),
        }))
    }

    public ngOnInit() {
        super.ngOnInit()
        this._reset()
    }

    public save() {
        const formData = this.form.value
        const data = {
            id: this.provider.id,
            nrszh_id: formData.nrszh_id,
            report_options: {
                login_name: formData.login_name,
                login_pass: formData.login_pass,
                kenyszi_agreement_deadline: formData.kenyszi_agreement_deadline,
                kenyszi_utilization_deadline: formData.kenyszi_utilization_deadline,
                kenyszi_optimal_reported_count: formData.kenyszi_optimal_reported_count,
                // id_tevadmin_jog: formData.id_tevadmin_jog,
            }
        }

        return this.providerBackend
            .save({ data })
            .pipe(tap(provider => {
                this.provider = provider
                this._reset()
                this.editorSvc.providerUpdated(provider)
            }))
    }

    public importAgreements() {
        this.uploadSvc.upload(this.fs.getUploadUrl("/kenyszi-agreement"), "file", this.importAgreementFile.value)
            .pipe(
                switchMap(file => {
                    if (file.state === "done") {
                        return this.taskSvc.start("kenyszi-import-agreement", {
                            provider_id: this.provider.id,
                            file_id: file.response.id
                        })
                    } else {
                        return NEVER
                    }
                })
            )
            .subscribe()
    }

    private _reset() {
        if (this.provider) {
            const report = this.provider.report_options

            this.form.reset({
                nrszh_id: this.provider.nrszh_id,
                login_name: report ? report.login_name : null,
                login_pass: report ? report.login_pass : null,
                kenyszi_agreement_deadline: report ? report.kenyszi_agreement_deadline : 1,
                kenyszi_utilization_deadline: report ? report.kenyszi_utilization_deadline : 1,
                kenyszi_optimal_reported_count: report ? report.kenyszi_optimal_reported_count : 0,
            })
        }
    }
}
