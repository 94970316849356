import { Injectable, Inject } from "@angular/core"

import { LayerService, ModalLayer } from "@anzar/core"
import { ClientFeedExportComponent, FEED_EXPORT_TYPES, FEED_EXPORT_SECTION_IDS, FEED_EXPORT_AUTHOR, FEED_EXPORT_CLIENT_ID } from "./client-feed-export.component"


@Injectable()
export class ClientFeedService {
    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService) {

    }

    public export(types: string[] = null, section_id: number = null, author: string = null, clientId: number = null) {
        const behavior = new ModalLayer({
            rounded: 3,
            elevation: 10
        })
        const ref = this.layerSvc.createFromComponent(ClientFeedExportComponent, behavior, null, [
            { provide: FEED_EXPORT_TYPES, useValue: types },
            { provide: FEED_EXPORT_SECTION_IDS, useValue: section_id ? [section_id] : null },
            { provide: FEED_EXPORT_AUTHOR, useValue: author },
            { provide: FEED_EXPORT_CLIENT_ID, useValue: clientId },
        ])
        ref.show()
    }
}
