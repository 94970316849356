import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { RouterModule, Route } from "@angular/router"
import { FormsModule } from "@angular/forms"
import { NzModule } from "@anzar/core"

import { PyzarCommonModule } from "@pyzar/common.module"

import { ClientApi } from "@backend/client.api"
import { OrgApi } from "@backend/org.api"
import { EventApi } from "@backend/event.api"
import { PlaceApi } from "@backend/place.api"
import { Service_roomApi } from "@backend/service_room.api"
import { Service_scheduleApi } from "@backend/service_schedule.api"
import { Service_mailingApi } from "@backend/service_mailing.api"
import { Service_social_mentalApi } from "@backend/service_social_mental.api"

import { SectionServiceModule } from "../section.service"
import { ComponentsModule } from "../components"
import { MarkModule } from "../mark.module"
import { ClientModule } from "../client.module"
import { DutyModule } from "../duty.module"
import { PlaceModule } from "../place.module"
import { ImageModule } from "../image.module"
import { KenysziModule } from "../kenyszi.module"

import { ServiceUsageScreen } from "./service-usage.screen"
export { ServiceUsageScreen }
import { ServiceUsageListComponent } from "./service-usage-list.component"
import { SerivceCheckboxComponent } from "./service-checkbox.component"
import { ServiceEditorComponent } from "./service-editor.component"
import { ServiceSummaryComponent } from "./service-summary.component"

import { RoomEditorComponent } from "./editor/room/room.component"
import { RoomConflictDialogComponent } from "./editor/room/conflict-dialog.component"
import { CapacityUsageEditorComponent } from "./editor/room/usage-editor.component"
import { CapacityUsageEditorService } from "./editor/room/usage-editor.service"
export { CapacityUsageEditorService }
import { StreetEditorComponent } from "./editor/street/street.component"
import { ScheduleEditorComponent } from "./editor/schedule/schedule.component"
import { SocialMentalEditorComponent } from "./editor/social_mental/social-mental.component"
import { CommunalEditorComponent } from "./editor/communal/communal.component"
import { BNOEditorComponent } from "./editor/bno/bno.component"
import { BNOConflictFialogComponent } from "./editor/bno/conflict-dialog.component"
import { MaybeCloseAndDeleteComponent } from "./editor/maybe-close-and-delete/maybe-close-and-delete.component"
import { MaybeCloseAndDeleteDirective } from "./editor/maybe-close-and-delete/maybe-close-and-delete.directive"

import { MailingEditorComponent } from "./editor/mailing/mailing.component"
import { MailingService } from "./editor/mailing/mailing.service"
import { NewMailComponent } from "./editor/mailing/new-mail.component"

import { AddClientEventComponent } from "./street/add-client-event.component"
import { AddCommonEventComponent } from "./street/add-common-event.component"
import { StreetCareDialogService } from "./street/street-care-dialog.service"
export { StreetCareDialogService }

import { ServiceSettingsScreen } from "./settings/service-settings.screen"
import { ServiceSettingsComponent } from "./settings/service-settings.component"
import { ServiceSettingsBlockComponent } from "./settings/service-settings-block.component"

import { ScDutyDayDataProvider } from "./sc-duty-day-data-provider"


export const SERVICE_ROUTES: Route[] = [
    { path: ":providerIds/lista", component: ServiceUsageScreen },
    { path: ":providerIds/lista/datum/:date", component: ServiceUsageScreen },
    { path: ":providerIds/lista/:dutyId", component: ServiceUsageScreen },
    // { path: ":providerIds/ugyfelesemeny/:dutyId/:providerServiceId", component: AddClientEventPageComponent },
    // { path: ":providerIds/tevekenyseg/:dutyId/:providerServiceId", component: AddCommonEventPageComponent }
]

export const SERVICE_SETTINGS_ROUTES: Route[] = [
    { path: "", component: ServiceSettingsScreen }
]


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NzModule,
        PyzarCommonModule,
        ClientApi,
        OrgApi,
        EventApi,
        PlaceApi,
        Service_roomApi,
        Service_scheduleApi,
        Service_mailingApi,
        Service_social_mentalApi,

        ComponentsModule,
        ClientModule,
        MarkModule,
        SectionServiceModule,
        DutyModule,
        PlaceModule,
        ImageModule,
        KenysziModule
    ],
    declarations: [
        ServiceUsageScreen,
        ServiceUsageListComponent,
        SerivceCheckboxComponent,
        ServiceEditorComponent,
        ServiceSummaryComponent,

        RoomEditorComponent,
        RoomConflictDialogComponent,
        CapacityUsageEditorComponent,
        StreetEditorComponent,
        ScheduleEditorComponent,
        SocialMentalEditorComponent,
        CommunalEditorComponent,
        BNOEditorComponent,
        BNOConflictFialogComponent,
        MailingEditorComponent,
        NewMailComponent,
        MaybeCloseAndDeleteComponent,
        MaybeCloseAndDeleteDirective,

        AddClientEventComponent,
        AddCommonEventComponent,

        ServiceSettingsScreen,
        ServiceSettingsComponent,
        ServiceSettingsBlockComponent,

        ScDutyDayDataProvider,

    ],
    exports: [
        ServiceUsageScreen,
        ServiceUsageListComponent,
        SerivceCheckboxComponent,
        ServiceEditorComponent,

        RoomEditorComponent,
        RoomConflictDialogComponent,
        CapacityUsageEditorComponent,
        StreetEditorComponent,
        ScheduleEditorComponent,
        SocialMentalEditorComponent,
        CommunalEditorComponent,
        BNOEditorComponent,
        BNOConflictFialogComponent,
        MailingEditorComponent,
        NewMailComponent,
        MaybeCloseAndDeleteComponent,
        MaybeCloseAndDeleteDirective,

        AddClientEventComponent,
        AddCommonEventComponent,

        ServiceSettingsScreen,
        ServiceSettingsComponent,
        ServiceSettingsBlockComponent,

        ScDutyDayDataProvider,
    ],
    entryComponents: [
        RoomConflictDialogComponent,
        BNOConflictFialogComponent,
        NewMailComponent,
        ServiceSummaryComponent,
        MaybeCloseAndDeleteComponent,
        CapacityUsageEditorComponent,
        AddClientEventComponent,
        AddCommonEventComponent
    ],
    providers: [
        MailingService,
        CapacityUsageEditorService,
        StreetCareDialogService
    ]
})
export class ServiceModule {

}
