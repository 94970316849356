import { Component, forwardRef, Inject, OnInit, Input } from "@angular/core"
import { FormGroup, FormArray, FormControl, Validators } from "@angular/forms"
import { take, tap } from "rxjs/operators"

import { LoadFields } from "@anzar/core"
import { LevelWorker, LevelWorkerBackend, LevelBackend } from "@backend/org.api"
import { WorkerRole } from "@backend/enums.api"
import { WorkerBackendSource } from "@backend/worker.api"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"


const SECTION_WORKER_FIELDS: LoadFields<LevelWorker> = [
    "level_id", "role", "rolev",
    { user: ["id", "name"] }
]


@Component({
    selector: ".rege-worker-editor",
    templateUrl: "./worker-editor.component.pug",
    host: {
        "[style.padding.px]": "16"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => WorkerEditorComponent) }
    ]
})
export class WorkerEditorComponent extends AbstractCLEditorBlock {
    @Input() public levelId: number

    public get workerControls() { return (this.form.get("workers") as FormArray).controls }

    public readonly roleSrc = WorkerRole.DATA
    public suspendChange = false
    public inherited: LevelWorker[] = []

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: AbstractCLEditorService,
        @Inject(LevelBackend) private readonly levelBackend: LevelBackend,
        @Inject(LevelWorkerBackend) private readonly levelWorkerBackend: LevelWorkerBackend,
        @Inject(WorkerBackendSource) public readonly userSrc: WorkerBackendSource) {
        super(editorSvc, new FormGroup({
            workers: new FormArray([])
        }))

        this.workerControls.push(this._createWorker())

        const workersCtrl = this.form.get("workers") as FormArray

        this.destruct.subscription(this.form.get("workers").valueChanges).subscribe(workers => {
            if (this.suspendChange) {
                return
            }

            if (workers[workers.length - 1].user_id) {
                workersCtrl.push(this._createWorker())
            }

            this.suspendChange = true
            for (let i = 0, l = workers.length; i < l; i++) {
                const roleCtrl = workersCtrl.at(i).get("role")
                if (workers[i].user_id) {
                    if (roleCtrl.disabled) {
                        roleCtrl.enable()
                    }
                } else {
                    if (roleCtrl.enabled) {
                        roleCtrl.disable()
                    }
                }
            }
            this.suspendChange = false
        })
    }

    public save() {
        return this.levelBackend.set_workers({ level_id: this.levelId, workers: this.form.get("workers").value })
            .pipe(
                take(1),
                tap(_ => {
                    this._reload()
                })
            )
    }

    public ngOnInit() {
        super.ngOnInit()
        if (this.levelId) {
            this._reload()
        }
    }

    public removeWorker(index: number) {
        (this.form.get("workers") as FormArray).removeAt(index)
    }

    private _reload() {
        this.suspendChange = true

        const workersCtrl = (this.form.get("workers") as FormArray)
        workersCtrl.controls.length = 0

        this.levelWorkerBackend.search({
            filter: { level_id: this.levelId },
            order: { "level_id": "asc", "rolev.position": "asc", "user.name.formatted": "asc" },
            begin: 0,
            count: 1000
        }, { loadFields: SECTION_WORKER_FIELDS })
            .pipe(take(1))
            .subscribe(workers => {
                this.inherited = []

                for (const worker of workers) {
                    if (worker.level_id === this.levelId) {
                        workersCtrl.push(this._createWorker(worker))
                    } else {
                        this.inherited.push(worker)
                    }
                }
                workersCtrl.push(this._createWorker())
                this.suspendChange = false
            })
    }

    private _createWorker(worker?: LevelWorker) {
        const role = new FormControl(worker ? worker.role : null, Validators.required)
        if (!worker) {
            role.disable()
        }
        return new FormGroup({
            user_id: new FormControl(worker ? worker.user : null),
            role: role
        })
    }
}
