import { Component, Input, Inject, OnDestroy, OnInit, Optional } from "@angular/core"
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from "@angular/forms"
import { merge, of, zip, Observable } from "rxjs"
import { take, map, mapTo, tap, debounceTime, switchMap, shareReplay, filter } from "rxjs/operators"
import IMask from "imask"
import { subYears } from "date-fns"

import { LoadFields, Destruct, LayerRef, ToastService, LayerEvent, DateMinMaxValidator } from "@anzar/core"
import { Client, ClientBackend } from "@backend/client.api"
import { Citizenship, CitizenshipStatus, RelationshipStatus, EducationalAttainment, AbylityToAct } from "@backend/enums.api"
import { SectionBackendSource, ClientRequirements, ClientRequirementsBackend, LevelBackendSource } from "@backend/org.api"
import { CurrentSection } from "../section.service/level"
import { NameInputComponent, NameInputRequiredValidator } from "../components/name-input.component"
import { ClientSheet } from "./client-sheet.component"
import { AddressInputComponent } from "../place.module"
import { socialInsuranceIdValidator } from "../common/social-insurance-id-validator"
import { ClientService } from "./client.service"
import { FileUploaderService } from "@pyzar/fs.module"
import { ClosingGuardService } from "../common"


const CLIENT_FIELD: LoadFields<Client> = [
    "id",
    "type",
    "manager_id",
    "name",
    "mothers_name",
    "birth_name",
    "gender",
    "social_insurance_id",
    "social_insurance_application_date",
    "birth_place",
    "birth_date",
    "citizenship",
    "citizenship_status",
    "public_health_card",
    "public_health_card_valid_until",
    "under_guardianship",
    "guardian_name",
    "guardian_contact",
    "relationship_status",
    "relatives",
    "ability_to_act",
    "identity_card_number",
    "tax_number",
    "pensioner_number",
    "phone_number",
    "educational_attainment",
    "has_qualification",
    "qualification",
    "privacy_signed",
    "privacy_signed_section_id",
    "privacy_signed_date",
    "is_unidentifiable",
    "profile_image_id",
    { permanent_address: ["id", "city", "postal_code", "public_place", "street_number"] },
    { residence_address: ["id", "city", "postal_code", "public_place", "street_number"] },
]


const MAX_BIRTH_DATE = subYears(new Date(), 14)


@Component({
    selector: ".rege-client-basic-data",
    templateUrl: "./client-basic-data.template.pug",
    providers: [
        FileUploaderService
    ]
})
export class ClientBasicDataComponent implements OnDestroy, OnInit {
    public readonly destruct = new Destruct()

    @Input() public client: Client
    @Input() public showLoadButton: boolean

    public readonly form = new FormGroup({
        id: new FormControl(),
        name: NameInputComponent.formModel(),
        birth_name: NameInputComponent.formModel(),
        mothers_name: NameInputComponent.formModel(),
        gender: new FormControl(),
        social_insurance_id: new FormControl(null, socialInsuranceIdValidator),
        social_insurance_application_date: new FormControl(),
        birth_place: new FormControl(),
        birth_date: new FormControl(),
        citizenship: new FormControl(),
        citizenship_status: new FormControl(),
        public_health_card: new FormControl(),
        public_health_card_valid_until: new FormControl(),
        under_guardianship: new FormControl(),
        guardian_name: new FormControl(),
        guardian_contact: new FormControl(),
        relationship_status: new FormControl(),
        relatives: new FormControl(),
        ability_to_act: new FormControl(),
        identity_card_number: new FormControl(),
        tax_number: new FormControl(),
        pensioner_number: new FormControl(),
        phone_number: new FormControl(),
        educational_attainment: new FormControl(),
        has_qualification: new FormControl(),
        qualification: new FormControl(),
        privacy_signed_section_id: new FormControl(),
        privacy_signed_date: new FormControl(),
        privacy_signed: new FormControl(),
        has_social_insurance_id: new FormControl(),
        has_residence: new FormControl(),
        is_unidentifiable: new FormControl(),
        permanent_address: AddressInputComponent.formGroup(),
        residence_address: AddressInputComponent.formGroup(),
        residence_is_same: new FormControl(),
        profile_image_id: new FormControl(),
    })

    public readonly citizenshipSrc = Citizenship.DATA
    public readonly citizenshipStatusSrc = CitizenshipStatus.DATA
    public readonly relationshipStatusSrc = RelationshipStatus.DATA
    public readonly educationalAttainmentSrc = EducationalAttainment.DATA
    public readonly abylityToActSrc = AbylityToAct.DATA

    public initialSearch: boolean = false
    public seraching: boolean = false
    public hasSearchFields: boolean = false
    public suggestions: Client[] = []
    public maxBirthDate = MAX_BIRTH_DATE

    public readonly socialInsuranceIdMask: IMask.AnyMaskedOptions = {
        mask: '000-`000-`000',
        lazy: false,
        overwrite: true,
        autofix: true,
    }

    private readonly requiredFields$ = this.currentSection.id$.pipe(
        switchMap(sectionId => {
            return this.crBackend.search({ section_id: sectionId })
        }),
        map(result => {
            let extra: ClientRequirements[] = []

            for (const cr of result) {
                if (cr.field === "social_insurance_id") {
                    extra.push(new ClientRequirements({ field: "social_insurance_application_date", normal: cr.normal, unidentifiable: cr.unidentifiable }))
                    extra.push(new ClientRequirements({ field: "has_social_insurance_id", normal: cr.normal, unidentifiable: cr.unidentifiable }))
                } else if (cr.field === "privacy") {
                    extra.push(new ClientRequirements({ field: "privacy_signed", normal: cr.normal, unidentifiable: cr.unidentifiable }))
                    extra.push(new ClientRequirements({ field: "privacy_signed_section_id", normal: cr.normal, unidentifiable: cr.unidentifiable }))
                    extra.push(new ClientRequirements({ field: "privacy_signed_date", normal: cr.normal, unidentifiable: cr.unidentifiable }))
                }
            }
            return result.concat(extra).filter(v => v.field !== "privacy")
        }),
        shareReplay(1)
    )

    public constructor(
        @Inject(SectionBackendSource) public readonly sectionSrc: SectionBackendSource,
        @Inject(LevelBackendSource) public readonly levelSrc: LevelBackendSource,
        @Inject(CurrentSection) public readonly currentSection: CurrentSection,
        @Inject(ClientBackend) protected readonly clientBackend: ClientBackend,
        @Inject(LayerRef) @Optional() private readonly layerRef: LayerRef,
        @Inject(ClientSheet) @Optional() private readonly sheet: ClientSheet,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(ClientRequirementsBackend) private readonly crBackend: ClientRequirementsBackend,
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(FileUploaderService) private uploader: FileUploaderService,
        @Inject(ClosingGuardService) @Optional() closingGuard: ClosingGuardService) {

        const searchFields = merge(
            this.form.get("social_insurance_id").valueChanges,
            this.form.get("name").valueChanges,
            this.form.get("birth_date").valueChanges,
        )

        this.destruct.subscription(searchFields)
            .pipe(
                map(v => [
                    this.form.get("social_insurance_id").value,
                    this.form.get("name").value,
                    this.form.get("birth_date").value,
                ]),
                tap(v => {
                    let [soc_id, name, birth_date] = v
                    name = !!(name.family && name.given)
                    this.hasSearchFields = [soc_id, name, birth_date].filter(item => !!item).length >= 2
                }),
                debounceTime(300),
                switchMap(v => {
                    const [soc_id, name, birth_date] = v
                    let params = {} as any

                    if (soc_id) {
                        params.social_insurance_id = soc_id
                    }

                    if (name.family || name.given) {
                        params.name = name
                    }

                    if (birth_date) {
                        params.birth_date = birth_date
                    }

                    if (this.client) {
                        params.exclude = this.client.id
                    }

                    return this.clientBackend.search_by_two_input(params)
                })
            )
            .subscribe(v => {
                this.suggestions = v
            })

        this.destruct.subscription(this.form.get("privacy_signed").valueChanges).subscribe(signed => {
            const dateField = this.form.get("privacy_signed_date")
            const sectionField = this.form.get("privacy_signed_section_id")
            if (signed) {
                dateField.enable()
                sectionField.enable()
                if (!dateField.value) {
                    dateField.setValue(new Date())
                }
                if (!sectionField.value) {
                    sectionField.setValue(this.currentSection.id)
                }
            } else {
                dateField.setValue(null)
                dateField.disable()
                sectionField.setValue(null)
                sectionField.disable()
            }
        })

        this.destruct.subscription(this.form.get("has_social_insurance_id").valueChanges).subscribe(hasSocId => {
            const idField = this.form.get("social_insurance_id")
            const dateField = this.form.get("social_insurance_application_date")
            if (hasSocId) {
                idField.enable()
                dateField.disable()
            } else {
                idField.disable()
                dateField.enable()
            }
        })

        this.destruct
            .subscription(merge(this.requiredFields$, this.form.get("is_unidentifiable").valueChanges as Observable<boolean>))
            .pipe(debounceTime(10), switchMap(_ => this.requiredFields$.pipe(take(1))))
            .subscribe(crList => {
                const isUnidentifiable = this.form.get("is_unidentifiable").value

                for (const cr of crList) {
                    const ctrl = this.form.get(cr.field)
                    if (isUnidentifiable) {
                        if (cr.unidentifiable) {
                            addRequiredValidator(cr.field, ctrl)
                        } else {
                            removeRequiredValidator(cr.field, ctrl)
                        }
                    } else {
                        if (cr.normal) {
                            addRequiredValidator(cr.field, ctrl)
                        } else {
                            removeRequiredValidator(cr.field, ctrl)
                        }
                    }
                }
            })

        if (closingGuard) {
            const closeable = closingGuard.closeableTracker("Elmentetlen változások vannak az adatlapon. Biztosan bezárod?", (close) => {
                if (!close && sheet) {
                    sheet.selectedTabIndex = ClientService.TAB_BASIC_DATA
                }
            })
            this.destruct.disposable(closeable)
            this.destruct.subscription(this.form.statusChanges).subscribe(v => {
                if (this.form.dirty) {
                    closeable.no()
                } else {
                    closeable.yes()
                }
            })
        }



        // // XXX: ....
        // this.form.statusChanges.subscribe(() => {
        //     console.log(this.getInvalidControls(this.form, []))
        // })
    }


    // private getInvalidControls(group: FormGroup, path: string[]) {
    //     let result: string[] = []
    //     for (const k in group.controls) {
    //         const ctrl = group.controls[k]
    //         if (ctrl instanceof FormGroup) {
    //             result = result.concat(this.getInvalidControls(ctrl, path.concat([k])))
    //         } else if (ctrl.invalid) {
    //             result.push(path.concat([k]).join("."))
    //         }
    //     }
    //     return result
    // }


    public ngOnInit() {
        this.initialSearch = !this.client
        this.reset()
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    public reset() {
        if (this.client) {
            this._loadClient(this.client.id)
        } else {
            this.form.reset()
            this._afterFill()
        }
    }

    public cancel() {
        if (this.client && this.sheet) {
            this.reset()
        } else if (this.layerRef) {
            this.layerRef.hide()
        }
    }

    public save() {
        this.uploader.upload("/client-pimage")
            .pipe(
                filter(e => e.state === "done"),
                switchMap(event => {
                    return this.saveClient()
                }),
                switchMap(client => {
                    return this.sheet
                        ? this.sheet.reload().pipe(mapTo(client))
                        : of(client)
                })
            )
            .subscribe(client => {
                if (this.layerRef) {
                    this.layerRef.emit(new LayerEvent("client-saved", client))
                }
            })
    }

    public saveClient() {
        const data = { ...this.form.value }

        if (data.has_social_insurance_id) {
            data.social_insurance_application_date = null
        } else {
            data.social_insurance_id = null
        }

        delete data.has_social_insurance_id
        delete data.has_residence
        data.is_unidentifiable = !!data.is_unidentifiable

        return this.clientBackend.save({ data })
            .pipe(
                this.toastSvc.handleSave({ align: "bottom center", successMsg: "Ügyfél mentés sikeres" }),
                tap(v => {
                    this._loadClient(v.id)
                })
            )
    }

    public loadClient(id: number) {
        this.initialSearch = false
        this._loadClient(id)
    }

    public createClient() {
        this.client = null
        this.form.get("id").setValue(null)
        this.initialSearch = false
    }

    public showClientSheet(clientId: number) {
        this.clientSvc.showSheet(clientId).subscribe()
    }

    private _loadClient(id: number) {
        this.destruct.subscription(this.clientBackend.get({ id: id }, { loadFields: CLIENT_FIELD }))
            .pipe(take(1))
            .subscribe(client => {
                this.client = client
                this.form.reset()
                this._fillFrom(this.form, client)
                this._afterFill()

                const address = client.permanent_address
                this.form.get("has_residence").setValue(!!address)

                if (address) {
                    const residence = client.residence_address
                    this.form.get("residence_is_same").setValue(residence && residence.id === address.id)
                }
            })
    }

    private _fillFrom(group: FormGroup, client: { [key: string]: any }) {
        const controls = group.controls
        for (const k in controls) {
            const control = controls[k]
            const value = client[k]

            if (control instanceof FormGroup) {
                if (value) {
                    this._fillFrom(control, value)
                }
            } else {
                control.setValue(value)
            }
        }
    }

    private _afterFill() {
        const social_insurance_id = this.form.get("social_insurance_id").value
        const social_insurance_application_date = this.form.get("social_insurance_application_date").value

        if (social_insurance_id != null || social_insurance_application_date != null) {
            this.form.get("has_social_insurance_id").setValue(!!social_insurance_id)
        } else {
            this.form.get("has_social_insurance_id").setValue(true)
        }

        this.form.markAsUntouched()
        this.form.markAsPristine()
    }
}


function getRequiredValidator(name: string) {
    switch (name) {
        case "name":
        case "birth_name":
        case "mothers_name":
            const v = new NameInputRequiredValidator()
            return v.validate.bind(v)

        case "privacy_signed":
            return (ctrl: AbstractControl): ValidationErrors | null => {
                return ctrl.value === true ? null : { required: true }
            }

        default:
            return Validators.required
    }
}


function addRequiredValidator(name: string, ctrl: AbstractControl) {
    if (name === "social_insurance_id") {
        ctrl.setValidators([getRequiredValidator(name), socialInsuranceIdValidator])
    } else if (name === "birth_date") {
        const dmm = new DateMinMaxValidator()
        dmm.max = MAX_BIRTH_DATE
        ctrl.setValidators([dmm, getRequiredValidator(name)])
    } else {
        ctrl.setValidators([getRequiredValidator(name)])
    }
    ctrl.updateValueAndValidity()
}


function removeRequiredValidator(name: string, ctrl: AbstractControl) {
    if (name === "social_insurance_id") {
        ctrl.setValidators([socialInsuranceIdValidator])
    } else {
        ctrl.clearValidators()
    }
    ctrl.updateValueAndValidity()
}
