import { Inject, Injectable, OnDestroy } from "@angular/core"
import { Observable, Subject, Observer, forkJoin } from "rxjs"
import { switchMap, shareReplay, map, tap } from "rxjs/operators"

import { Destruct, rawSetTimeout, rawClearTimeout } from "@anzar/core"
import { MarkBackend, OrgMark, ClientMark } from "@backend/mark.api"
import { CurrentSection } from "../section.service/level"


export interface Alert {
    readonly level?: number
    readonly icon?: string
    readonly mark?: string
}


export const EDITABLE_MARK_TYPES = ["deadline", "siid_deadline", "lung_screening", "date_range"]
export const COLOR_BY_ALERT_LEVEL: { [key: number]: string } = {
    1: "light-info",
    5: "light-warn",
    9: "light-vibrant",
    15: "light-critical",
}

export const ICON_COLOR_BY_ALERT_LEVEL: { [key: number]: string } = {
    1: "info",
    5: "warn",
    9: "vibrant",
    15: "critical",
}

export interface ClientMarkOptions {
    start?: Date
    deadline?: Date
    deadline_type?: string
    text?: string
    is_active?: boolean
}


export interface ClientAlertLevel {
    level: number
    color: string
}


export class ClientMarker {
    public constructor(
        public readonly mark: OrgMark,
        public readonly clientMark: ClientMark,
        public readonly alert: Alert,
        public readonly condition?: any) {
    }
}


@Injectable()
export class MarkService implements OnDestroy {
    public readonly destruct = new Destruct()

    public readonly marks = this.destruct.subscription(this.section.id$)
        .pipe(
            switchMap(sectionId => {
                return this.markBackend.get_section_marks({ section_id: sectionId })
            }),
            map(marks => marks.sort((a, b) => {
                if (a.mark.position === b.mark.position) {
                    return a.mark.title.localeCompare(b.mark.title)
                } else {
                    return a.mark.position - b.mark.position
                }
            })),
            shareReplay(1)
        )

    public readonly clientMarkChanged: Observable<ClientMark> = this.destruct.subject(new Subject())

    protected readonly alertLevelCache: { [key: string]: { [key: string]: Observable<Alert[]> } } = {}
    // private readonly alertLevelChange = new Subject<ClientAlertLevel>()

    // public readonly alertLevel = this.alertLevelChange
    //         .pipe()

    private _batchTimer: any
    private _batchQ: { [key: string]: { clientIds: number[], observers: Array<[number[], Observer<Alert[][]>]> } } = {}

    public constructor(
        @Inject(CurrentSection) private readonly section: CurrentSection,
        @Inject(MarkBackend) private readonly markBackend: MarkBackend) {
    }

    public markClient(mark: OrgMark, clientId: number, options: ClientMarkOptions): Observable<ClientMark> {
        return this.markBackend.mark_client({
            client_id: clientId,
            org_mark_id: mark.id,
            start: options.start,
            deadline: options.deadline,
            deadline_type: options.deadline_type,
            text: options.text,
        }).pipe(tap(cm => {
            this.invalidateAlertLevel(clientId);
            (this.clientMarkChanged as Subject<ClientMark>).next(cm)
        }))
    }

    public unmarkClient(clientMark: ClientMark, params: ClientMarkOptions = null): Observable<any> {
        return this.markBackend.unmark_client({ client_mark_id: clientMark.id, params })
            .pipe(tap(() => {
                this.reload(clientMark)
            }))
    }

    public updateClientMark(clientMark: ClientMark, params: ClientMarkOptions): Observable<any> {
        return this.markBackend.update_client_mark({ client_mark_id: clientMark.id, params }).pipe(
            tap(() => {
                this.reload(clientMark)
            })
        )
    }

    public deleteClientMark(clientMark: ClientMark): Observable<any> {
        return this.markBackend.delete_client_mark({ client_mark_id: clientMark.id }).pipe(
            tap(() => {
                this.reload(clientMark)
            })
        )
    }

    public reload(clientMark: ClientMark) {
        this.invalidateAlertLevel(clientMark.client_id);
        (this.clientMarkChanged as Subject<ClientMark>).next(clientMark)
    }

    public invalidateAlertLevel(clientId: number) {
        delete this.alertLevelCache[clientId]
    }

    public getClientAlertLevel(clientId: number, types: string[] = null): Observable<Alert[]> {
        // const key = `${clientId}-${types ? types.join(",") : ""}`
        const tkey = types?.length ? types.join(",") : "@all"

        if (this.alertLevelCache[clientId]) {
            if (this.alertLevelCache[clientId][tkey]) {
                return this.alertLevelCache[clientId][tkey]
            }
        } else {
            this.alertLevelCache[clientId] = {}
        }

        return this.alertLevelCache[clientId][tkey] = this.getClientsAlertLevel([clientId], types)
            .pipe(map(v => v[0]), shareReplay(1))
    }

    public getClientsAlertLevel(clientIds: number[], types: string[] = null): Observable<Alert[][]> {
        return new Observable((observer: Observer<Alert[][]>) => {
            if (!this._batchTimer) {
                this._batchTimer = rawSetTimeout(this._batchedGetAlertLevels.bind(this), 50)
            } else {
                rawClearTimeout(this._batchTimer)
                this._batchTimer = rawSetTimeout(this._batchedGetAlertLevels.bind(this), 50)
            }

            if (!types || !types.length) {
                types = ["@"]
            }

            const tk = types.sort().join(",")

            if (!this._batchQ[tk]) {
                this._batchQ[tk] = { clientIds: [], observers: [] }
            }

            const cids = this._batchQ[tk].clientIds
            for (const clientId of clientIds) {
                if (cids.indexOf(clientId) === -1) {
                    cids.push(clientId)
                }
            }
            this._batchQ[tk].observers.push([clientIds, observer])

            return this._stopBatchTimer.bind(this)
        })
    }

    public getAlertColor(alertLevel: number, defaultColor?: string): string {
        return COLOR_BY_ALERT_LEVEL[alertLevel] || defaultColor || "base"
    }

    private _batchedGetAlertLevels() {
        delete this._batchTimer
        const queries = Object.keys(this._batchQ)
            .map(key => {
                const types = key === "@" ? null : key.split(",")
                const data = this._batchQ[key]
                delete this._batchQ[key]
                return [types, data] as [typeof types, typeof data]
            })
            .map(([types, data]) => {
                return this.markBackend.get_due_alert_levels({ client_ids: data.clientIds, section_id: this.section.id, types })
                    .pipe(tap(result => {
                        for (const observer of data.observers) {
                            const subResult = observer[0].map(clientId => result[data.clientIds.indexOf(clientId)])
                            observer[1].next(subResult)
                            observer[1].complete()
                        }
                    }))
            })

        if (queries.length) {
            this.destruct.subscription(forkJoin(queries)).subscribe()
        }
    }

    private _stopBatchTimer() {
        if (this._batchTimer) {
            const t = this._batchTimer
            this._batchTimer = null
            rawClearTimeout(t)
        }
    }

    public ngOnDestroy() {
        this._stopBatchTimer()
        this._batchQ = {}
        this.destruct.run()
    }
}
