import { Component, Inject, InjectionToken, Optional, OnInit } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { take, switchMap, map } from "rxjs/operators"

import { LayerRef, ToastService, StaticSource, Model, Field, LayerEvent, Destructible } from "@anzar/core"
import { ContractBackend } from "@backend/documents.api"
import { SectionBackendSource } from "@backend/org.api"
import { Client } from "@backend/client.api"
import { WorkerBackendSource } from "@backend/worker.api"
import { CurrentSection } from "../../../section.service/level"
import { AuthService } from "@pyzar/auth.module"
import { DOCUMENT_FORM_DATA } from "../documents.service"


export type ContractType = string
export const CONTRACT_TYPE = new InjectionToken<ContractType>("CONTRACT_TYPE")


@Component({
    selector: "rege-doc-form-contract",
    templateUrl: "./contract-form.component.pug"
})
export class ContractFormComponent extends Destructible implements OnInit {
    public readonly form = new FormGroup({
        id: new FormControl(),
        begin: new FormControl(new Date()),
        end: new FormControl(),
        section_id: new FormControl(),
        description: new FormControl(),
        payed_amount: new FormControl(),
        creator_id: new FormControl(),
        created_time: new FormControl(new Date()),
    })

    public constructor(
        @Inject(Client) private readonly client: Client,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(CurrentSection) section: CurrentSection,
        @Inject(CONTRACT_TYPE) public readonly type: string,
        @Inject(DOCUMENT_FORM_DATA) @Optional() private readonly initialData: string,
        @Inject(ContractBackend) private readonly contractBackend: ContractBackend,
        @Inject(AuthService) public readonly authSvc: AuthService,
        @Inject(SectionBackendSource) public readonly sectionSrc: SectionBackendSource,
        @Inject(WorkerBackendSource) public readonly userSrc: WorkerBackendSource) {
        super()

        this.destruct.subscription(section.id$).subscribe(sectionId => {
            this.form.get("section_id").setValue(sectionId)
        })

        authSvc.currentUser$.pipe(take(1)).subscribe(user => {
            this.form.get("creator_id").setValue(user.id)
        })
    }

    public ngOnInit() {
        if (this.initialData) {
            this.form.reset(this.initialData)
        }
    }

    public save() {
        const data = this.form.value
        const params: any = {
            description: data.description,
            payed_amount: data.payed_amount,
        }

        if (data.creator_id) {
            params.creator_id = data.creator_id
        }

        if (data.created_time) {
            params.created_time = data.created_time
        }


        this.contractBackend
            .save({
                id: data.id,
                client_id: this.client.id,
                section_id: data.section_id,
                type: this.type,
                begin: data.begin,
                end: data.end,
                params: params
            })
            .pipe(this.toastSvc.handleSave({ align: "bottom center" }))
            .subscribe(res => {
                this.layerRef.emit(new LayerEvent("saved", res))
                this.layerRef.close()
            })
    }

    public cancel() {
        this.layerRef.close()
    }
}
