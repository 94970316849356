import { Component, Inject, Input } from "@angular/core"
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { startOfMonth, endOfMonth } from "date-fns"

import { StatsService, DispatcherStatType } from "../stats.service"


@Component({
    selector: ".rege-stats-bno",
    templateUrl: "./bno.component.pug"
})
export class BNOComponent {
    @Input() public type: "excel" | "case_study"

    public readonly form = new FormGroup({
        date_from: new FormControl(startOfMonth(new Date()), [Validators.required]),
        date_to: new FormControl(endOfMonth(new Date()), [Validators.required]),
    })

    public constructor(
        @Inject(StatsService) private readonly stats: StatsService) {
    }

    public download() {
        if (this.type === "excel") {
            this.stats.bno_excel(this.form.get("date_from").value, this.form.get("date_to").value)
        } else if (this.type === "case_study") {
            this.stats.bno_case_study(this.form.get("date_from").value, this.form.get("date_to").value)
        }
    }
}
