import { Component, Input, Inject, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Observable } from "rxjs"
import { take, switchMap } from "rxjs/operators"

import { ToastService, LoadFields } from "@anzar/core"
import { WorkerMessage, WorkerMsgBackend, WorkerMsgBackendSource } from "@backend/worker.api"
import { User } from "@backend/pyzar.api"
import { AuthService } from "@pyzar/auth.module"
import { WorkerCardService } from "../worker.module"
import type { MessageListComponent } from "./message-list.component"


export const MESAGE_FIELDS: LoadFields<WorkerMessage> = [
    "id", "is_pinned", "created_time", "updated_time", "creator_id", "alias_id", "child_count", "parent_id",
    { alias: ["id", "name", "profile_image_id"] },
    { richtext: ["text"] }
]


@Component({
    selector: ".rege-message",
    host: {
        "[attr.variant]": "_message && _message.is_pinned ? 'pinned' : null"
    },
    templateUrl: "./message.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent implements OnInit {
    @ViewChild("replies", { static: false }) public readonly replies: any

    @Input()
    public set message(val: WorkerMessage) {
        if (this._message !== val) {
            this._message = val
            this.updateCan()
        }
    }
    public get message(): WorkerMessage { return this._message }
    public _message: WorkerMessage

    @Input()
    public canReply: boolean = false

    @Input()
    public canPin: boolean = false

    @Input()
    public canDelete: boolean = false

    @Output()
    public readonly pinchange: Observable<boolean> = new EventEmitter()

    @Output()
    public readonly deleted: Observable<WorkerMessage> = new EventEmitter()

    public canEditAccess: boolean = false
    public canDeleteAccess: boolean = false
    public canPinAccess: boolean = false
    public canReplyAccess: boolean = false

    public set editing(val: boolean) {
        if (this._editing !== val) {
            this._editing = val
            this.cdr.detectChanges()
        }
    }
    public get editing(): boolean { return this._editing }
    public _editing: boolean = false

    public set replying(val: boolean) {
        if (this._replying !== val) {
            this._replying = val
            this.cdr.detectChanges()
        }
    }
    public get replying(): boolean { return this._replying }
    public _replying: boolean

    public set showReplies(val: boolean) {
        if (this._showReplies !== val) {
            this._showReplies = val
            if (!val) {
                this._replying = false
            }
            this.cdr.detectChanges()
        }
    }
    public get showReplies(): boolean { return this._showReplies }
    public _showReplies: boolean

    public readonly content = new FormControl()
    private readonly currentUser: User

    public constructor(
        @Inject(WorkerMsgBackend) public readonly backend: WorkerMsgBackend,
        @Inject(WorkerMsgBackendSource) public readonly wmSource: WorkerMsgBackendSource,
        @Inject(WorkerCardService) public readonly workerSheet: WorkerCardService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(AuthService) private readonly auth: AuthService) {
    }

    public ngOnInit() {
        this.auth.currentUser$.pipe(take(1)).subscribe(user => {
            (this as any).currentUser = user
            this.updateCan()
        })
    }

    public setPinned(pinned: boolean): void {
        this.backend.set_pinned({ id: this.message.id, pinned })
            .pipe(take(1), this.toast.handleSave({ align: "bottom center" }))
            .subscribe(changed => {
                if (changed) {
                    (this.pinchange as EventEmitter<boolean>).emit(pinned)
                }
            })
    }

    public deleteEntry() {
        this.backend.remove({ id: this.message.id })
            .pipe(take(1), this.toast.handleSave({ align: "bottom center" }))
            .subscribe(succ => {
                if (succ) {
                    (this.deleted as EventEmitter<WorkerMessage>).emit(this.message)
                }
            })
    }

    public reply() {
        if (this.replying) {
            this.replying = false
            this.showReplies = false
        } else {
            this.replying = true
            this.showReplies = true
        }
    }

    public onReply() {
        let count = this.message.child_count || 0
        this.message.child_count = count + 1
        this.cdr.detectChanges()
        if (this.replies) {
            (this.replies as MessageListComponent).realod()
            this.replying = false
        }
    }

    public editEntry() {
        this.content.setValue(this.message.richtext.text)
        this.editing = true
    }

    public cancelEdit() {
        this.editing = false
    }

    public save() {
        this.backend.save({ data: { id: this.message.id, content: this.content.value } })
            .pipe(
                take(1),
                this.toast.handleSave({ align: "bottom center" }),
                switchMap(r => {
                    return this.backend.get({ id: r.id }, { loadFields: MESAGE_FIELDS })
                })
            )
            .subscribe(msg => {
                this.message = msg
                this.cdr.detectChanges()
                this.editing = false
            })
    }

    private updateCan() {
        const message = this._message
        const user = this.currentUser

        if (message && user) {
            this.canDeleteAccess = this.canDelete
                || user.id === message.creator_id
                || user.id === message.alias_id
            this.canEditAccess = this.canDeleteAccess
            this.canPinAccess = this.canPin && !message.parent_id
            this.canReplyAccess = this.canReply && !message.parent_id
        } else {
            this.canDeleteAccess = this.canEditAccess = this.canPinAccess = this.canReplyAccess = false
        }

        this.cdr.markForCheck()
    }
}
