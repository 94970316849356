import { Component, Inject, ViewChild } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"

import { RICHTEXT_EDITABLE, LayerRef, RichtextComponentRef, SelectComponent } from "@anzar/core"
import { AbstractForm } from "../abstract"
import { SectionBackendSource, Section } from "@backend/org.api"


@Component({
    selector: "rege-rt-medical-exam-form",
    templateUrl: "./medical-exam-form.component.pug"
})
export class MedicalExamFormComponent extends AbstractForm {
    @ViewChild("select", { static: true }) private readonly select: SelectComponent<Section>

    public readonly form = new FormGroup({
        contact_name: new FormControl(),
        contact_tel: new FormControl(),
        section_id: new FormControl(),
        section_title: new FormControl(),
        diagnosis: new FormControl()
    })

    public constructor(
        @Inject(LayerRef) layerRef: LayerRef,
        @Inject(RichtextComponentRef) rtRef: RichtextComponentRef<AbstractForm>,
        @Inject(RICHTEXT_EDITABLE) rtEditable: boolean,
        @Inject(SectionBackendSource) public readonly sectionSrc: SectionBackendSource) {
        super(layerRef, rtRef, rtEditable)

        this.destruct.subscription(this.form.get("section_id").valueChanges).subscribe(value => {
            const selected = this.select.selected[0]
            this.form.get("section_title").setValue(selected ? selected.title : null)
        })
    }
}
