import { Component, Inject, Injectable, Directive } from "@angular/core"
import { Observable } from "rxjs"
import { shareReplay, map, filter, distinctUntilChanged } from "rxjs/operators"

import { LayerRef, Destructible } from "@anzar/core"
import { TaskDetailsFactory, TASK_TITLE, TASK_BUTTONS, ButtonOption } from "./task-details-factory"
import { TaskService } from "./task.service"
import { RunningTask } from "./running-task"


@Directive({})
export abstract class TaskDetailsPart extends Destructible {
    public readonly info = this.task.info

    public readonly isScheduled: Observable<boolean | null> = this.info.pipe(
        map(info => info.status === "scheduled"),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public isStarted: Observable<boolean> = this.info.pipe(
        map(info => info.status === "running"),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public readonly isSuccess: Observable<boolean | null> = this.info.pipe(
        map(info => info.status === "success"),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public readonly isFailure: Observable<boolean | null> = this.info.pipe(
        map(info => info.status === "failure"),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public isFinished = this.info.pipe(
        map(info => !!info.result),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public hasLogs: Observable<boolean> = this.task.logs.pipe(
        map(logs => logs.length > 0),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public readonly percent = this.info.pipe(
        map(info => {
            if (info.result) {
                return 100
            } else if (info.progress) {
                return Math.round(info.progress.current / info.progress.total * 100) || 0
            }
        }),
        shareReplay(1)
    )

    public readonly duration = this.info.pipe(
        filter(info => !!info.result),
        map(info => info.result.execution_time),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public constructor(
        @Inject(TaskService) protected readonly taskSvc: TaskService,
        @Inject(RunningTask) protected readonly task: RunningTask) {
        super()
    }
}


@Directive({})
export abstract class TaskDetailsComponent extends TaskDetailsPart {
    public constructor(
        @Inject(TASK_TITLE) public readonly taskTitle: string,
        @Inject(TASK_BUTTONS) public readonly buttons: ButtonOption[],
        @Inject(LayerRef) protected readonly layerRef: LayerRef,
        @Inject(TaskService) taskSvc: TaskService,
        @Inject(RunningTask) task: RunningTask) {
        super(taskSvc, task)
    }

    public doClose() {
        this.layerRef.hide()
    }

    public handleButtonClick(event: Event, role: string) {
        this.layerRef.emit({ type: "button", data: role })
    }
}


@Component({
    selector: "pz-task-details",
    templateUrl: "./task-details.component.pug",
    host: {
        "[style.min-width.px]": "700"
    }
})
export class DefaultTaskDetailsComponent extends TaskDetailsComponent {
}


@Injectable({ providedIn: "root" })
export class DefaultTaskDetailsFactory extends TaskDetailsFactory {
    public readonly name = "default"
    public readonly title = "Folyamat állapota"
    public readonly component = DefaultTaskDetailsComponent
}
