import { Component, Input, ChangeDetectionStrategy, Inject, OnInit, HostBinding, OnChanges, SimpleChanges } from "@angular/core"

import { Notification, NotificationAssign, NotificationBackend } from "@backend/notification.api"
import { NotificationService } from "./notification.service"


@Component({
    selector: ".rege-notification-entry",
    templateUrl: "./notification-entry.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationEntryComponent implements OnChanges {
    @Input() public nassign: NotificationAssign

    public notification: Notification

    @HostBinding("attr.variant")
    public get variantAttr(): string { return this.nassign.seen_time ? null : "unseen" }

    public constructor(
        @Inject(NotificationService) private readonly nsvc: NotificationService) {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if ("nassign" in changes) {
            const old = changes.nassign.previousValue as NotificationAssign
            const current = changes.nassign.currentValue as NotificationAssign
            if (!old || !current || old.pk !== current.pk) {
                this.notification = current.notification

                if (!current.seen_time) {
                    this.nsvc.markAsSeen(current)
                }
            }
        }
    }
}
