import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzModule } from "@anzar/core"

import { ComponentsModule } from "../components"
import { HelpPagesModule } from "@malta/help"

import { ChangelogComponent } from "./changelog.component"
import { FeedbackComponent } from "./feedback.component"
import { HelpWindowComponent } from "./help-window.component"


import { HelpService } from "./help.service"
export { HelpService }




@NgModule({
    imports: [
        CommonModule,
        NzModule,
        ComponentsModule,
        HelpPagesModule
    ],
    declarations: [
        HelpWindowComponent,
        ChangelogComponent,
        FeedbackComponent
    ],
    exports: [
        HelpWindowComponent
    ],
    providers: [
        HelpService
    ],
    entryComponents: [
        HelpWindowComponent
    ]
})
export class HelpModule {

}
