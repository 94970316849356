import { Inject, Injectable } from "@angular/core"
import { FormGroup } from "@angular/forms"
import { Observable, Subject } from "rxjs"


import { StaticSource, CombinedSource, Destructible } from "@anzar/core"
import { Story, StoryBackendSource, StoryEntry } from "@backend/dispatcher.api"
import { CurrentSection } from "../section.service/level"


export type CreateMode = "incoming" | "outgoing"


export interface StoryFormOptions {
    createMode: CreateMode
    data?: any
}


export interface StoryForm {
    storyId: number
    storyEntryId: number
    form: FormGroup
    isIncoming: boolean
    doSave(): Observable<any>
}


@Injectable()
export class StoriesService extends Destructible {
    public initFormWith: StoryFormOptions = {} as any

    public currentForm: StoryForm
    public currentModel: Story

    protected readonly staticSource = new StaticSource(Story as any, [])
    public readonly source = new CombinedSource([this.staticSource as any, this.storySource as any])
    public readonly open$ = this.destruct.subject(new Subject<Story>())
    public readonly reload$ = this.destruct.subject(new Subject<Story | null>())
    public readonly move$ = this.destruct.subject(new Subject<StoryEntry>())

    public constructor(
        @Inject(StoryBackendSource) public readonly storySource: StoryBackendSource,
        @Inject(CurrentSection) private readonly section: CurrentSection) {
        super()
    }

    public setOpened(model: Story) {
        this.open$.next(model)
    }

    public newStory(options: StoryFormOptions) {
        const model = new Story({ status: "progress", section_id: this.section.id }) as any
        this.initFormWith = options
        this.staticSource.add(model)

        setTimeout(() => {
            this.setOpened(model)
        }, 200)
    }

    public hasNewStory(): boolean {
        return this.staticSource.data.length > 0
    }

    public removeUnsaved() {
        if (this.staticSource.data.length) {
            this.staticSource.replace([])
        }
    }

    public reload() {
        (this.staticSource.data as any[]).length = 0
        this.reload$.next(null)
    }

    public copyCurrentForm() {
        const cform = this.currentForm
        this.initFormWith = {
            createMode: cform.isIncoming ? "incoming" : "outgoing",
            data: cform.form.value
        }
    }

    public resetFormInit() {
        this.initFormWith = {} as any
    }

    public reloadStory(story: Story) {
        this.reload$.next(story)
    }

    public startMoveStoryEntry(entry: StoryEntry) {
        this.move$.next(entry)
    }
}
