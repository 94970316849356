import { Component, Inject, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"


import { Destruct } from "@anzar/core"
import { SectionSheetService } from "./section-sheet.service"


@Component({
    templateUrl: "./section-sheet.screen.pug",
    providers: [SectionSheetService]
})
export class SectionSheetScreen implements OnDestroy, OnInit {
    public readonly destruct = new Destruct()

    public constructor(
        @Inject(SectionSheetService) public readonly sheet: SectionSheetService,
        @Inject(ActivatedRoute) private readonly route: ActivatedRoute) {
    }

    public ngOnInit() {
        this.destruct.subscription(this.route.queryParams).subscribe(params => {
            this.sheet.parentId = Number(params.parentId)
        })

        this.destruct.subscription(this.route.params).subscribe(params => {
            this.sheet.sectionId = Number(params["id"])
        })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}
