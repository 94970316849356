import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { of } from "rxjs"
import { map, switchMap } from "rxjs/operators"

import { Destructible, LayerService, DropdownLayer } from "@anzar/core"
import { SectionBackendSource, LevelBackend } from "@backend/org.api"
import { CurrentSection } from "../section.service/level"
import { StatsService } from "./stats.service"
import { AutoFillComponent, AUTO_FILL_DATA } from "./auto-fill.component"


@Component({
    templateUrl: "./stats.screen.pug",
    providers: [StatsService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatsScreen extends Destructible implements OnInit {
    public readonly sectionIdsControl = new FormControl()

    public providerTypes: string[] = []
    public providerKenysziTypes: string[] = []
    private autoFillData: any

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(SectionBackendSource) public readonly sectionSrc: SectionBackendSource,
        @Inject(StatsService) private readonly statsService: StatsService,
        @Inject(CurrentSection) private readonly section: CurrentSection,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(LevelBackend) private readonly levelBackend: LevelBackend) {
        super()
    }

    public ngOnInit() {
        this.destruct.subscription(this.statsService.providerTypes$).subscribe(v => {
            this.providerTypes = v
            this.cdr.markForCheck()
        })

        this.destruct.subscription(this.statsService.providerKenysziTypes$).subscribe(v => {
            this.providerKenysziTypes = v
            this.cdr.markForCheck()
        })

        this.destruct.subscription(this.sectionIdsControl.valueChanges).subscribe(value => {
            this.statsService.sectionIds = value
        })

        this.destruct.subscription(this.section.id$).subscribe(sectionId => {
            const val = this.sectionIdsControl.value
            if (!val || val.length === 0) {
                this.sectionIdsControl.setValue(sectionId)
            }
        })
    }

    public showAutoFill(event: Event) {
        const behavior = new DropdownLayer({
            backdrop: { type: "empty", hideOnClick: true },
            rounded: 3,
            elevation: 10,
            position: {
                anchor: {
                    ref: event.target as HTMLElement,
                    align: "bottom center"
                },
                align: "top center"
            }
        })
        const ref = this.layerSvc.createFromComponent(AutoFillComponent, behavior, null, [
            { provide: AUTO_FILL_DATA, useValue: this.autoFillData }
        ])
        ref.subscribe(event => {
            if (event.type === "fill") {
                this.autoFillData = event.data
                this._updateSectionIds()
                this.statsService.providerTypes = event.data.provider_types
            }
        })
        ref.show()
    }

    private _updateSectionIds() {
        if (this.autoFillData) {
            this.levelBackend
                .search(
                    { filter: { descendant_id: this.autoFillData.level_id, variant: "section" } },
                    { loadFields: ["id"] }
                )
                .pipe(
                    map(res => res.map(v => v.id)),
                    switchMap(sectionIds => {
                        const providerTypes = this.autoFillData.provider_types
                        if (providerTypes && providerTypes.length) {
                            return this.levelBackend
                                .search(
                                    { filter: { parent_id: { in: sectionIds }, variant: "provider", type: { in: providerTypes } } },
                                    { loadFields: ["parent_id"] }
                                )
                                .pipe(map(res => {
                                    return res.map(v => v.parent_id).filter((v, i, a) => a.indexOf(v) === i)
                                }))
                        } else {
                            return of(sectionIds)
                        }
                    })
                )
                .subscribe(sectionIds => {
                    this.sectionIdsControl.setValue(sectionIds)
                })
        }


    }
}
