import { NgModule } from "@angular/core"
import { CrisisCar, CrisisCarWorker, Story, StoryClients, StoryEntry, StoryMessage, StoryMessageMarks, PublicServiceType, RC_PublicService, RC_MedicalExam, RC_Housing, RC_Transport, CrisisCarBackend, StoryBackend, StoryEntryBackend, CrisisCarBackendSource, StoryBackendSource, StoryEntryBackendSource } from "./__anzar_rpc_output"

export { CrisisCar, CrisisCarWorker, Story, StoryClients, StoryEntry, StoryMessage, StoryMessageMarks, PublicServiceType, RC_PublicService, RC_MedicalExam, RC_Housing, RC_Transport, CrisisCarBackend, StoryBackend, StoryEntryBackend, CrisisCarBackendSource, StoryBackendSource, StoryEntryBackendSource }


@NgModule({
    providers: [
        CrisisCarBackendSource,
        StoryBackendSource,
        StoryEntryBackendSource,
        CrisisCarBackend,
        StoryBackend,
        StoryEntryBackend
    ],
})
export class DispatcherApi {}