import { Component, Inject, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"
import { take, map, tap, switchMap } from "rxjs/operators"

import { Destruct, LayerRef, StaticSource, ToastService, SyncHeightFromDirective } from "@anzar/core"
import { ProviderServices } from "../provider.service"
import { ProviderService, DutyBackend, Duty } from "@backend/org.api"
import { EventBackendSource, EventBackend } from "@backend/event.api"
import { PlaceBackend } from "@backend/place.api"
import { CurrentSection } from "../../section.service/level"
import { IS_FULLSCREEN, IS_SIDE_BY_SIDE } from "./_tokens"


@Component({
    selector: ".rege-add-common-event-dialog",
    templateUrl: "./add-common-event.component.pug",
    providers: [ProviderServices]
})
export class AddCommonEventComponent implements OnDestroy, OnInit {
    public readonly destruct = new Destruct()

    public servicesSrc: StaticSource<any> = new StaticSource(ProviderService as any, [])

    public set selectedService(val: ProviderService) {
        if (this._selectedService !== val) {
            this._selectedService = val
        }
    }
    public get selectedService(): ProviderService { return this._selectedService }
    public _selectedService: ProviderService
    public showFeedInSidebar: boolean = false
    public readonly selectService = new FormControl()

    public serviceData: any = {}
    public _providerIds: string

    public constructor(
        @Inject(EventBackendSource) public readonly eventSource: EventBackendSource,
        @Inject(ProviderServices) private readonly providerServices: ProviderServices,
        @Inject(EventBackend) private readonly eventBackend: EventBackend,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(Duty) public readonly duty: Duty,
        @Inject(ProviderService) public readonly providerService: ProviderService,
        @Inject(IS_SIDE_BY_SIDE) public readonly isSideBySide: boolean,
        @Inject(IS_FULLSCREEN) public readonly isFullscreen: boolean) {

    }

    public ngOnInit() {
        this.destruct.subscription(this.providerServices.services).pipe(take(1)).subscribe(services => {
            let src = services
                .map(v => v.services[0])
                .filter(s => s.is_active && s.service.without_client && s.provider_id === this.duty.provider_id)
            this.servicesSrc.replace(src)
        })
    }

    public onSelectService(service: ProviderService) {
        this.selectService.setValue(null)
        this.selectedService = service
    }

    public cancel() {
        this.layerRef.hide()
    }

    public save() {
        const serviceData = this.serviceData

        this.eventBackend
            .save_street_care({
                place_id: serviceData.place_id || null,
                place_extra: serviceData.place_extra || null,
                text: serviceData.description || null,
                duty_id: this.duty.id,
                event_id: null,
                provider_service_id: this.providerService.id,
                service_ids: [this.selectedService.id],
                begin: serviceData.begin_time ? serviceData.begin_time.toJSON() : null,
                end: serviceData.end_time ? serviceData.end_time.toJSON() : null,
                type: this.providerService.service.type.toLowerCase()
            })
            .pipe(
                take(1),
                this.toast.handleSave({ align: "bottom center" })
            )
            .subscribe(v => {
                if (v) {
                    this.layerRef.emit({ type: "save", data: v })
                    this.layerRef.hide()
                }
            })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}
