import { Component, Input, forwardRef, Inject, ChangeDetectorRef } from "@angular/core"

import { Global } from "@backend/org.api"
import { AbstractCLEditor, AbstractCLEditorService } from "@pyzar/common.module"
import { AuthService } from "@pyzar/auth.module"
import { GlobalEditorService } from "./global-editor.service"


@Component({
    selector: ".rege-global-editor",
    templateUrl: "./global-editor.component.pug",
    providers: [
        { provide: AbstractCLEditor, useExisting: forwardRef(() => GlobalEditorComponent) }
    ]
})
export class GlobalEditorComponent extends AbstractCLEditor {
    @Input() public global: Global

    public constructor(
        @Inject(AbstractCLEditorService) service: GlobalEditorService,
        @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
        @Inject(AuthService) public readonly auth: AuthService) {
        super(service)

        this.destruct.subscription(service.global$).subscribe(global => {
            this.global = global
            this.cdr.detectChanges()
        })
    }
}
