import { Component, forwardRef, Inject, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormControl, FormArray } from "@angular/forms"
import { Observable, of } from "rxjs"
import { take, tap } from "rxjs/operators"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { ServiceType, ServiceBackend, Service } from "@backend/service.api"


interface ServiceItem {
    id: number
    title: string
    type: string
}


@Component({
    selector: ".rege-service-settings-block",
    templateUrl: "./service-settings-block.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => ServiceSettingsBlockComponent) }
    ]
})
export class ServiceSettingsBlockComponent extends AbstractCLEditorBlock {
    public get serviceControls() { return (this.form.get("services") as FormArray).controls }

    public readonly typeSrc = ServiceType.DATA

    private _disableChange = false

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: AbstractCLEditorService,
        @Inject(ServiceBackend) private readonly serviceBackend: ServiceBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super(editorSvc, new FormGroup({
            services: new FormArray([])
        }))

        const ctrl = this.form.get("services") as FormArray
        this.destruct.subscription(ctrl.valueChanges).subscribe((values: ServiceItem[]) => {
            if (this._disableChange) {
                return
            }
            this._disableChange = true

            let hasEmpty = false
            for (const v of values) {
                if (!v.title || v.title.length === 0 || !v.type || v.type.length === 0) {
                    hasEmpty = true
                    break
                }
            }

            if (!hasEmpty) {
                ctrl.push(this._createRow())
            }

            ctrl.markAsTouched()
            ctrl.markAsPristine()
            ctrl.markAsDirty()
            ctrl.updateValueAndValidity()
            this._disableChange = false
        })
    }

    public ngOnInit() {
        super.ngOnInit()
        this._reload()
    }

    public save(): Observable<any> {
        return this.serviceBackend
            .update({ services: this.form.value.services })
            .pipe(
                take(1),
                tap(this._reload.bind(this))
            )
    }

    private _reload() {
        this.serviceBackend
            .search({
                order: { title: "asc" }
            })
            .pipe(take(1))
            .subscribe(services => {
                this._disableChange = true

                const ctrl = this.form.get("services") as FormArray
                ctrl.controls.length = 0

                for (const svc of services) {
                    ctrl.push(this._createRow(svc))
                }

                ctrl.push(this._createRow())
                this._disableChange = false

                this.cdr.detectChanges()
            })
    }

    private _createRow(svc?: Service) {
        return new FormGroup({
            id: new FormControl(svc ? svc.id : null),
            title: new FormControl(svc ? svc.title : null),
            type: new FormControl(svc ? svc.type : null),
            without_client: new FormControl(svc ? svc.without_client : false),
        })
    }
}
