import { Pipe, PipeTransform } from "@angular/core"


@Pipe({
    name: "TAJ"
})
export class TajPipe implements PipeTransform {
    public transform(value: string): string {
        if (typeof value === "string" && value.length) {
            return `${value.substr(0, 3)}-${value.substr(3, 3)}-${value.substr(6, 3)}`
        } else {
            return "-"
        }
    }
}
