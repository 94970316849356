import { Inject, Injectable } from "@angular/core"
import { Observable, Observer, NEVER } from "rxjs"
import { take, switchMap, map, shareReplay } from "rxjs/operators"

import { Destructible, Rect, LoadFields, LayerService, DialogService, ModalLayer, FullscreenLayer, LayerEvent, ToastService, MediaQueryService } from "@anzar/core"
import { ClientBackend, Client } from "@backend/client.api"

import { ClientSheet, INITIAL_TAB_INDEX, FULLSCREEN } from "./client-sheet.component"
import { CreateClientComponent } from "./create-client.component"


export const CLIENT_FIELDS: LoadFields<Client> = [
    "id", "name", "mothers_name", "birth_date", "birth_place", "created_time", "social_insurance_id", "manager_id", "idTevadminIgenybevevo", "profile_image_id",
    { manager: ["id", "name", "phone"] },
    { creator: ["id", "name"] }
]


@Injectable()
export class ClientService extends Destructible {
    public static readonly TAB_NEWS = 0
    public static readonly TAB_MARKS = 1
    public static readonly TAB_DOCUMENTS = 2
    public static readonly TAB_ACTIVITY = 3
    public static readonly TAB_BASIC_DATA = 4

    public isFullscreen$ = this.destruct.subscription(this.mq.watch("lt-md")).pipe(
        // map(v => __PLATFORM__ !== "browser" && v.matches),
        map(v => v.matches),
        shareReplay(1)
    )

    public constructor(
        @Inject(LayerService) public readonly layerSvc: LayerService,
        @Inject(DialogService) public readonly dialogSvc: DialogService,
        @Inject(ClientBackend) public readonly clients: ClientBackend,
        @Inject(ToastService) public readonly toastSvc: ToastService,
        @Inject(MediaQueryService) public readonly mq: MediaQueryService) {
        super()
    }

    public showSheet(clientId: number, tabIndex: number = 0): Observable<LayerEvent<any>> {
        const rect = Rect.viewport()
        return this.clients.get({ id: clientId }, { loadFields: CLIENT_FIELDS })
            .pipe(
                take(1),
                this.toastSvc.catchError(),
                switchMap(client => {
                    return this.isFullscreen$.pipe(take(1), map(isFullscreen => [isFullscreen, client] as [boolean, Client]))
                }),
                switchMap(([isFullscreen, client]) => {
                    if (client) {
                        const behavior = isFullscreen
                            ? new FullscreenLayer({})
                            : new ModalLayer({
                                elevation: 20,
                                rounded: 3,
                                backdrop: { type: "filled", hideOnClick: false },
                                minHeight: rect.height * 0.9
                            })
                        const ref = this.layerSvc.createFromComponent(ClientSheet, behavior, null, [
                            { provide: Client, useValue: client },
                            { provide: INITIAL_TAB_INDEX, useValue: tabIndex },
                            { provide: FULLSCREEN, useValue: isFullscreen },
                        ])
                        ref.show()
                        return ref.output
                    } else {
                        this.dialogSvc.error("Hiba!", "Nem lehet betölteni a kívánt ügyfelet.")
                        return NEVER
                    }
                })
            )
    }

    public createClient(showLoadButton: boolean, client?: Client): Observable<Client | null> {
        return new Observable((observer: Observer<Client>) => {
            const rect = Rect.viewport()
            const ref = this.layerSvc.createFromComponent(
                CreateClientComponent,
                new ModalLayer({
                    elevation: 20,
                    rounded: 3,
                    backdrop: { type: "filled", hideOnClick: false },
                    // minHeight: rect.height * 0.9,
                    position: {
                        constraint: {
                            ref: "viewport",
                            inset: 16
                        }
                    }
                }),
                null,
                [
                    { provide: Client, useValue: client }
                ])

            const s = ref.subscribe(event => {
                if (event.type === "client-saved") {
                    observer.next(event.data as Client)
                    observer.complete()
                } else if (event.type === "destroy") {
                    observer.next(null)
                    observer.complete()
                }
            })

            ref.show()
            ref.component.instance.showLoadButton = !client && showLoadButton

            return () => {
                s.unsubscribe()
                if (ref.isVisible) {
                    ref.hide()
                }
            }
        })
    }
}
