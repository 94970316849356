<ng-template nzViewportItem="topLeft:20"><div nzLayout="column" nzLayoutAlign="start stretch"><h1 class="a">MMSZ Instrumentum Kft.</h1><div class="b c d a">Szolgáltatói információs oldal a Rege életútkövető programról</div></div></ng-template><ng-container nzLayout="column" nzLayoutAlign="start stretch"><div class="nz-scroller" nzFlex="grow"><div scrollable><div class="io" nzLayout="column" nzLayoutAlign="start center" nzLayoutGap="48px"><div class="by"><svg viewBox="0 0 347 367"
    xmlns="http://www.w3.org/2000/svg">
    <!-- ROOF -->
    <g >
        <line stroke-linecap="round" stroke-width="10" stroke="#e53935" x1="173" y1="5" x2="5" y2="104" />
        <line stroke-linecap="round" stroke-width="10" stroke="#e53935" x1="173" y1="5" x2="342" y2="104" />
        <line stroke-linecap="butt" stroke-width="10" stroke="#e53935" x1="50" y1="79" x2="50" y2="113" />
        <line stroke-linecap="butt" stroke-width="10" stroke="#e53935" x1="295" y1="79" x2="295" y2="113" />
    </g>

    <!-- REGE TEXT -->
    <g stroke-linecap="round" fill-rule="evenodd" fill="#000000" transform="translate(45,124)">
        <path d="M 53.788 79.666 L 41.471 79.666 L 22.713 47.461 L 10.283 47.461 L 10.283 79.666 L 0 79.666 L 0 0.905 L 25.425 0.905 Q 34.352 0.905 40.511 3.956 A 24.044 24.044 0 0 1 45.747 7.416 A 19.478 19.478 0 0 1 49.72 12.205 A 22.275 22.275 0 0 1 52.664 21.622 A 27.459 27.459 0 0 1 52.771 24.07 Q 52.771 32.206 48.082 38.421 A 20.368 20.368 0 0 1 40.209 44.641 A 29.699 29.699 0 0 1 34.013 46.67 L 53.788 79.666 Z M 10.283 9.38 L 10.283 39.212 L 25.425 39.212 Q 33.787 39.212 37.968 35.088 A 13.779 13.779 0 0 0 41.879 27.392 A 19.643 19.643 0 0 0 42.149 24.07 A 19.058 19.058 0 0 0 41.662 19.628 A 12.506 12.506 0 0 0 38.025 13.222 A 12.764 12.764 0 0 0 33.488 10.562 Q 31.544 9.879 29.153 9.591 A 31.197 31.197 0 0 0 25.425 9.38 L 10.283 9.38 Z" vector-effect="non-scaling-stroke"></path>
        <path d="M 111.079 9.267 L 78.987 9.267 L 78.987 35.596 L 107.689 35.596 L 107.689 44.071 L 78.987 44.071 L 78.987 71.191 L 111.079 71.191 L 111.079 79.666 L 68.704 79.666 L 68.704 0.792 L 111.079 0.792 L 111.079 9.267 Z" vector-effect="non-scaling-stroke"></path>
        <path d="M 198.654 23.731 L 186.337 23.731 Q 183.06 16.838 176.845 13.053 A 25.908 25.908 0 0 0 166.378 9.504 A 32.924 32.924 0 0 0 162.381 9.267 A 30.891 30.891 0 0 0 152.71 10.746 A 27.864 27.864 0 0 0 147.522 13.053 A 26.729 26.729 0 0 0 137.155 23.846 A 31.724 31.724 0 0 0 137.126 23.901 A 31.737 31.737 0 0 0 133.71 34.653 A 40.794 40.794 0 0 0 133.34 40.229 Q 133.34 49.495 137.126 56.501 Q 140.911 63.507 147.522 67.293 A 28.841 28.841 0 0 0 160.344 71.019 A 34.679 34.679 0 0 0 162.381 71.078 A 31.553 31.553 0 0 0 171.382 69.851 A 24.966 24.966 0 0 0 181.365 64.185 Q 188.823 57.292 190.066 45.54 L 158.652 45.54 L 158.652 37.178 L 201.027 37.178 L 201.027 45.088 Q 200.123 54.806 194.925 62.886 Q 189.727 70.965 181.252 75.655 A 37.846 37.846 0 0 1 164.313 80.303 A 44.941 44.941 0 0 1 162.381 80.344 A 40.302 40.302 0 0 1 145.985 77.015 A 39.125 39.125 0 0 1 142.38 75.203 Q 133.34 70.061 128.086 60.908 A 39.817 39.817 0 0 1 122.959 43.773 A 48.273 48.273 0 0 1 122.831 40.229 A 43.389 43.389 0 0 1 124.833 26.884 A 38.845 38.845 0 0 1 128.086 19.494 Q 133.34 10.284 142.38 5.143 A 39.674 39.674 0 0 1 162.039 0.003 A 46.546 46.546 0 0 1 162.381 0.001 A 43.06 43.06 0 0 1 175.58 1.968 A 37.971 37.971 0 0 1 184.586 6.216 A 35.469 35.469 0 0 1 198.48 23.292 A 43.338 43.338 0 0 1 198.654 23.731 Z" vector-effect="non-scaling-stroke"></path>
        <path d="M 256.962 9.267 L 224.87 9.267 L 224.87 35.596 L 253.572 35.596 L 253.572 44.071 L 224.87 44.071 L 224.87 71.191 L 256.962 71.191 L 256.962 79.666 L 214.587 79.666 L 214.587 0.792 L 256.962 0.792 L 256.962 9.267 Z" vector-effect="non-scaling-stroke"></path>
    </g>


    <!-- COLUMNS -->
    <g>
        <line stroke-linecap="butt" stroke-width="10" stroke="#000" x1="50" y1="215" x2="50" y2="364"></line>
        <line stroke-linecap="butt" stroke-width="10" stroke="#000" x1="295" y1="215" x2="295" y2="364"></line>
    </g>

    <!-- FLOOR -->
    <line stroke-linecap="round" stroke-width="10" stroke="#000" x1="5" y1="360" x2="342" y2="360"></line>

    <!-- HORIZONTAL LINES -->
    <line stroke-linecap="round" stroke-width="10" stroke="#000" x1="26" y1="236" x2="318" y2="236"></line>
    <line stroke-linecap="round" stroke-width="10" stroke="#000" x1="26" y1="255" x2="318" y2="255"></line>
    <line stroke-linecap="round" stroke-width="10" stroke="#000" x1="26" y1="274" x2="318" y2="274"></line>
    <line stroke-linecap="round" stroke-width="10" stroke="#000" x1="26" y1="305" x2="318" y2="305"></line>
</svg>
</div><div class="at ip av be iq" nzLayout="column" nzLayoutAlign="start stretch"><h5>Letölthető dokumentumok</h5><a class="nz-link" href="/assets/connect/Általános Tájékoztató.pdf" color="accent">Általános Tájékoztató</a><a class="nz-link" href="/assets/connect/Díjtáblázat.pdf" color="accent">Díjtáblázat</a><a class="nz-link" href="/assets/connect/Általános szerződési feltételek.pdf" color="accent">Általános szerződési feltételek</a><a class="nz-link" href="/assets/connect/Általános szerződési feltételek a Rege Életútkövető Programmal összefüggő közös adatkezelésről.pdf" color="accent">Általános szerződési feltételek a Rege Életútkövető Programmal összefüggő közös adatkezelésről</a><a class="nz-link" href="/assets/connect/Adatkezelési tájékoztató.pdf" color="accent">Adatkezelési tájékoztató</a><h5>Csatlakozás a programhoz</h5><p class="ir">Kérjük, add meg az e-mail címed, és utána kattints a <b>Csatlakozás</b> gombra. Az ezután megnyíló adatszolgáltató oldalon bekérjük valamennyi, a csatlakozáshoz szükséges fenntartói és intézményi adatot, így a sikeres kitöltés után már csak le kell töltened a kész csatlakozási dokumentumokat a nyomtatáshoz és aláíráshoz.<br><br>A megadott e-mai címre elküldjük a személyes adatszolgáltató oldalad közvetlen linkjét, hogy ha nem sikerül egy menetben befejezni a kitöltést, ott folytathasd, ahol abbahagytad.</p><div class="nz-form-field"><label>Email cím</label><input class="nz-input" [formControl]="email" required></div><div nzFlex="none" nzLayout="row" nzLayoutAlign="center center"><button class="nz-button" color="confirm" variant="filled" (tap)="doConnect()" [disabled]="busy | async">CSATLAKOZÁS</button></div></div></div></div></div></ng-container>