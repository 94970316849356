import { Component, forwardRef, Inject, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormArray, FormControl } from "@angular/forms"
import { forkJoin } from "rxjs"
import { tap } from "rxjs/operators"

import { ProviderDuty, ProviderVehicle, ProviderDutyBackend, ProviderVehicleBackend, ProviderBackend } from "@backend/org.api"

import { ProviderEditorService } from "../provider/provider-editor.service"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"


@Component({
    selector: ".rege-provider-duty-editor",
    templateUrl: "./duty-editor.component.pug",
    host: {
        "[style.padding.px]": "16"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => DutyEditorComponent) }
    ]
})
export class DutyEditorComponent extends AbstractCLEditorBlock<ProviderEditorService> {
    public get dutyControls() { return (this.form.get("duties") as FormArray).controls }
    public get vehicleControls() { return (this.form.get("vehicles") as FormArray).controls }

    public isStreet: boolean = this.editorSvc.isStreet
    public isCommunal: boolean = this.editorSvc.isCommunal
    public isDispatcher: boolean = this.editorSvc.isDispatcher
    public providerId: number = this.editorSvc.providerId

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: ProviderEditorService,
        @Inject(ProviderBackend) private readonly providerBackend: ProviderBackend,
        @Inject(ProviderDutyBackend) private readonly dutyBackend: ProviderDutyBackend,
        @Inject(ProviderVehicleBackend) private readonly vehicleBackend: ProviderVehicleBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super(editorSvc, new FormGroup({
            duties: new FormArray([]),
            vehicles: new FormArray([]),
        }))

        this.destruct.subscription(editorSvc.changes).subscribe(changes => {
            this.isStreet = editorSvc.isStreet
            this.isDispatcher = editorSvc.isDispatcher
            this.isCommunal = editorSvc.isCommunal

            if (this.providerId !== editorSvc.providerId) {
                this.providerId = editorSvc.providerId
                this.reload()
            }

            this.cdr.detectChanges()
        })
    }

    public ngOnInit() {
        super.ngOnInit()
        if (this.providerId) {
            this.reload()
        }
    }

    public reload() {
        this.form.reset()

        const dutiesCtrl = this.form.get("duties") as FormArray
        const vehiclesCtrl = this.form.get("vehicles") as FormArray

        dutiesCtrl.controls.length = 0
        vehiclesCtrl.controls.length = 0

        if (this.providerId) {
            this.dutyBackend.search({ filter: { provider_id: this.providerId }, order: { begin: "asc" } })
                .subscribe(duties => {
                    for (const duty of duties) {
                        dutiesCtrl.push(this._createDutyGroup(duty))
                    }
                    if (duties.length === 0) {
                        dutiesCtrl.push(this._createDutyGroup())
                    }
                })

            this.vehicleBackend.search({ filter: { provider_id: this.providerId }, order: { name: "asc" } })
                .subscribe(vehicles => {
                    for (const vehicle of vehicles) {
                        vehiclesCtrl.push(this._createVehicleGroup(vehicle))
                    }
                })
        }
    }

    public save() {
        const data = this.form.value

        return forkJoin(
            this.providerBackend.set_duties({ provider_id: this.providerId, duties: data.duties }),
            this.providerBackend.set_vehicles({ provider_id: this.providerId, vehicles: data.vehicles }),
        ).pipe(tap(() => {
            this.reload()
        }))
    }

    public addDuty() {
        const dutiesCtrl = this.form.get("duties") as FormArray
        dutiesCtrl.push(this._createDutyGroup())
        dutiesCtrl.markAsPristine()
    }

    public delDuty(index: number) {
        const dutiesCtrl = this.form.get("duties") as FormArray
        dutiesCtrl.removeAt(index)
        dutiesCtrl.markAsPristine()
    }

    public addVehicle() {
        const vehiclesCtrl = this.form.get("vehicles") as FormArray
        vehiclesCtrl.push(this._createVehicleGroup())
        vehiclesCtrl.markAsPristine()
    }

    public delVehicle(index: number) {
        const vehiclesCtrl = this.form.get("vehicles") as FormArray
        vehiclesCtrl.removeAt(index)
        vehiclesCtrl.markAsPristine()
    }

    private _createDutyGroup(existing?: ProviderDuty) {
        return new FormGroup({
            id: new FormControl(existing ? existing.id : null),
            begin: new FormControl(existing ? existing.begin : null),
            end: new FormControl(existing ? existing.end : null),
        })
    }

    private _createVehicleGroup(existing?: ProviderVehicle) {
        return new FormGroup({
            id: new FormControl(existing ? existing.id : null),
            name: new FormControl(existing ? existing.name : null),
            is_active: new FormControl(existing ? existing.is_active : true),
        })
    }
}
