import { Injectable, Inject } from "@angular/core"
import { Observable, of } from "rxjs"
import { map } from "rxjs/operators"


import { DatePickerDayDataProvider, ExternalDayData, DayData } from "@anzar/core"
import { ResourceBackend } from "@backend/service_schedule.api"


@Injectable()
export class DatePickedScheduleFreeCountDataProvider extends DatePickerDayDataProvider {
    public serviceId: number

    public constructor(@Inject(ResourceBackend) private readonly backend: ResourceBackend) {
        super()
    }

    public extraData(begin: Date, end: Date): Observable<ExternalDayData> {
        return this.backend.get_calendar_data({ begin, end, provider_service_id: this.serviceId }).pipe(
            map(result => {
                const data: ExternalDayData = {}

                for (const day in result) {
                    data[Number(day)] = {
                        data: result[day],
                        disabled: result[day] == null,
                    }
                }

                return data
            })
        )
    }
}
