import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { Route, RouterModule } from "@angular/router"

import { NzModule } from "@anzar/core"

import { ConnectApi } from "@backend/connect.api"

import { ComponentsModule } from "../components"
import { ConnectFormComponent } from "./connect-form.component"
import { ConnectInfoComponent } from "./connect-info.component"

export const CONNECT_ROUTES: Route[] = [
    { path: "", component: ConnectInfoComponent },
    { path: ":token", component: ConnectFormComponent }
]

@NgModule({
    imports: [CommonModule, RouterModule, NzModule, ComponentsModule, ConnectApi],
    declarations: [ConnectInfoComponent, ConnectFormComponent],
    exports: [ConnectInfoComponent, ConnectFormComponent]
})
export class ConnectModule {}
