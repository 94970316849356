import { Component, Input, Inject, OnInit, ChangeDetectorRef } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Subject, merge, zip, of, EMPTY, Observable } from "rxjs"
import { filter, map, shareReplay, switchMap, tap, debounceTime, take, catchError, materialize, mapTo } from "rxjs/operators"
import { isWithinInterval, compareDesc, subDays } from "date-fns"

import { Destructible, LoadFields, ToastService, ModalLayer, LayerService } from "@anzar/core"
import { BNO, BNOBackend, BNOReservationBackend, BNOReservation } from "@backend/service_bno.api"
import { UsableService, ProviderServices } from "../../../service.module/provider.service"
import { ServiceUsage, DutyService } from "../../../duty.module"
import { ClientService } from "../../../client.module"
import { CurrentSection } from "../../../section.service/level"
import { BNOConflictFialogComponent, OVERLAPPING_RESERVATIONS, RESERVING_CLIENT_ID, RESERVING_ENTRY } from "./conflict-dialog.component"


const BNO_FIELDS: LoadFields<BNO> = [
    "id", "title", "provider_service_id"
]

const BNO_RESV_FIELDS: LoadFields<BNOReservation> = [
    "id", "begin", "end", "bno_id",
    { client: ["id", "name"] }
]


interface BNOStat {
    total: number
}


export interface BNOEntry {
    clientId: FormControl
    begin: FormControl
    reservation: BNOReservation
    bno: BNO
}


@Component({
    selector: ".rege-bno-service-editor",
    templateUrl: "./bno.component.pug"
})
export class BNOEditorComponent extends Destructible implements OnInit {
    @Input()
    public set service(val: UsableService) {
        if (this._service !== val) {
            this._service = val
            this.serviceChange.next(val)
        }
    }
    public get service(): UsableService { return this._service }
    private _service: UsableService

    @Input()
    public set usage(val: ServiceUsage) {
        if (this._usage !== val) {
            this._usage = val
        }
    }
    public get usage(): ServiceUsage { return this._usage }
    private _usage: ServiceUsage

    public set date(val: Date) {
        if (this._date !== val) {
            this._date = val
            this.dateChange.next(val)
        }
    }
    public get date(): Date { return this._date }
    private _date: Date

    public set selectedBNO(val: BNO) {
        if (this._selectedBNO !== val) {
            this._selectedBNO = val
            this.bnoChange.next(val)
        }
    }
    public get selectedBNO(): BNO { return this._selectedBNO }
    private _selectedBNO: BNO

    public bnoStats: { [key: number]: BNOStat } = {}
    public readonly serviceChange = this.destruct.subject(new Subject<UsableService>())
    public readonly bnoChange = new Subject<BNO>()
    public readonly dateChange = new Subject<Date>()

    private readonly _forceReload = new Subject<void>()
    public readonly _reload = this.dutyService.onUsageChange.pipe(
        filter(evt => {
            return evt.providerService.service.type === "bno"
        })
    )

    public readonly providerServiceIds = this.destruct.subscription(this.serviceChange).pipe(
        map(service => {
            if (service) {
                return service.services
                    .map(s => s.id)
                    .filter((v, i, a) => a.indexOf(v) === i)
            } else {
                return []
            }
        }),
        shareReplay(1)
    )

    public readonly bnos$ = this.destruct.subscription(this.providerServiceIds).pipe(
        switchMap(ids => {
            return this.bnoBackend.search({
                filter: { provider_service_id: { in: ids }, is_active: true },
                order: { title: "asc" }
            }, { loadFields: BNO_FIELDS })
        }),
        tap(bnos => {
            this.bnoStats = {}
            for (const r of bnos) {
                this.bnoStats[r.id] = { total: 0 }
            }
            this.selectedBNO = bnos[0]
        }),
        shareReplay(1)
    )

    public readonly reservations$ = merge(
        this.bnos$, this.dateChange, this.serviceChange, this._forceReload, this._reload
    ).pipe(
        debounceTime(10),
        switchMap(_ => this.providerServiceIds),
        switchMap(providerServiceIds => {
            if (this._selectedBNO && this._date) {
                return this.bnoResvBackend.search({
                    filter: {
                        "bno.provider_service_id": { in: providerServiceIds },
                        "date": this._date
                    }
                }, { loadFields: BNO_RESV_FIELDS })
            } else {
                return of([] as BNOReservation[])
            }
        }),
        tap(reservations => {
            for (const k in this.bnoStats) {
                this.bnoStats[k].total = 0
            }

            for (const r of reservations) {
                this.bnoStats[r.bno_id].total += 1
            }
        }),
        shareReplay(1)
    )

    public readonly entries$ = merge(this.reservations$, this.bnoChange).pipe(
        debounceTime(10),
        switchMap(_ => this.reservations$.pipe(take(1))),
        map(reservations => {
            const bno = this._selectedBNO
            return reservations
                .filter(resv => resv.bno_id === bno.id)
                .map(resv => {
                    return {
                        clientId: new FormControl(resv.client.id),
                        begin: new FormControl(resv.begin),
                        reservation: resv,
                        bno: bno
                    } as BNOEntry
                })
        }),
        shareReplay(1)
    )

    public entries: BNOEntry[] = []

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(DutyService) private readonly dutyService: DutyService,
        @Inject(BNOBackend) private readonly bnoBackend: BNOBackend,
        @Inject(BNOReservationBackend) private readonly bnoResvBackend: BNOReservationBackend,
        @Inject(ProviderServices) private readonly providerServices: ProviderServices,
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(CurrentSection) public readonly section: CurrentSection) {
        super()

        this.entries$.subscribe(entries => {
            this.entries = entries.slice(0)
            this.entries.push({
                clientId: new FormControl(this.usage ? this.usage.client.id : null),
                begin: new FormControl(this.date),
                bno: this._selectedBNO
            } as any)
            this.cdr.detectChanges()
        })


        this.date = dutyService.date
    }

    public ngOnInit() {
        // this.destruct.subscription(this.entries$).subscribe(entries => {
        //     console.log("entries", entries)
        //     this.entries = entries.slice(0)
        //     this.entries.push({} as any)
        // })
    }

    public onDateChange(date: Date) {
        this.date = date
    }

    public reserve(entry: BNOEntry) {
        let clientId = entry.clientId.value
        let usage = this.usage
            ? this.usage
            : this.dutyService.usage.data.find(u => u.client.id === clientId) as ServiceUsage

        this.bnoResvBackend
            .reserve({
                client_id: clientId,
                bno_id: entry.bno.id,
                begin: entry.begin.value,
                end: null,
            })
            .pipe(
                take(1),
                catchError(err => {
                    switch (err.data.reason) {
                        case "overlaps":
                            this.showConflictDialog(clientId, entry, err.data.overlapping)
                                .pipe(take(1))
                                .subscribe(() => {
                                    this.reserve(entry)
                                })
                            break

                        default:
                            this.toastSvc.error(err.message)
                            break
                    }
                    return EMPTY
                }),
                switchMap(result => {
                    if (usage && result && this._dateInRange(this.dutyService.date, result.begin, result.end)) {
                        let pservice = this.providerServices.getProviderServiceById(entry.bno.provider_service_id)
                        if (pservice) {
                            return this.dutyService.setUsage(usage, pservice, true, false).pipe(
                                materialize(),
                                take(1),
                                mapTo(result)
                            )
                        }
                    }
                    return of(result)
                })
            )
            .subscribe(res => {
                this._forceReload.next()
            })
    }

    public free(entry: BNOEntry, openClientSheet: boolean) {
        this.bnoResvBackend
            .close_resv({
                bno_reservation_id: entry.reservation.id,
                client_id: entry.clientId.value,
                end: subDays(this.date, 1)
            })
            .pipe(take(1))
            .subscribe(_ => {
                if (openClientSheet) {
                    this.clientSvc.showSheet(entry.clientId.value).subscribe()
                }
            })
        this._forceReload.next()
    }

    protected showConflictDialog(clientId: number, entry: BNOEntry, overlapping: any): Observable<any> {
        let behavior = new ModalLayer({
            rounded: 3,
            elevation: 10
        })
        let layerRef = this.layerSvc.createFromComponent(BNOConflictFialogComponent, behavior, null, [
            { provide: OVERLAPPING_RESERVATIONS, useValue: overlapping },
            { provide: RESERVING_ENTRY, useValue: entry },
            { provide: RESERVING_CLIENT_ID, useValue: clientId },
        ])
        layerRef.show()

        return layerRef.output.pipe(filter(x => x.type === "resolved"))
    }

    private _dateInRange(date: Date, start: Date, end?: Date): boolean {
        if (end) {
            return isWithinInterval(date, { start, end })
        } else {
            return compareDesc(start, date) >= 0
        }
    }

    public showClient(clientId: number) {
        this.clientSvc.showSheet(clientId).subscribe()
    }
}
