import { NgModule } from "@angular/core"
import { Template, ClientBan, Contract, Fill, Agreement, Document, DocumentGroup, AgreementACQ, TemplateBackend, ClientBanBackend, ContractBackend, FillBackend, AgreementBackend, DocumentBackend, DocumentGroupBackend, DocumentBackendSource, DocumentGroupBackendSource, TemplateBackendSource } from "./__anzar_rpc_output"

export { Template, ClientBan, Contract, Fill, Agreement, Document, DocumentGroup, AgreementACQ, TemplateBackend, ClientBanBackend, ContractBackend, FillBackend, AgreementBackend, DocumentBackend, DocumentGroupBackend, DocumentBackendSource, DocumentGroupBackendSource, TemplateBackendSource }


@NgModule({
    providers: [
        DocumentBackendSource,
        DocumentGroupBackendSource,
        TemplateBackendSource,
        AgreementBackend,
        ClientBanBackend,
        ContractBackend,
        DocumentBackend,
        DocumentGroupBackend,
        FillBackend,
        TemplateBackend
    ],
})
export class DocumentsApi {}