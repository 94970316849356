import { Component, Inject, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from "@angular/core"

import { Destruct, LoadFields } from "@anzar/core"
import { NotificationAssignBackendSource, NotificationAssign } from "@backend/notification.api"
import { CaseTransfer } from "@backend/client.api"
import { Event } from "@backend/event.api"
import { AuthService } from "@pyzar/auth.module"


const NOTIFICATION_FIELDS: LoadFields<NotificationAssign> = [
    "seen_time", "processed_time", "auto_process", "user_id", "notification_id",
    {
        notification: [
            "id",
            "type",
            "type_value",
            {
                case_transfer: [
                    "state", "id", "type",
                    { client: ["id", "name"] },
                    { from_user: ["id", "name"] },
                    { to_user: ["id", "name"] },
                ] as LoadFields<CaseTransfer>
            } as any,
            {
                event: [
                    { richtext: ["text", "content"] as any },
                    { client: ["id", "name"] },
                    { section: ["id", "title"] }
                ] as LoadFields<Event>
            } as any
        ]
    }
]

@Component({
    selector: "rege-notification-popup",
    templateUrl: "./notification-popup.component.pug"
})
export class NotificationPopupComponent implements OnDestroy, OnInit {
    public readonly destruct = new Destruct()

    public fields = NOTIFICATION_FIELDS
    public userId: number

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(NotificationAssignBackendSource) public readonly source: NotificationAssignBackendSource,
        @Inject(AuthService) public readonly authSvc: AuthService) {
    }

    public ngOnInit() {
        this.destruct.subscription(this.authSvc.currentUser$).subscribe(user => {
            let userId = user ? user.id : null
            if (this.userId !== userId) {
                this.userId = userId
                this.cdr.detectChanges()
            }
        })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}
