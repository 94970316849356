import { Component, Inject, Input, OnDestroy, OnInit, ChangeDetectorRef, Optional } from "@angular/core"
import { FormControl } from "@angular/forms"
import { coerceBooleanProperty } from "@angular/cdk/coercion"
import { Subject, merge, zip, of, NEVER, EMPTY } from "rxjs"
import { shareReplay, switchMap, debounceTime, tap, map, catchError, mapTo, take } from "rxjs/operators"
import { isSameDay, isSameWeek, startOfWeek, compareDesc, compareAsc, differenceInDays, startOfDay, addMinutes, format } from "date-fns"


import { FileDownloadService, StackItemRef, LoadFields, ToastService, Destruct, Time, DatePickerDayDataProvider } from "@anzar/core"
import { WeekDay } from "@backend/enums.api"
import { Resource, ResourceOpen, ResourceBackend, ResourceReservation, ResourceReservationBackend } from "@backend/service_schedule.api"
import { CurrentSection } from "../../../section.service/level"
import { UsableService, ProviderServices } from "../../provider.service"
import { ServiceUsage, DutyService } from "../../../duty.module"
import { ClientService } from "../../../client.module"
import { DatePickedScheduleFreeCountDataProvider } from "./datepicker-data-provider.service"

declare const BACKEND_URL: string

const RESOURCE_FIELDS: LoadFields<Resource> = [
    "id", "provider_service_id", "title"
]


const RESERVATION_FIELDS: LoadFields<ResourceReservation> = [
    "id", "resource_id", "begin", "end", "client_id",
    {
        client: ["id", "name"]
    }
]


class ListEntry {
    public readonly clientId = new FormControl()

    public constructor(
        public readonly resource: Resource,
        public readonly begin: Date,
        public readonly end: Date,
        public readonly reservation: ResourceReservation
    ) {
        if (reservation) {
            this.clientId.setValue(reservation.client_id)
        }
    }
}


interface OpeningHour {
    begin: Date,
    end: Date
}


@Component({
    selector: ".rege-schedule-service-editor",
    templateUrl: "./schedule.component.pug",
    providers: [
        { provide: DatePickerDayDataProvider, useClass: DatePickedScheduleFreeCountDataProvider }
    ]
})
export class ScheduleEditorComponent implements OnDestroy, OnInit {
    public readonly destruct = new Destruct()

    public set date(val: Date) {
        if (!val || !this._date || !isSameDay(this._date, val)) {
            let wchange = !val || !this._date || !isSameWeek(this._date, val, { weekStartsOn: 1 })
            this._date = val
            this._dateChange.next(val)
            if (wchange) {
                this._weekChange.next(startOfWeek(val, { weekStartsOn: 1 }))
            }
            (this as any).isPast = compareDesc(startOfDay(val), startOfDay(new Date())) > 0;
            (this as any).isFuture = compareDesc(startOfDay(val), startOfDay(new Date())) < 0
        }
    }
    public get date(): Date { return this._date }
    private _date: Date
    private _dateChange = this.destruct.subject(new Subject<Date>())
    private _weekChange = this.destruct.subject(new Subject<Date>())
    public readonly date$ = this._dateChange.pipe(shareReplay(1))
    public readonly week$ = this._weekChange.pipe(shareReplay(1))
    public readonly isPast: boolean
    public readonly isFuture: boolean

    public set selectedResource(val: Resource) {
        if (this._selectedResource !== val) {
            this._selectedResource = val
            this._resourceChange.next(val)
        }
    }
    public get selectedResource(): Resource { return this._selectedResource }
    private _selectedResource: Resource
    private _resourceChange = this.destruct.subject(new Subject<Resource>())
    public readonly selectedResource$ = this._resourceChange.pipe(shareReplay(1))

    public set reserving(val: ListEntry) {
        if (this._reserving !== val) {
            this._reserving = val

            if (val) {
                if (this.usage) {
                    val.clientId.setValue(this.usage.client.id)
                } else {
                    val.clientId.setValue(null)
                }
            }

            this.cdr.detectChanges()
        }
    }
    public get reserving(): ListEntry { return this._reserving }
    private _reserving: ListEntry

    @Input()
    public set service(val: UsableService) {
        if (this._service !== val) {
            this._service = val
            this.dpFreeProvider.serviceId = val.services[0].id
            this._serviceChange.next(val)
        }
    }
    public get service(): UsableService { return this._service }
    private _service: UsableService
    private _serviceChange = this.destruct.subject(new Subject<UsableService>())
    public readonly service$ = this._serviceChange.pipe(shareReplay(1))

    @Input()
    public set usage(val: ServiceUsage) {
        if (this._usage !== val) {
            this._usage = val
        }
    }
    public get usage(): ServiceUsage { return this._usage }
    private _usage: ServiceUsage

    @Input()
    public set allowFuture(val: boolean) {
        this._allowFuture = coerceBooleanProperty(val)
    }
    public get allowFuture(): boolean { return this._allowFuture }
    public _allowFuture: boolean = false

    private _reload = new Subject<void>()

    public readonly resources$ = this.service$.pipe(
        switchMap(svc => {
            let ids = svc.services.map(s => s.id)
            return this.resourceBackend.search({
                filter: { provider_service_id: { in: ids }, is_active: true },
                order: { title: "asc" },
                begin: 0,
                count: 100
            }, { loadFields: RESOURCE_FIELDS })
        }),
        tap(resources => {
            this.selectedResource = resources[0]

            for (const res of resources) {
                this.resourceStat[res.id] = { reserved: 0, total: 0 }
            }
        }),
        shareReplay(1)
    )


    public readonly resourceOpen$ = merge(this.resources$, this.week$).pipe(
        debounceTime(10),
        switchMap(v => zip(this.resources$, this.week$).pipe(take(1))),
        switchMap(v => {
            const [resources, week] = v
            let ids = resources.map(r => r.id)
            return this.resourceBackend.get_opening_hours({ resource_ids: ids, week_start: week })
        }),
        shareReplay(1)
    )

    public readonly openingHours$ = merge(this.resourceOpen$, this.date$).pipe(
        switchMap(v => zip(this.resourceOpen$, this.date$)),
        map(v => {
            const [opens, date] = v
            for (const k in this.resourceStat) {
                const stat = this.resourceStat[k]
                stat.total = 0
            }

            return opens.map(open => {
                const hours = this._createOpeningHours(date, open)
                const stat = this.resourceStat[open.resource_id]
                stat.total = hours.length
                return {
                    resource_id: open.resource_id,
                    hours: hours
                }
            })
        }),
        shareReplay(1)
    )

    public readonly reservations$ = merge(this.resources$, this.date$, this._reload).pipe(
        debounceTime(10),
        switchMap(v => zip(this.resources$, this.date$).pipe(take(1))),
        switchMap(v => {
            const [resources, date] = v
            let ids = resources.map(r => r.id)
            return this.resvBackend.search({
                filter: { resource_id: { in: ids }, date: date },
                order: null,
                begin: 0,
                count: 100
            }, { loadFields: RESERVATION_FIELDS })
        }),
        tap(reservations => {
            for (const k in this.resourceStat) {
                const stat = this.resourceStat[k]
                if (stat) {
                    stat.reserved = 0
                }
            }

            for (const res of reservations) {
                const stat = this.resourceStat[res.resource_id]
                if (stat) {
                    stat.reserved++
                }
            }
        }),
        shareReplay(1)
    )

    public readonly entries$ = merge(this.selectedResource$, this.openingHours$, this.reservations$).pipe(
        debounceTime(10),
        switchMap(v => zip(this.selectedResource$, this.openingHours$, this.reservations$).pipe(take(1))),
        switchMap(v => {
            const [selected, opens, reservations] = v
            const open = opens.find(o => o.resource_id === selected.id)
            let selectedReservations = reservations.filter(r => r.resource_id === selected.id)
            let res: ListEntry[] = []
            let inserted: number[] = []

            for (const x of open.hours) {
                let resv = selectedReservations.find(r => {
                    return compareDesc(r.begin, x.end) > 0
                        && compareAsc(r.end, x.begin) > 0
                })
                resv && inserted.push(resv.id)
                res.push(new ListEntry(selected, x.begin, x.end, resv))
            }

            if (inserted.length !== selectedReservations.length) {
                for (const resv of selectedReservations) {
                    if (inserted.indexOf(resv.id) === -1) {
                        res.push(new ListEntry(selected, resv.begin, resv.end, resv))
                    }
                }
                res.sort((a, b) => compareAsc(a.begin, b.begin))
            }

            return of(res)
        }),
        shareReplay(1)
    )

    public entries: ListEntry[] = []
    public resourceStat: { [key: number]: { total: number, reserved: number } } = {}

    public constructor(
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(CurrentSection) public readonly section: CurrentSection,
        @Inject(ResourceBackend) protected readonly resourceBackend: ResourceBackend,
        @Inject(ResourceReservationBackend) protected readonly resvBackend: ResourceReservationBackend,
        @Inject(ToastService) protected readonly toast: ToastService,
        @Inject(DutyService) protected readonly dutyService: DutyService,
        @Inject(ProviderServices) protected readonly providerServices: ProviderServices,
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService,
        @Inject(StackItemRef) @Optional() stackItem: StackItemRef,
        @Inject(DatePickerDayDataProvider) private readonly dpFreeProvider: DatePickedScheduleFreeCountDataProvider) {

        // this.service$.subscribe(v => console.log("service$", v))
        // this.resources$.subscribe(v => console.log("resources$", v))
        // this.selectedResource$.subscribe(v => console.log("resource$", v))
        // this.resourceOpen$.subscribe(v => console.log("resourceOpen$", v))
        // this.entries$.subscribe(v => console.log("entries$", v))
        // this.reservations$.subscribe(v => console.log("reservations$", v))

        this.destruct.subscription(this.entries$)
            .pipe(
                take(1),
                switchMap(_ => {
                    if (stackItem) {
                        return stackItem.activate
                    } else {
                        return EMPTY
                    }
                })
            )
            .subscribe(v => {
                this._reload.next()
            })
    }

    public ngOnInit() {
        this.date = this.dutyService.date
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    public reserve(entry: ListEntry) {
        let clientId = this.usage
            ? this.usage.client.id
            : entry.clientId.value
        if (clientId) {
            this.resourceBackend
                .reserve({
                    client_id: clientId,
                    resource_id: entry.resource.id,
                    begin: entry.begin,
                    end: entry.end
                })
                .pipe(
                    catchError(err => {
                        this.toast.error(err.message)
                        return NEVER
                    }),
                    switchMap(res => {
                        if (this.usage && isSameDay(this.dutyService.date, res.begin)) {
                            let pservice = this.providerServices.getProviderServiceById(entry.resource.provider_service_id)
                            return this.dutyService.setUsage(this.usage, pservice, true).pipe(mapTo(res))
                        } else {
                            return of(res)
                        }
                    })
                )
                .subscribe()
            this._reload.next()
        } else {
            this.reserving = entry
        }
    }

    public free(entry: ListEntry) {
        this.resourceBackend.free({ resource_reservation_id: entry.reservation.id })
            .pipe(
                catchError(err => {
                    this.toast.error(err.message)
                    return NEVER
                }),
                switchMap(res => {
                    if (this.usage && isSameDay(this.dutyService.date, res.begin)) {
                        return this.resvBackend.search({
                            filter: {
                                client_id: res.client_id,
                                date: this.dutyService.date,
                                "resource.provider_service_id": entry.resource.provider_service_id,
                            },
                            order: null,
                            begin: 0,
                            count: 1
                        })
                    } else {
                        return of([])
                    }
                }),
                switchMap(res => {
                    if (this.usage && (!res || res.length === 0)) {
                        let pservice = this.providerServices.getProviderServiceById(entry.resource.provider_service_id)
                        return this.dutyService.setUsage(this.usage, pservice, false)
                    } else {
                        return of(null)
                    }
                })
            )
            .subscribe()
        this._reload.next()
    }

    private _createOpeningHours(date: Date, open: ResourceOpen): OpeningHour[] {
        let res: OpeningHour[] = []
        let weekDayIdx = differenceInDays(startOfDay(date), startOfWeek(date, { weekStartsOn: 1 }))
        let weekDay = WeekDay.DATA.data.find(v => v.index === weekDayIdx)

        for (const openDay of open.days) {
            if (openDay.day === weekDay.id) {
                let openBegin = this._replaceTime(date, openDay.begin)
                let openEnd = this._replaceTime(date, openDay.end)
                while (openBegin < openEnd) {
                    let end = addMinutes(openBegin, openDay.interval)
                    res.push({ begin: openBegin, end: end })
                    openBegin = end
                }
            }
        }

        res.sort((a, b) => compareAsc(a.begin, b.begin))

        return res
    }

    private _replaceTime(date: Date, time: Time) {
        // let [hour, minute] = time.split(":")
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.hours, time.minutes)
    }

    public showClient(clientId: number) {
        this.clientSvc.showSheet(clientId).subscribe()
    }

    public print() {
        this.destruct.subscription(this.service$)
            .pipe(
                switchMap(service => {
                    const serviceIds = service.services.map(s => s.id).join(",")
                    const datef = format(this.date, "yyyy-MM-dd")
                    const url = `${BACKEND_URL}/stats/service_schedule/${serviceIds}/${datef}`
                    return this.downloader.download(url)
                }),
                this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Dokumentum generálása" })
            )
            .subscribe()
    }
}
