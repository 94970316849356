import { Directive, Input, Inject } from "@angular/core"
import { Observable } from "rxjs"

import { ToastService, FileDownloadService } from "@anzar/core"
import { Document, DocumentBackend } from "@backend/documents.api"
import { DocumentsService } from "./documents.service"
import { FsService } from "@pyzar/fs.module"


@Directive()
export abstract class DocumentItem {
    @Input() public readonly document: Document

    public constructor(
        @Inject(DocumentBackend) protected readonly documentBackend: DocumentBackend,
        @Inject(DocumentsService) protected readonly docSvc: DocumentsService,
        @Inject(FsService) protected readonly fsService: FsService,
        @Inject(ToastService) protected readonly toast: ToastService,
        @Inject(FileDownloadService) protected readonly downSvc: FileDownloadService) {
    }

    protected deleteDocument(): Observable<any> {
        return this.documentBackend.remove({ id: this.document.id })
    }

    public reload() {
        this.docSvc.reload()
    }

    public download() {
        this.downSvc
            .download(this.fsService.getDownloadUrl(this.document.file))
            .pipe(this.toast.handleFileDownload({ align: "bottom center" }))
            .subscribe()
    }
}
