import { Component, Inject, ChangeDetectorRef, OnInit } from "@angular/core"
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { Router, ActivatedRoute } from "@angular/router"
import { NEVER } from "rxjs"
import { catchError, switchMap, take } from "rxjs/operators"

import { ViewportService, ToastService } from "@anzar/core"
import { Auth, UserBackend } from "@backend/pyzar.api"
import { AuthService } from "../auth.service"
import { validateEqual } from "../../common.module"


@Component({
    templateUrl: "./registration.component.pug",
    providers: [
        ViewportService
    ]
})
export class RegistrationViewport implements OnInit {
    public readonly form = new FormGroup({
        phone: new FormControl(null),
        password: new FormControl(null, [Validators.required]),
        password_re: new FormControl(null, [Validators.required, validateEqual("password")])
    })

    public set isBusy(val: boolean) {
        if (this._isBusy !== val) {
            this._isBusy = val
            this.cdr.detectChanges()
        }
    }
    public get isBusy(): boolean { return this._isBusy }
    private _isBusy: boolean = true

    public set isOk(val: boolean) {
        if (this._isOk !== val) {
            this._isOk = val
        }
    }
    public get isOk(): boolean { return this._isOk }
    private _isOk: boolean = false

    public error: string

    public constructor(
        @Inject(Auth) protected auth: Auth,
        @Inject(AuthService) protected authSvc: AuthService,
        @Inject(Router) protected readonly router: Router,
        @Inject(ActivatedRoute) protected readonly route: ActivatedRoute,
        @Inject(ToastService) protected readonly toastSvc: ToastService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(UserBackend) private readonly userBackend: UserBackend) {

    }

    public ngOnInit() {
        this.isBusy = true
        this.route.params
            .pipe(
                switchMap(params => {
                    return this.auth.handle_token({ token: params["token"] })
                }),
                catchError(err => {
                    this.error = err.message
                    this.isBusy = false
                    this.isOk = false
                    return NEVER
                })
            )
            .subscribe(tokenType => {
                this.isBusy = false
                if (tokenType === "registration") {
                    this.isOk = true
                } else {
                    this.error = "Hibás kulcssal próbáltál meg regisztrálni"
                    this.isOk = false
                }
            })
    }

    public gotoLogin() {
        this.router.navigate(["/"])
    }

    public doChange() {
        const data = this.form.value
        data.email_is_valid = true

        this.isBusy = true
        this.authSvc.currentUser$
            .pipe(
                switchMap(user => {
                    data.id = user.id
                    return this.userBackend.save({ data })
                }),
                this.toastSvc.handleSave({ align: "bottom center", successMsg: "Sikeresen módosítottad az adataidat" }),
                take(1)
            )
            .subscribe(_ => {
                this.isBusy = false
                this.router.navigate(["/"])
            })
    }
}
