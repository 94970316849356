import { Component, Input, Inject, ChangeDetectorRef, Output, EventEmitter } from "@angular/core"
import { Subject, merge } from "rxjs"
import { shareReplay, switchMap, debounceTime } from "rxjs/operators"

import { Destructible } from "@anzar/core"

import { StoryEntryBackend, StoryEntry } from "@backend/dispatcher.api"
import { STORY_ENTRY_FIELDS, ActionEvent } from "./story-entry-card.component"


@Component({
    selector: ".rege-disp-story-entry-list",
    templateUrl: "./story-entry-list.component.pug"
})
export class StoryEntryListComponent extends Destructible {
    @Output() public readonly action = new EventEmitter<ActionEvent>()

    @Input() public embedded: boolean

    @Input()
    public set storyId(val: number) {
        if (this._storyId !== val) {
            this._storyId = val
            this._storyIdChange.next(val)
        }
    }
    public get storyId(): number { return this._storyId }
    private _storyId: number
    private _storyIdChange = this.destruct.subject(new Subject<number>())
    public readonly storyId$ = this._storyIdChange.pipe(shareReplay(1))

    @Input()
    public set entryIds(val: number[]) {

    }

    private readonly _reload = new Subject<void>()

    public readonly entries$ = merge(this._reload, this.storyId$).pipe(
        debounceTime(10),
        switchMap(() => this.storyId$),
        switchMap(storyId => {
            return this.storyEntryBackend.search({
                filter: { story_id: storyId },
                order: { call_time: "desc" }
            }, { loadFields: STORY_ENTRY_FIELDS })
        }),
        shareReplay(1)
    )

    public readonly entries: StoryEntry[]

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(StoryEntryBackend) private readonly storyEntryBackend: StoryEntryBackend) {
        super()

        this.destruct.subscription(this.entries$).subscribe(entries => {
            (this as { entries: StoryEntry[] }).entries = entries
            this.cdr.detectChanges()
        })
    }

    public reload() {
        this._reload.next()
    }
}
