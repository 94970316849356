import { Component, Inject, Input, OnInit, ChangeDetectorRef, forwardRef } from "@angular/core"

import { Provider } from "@backend/org.api"
import { ProviderEditorService } from "./provider-editor.service"
import { AbstractCLEditor, AbstractCLEditorService } from "@pyzar/common.module"
import { AuthService } from "@pyzar/auth.module"


@Component({
    selector: ".rege-provider-editor",
    templateUrl: "./provider-editor.component.pug",
    providers: [
        { provide: AbstractCLEditor, useExisting: forwardRef(() => ProviderEditorComponent) },
        { provide: AbstractCLEditorService, useClass: ProviderEditorService },
    ]
})
export class ProviderEditorComponent extends AbstractCLEditor<ProviderEditorService> {
    @Input()
    public set provider(val: Provider) {
        if (this._provider !== val) {
            this._provider = val
            this.editorSvc.providerId = val ? val.id : null
        }
    }
    public get provider(): Provider { return this._provider }
    private _provider: Provider

    public get isKenyszi() { return this.editorSvc.isKenyszi }
    public get editableServices() { return this.editorSvc.editableServices }

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: ProviderEditorService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(AuthService) public readonly auth: AuthService) {
        super(editorSvc)

        this.destruct.subscription(editorSvc.changes).subscribe(this.cdr.detectChanges.bind(this.cdr))
    }

    private __xx() {
        return this.editableServices.map(s => s.service.title).join(", ")
    }
}
