// disable patch requestAnimationFrame
(window as any).__Zone_disable_requestAnimationFrame = true;

// disable patch onProperty such as onclick
(window as any).__Zone_disable_on_property = true;

// disable patch specified eventNames
(window as any).__zone_symbol__UNPATCHED_EVENTS = [
    "scroll",
    "mousemove",
    "touchmove",
    "deviceready",
    "backbutton",
    "keyboardDidHide",
    "keyboardDidShow",
    "keyboardWillShow",
    "keyboardWillHide",
    "transitioncancel",
    "transitionend",
    "transitionrun",
    "transitionstart",
]
