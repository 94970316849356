import { Component, Inject, InjectionToken, Injectable } from "@angular/core"
import { take } from "rxjs/operators"

import { LayerRef, LayerService, ModalLayer, MediaQueryService, FullscreenLayer } from "@anzar/core"


export const MD_CONTENT = new InjectionToken("MD_CONTENT")


@Component({
    selector: "rege-md-popup",
    templateUrl: "./md-popup.component.pug"
})
export class MarkdownPopupComponent {
    public constructor(
        @Inject(MD_CONTENT) public readonly content: string,
        @Inject(LayerRef) private readonly layerRef: LayerRef) {
    }

    public close() {
        this.layerRef.hide()
    }
}



@Injectable()
export class MarkdownPopupService {
    public readonly isSm$ = this.mq.watch("lt-md")

    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(MediaQueryService) private readonly mq: MediaQueryService) {

    }

    public show(content: string) {
        this.isSm$.pipe(take(1)).subscribe(event => {
            const behavior = event.matches
                ? new FullscreenLayer({})
                : new ModalLayer({
                    backdrop: { type: "filled", hideOnClick: true },
                    elevation: 10,
                    rounded: 3,
                    position: {
                        constraint: {
                            ref: "viewport",
                            inset: 16
                        }
                    }
                })
            const ref = this.layerSvc.createFromComponent(MarkdownPopupComponent, behavior, null, [
                { provide: MD_CONTENT, useValue: content }
            ])
            ref.show()
        })

    }
}
