import { Component, InjectionToken, Inject } from "@angular/core"
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from "@angular/forms"
import { Router } from "@angular/router"
import { forkJoin, of } from "rxjs"
import { switchMap, mapTo, map } from "rxjs/operators"

import { LoadFields, LayerRef, Destructible, ToastService } from "@anzar/core"

import { ProviderBackendSource, ProviderBackend, Provider, SectionBackendSource, Section } from "@backend/org.api"


export const PROVIDER_ID = new InjectionToken("PROVIDER_ID")

const PROVIDER_FIELDS: LoadFields<Provider> = ["id", "extended_title"]
const SECTION_FIELDS: LoadFields<Section> = ["id", "title"]


function nameValidator(eqWith: string): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
        if (control.value === eqWith) {
            return null
        } else {
            return { notEq: true }
        }
    }
}


@Component({
    selector: "rege-provider-remove-dialog",
    templateUrl: "./provider-remove.component.pug"
})
export class ProviderRemoveComponent extends Destructible {
    public readonly form = new FormGroup({
        mode: new FormControl("delete", [Validators.required]),
        mergeIntoId: new FormControl(null, [Validators.required]),
        moveIntoId: new FormControl(null, [Validators.required]),
        confirmText: new FormControl(null, [Validators.required, nameValidator("\0")]),
    })

    public readonly providerFields = PROVIDER_FIELDS
    public readonly sectionFields = SECTION_FIELDS
    public busy: boolean = false

    public constructor(
        @Inject(PROVIDER_ID) private readonly providerId: number,
        @Inject(ProviderBackend) public readonly providerBackend: ProviderBackend,
        @Inject(ProviderBackendSource) public readonly providerSrc: ProviderBackendSource,
        @Inject(SectionBackendSource) public readonly sectionSrc: SectionBackendSource,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(Router) private readonly router: Router,
        @Inject(ToastService) private readonly toast: ToastService) {
        super()

        this.destruct.subscription(this.form.get("mode").valueChanges).subscribe(value => {
            const mergeIntoId = this.form.get("mergeIntoId")
            const moveIntoId = this.form.get("moveIntoId")

            switch (value) {
                case "delete":
                    mergeIntoId.clearValidators()
                    moveIntoId.clearValidators()
                    break

                case "move":
                    mergeIntoId.clearValidators()
                    moveIntoId.setValidators([Validators.required])
                    break

                case "merge":
                    mergeIntoId.setValidators([Validators.required])
                    moveIntoId.clearValidators()
                    break
            }
            mergeIntoId.updateValueAndValidity()
            moveIntoId.updateValueAndValidity()
        })

        this.destruct.subscription(this.providerBackend.get({ id: providerId })).subscribe(provider => {
            const confirm = this.form.get("confirmText")
            confirm.setValidators([Validators.required, nameValidator(provider.title)])
            confirm.updateValueAndValidity()
        })

    }

    public doAction() {
        const data = this.form.value
        let beginMessages: { [key: string]: string } = {
            merge: "Ellátási formák összevonása folyamatban",
            move: "Ellátási forma áthelyezése folyamatban",
            delete: "Ellátási forma törlése folyamatban"
        }

        this.providerBackend.get({ id: this.providerId })
            .pipe(
                switchMap(oldProvider => {
                    return this.providerBackend
                        .remove({
                            id: this.providerId,
                            action: data.mode,
                            merge_into_id: data.mergeIntoId,
                            move_into_id: data.moveIntoId
                        })
                        .pipe(
                            this.toast.handleSave({
                                align: "bottom center",
                                beginMsg: beginMessages[data.mode]
                            }),
                            mapTo(oldProvider)
                        )
                }),
                switchMap(oldProvider => {
                    switch (data.mode) {
                        case "move":
                            return this.providerBackend.get({ id: this.providerId })
                                .pipe(map(newProvider => [oldProvider, newProvider] as const))

                        case "merge":
                            return this.providerBackend.get({ id: data.mergeIntoId })
                                .pipe(map(newProvider => [oldProvider, newProvider] as const))

                        case "delete":
                            return of([oldProvider, null])

                    }
                })
            )
            .subscribe(([oldProvider, newProvider]) => {
                this.layerRef.hide()
                this.router.navigateByUrl("/", { skipLocationChange: true }).then(_ => {
                    console.log("XXX")
                    if (oldProvider && newProvider) {
                        console.log(["/org/reszleg/", newProvider.parent_id])
                        this.router.navigate(["/org/reszleg/", newProvider.parent_id], { replaceUrl: true })
                    } else {
                        console.log(["/org/reszleg/", oldProvider.parent_id])
                        this.router.navigate(["/org/reszleg/", oldProvider.parent_id], { replaceUrl: true })
                    }
                })
            })
    }

    public cancel() {
        this.layerRef.hide()
    }
}
