import { Component, ContentChild, TemplateRef, Input, Inject } from "@angular/core"
import { map } from "rxjs/operators"

import { MediaQueryService } from "@anzar/core"
import { OrgMark } from "@backend/mark.api"


@Component({
    selector: ".rege-mark-entry-row",
    host: {
        "[attr.expandable]": "extContent ? '' : null"
    },
    templateUrl: "mark-entry-row.component.pug"
})
export class MarkEntryRowComponent {
    @ContentChild("markEntryTitle", { static: true, read: TemplateRef }) public readonly title: TemplateRef<any>
    @ContentChild("markEntryContent", { static: true, read: TemplateRef }) public readonly content: TemplateRef<any>
    @ContentChild("markEntryExtContent", { static: true, read: TemplateRef }) public readonly extContent: TemplateRef<any>
    @ContentChild("markEntryButtons", { static: true, read: TemplateRef }) public readonly buttons: TemplateRef<any>

    @Input()
    public mark: OrgMark

    @Input()
    public expanded: boolean

    public readonly isSm$ = this.mq.watch("lt-sm").pipe(map(v => v.matches))

    public constructor(
        @Inject(MediaQueryService) private readonly mq: MediaQueryService) {
    }
}
