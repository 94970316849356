import { Component, Inject, ChangeDetectorRef, ViewChild } from "@angular/core"
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { distinctUntilChanged, startWith } from "rxjs/operators"

import { LoadFields, RICHTEXT_EDITABLE, LayerRef, RichtextComponentRef, SelectComponent, SelectionModel, SingleSelection } from "@anzar/core"
import { Section, SectionBackendSource } from "@backend/org.api"
import { AbstractForm } from "../abstract"


const SECTION_FIELDS: LoadFields<Section> = [
    "id", "title"
]


@Component({
    selector: "rege-rt-transport-form",
    templateUrl: "./transport-form.component.pug",
    providers: [{ provide: SelectionModel, useClass: SingleSelection }]
})
export class TransportFormComponent extends AbstractForm {
    @ViewChild("select", { static: true }) private readonly selectCmp: SelectComponent<Section>

    public readonly form = new FormGroup({
        section_id: new FormControl(null, [Validators.required]),
        section_title: new FormControl(),
        other: new FormControl(null, [Validators.required])
    })

    public constructor(
        @Inject(LayerRef) layerRef: LayerRef,
        @Inject(RichtextComponentRef) rtRef: RichtextComponentRef<AbstractForm>,
        @Inject(RICHTEXT_EDITABLE) rtEditable: boolean,
        @Inject(SectionBackendSource) public readonly sectionSrc: SectionBackendSource,
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef) {
        super(layerRef, rtRef, rtEditable)
    }

    public ngOnInit() {
        super.ngOnInit()

        if (this.rtEditable) {
            this.destruct.subscription(this.form.get("other").valueChanges)
                .pipe(startWith(null), distinctUntilChanged())
                .subscribe(value => {
                    if (value && value.length) {
                        this.updateRequired("other")
                    } else {
                        this.updateRequired("section_id")
                    }
                    this.cdr.detectChanges()
                })

            this.destruct.subscription(this.form.get("section_id").valueChanges)
                .pipe(startWith(null), distinctUntilChanged())
                .subscribe(value => {
                    if (value) {
                        this.form.get("section_title").setValue(this.selectCmp.selected[0].title)
                        this.updateRequired("section_id")
                    } else {
                        this.form.get("section_title").setValue(null)
                        this.updateRequired("other")
                    }
                    this.cdr.detectChanges()
                })
        }
    }

    protected updateRequired(required: "section_id" | "other") {
        const fields = ["section_id", "other"]

        for (const f of fields) {
            const ctrl = this.form.get(f)
            ctrl.clearValidators()
            if (f === required) {
                ctrl.setValidators([Validators.required])
            }
            ctrl.updateValueAndValidity()
        }
    }
}
