import { Inject, InjectionToken, Injectable, StaticProvider } from "@angular/core"
import { ComponentType } from "@angular/cdk/portal"
import { LayerRef, LayerService, ModalLayer } from "@anzar/core"

export const TASK_TITLE = new InjectionToken<string>("TASK_TITLE")
export const TASK_BUTTONS = new InjectionToken<string>("TASK_BUTTONS")


export interface ButtonOption {
    role: string
    label: string
    color?: string
    variant?: string
}


@Injectable({ providedIn: "root" })
export abstract class TaskDetailsFactory {
    public abstract readonly name: string
    public abstract readonly title: string
    public abstract readonly component: ComponentType<any>
    public readonly buttons: ButtonOption[]

    public constructor(@Inject(LayerService) private readonly layerSvc: LayerService) {
    }

    public create(providers: StaticProvider[] = []): LayerRef {
        const behavior = this.newBehavior()
        return this.layerSvc.createFromComponent(this.component, behavior, null, providers.concat([
            { provide: TASK_TITLE, useValue: this.title },
            { provide: TASK_BUTTONS, useValue: this.buttons },
        ]))
    }

    protected newBehavior() {
        return new ModalLayer({
            backdrop: { type: "filled", hideOnClick: false },
            closeable: false,
            trapFocus: true,
            rounded: 3,
            elevation: 10,
            maxWidth: 900,
            maxHeight: 500,
            position: {
                align: "top center",
                constraint: {
                    ref: "viewport",
                    inset: 16
                }
            }
        })
    }
}
