import { Component, Inject, Output, EventEmitter, OnDestroy, Input } from "@angular/core"
import { Observable } from "rxjs"

import { LoadFields } from "@anzar/core"
import { ClientBackendSource, Client } from "@backend/client.api"
import { CurrentSection } from "../../section.service/level"

import { ClientService } from "../client.service"
import { ClientWithAlertsSource } from "./data-source"


const CLIENT_FIELDS: LoadFields<Client> = [
    "id", "name", "social_insurance_id", "birth_date"
]


@Component({
    selector: ".rege-client-search",
    host: {
        "[style.width]": "'100%'"
    },
    templateUrl: "./client-search.component.pug",
    providers: [ClientWithAlertsSource]
})
export class ClientSearchComponent implements OnDestroy {
    public readonly loadFields = CLIENT_FIELDS

    @Input() public sectionId: number
    @Input() public workerId: number
    @Input() public usageDate: Date
    @Input() public reason: "search" | "select" = "select"
    @Input() public opened: boolean
    @Input() public markTypes: string[] | "all" = "all" // ["ban"]
    @Output() public readonly selectionChange: Observable<Client> = new EventEmitter()

    public constructor(
        @Inject(ClientWithAlertsSource) public readonly clientSrc: ClientWithAlertsSource,
        @Inject(CurrentSection) protected readonly section: CurrentSection,
        @Inject(ClientService) private readonly clientSvc: ClientService) {
    }

    public ngOnDestroy() {
        (this.selectionChange as EventEmitter<any>).complete()
    }

    public onSelectClient(client: Client) {
        (this.selectionChange as EventEmitter<Client>).emit(client)
    }

    public onDetailedSearch() {
        this.clientSvc.createClient(this.reason === "select").subscribe(client => {
            if (client) {
                (this.selectionChange as EventEmitter<Client>).emit(client)
            }
        })
    }

    public showClientSheet(event: Event, client: Client) {
        event.preventDefault()
        this.clientSvc.showSheet(client.id).subscribe()
    }
}
