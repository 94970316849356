import { Component, Input, forwardRef, Inject, ChangeDetectorRef } from "@angular/core"

import { Institution } from "@backend/org.api"
import { AbstractCLEditor, AbstractCLEditorService } from "@pyzar/common.module"
import { AuthService } from "@pyzar/auth.module"
import { InstitutionEditorService } from "./institution-editor.service"


@Component({
    selector: ".rege-institution-editor",
    templateUrl: "./institution-editor.component.pug",
    providers: [
        { provide: AbstractCLEditor, useExisting: forwardRef(() => InstitutionEditorComponent) }
    ]
})
export class InstitutionEditorComponent extends AbstractCLEditor {
    @Input() public institution: Institution

    public constructor(
        @Inject(AbstractCLEditorService) service: InstitutionEditorService,
        @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
        @Inject(AuthService) public readonly auth: AuthService) {
        super(service)

        this.destruct.subscription(service.institution$).subscribe(institution => {
            this.institution = institution
            this.cdr.detectChanges()
        })
    }
}
