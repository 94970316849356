import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzModule } from "@anzar/core"

import { PyzarApi } from "@backend/pyzar.api"

import { TaskService, TaskProgressEvent } from "./task.service"
export { TaskService, TaskProgressEvent }

import { TaskDetailsFactory, ButtonOption } from "./task-details-factory"
export { TaskDetailsFactory, ButtonOption }

import { TaskLogComponent } from "./task-log.component"
import { TaskDetailsComponent, DefaultTaskDetailsComponent, DefaultTaskDetailsFactory } from "./task-details.component"
import { TaskTitleComponent } from "./task-title.component"
export { DefaultTaskDetailsComponent, TaskDetailsComponent }


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        PyzarApi
    ],
    providers: [
        TaskService,
        { provide: TaskDetailsFactory, useClass: DefaultTaskDetailsFactory, multi: true }
    ],
    declarations: [
        TaskLogComponent,
        TaskTitleComponent,
        DefaultTaskDetailsComponent
    ],
    exports: [
        TaskLogComponent,
        TaskTitleComponent,
        DefaultTaskDetailsComponent
    ],
    entryComponents: [
        DefaultTaskDetailsComponent
    ]
})
export class PyzarTaskModule {

}
